import styled from "styled-components";
import {Link} from "react-router-dom";
import React from "react";

const ButtonSTS = styled(Link)`
  display: flex;
  color: #191B21;
  background: #EFB608;
  border: 1px solid #EFB608;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  width: 199px;
  height: 60px;
  align-self: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  justify-self: center;
  //white-space: nowrap;
  
  &:hover {
    background-color: #FCD535;
    color: #191B21;

  }
    `;

interface ButtonSTProps {
  href: string;
  buttonText: string;
  style?: React.CSSProperties;
}
const ButtonSTL: React.FC<ButtonSTProps> = ({href,buttonText,style}) => {
  return(
      <ButtonSTS to={href} style={style}>{buttonText}</ButtonSTS>
  );
};
export default ButtonSTL;