import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import AdminP2PFakesTable from "../../styles/tables/forAdmin/AdminP2PFakesTable";
import AddNewFake from "./AddNewFake";
import { useGetP2PFakeListQuery } from "../../redux/apiService";

const AdminP2PFakes: React.FC = () => {
  const {
    data: fakeP2PList,
    error,
    isLoading,
    refetch,
  } = useGetP2PFakeListQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading fakeP2PList: {error.toString()}</div>;
  }
  console.log("fakeP2PList data:", fakeP2PList);

  const testData = [
    {
      id: 38,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: "Casa Roble De Roca",
      orders: "216 orders, 88%",
      limit: "50 - 18 850,00 USD",
      paymentMethod: "Advcash",
    },
    {
      id: 39,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: "John Doe",
      orders: "150 orders, 75%",
      limit: "30 - 9 450,00 USD",
      paymentMethod: "PayPal",
    },
    {
      id: 40,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: "Jane Smith",
      orders: "200 orders, 95%",
      limit: "60 - 27 000,00 USD",
      paymentMethod: "Bitcoin",
    },
    {
      id: 41,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: "Mike Johnson",
      orders: "180 orders, 80%",
      limit: "40 - 13 800,00 USD",
      paymentMethod: "Skrill",
    },
    {
      id: 42,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: "Emily Davis",
      orders: "250 orders, 99%",
      limit: "70 - 33 750,00 USD",
      paymentMethod: "Neteller",
    },
  ];

  const formattedData =
    fakeP2PList?.body.data.map((p2pFake: any) => ({
      id: "42",
      id_true: p2pFake.id,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: p2pFake.username,
      orders: p2pFake.orders,
      limit: p2pFake.limit,
      paymentMethod: p2pFake.payment_method,
    })) || testData;

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock>P2P Fake List</HeadBlock>
      <AddNewFake refetch={refetch} />
      <AdminP2PFakesTable data={formattedData} refetch={refetch} />
    </ContainerV>
  );
};
export default AdminP2PFakes;
