import React from "react";
import IntroPart from "../components/forMainPage/IntroPart";
import UnionTrade from "../components/forMainPage/UnionTrade";
import ExpTraders from "../components/forMainPage/ExpTraders";
import OutroPart from "../components/forMainPage/OutroPart";

const MainPage: React.FC = () => {
  return <>
      <div className="main-part">
      <IntroPart/>
      <UnionTrade/>
      <ExpTraders/>
      <OutroPart/>
      </div>
  </>;
};
export default MainPage;