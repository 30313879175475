import React from "react";
import styled from "styled-components";
import DeleteButton from "../../functional/buttons/DeleteButton";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
    min-width: 100%;
  
`;


const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  //min-width: 10rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3,2fr) 1fr; 
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  //max-width: 9rem;
`;


const Title = styled.div`
  color: white;
  margin-bottom: 2rem;
  align-self: flex-start;
  font-size: 20px;
`;

interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const WorkerAddedCoinsTable:React.FC<TableProps> = ({ data }) => {

    return (
        <BorderBlock>
            <Title>
                Table of added coins
            </Title>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Coin</TableHeader>
                    <TableHeader>Amount</TableHeader>
                    <TableHeader>Action</TableHeader>

                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                            <img src={item.imagePath} alt={item.coin} style={{marginRight:"0.5rem"}}/>
                            {item.coin}
                        </TableCell>
                        <TableCell>
                            {item.amount}
                        </TableCell>
                        <TableCell>
                            <DeleteButton/>
                        </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default WorkerAddedCoinsTable;