import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";

const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
  //gap: 3rem;
`;

const TitleMiddle = styled.div`
  color: #848E9C;
  margin-bottom: 20px;
  align-self: flex-start; 
  font-size: 14px;
  text-align: left;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start; 
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 7rem;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #FFFF66;
  }
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;




const SupportMessagePresets:React.FC = () => {
    // Step 1: Define the switchStates state
    const [switchState, setSwitchState] = useState(false);

    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Toggle the switch state at the given index
        setSwitchState(event.target.checked);
        console.log("Switch state changed to:", event.target.checked);
    };

    return (
      <BorderBlock style={{paddingBottom:"2rem"}}>
          <ContainerH style={{margin:"0", width:"100%"}}>
              <div>
                  <TitleMiddle>
                      Стандартные шаблоны ответов пользователям в саппорте.
                  </TitleMiddle>
                  <TitleMiddle style={{margin:"0"}}>
                      <strong>Включение/Отключение</strong> отображения шаблонов сообщений для ответа в саппорте.
                  </TitleMiddle>
                  <TitleMiddle>
                      Если включено, то в саппорте будут отображаться шаблоны для ответов на сообщения пользователей.
                  </TitleMiddle>
              </div>
              <div>
                  <Switch id="switch" checked={switchState} onChange={handleSwitchChange} />
                  <SwitchLabel htmlFor="switch"></SwitchLabel>
              </div>
          </ContainerH>
        <BorderBlock >
            <TitleLittle>Title</TitleLittle>
            <CustomInput type="message" placeholder="Приветственное сообщение" />
        </BorderBlock>

        <BorderBlock >
            <TitleLittle>Message</TitleLittle>
            <CustomInput type="message" placeholder="Hello, how i can help you?" />
        </BorderBlock>

          <TitleMiddle>
              {'{domain_url}'} - будет заменяться на домен сайта пользователя, которому отправляется сообщение.
          </TitleMiddle>
        <SubmitButton>Add</SubmitButton>
      </BorderBlock>
  );
};
export default SupportMessagePresets;