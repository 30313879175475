import React, { useState } from "react";
import styled from "styled-components";
import ContainerH from "../../../containers/ContainerH";
import HeaderFAL from "../../../headers/HeaderFAL";
import { GreenButton } from "../../../functional/buttons/RedGreenButtons";
import UserBalanceNav from "../../../navs/UserBalanceNav";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 3rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.25fr 0.25fr 1fr 0.25fr;
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  width: 100%;

  &:hover {
    background-color: #1e2329;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  display: flex;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
`;

const CoinImage = styled.img`
  margin-right: 0.5rem;
`;

const CustomInput = styled.input`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 0.25rem;
  color: white;
  padding: 0.5rem;
  min-width: 100%;
  justify-self: flex-start;
  resize: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const UserBalanceViewTable: React.FC<TableProps> = ({ data }) => {
  const [userBalances, setUserBalances] = useState(
    data.map((item) => item.balance),
  );

  const handleChangeUserBalance = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedBalances = [...userBalances];
    updatedBalances[index] = event.target.value;
    setUserBalances(updatedBalances);
  };

  return (
    <>
      <BorderBlock>
        <ContainerH
          style={{
            margin: "0",
            marginTop: "1rem",
            marginBottom: "1rem",
            //justifyContent: "flex-end",
          }}
        >
          <HeaderFAL style={{ fontSize: "32px" }}>Balance</HeaderFAL>
          <UserBalanceNav initialActiveButton={1} />
        </ContainerH>
        <table>
          <thead>
            <TableRow style={{ borderBottom: "none" }}>
              <TableHeader>ID</TableHeader>
              <TableHeader>Coin</TableHeader>
              <TableHeader>Balance</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  <CoinImage src={item.imagePath} />
                  {item.coin}
                </TableCell>
                <TableCell>
                  <CustomInput
                    type="message"
                    value={userBalances[index]}
                    onChange={(event) => handleChangeUserBalance(event, index)}
                  />
                </TableCell>
                <TableCell>
                  <GreenButton>Save</GreenButton>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </table>
      </BorderBlock>
    </>
  );
};
export default UserBalanceViewTable;
