import React from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import {TERMS} from "../../constants/constants";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    align-self: flex-start;
    min-width: 100%;
`;

const SaveButton = styled.button`
    background-color: #FCD535;
    color: #191B21;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    height: 2.5rem;
    width: 10rem;
    margin-top: 2rem;
    align-self: flex-end;
  
  &:hover{
    background-color: #FFFF66;
  }
`;

const TextArea = styled.textarea`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 35rem;
  color: white;
`;
const WorkerEditTerms:React.FC = () => {
  return (
      <ContainerV style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding:"0 5rem 0 5rem",
      }}>
          <HeadBlock >Edit Terms</HeadBlock>
          <BorderBlock>
              <TextArea defaultValue={TERMS}></TextArea>
              <BlockGreyText style={{fontSize:"14px"}}>
                  {"{domain_url} - заменяется на домен сайта, на котором находится пользователь. {domain_name} - заменяется на название биржи, указаное в настройках домена."}
              </BlockGreyText>
              <SaveButton>Save changes</SaveButton>
          </BorderBlock>
      </ContainerV>
  );
};
export default WorkerEditTerms;