import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IndexOnlineButton from "../../functional/buttons/IndexOnlineButton";
import { IPButton } from "../forWorker/WorkerLogListTable";
import {
  UsersManageGoldButton,
  UsersManageGreenButton,
} from "../../functional/buttons/UsersManageButtons";
import ContainerH from "../../containers/ContainerH";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUser } from "../../../redux/slices/selectedUserSlice";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
  max-width: 7rem;
  min-width: 3rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1.75fr repeat(9, 1fr);
  gap: 0.6rem;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 7rem;
  min-width: 3rem;
`;

const AvatarImg = styled.img`
  height: 62px;
  width: 62px;
  padding-right: 1rem;
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

const SearchInput = styled.input.attrs({ type: "text" })`
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
  align-self: center;
  color: #848e9c;
`;

const SearchIcon = styled.img`
  height: 15.8px;
  width: 15.42px;
  margin-right: 1rem;
  align-self: center;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const AdminUsersTable: React.FC<TableProps> = ({ data }) => {
  // Step 1: Define the switchStates state
  const [switchState, setSwitchState] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelectUser = (id: string) => {
    dispatch(setSelectedUser(id));
    console.log("Name was clicked");
    console.log("id", id);
    navigate("/user-profile");
  };

  // Step 2: Implement the handleSwitchChange function
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the switch state at the given index
    setSwitchState(event.target.checked);
    console.log("Switch state changed to:", event.target.checked);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = !switchState
    ? data
    : data.filter((item) => item.status === "Online");
  const searchedData = searchTerm
    ? data.filter((item) =>
        item.username.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : filteredData;

  const selectedUserId = useSelector(
    (state: RootState) => state.selectedUser.id,
  );

  useEffect(() => {
    console.log("Выбранный selectedUserId:", selectedUserId);
  }, [selectedUserId]);

  useEffect(() => {
    console.log("searchedData", searchedData);
  }, [searchedData]);

  return (
    <BorderBlock>
      <BorderBlock
        style={{
          width: "33%",
          padding: "1rem",
          minWidth: "0",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <ContainerH
          style={{
            margin: "0",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon
            src="/assets/images/forpages/adminpanel/ZoomOut.svg"
            alt="ZoomOut"
          />
          <SearchInput
            placeholder="Поиск"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </ContainerH>
      </BorderBlock>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "5rem",
        }}
      >
        <Switch
          id="switch"
          checked={switchState}
          onChange={handleSwitchChange}
        />
        <SwitchLabel htmlFor="switch"></SwitchLabel>
      </div>
      <table>
        <thead>
          <TableRow style={{ borderBottom: "none" }}>
            <TableHeader>User</TableHeader>
            <TableHeader>Username</TableHeader>
            <TableHeader>Worker</TableHeader>
            <TableHeader>Domain</TableHeader>
            <TableHeader>Deposits</TableHeader>
            <TableHeader>Registered</TableHeader>
            <TableHeader>Location</TableHeader>
            <TableHeader>IP</TableHeader>
            <TableHeader>Last activity</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {searchedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <ContainerH style={{ margin: "0" }}>
                  {/*<AvatarImg src={item.imagePath} alt="Avatar" />*/}
                  <AvatarImg
                    src="/assets/images/forpages/adminpanel/default-avatar 1.svg"
                    alt="Avatar"
                  />
                  <div>
                    {item.email}
                    <IndexOnlineButton text={item.status} />
                  </div>
                </ContainerH>
              </TableCell>
              <TableCell
                onClick={() => {
                  console.log("item", item);
                  handleSelectUser(item.id);
                }}
                style={{ cursor: "pointer", color: "#fcd535" }}
              >
                {item.username}
              </TableCell>
              <TableCell>{item.worker}</TableCell>
              <TableCell>{item.domain}</TableCell>
              <TableCell>{item.deposits}</TableCell>
              <TableCell>{item.registered}</TableCell>
              <TableCell>
                {/*{item.country},{item.city}*/}
                {item.location}
                <br />
                <img
                  src="/assets/images/forpages/adminpanel/ru 1.svg"
                  alt="Location"
                />
              </TableCell>
              <TableCell>
                <IPButton>198.51.100.1</IPButton>
              </TableCell>
              <TableCell>{item.lastActivity}</TableCell>
              <TableCell style={{ width: "7rem" }}>
                <UsersManageGreenButton>Support dialog</UsersManageGreenButton>
                <UsersManageGoldButton style={{ marginTop: "1rem" }}>
                  View
                </UsersManageGoldButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </BorderBlock>
  );
};

export default AdminUsersTable;
