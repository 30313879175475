export enum AppRoutes {
  // AUTH PART //
  authBasePage = "/auth-page",
  authCreatAccountPage = "/auth-page/create-account",
  authVerificatePage = "/auth-page/verificate",
  authLoginPage = "/auth-page/login",
  // ADMIN PART //
  adminBasePage = "/admin",
  adminStaticsPage = "/admin/statics",

  //     <Route path="/admin" element={<AdminPanel />}>
  // <Route index element={<AdminStatics />} />
  // <Route path="statics" element={<AdminStatics />} />
  // <Route path="deposits" element={<AdminDeposits />} />
  // <Route path="wallet-connect" element={<AdminWalletConnect />} />
  // <Route path="logs" element={<AdminLogList />} />
  // <Route path="P2P-fakes" element={<AdminP2PFakes />} />
  // <Route path="workers" element={<AdminWorkerList />} />
  // <Route path="domains" element={<AdminDomains />} />
  // <Route path="users" element={<AdminUsers />} />
  // <Route path="support" element={<AdminSupport />} />
  // <Route path="payments" element={<AdminDepositSettings />} />
  // <Route path="coins" element={<AdminDepositCoins />} />
  // <Route path="telegram" element={<AdminTelegramSettings />} />
  // <Route
  // path="support-presents"
  // element={<AdminSupportMessagePresets />}
  // />
  // <Route path="terms" element={<AdminEditTerms />} />
  // <Route
  // path="aml-kyc-policy"
  // element={<AdminEditAmlKycPolicy />}
  // />
  // <Route path="settings" element={<AdminDefaultSettings />} />
  // </Route>
  userProfilePage = "/user-profile",
  userProfileOverviewPage = "/user-profile/overview",
  userProfileLogsPage = "/user-profile/logs",
  userProfileErrorMessagesPage = "/user-profile/error-messages",
  userProfileTransactionPage = "/user-profile/transactions",
  userProfileTransactionBlockPage = "/user-profile/transactions/transactions",
  userProfileStakingBlockPage = "/user-profile/transactions/staking",
  userProfileAddressesPage = "/user-profile/addresses",
  userProfileUserBalancePage = "/user-profile/balance",
  userProfileUserBalanceViewBlockPage = "/user-profile/balance/view",
  userProfileUserBalanceSendBlockPage = "/user-profile/balance/send",
  userProfileUserAlertPopUp = "/user-profile/alert-pop-up",
  userProfileUserBonusPopUp = "/user-profile/bonus-pop-up",
  userProfileUserWithdrawVerify = "/user-profile/withdraw-verify",
}
