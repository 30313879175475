import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import MarketViewCard from "../../styles/cards/MarketViewCard";
import ReviewDataButtons from "../../styles/functional/buttons/ReviewDataButtons";
import MarketPageNav from "../../styles/navs/MarketPageNav";
import ButtonSRC from "../../styles/functional/buttons/ButtonSRC";


const MarketHeaderView: React.FC = () => {
    const header = "Популярные монеты";
    const img_adress = "/assets/images/cryptoicons/Bitcoin Coin.svg";
    const value = "₽27,68K";
    const percents = "+1.69%";
    const linkText = "Подробнее";
    const name = "BTC";
  return <>
      <ContainerV style={{marginTop:"3rem"}}>
          <ContainerH style={{justifyContent: "flex-start"}}>
              <ReviewDataButtons/>
          </ContainerH>
          <ContainerH style={{gap:"10px"}}>
            <MarketViewCard header={header} img_adress={img_adress} name={name} linkText={linkText} value={value} percents={percents}/>
             <MarketViewCard header={header} img_adress={img_adress} name={name} linkText={linkText} value={value} percents={percents}/>
             <MarketViewCard header={header} img_adress={img_adress} name={name} linkText={linkText} value={value} percents={percents}/>
             <MarketViewCard header={header} img_adress={img_adress} name={name} linkText={linkText} value={value} percents={percents}/>
          </ContainerH>
          <ContainerH style={{marginBottom:"2rem"}}>
              <MarketPageNav initialActiveButton={1}/>
              <ButtonSRC/>
          </ContainerH>
      </ContainerV>
  </>;
};
export default MarketHeaderView;