import React from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderACS from "../../styles/headers/HeaderACS";
import HeaderGreyCenter from "../../styles/headers/HeaderGreyCenter";
import ConvertTable from "../../styles/tables/ConvertTable";

const PopularConvertation: React.FC = () => {
  return (
      <BasicDiv>
        <ContainerV>
            <HeaderACS>Популярные конвертации Bitcoin</HeaderACS>
            <HeaderGreyCenter>Подборка других популярных конвертаций Bitcoin в различные фиатные валюты.</HeaderGreyCenter>
            <ConvertTable/>
        </ContainerV>
      </BasicDiv>
  );
};
export default PopularConvertation;