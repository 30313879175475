import React from "react";
import BasicDiv from "../styles/blosks/BasicDiv";
import ContainerV from "../styles/containers/ContainerV";
import P2PTable from "../styles/tables/P2PTable";
import { useGetP2PFakeListQuery } from "../redux/apiService";

const P2PPage: React.FC = () => {
  const { data: P2PList, error, isLoading, refetch } = useGetP2PFakeListQuery();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading P2PList: {error.toString()}</div>;
  }
  console.log("P2PList data:", P2PList);

  const formattedData =
    P2PList?.body.data.map((p2p: any) => ({
      id: p2p.id,
      imagePath: "/assets/images/forpages/accountpage/Trader_avatar.svg",
      username: p2p.username,
      orders: p2p.orders,
      limits: p2p.limit,
      paymentMethod: p2p.payment_method,
      price: p2p.price || "66178.94 USD",
    })) || [];
  return (
    <BasicDiv>
      <ContainerV>
        <P2PTable data={formattedData} />
      </ContainerV>
    </BasicDiv>
  );
};
export default P2PPage;
