import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../constants/routing";
import App from "../../App";

const CustomBlockContainer = styled.div`
  display: flex;
  //gap: 1rem;
  align-items: center;
  justify-content: space-between;
  justify-self: center;
  align-self: center;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 3rem;
  //padding-left: 5rem;
`;

const CustomButton = styled(Link)<{ isActive: boolean }>`
  position: relative;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#848E9C")};
  text-decoration: none;

  &:hover {
    //color: ${({ isActive }) => (isActive ? "#848E9C" : "#EFB608")};
    color: #efb608;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ isActive }) =>
      isActive ? "#EFB608" : "transparent"};
  }
`;

interface AccountPageNavProps {
  initialActiveButton: number;
}

const UserProfileNav: React.FC<AccountPageNavProps> = ({
  initialActiveButton,
}) => {
  // По умолчанию activeButton равна 1
  const [activeButton, setActiveButton] = useState(initialActiveButton);

  const handleButtonClick = (buttonNumber: number) => {
    setActiveButton(buttonNumber);
  };

  return (
    <CustomBlockContainer>
      <CustomButton
        to={AppRoutes.userProfileOverviewPage}
        isActive={activeButton === 1}
        onClick={() => handleButtonClick(1)}
      >
        Overview
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileLogsPage}
        isActive={activeButton === 2}
        onClick={() => handleButtonClick(2)}
      >
        Logs
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileErrorMessagesPage}
        isActive={activeButton === 3}
        onClick={() => handleButtonClick(3)}
      >
        Error messages
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileTransactionPage}
        isActive={activeButton === 4}
        onClick={() => handleButtonClick(4)}
      >
        Transactions
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileAddressesPage}
        isActive={activeButton === 5}
        onClick={() => handleButtonClick(5)}
      >
        Addresses
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileUserBalancePage}
        isActive={activeButton === 6}
        onClick={() => handleButtonClick(6)}
      >
        Balance
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileUserAlertPopUp}
        isActive={activeButton === 7}
        onClick={() => handleButtonClick(7)}
      >
        Alert Pop-Up
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileUserBonusPopUp}
        isActive={activeButton === 8}
        onClick={() => handleButtonClick(8)}
      >
        Bonus Pop-Up
      </CustomButton>
      <CustomButton
        to={AppRoutes.userProfileUserWithdrawVerify}
        isActive={activeButton === 9}
        onClick={() => handleButtonClick(9)}
      >
        Withdraw verify
      </CustomButton>
      <CustomButton
        to="/user-profile/kyc-request"
        isActive={activeButton === 10}
        onClick={() => handleButtonClick(10)}
      >
        KYC Request
      </CustomButton>
    </CustomBlockContainer>
  );
};

export default UserProfileNav;
