import styled from "styled-components";

const ContainerTC = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #1E2329;
    border-radius: 24px;
    margin-top: 1rem;
    max-width: 100%;
    //margin-left: 9rem;
    padding: 1rem;
    
    `;
export default ContainerTC;