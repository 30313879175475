import React from "react";
import BasicDiv from "../styles/blosks/BasicDiv";
import AuthHeader from "../components/forAuthPages/AuthHeader";
import {Outlet} from "react-router-dom";


const AuthPage: React.FC = () => {

  return <>
      <AuthHeader/>
      <BasicDiv>
        <Outlet/>
      </BasicDiv>
  </>;
};
export default AuthPage;