import {SET_EMAIL, SET_TOKENS, SET_USER_PROFILE} from "./constants";
import {ProfileData} from "./reducers/simpleUserReducer";


export const setEmail = (email: string) => ({
    type: SET_EMAIL,
    payload: email,
});

interface Tokens {
    refresh: string;
    access: string;
}
export const setTokens = (tokens: Tokens) => ({
    type: SET_TOKENS,
    payload: tokens,
});

export const setUserProfile = (profileData: ProfileData) => ({
    type: SET_USER_PROFILE,
    payload: profileData,
});

