import React, { useState } from "react";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import ButtonST from "../../styles/functional/buttons/ButtonST";
import { useNavigate } from "react-router-dom";
import ContainerH from "../../styles/containers/ContainerH";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import AuthSmollLink from "../../styles/functional/links/AuthSmollLink";
import CustomInput from "../../styles/functional/inputs/CustomInput";
import TermsAndConditions from "../../styles/functional/AuthCheckBox";
import { useRegisterUserMutation } from "../../redux/apiService";
import { useDispatch } from "react-redux";
import { setEmail } from "../../redux/actions";

const CreateAccount: React.FC = () => {
    const buttonText = "Дальше";
    const dispatch = useDispatch();
    const verificate = "/auth-page/verificate";
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirmation_password: "",
    });

    const [registerUser, { isLoading, isError }] = useRegisterUserMutation();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleButtonClick = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('username', formData.username);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('password', formData.password);
            formDataToSend.append('confirmation_password', formData.confirmation_password);

            const response = await registerUser(formDataToSend).unwrap();
            console.log('Успешно зарегистрированы:', response);
            dispatch(setEmail(formData.email));
            navigate(verificate);
        } catch (error) {
            console.error('Ошибка регистрации:', error);
        }
    };

    return (
        <>
            <ContainerH>
                <ContainerV>
                    <HeaderALTH>Создание личного<br /> аккаунта</HeaderALTH>
                    <CustomInput
                        placeholder="Введите имя"
                        style={{
                            alignSelf: "flex-start",
                            width: "410px",
                            height: "60px",
                        }}
                        onChange={handleInputChange}
                        name="username"
                    />
                    <CustomInput
                        type="email"
                        name="email"
                        placeholder="Эл.почта / номер телефона"
                        style={{
                            alignSelf: "flex-start",
                            width: "410px",
                            height: "60px",
                        }}
                        onChange={handleInputChange}
                    />
                    <CustomInput
                        type="password"
                        placeholder="Пароль"
                        style={{
                            alignSelf: "flex-start",
                            width: "410px",
                            height: "60px",
                        }}
                        onChange={handleInputChange}
                        name="password"
                    />
                    <CustomInput
                        type="password"
                        placeholder="Повторите пароль"
                        style={{
                            alignSelf: "flex-start",
                            width: "410px",
                            height: "60px",
                        }}
                        onChange={handleInputChange}
                        name="confirmation_password"
                    />
                    <TermsAndConditions />
                    <ButtonST
                        style={{
                            width: "410px",
                            height: "60px",
                            alignSelf: "flex-start",
                        }}
                        onClick={handleButtonClick}
                        disabled={isLoading}
                    >
                        {buttonText}
                    </ButtonST>
                    {isError && <p style={{ color: 'red' }}>Ошибка регистрации</p>}
                    <ContainerH style={{ justifyContent: "flex-start" }}>
                        <BlockGreyText style={{ whiteSpace: "nowrap" }}>
                            Не нужен личный аккаунт?
                            <AuthSmollLink
                                to={verificate}
                                style={{ whiteSpace: "wrap" }}
                            >
                                Зарегистрируйте корпоративный аккаунт
                            </AuthSmollLink>
                        </BlockGreyText>
                    </ContainerH>
                </ContainerV>
                <ContainerV>
                    <img
                        src="/assets/images/forpages/auth/two-brokers.svg"
                        alt="meet"
                    />
                </ContainerV>
            </ContainerH>
        </>
    );
};

export default CreateAccount;
