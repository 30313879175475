import React, {useState} from "react";
import BasicDiv from "../styles/blosks/BasicDiv";
import ContainerV from "../styles/containers/ContainerV";
import ContainerH from "../styles/containers/ContainerH";
import TopLongBlock from "../components/forSpotTraidingPage/TopLongBlock";


const SpotTradingPage:React.FC = () => {


    const testData = [
        {
            sum:"≈$67959.99",
            percents:"-1.221%",
            high:70250.0,
            low:67486.65,
            volume:3192,
            amount:218442120,
        }
    ]
  return <>
      <BasicDiv>
          <ContainerV>
              <ContainerH>
                    <TopLongBlock data={testData}/>
              </ContainerH>
          </ContainerV>
      </BasicDiv>
  </>;
};
export default SpotTradingPage;