import styled from "styled-components";

const HeaderALTH = styled.div`
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    display: flex;
    align-items: flex-start;
    `;


export default HeaderALTH;