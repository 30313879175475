import axios from 'axios';
import {Dispatch} from "redux";
import {setTokens, setUserProfile} from "../redux/actions";



// Базовый урл для пользователя
//const userApi = axios.create({
//    baseURL: 'http://83.136.30.248:3044/api/v1/profiles/user/',
//});

const userApi = axios.create({
    baseURL: 'https://back.sdfsdfsdfsdfsfsfsghsdgw.ru/api/v1/profiles/user/',
});

// Регистрация пользователя
export const registerUser = async (formData: FormData) => {
    try {
        const response = await userApi.post('registration/', formData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': 'CSRF_token', // Наш CSRF-токен
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as import('axios').AxiosError;
            if (axiosError.response) {
                console.error('Ошибка регистрации:', axiosError.response.data);
            } else {
                console.error('Ошибка Axios:', axiosError.message);
            }
        } else {
            console.error('Неизвестная ошибка:', error);
        }
        throw error;
    }
};

// Подтверждение регистрации
export const confirmUser = async (token: string) => {
    try {
        const response = await userApi.post('confirm/', { token }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': 'oN75FqYZEz3nVOWxA5aZgwDHaJpDkIp9hOduilbYwGKmiwpvw4V0QNnboZKEuc3n',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as import('axios').AxiosError;
            if (axiosError.response) {
                console.error('Ошибка подтверждения:', axiosError.response.data);
            } else {
                console.error('Ошибка Axios:', axiosError.message);
            }
        } else {
            console.error('Неизвестная ошибка:', error);
        }
        throw error;
    }
};

// Авторизация
const tokensApi = axios.create({
    baseURL: 'https://back.sdfsdfsdfsdfsfsfsghsdgw.ru/api/v1/profiles/',
});

export const getTokens = (formData: FormData) => {
    return async (dispatch: Dispatch) => {
    try {
        const response = await tokensApi.post('token/', formData, {
            headers: {
                'Content-Type': 'application/json',
                    'X-CSRFToken': 'CSRF_token',
            },
        });
        const tokens = response.data.body;
        dispatch(setTokens(tokens)); // Диспатчим действие для установки токенов в хранилище
        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as import('axios').AxiosError;
            if (axiosError.response) {
                // Ошибка с сервера
                const responseData = axiosError.response.data;
                console.error('Ошибка от сервера:', responseData);
                return responseData; // Возвращаем объект с ошибкой для обработки в компоненте
            } else {
                // Другая ошибка Axios
                console.error('Ошибка Axios:', axiosError.message);
                throw axiosError;
            }
        } else {
            // Другая неизвестная ошибка
            console.error('Неизвестная ошибка:', error);
            throw error;
        }
    }
    };
};

// Получаем Refresh Token при устаревании access
export const refreshToken = async (refreshToken: string) => {
    try {
        // Отправка POST запроса с токеном обновления
        const response = await axios.post(
            'https://back.sdfsdfsdfsdfsfsfsghsdgw.ru/api/v1/profiles/token/refresh/',
            { refresh: refreshToken }, // Отправляем токен обновления в теле запроса
            {
                headers: {
                    'accept': 'application/json',
                    'authorization': 'Basic dWdheTAzQGdtYWlsLmNvbTpleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKMGIydGxibDkwZVhCbElqb2lZV05qWlhOeklpd2laWGh3SWpveE56RXhNelkyT0RNNUxDSnBZWFFpT2pFM01URXpOVGsyTXprc0ltcDBhU0k2SWpsaVptWTRZbU0zWXpneVlqUXpabU00TlRRNFpUSmpNRE0wWkRkalkyRmpJaXdpZFhObGNsOXBaQ0k2SWpRd1lUTTFZbU13TFRjM01HWXROR0ZoTlMxaVkyUmhMV1F3TVdZNU5ESm1aR1V4TVNKOS5PVkVob1RiUVg0UEhWeEg5bzNLQTc2bjU0eU5wZVE2VGYxM3YwMEZiRzIw',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': 'uVMQH0udqW7J0RYS2YFiV2sEaSiLj7kDz70nE20S6gzoQB0YmMM0SvmEqMUNZK6D'
                },
            }
        );

        // Вывод полного ответа в консоль
        console.log('Ответ сервера:', response.data);

        // Возвращаем обновленные токены
        return response.data;

    } catch (error) {
        // В случае ошибки выводим её в консоль
        console.error('Ошибка при обновлении токена:', error);
        throw error;
    }
};






const retrieveOneApi = axios.create({
    baseURL: 'https://back.sdfsdfsdfsdfsfsfsghsdgw.ru/api/v1/profiles/user/retrieve_one/',
});
export const retrieveOne = async ( accessToken: string) => {
   // return async (dispatch: Dispatch) => {
        try {
            const response = await retrieveOneApi.get('', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            console.log('Успешно получены данные:', response.data.body);
           // const profileData = response.data.body;
           // dispatch(setUserProfile(profileData));

            return response.data.body; // Возвращаем данные ответа

        } catch (error) {
            console.error('Ошибка при получении данных профиля:', error);
            throw error; // Пробрасываем ошибку дальше для обработки в вызывающем коде
        }
    //}
};
