import { createSlice, PayloadAction } from '@reduxjs/toolkit';
 interface ProfileData {
    id: string;
    username: string;
    first_name: string | null;
    last_name: string | null;
    email: string;
    avatar: string | null;
    phone: string | null;
    is_active: boolean;
    is_premium: boolean;
    balance: number;
    transactions: any[];
    mnemonics: any[];
    confirm: any[];
    birthday: string | null;
    address: string | null;
    city: string | null;
    country: string | null;
    is_notification: boolean;
}
interface UserProfileState {
    profile: ProfileData | null;
    loading: boolean;
    error: string | null;
}

const initialState: UserProfileState = {
    profile: null,
    loading: false,
    error: null,
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        fetchProfileStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProfileSuccess(state, action: PayloadAction<ProfileData>) {
            state.profile = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchProfileFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        clearProfile(state) {
            state.profile = null;
        },
    },
});

export const { fetchProfileStart, fetchProfileSuccess, fetchProfileFailure, clearProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
