import React, { useState } from 'react';
import styled from 'styled-components';
import ContainerH from "../containers/ContainerH";
import {PRIVACY} from "../../constants/constants";

// Создаем стилизованные компоненты
const CheckboxContainer = styled.div`
 display: flex;
 align-items: center;
 flex-direction: column;
 align-self: center; 
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })<{active: boolean}>`
 margin-right: 10px;
 align-self: flex-start;
 background-color: ${({ active }) => (active ? '#FCD535' : '#293039')};
  
  &:checked{
    background-color: #FCD535;
  }
`;

const LinkContainer = styled.a`
 color: white;
 text-decoration: none;
 align-self: flex-start;
 margin-top: -0.3rem; 
 white-space: nowrap; 
`;

const Button = styled.button<{ active: boolean }>`
 background-color: ${({ active }) => (active ? '#FCD535' : '#293039')};
 color: ${({active}) =>(active ? '#191B21' : 'white')};
 font-weight: 700; 
 padding: 10px;
 border: none;
 cursor: pointer;
 border-radius: 4px;
 margin-top: 1rem;
 //margin-right: -5rem;
  //margin-left: 15rem;
`;

// Компонент
const ConfirmForm: React.FC = () => {
    const [isChecked, setIsChecked] = useState(false);
    //const [buttonColor, setButtonColor] = useState('gray');

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        //setButtonColor(event.target.checked ? '#FCD535' : '#293039');
    };

    return <>
        <CheckboxContainer>
            <ContainerH>
                <StyledCheckbox onChange={handleCheckboxChange}  active={isChecked}/>
                <LinkContainer href="#" dangerouslySetInnerHTML={{ __html: PRIVACY }}/>
            </ContainerH>
            <Button active={isChecked}>Подтвердить</Button>
        </CheckboxContainer>
    </>;
};

export default ConfirmForm;
