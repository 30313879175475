import React, { useEffect } from "react";
import styled from "styled-components";
import IndexOnlineButton from "../../functional/buttons/IndexOnlineButton";
import DeleteButton from "../../functional/buttons/DeleteButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useAddWorkerToDomainMutation } from "../../../redux/apiService";
import { AddWorker } from "../../../redux/types/apiRequestsTypes";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
  //max-width: 8rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  //grid-template-columns: auto repeat(9,1fr);
  grid-template-columns: 0.5fr 1.75fr 1fr 1fr 0.5fr 1fr 1fr 1.25fr 1fr 1fr;
  //gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  //max-width: 10rem;
  font-size: 14px;
`;

const AvatarImg = styled.img`
  height: 62px;
  width: 62px;
  padding-right: 1rem;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
  refetch: () => void;
}

const AdminWorkerListTable: React.FC<TableProps> = ({ data, refetch }) => {
  const [addNewWorker] = useAddWorkerToDomainMutation();

  const handleDeleteWorker = async (workerEmail: string) => {
    try {
      const deleteWorkerData: AddWorker = {
        email: workerEmail,
        role: {
          code: "readonly",
        },
      };
      if (deleteWorkerData) {
        await addNewWorker(deleteWorkerData).unwrap(); // Отправка email на сервер
        console.log("Worker added successfully");
        refetch(); // Обновить список воркеров
      } else {
        console.error("Please enter a worker email");
      }
    } catch (error) {
      console.error("Failed to add worker:", error);
    }
  };
  const selectedUserId = useSelector(
    (state: RootState) => state.selectedUser.id,
  );

  useEffect(() => {
    console.log("Выбранный  на той странице selectedUserId:", selectedUserId);
  }, [selectedUserId]);

  return (
    <BorderBlock>
      <table>
        <TableRow style={{ borderBottom: "none" }}>
          <TableHeader>User</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader>Username</TableHeader>
          <TableHeader>Password</TableHeader>
          <TableHeader>Users</TableHeader>
          <TableHeader>Deposits</TableHeader>
          <TableHeader>Registered</TableHeader>
          <TableHeader>Domains</TableHeader>
          <TableHeader>Last activity</TableHeader>
          <TableHeader>Action</TableHeader>
        </TableRow>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: "3rem" }}>
                <AvatarImg src={item.imagePath} alt="Avatar" />
              </TableCell>
              <TableCell>
                {item.user}
                <IndexOnlineButton text={item.status} />
              </TableCell>

              {/*<TableCell>*/}
              {/*    <ContainerH style={{margin:"0"}}>*/}
              {/*        <AvatarImg src={item.imagePath} alt="Avatar" />*/}
              {/*        <ContainerV style={{alignSelf:"flex-start"}}>*/}
              {/*            {item.user}*/}
              {/*            <IndexOnlineButton text = {item.status}/>*/}
              {/*        </ContainerV>*/}
              {/*    </ContainerH>*/}
              {/*</TableCell>*/}
              <TableCell>{item.username}</TableCell>
              <TableCell>{item.password}</TableCell>
              <TableCell>{item.users}</TableCell>
              <TableCell>{item.deposits}</TableCell>
              <TableCell>{item.registered}</TableCell>
              <TableCell>{item.domain}</TableCell>
              <TableCell>{item.lastActivity}</TableCell>
              <TableCell>
                <DeleteButton onClick={() => handleDeleteWorker(item.user)} />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </BorderBlock>
  );
};
export default AdminWorkerListTable;
