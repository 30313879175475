import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import AccountPageNav from "../../styles/navs/AccountPageNav";
import AccountSubNav from "../../styles/navs/AccountSubNav";
import styled from "styled-components";
import AccountNewListingTable from "../../styles/tables/forAccount/AccountNewListingTable";

const BorderBlock = styled.div`
  border: 1px solid #293039;
  border-radius: 20px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;
const AccountMainValue = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', '₽2 467,75B', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
    ];

    const names = ['Монета', 'Стоимость монеты', 'Изменение за 24ч', 'Объем (USD)', 'Торговля'];
  return (
      <BorderBlock>
          <ContainerH>
              <HeaderALTH style={{fontSize:"20px"}}> Рынки</HeaderALTH>
              <GreyLinkArrowR to={"/"}  customStyles={{color:"#fff"}}>Подробнее</GreyLinkArrowR>
          </ContainerH>
          <AccountPageNav initialActiveButton={6}/>
          <AccountSubNav names={names}/>
          <AccountNewListingTable data={tableData}/>
      </BorderBlock>
  );
};
export default AccountMainValue;