import React, { useState } from "react";
import BalanceBlock from "./BalanceBlock";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import { BorderBlock } from "./Deposits";
import AssetsSpotTable from "../../styles/tables/forAccount/AssetsSpotTable";
import styled from "styled-components";
import BlockH from "../../styles/blosks/BlockH";
import ButtonSRC from "../../styles/functional/buttons/ButtonSRC";

interface CheckboxProps {
  isChecked: boolean;
}

const CheckboxContainer = styled.label<CheckboxProps>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.isChecked ? "#FCD535" : "#1E2329")};
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Ensure the container hides its children */
  transition: background-color 0.3s ease;
  margin-right: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -35%); /* Adjust positioning */
    width: 15px; /* Width of the checkmark */
    height: 25px; /* Height of the checkmark */
    background-image: url("/assets/images/forpages/adminpanel/checkmark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${(props) => (props.isChecked ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid
      ${(props) => (props.isChecked ? "transparent" : "#848E9C")}; /* Рамка появляется, когда чекбокс не выбран */
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
`;

const SpotOverview: React.FC = () => {
  const tableData = [
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
  ];
  const condition = "Скрыть активы <1 USD";
  const [checkboxState, setCheckboxState] = useState(true);

  const handleCheckboxChange = () => {
    setCheckboxState(!checkboxState);
    console.log(checkboxState);
  };

  return (
    <>
      <BalanceBlock />
      <BorderBlock>
        <ContainerH>
          <HeaderALTH style={{ fontSize: "20px" }}> Спотовый</HeaderALTH>
          <ContainerH>
            <BlockH>
              <ButtonSRC />
            </BlockH>
            <BlockH>
              Конвертировать маленькие балансы в BNB
              <img
                src="/assets/images/forpages/accountpage/change.svg"
                alt="Change"
                style={{ marginLeft: "1rem" }}
              />
            </BlockH>

            <BlockH>
              <CheckboxContainer
                isChecked={checkboxState}
                onClick={handleCheckboxChange}
              />
              {condition}
            </BlockH>
          </ContainerH>
        </ContainerH>
        <AssetsSpotTable data={tableData} />
      </BorderBlock>
    </>
  );
};
export default SpotOverview;
