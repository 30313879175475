import styled from "styled-components";
import { Link } from "react-router-dom";


const CustomLinkGrey = styled(Link)`
    color: #848E9C;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    
    &:hover {
      color: #EFB608;
    }
  
    `;
export default CustomLinkGrey;