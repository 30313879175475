import React from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALS from "../../styles/headers/HeaderALS";
import HeaderFAL from "../../styles/headers/HeaderFAL";
import Block from "../../styles/blosks/Block";
import RegistrBlock from "../../styles/blosks/RegistrBlock";
import TradeBlock from "../../styles/blosks/TradeBlock";

const SaleIntro: React.FC = () => {

    return(
        <BasicDiv style={{paddingBottom:"7rem"}}>
            <ContainerH>
                <ContainerV>
                    <HeaderFAL style={{marginBottom:"0.5rem"}}>
                        Продажа<br/>
                        криптовалюты
                    </HeaderFAL>
                    <HeaderALS>
                        Доступно множество способов оплаты
                    </HeaderALS>
                    <Block>
                    <RegistrBlock/>
                    </Block>
                </ContainerV>
                <TradeBlock/>
            </ContainerH>
        </BasicDiv>
    );
};
export default SaleIntro;