import React from "react";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";
import ContainerV from "../../styles/containers/ContainerV";
import TelegramMessages from "./forAdminTelegramSettings/TelegramMessages";
import FiveBlocksAtTop from "./forAdminTelegramSettings/FiveBlocksAtTop";

const AdminTelegramSettings:React.FC = () => {
  return <>
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Telegram settings</HeadBlock>
          <FiveBlocksAtTop/>
          <TelegramMessages/>
      </ContainerV>
  </>;
};
export default AdminTelegramSettings;