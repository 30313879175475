import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: #EFB608;
  }
`;

export default CustomLink;
