import React, {useState} from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import ContainerH from "../../styles/containers/ContainerH";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";



const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.text`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start; 
  font-size: 12px;
`;
const GrayLittle = styled.text`
  color: #848E9C;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 14px;
  text-align: left;
`;

const StyledGreyLink = styled.a`
  color: #848E9C;
  
`;

const EmailInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  min-width: 35rem;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 10rem;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #FFFF66;
  }
`;


const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

const AdminDepositSettings:React.FC = () => {
    // Step 1: Define the switchStates state
    const [switchState, setSwitchState] = useState(false);

    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Toggle the switch state at the given index
        setSwitchState(event.target.checked);
        console.log("Switch state changed to:", event.target.checked);
    };
  return <>

      <ContainerV
          style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Deposit settings</HeadBlock>
              <BorderBlock>
                  <GrayLittle>Подключение API <StyledGreyLink href="#">WestWallet.io</StyledGreyLink>, необходимого для генерации адресов и проверки депозитов.</GrayLittle>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <Title>Public key</Title>
                        <EmailInput type="text" placeholder="6Xf8N8_PcgI0FiAngpvQJzm6K0k2tjGaOeGJ9Ikk" style={{justifySelf:"flex-end", width:"25rem"}}/>
                  </BorderBlock>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <Title>Private key</Title>
                      <EmailInput type="text" placeholder="1kiCR1W-0dzRgFf-SYj6uxCGyIhq4Qq4uSOWZohFdHNgZg0Zi-bZ8Q" style={{justifySelf:"flex-end", width:"25rem"}}/>
                  </BorderBlock>
                  <GrayLittle>
                      Для подключения депозитов, вам необходимо: <br/>
                      <ol style={{marginBottom:"1rem"}}>
                          <li>
                              Зарегистрировать аккаунт: <StyledGreyLink href="#">WestWallet.io/register</StyledGreyLink>
                          </li>
                          <li>
                              Создать API ключ: <StyledGreyLink href="#">WestWallet.io/settings</StyledGreyLink>
                          </li>
                          <li>
                              Ввести полученные Публичный и Приватный ключи в соответствующие поля выше
                          </li>
                      </ol>
                      <GrayLittle>
                          Так же WestWallet позволяет настроить различный функционал, вроде автоматического вывода или SmartExchange.
                      </GrayLittle>
                  </GrayLittle>

                  <SubmitButton>Add a worker</SubmitButton>
              </BorderBlock>

          <BorderBlock>
              <Title style={{fontSize:"20px"}}>Card purchase settings</Title>
              <ContainerH style={{minWidth:"100%", margin:"0"}}>
                  <GrayLittle>
                      Если включено, то пользователям будет доступна страница /profile/buy-crypto на которой они смогут покупать <br/>
                      криптовалюту с карт через различные платежные системы.
                  </GrayLittle>
                  <div>
                      <Switch id="switch" checked={switchState} onChange={handleSwitchChange} />
                      <SwitchLabel htmlFor="switch"></SwitchLabel>
                  </div>
              </ContainerH>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
      </ContainerV>
  </>;
};
export default AdminDepositSettings;