import React from "react";
import ContainerH from "../styles/containers/ContainerH";
import {Outlet} from "react-router-dom";
import AdminHeader from "../components/forAdminPanel/AdminHeader";
import AdminNavPanel from "../components/forAdminPanel/AdminNavPanel";

const AdminPanel:React.FC = () => {
    return <>
        <AdminHeader/>
        <ContainerH style={{backgroundColor:"#131519", paddingBottom:"3rem",alignItems:"flex-start", marginTop:"0"}}>
            <AdminNavPanel/>
            <div style={{ width: 'calc(100% - 15rem)', marginLeft: '15rem' }}> {/* Добавляем контейнер для <Outlet/> */}
                <Outlet />
            </div>
        </ContainerH>
    </>;
};
export default AdminPanel;