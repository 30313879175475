import React, { useState } from "react";
import styled from "styled-components";

const CustomAccordionContainer = styled.div`
  border: 1px solid #1E2329;
  border-radius: 20px;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div<{isOpen: boolean}>`
  padding: 15px;
  background-color: #1E2329;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ isOpen }) => (isOpen ? "20px 20px 0 0" : "20px")};

`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  background-color: #1E2329;
  text-align: left;
  border-bottom: 1px #1E2329;
  border-radius: 0 0 20px 20px;
  
`;

const ToggleButton = styled.button`
  background-color: #FCD535;
  color: #000;
  font-weight: 700;
  font-size: 30px;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px; 
  height: 40px; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberBlock = styled.div`
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #293039;
  border-radius: 10px;
  margin-right: 10px; /* Регулируйте отступ справа по вашему желанию */
`;


interface CustomAccordionProps {
    title: string;
    number: number;
    children?: React.ReactNode;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ title, number, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CustomAccordionContainer>
            <AccordionHeader onClick={toggleAccordion} isOpen={isOpen}>
                <NumberBlock>{number}</NumberBlock>

                <div>{title}</div>
                <ToggleButton onClick={toggleAccordion}>{isOpen ? <img src="/assets/images/icons/minus.svg" alt="-"/> : <img src="/assets/images/icons/plus.svg" alt="+"/>}</ToggleButton>
            </AccordionHeader>
            <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
        </CustomAccordionContainer>
    );
};

export default CustomAccordion;
