import React from "react";
import "./App.css";
import Header from "./components/layout/Header";
import MainPage from "./pages/MainPage";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Footer from "./components/layout/Footer";
import SalePage from "./pages/SalePage";
import MarketPage from "./pages/MarketPage";
import MarketsAllCryptos from "./components/formarketpage/MarketsAllCryptos";
import MarketsFavorite from "./components/formarketpage/MarketsFavorite";
import Slot from "./components/formarketpage/forMarketsFavorite/Slot";
import Futures from "./components/formarketpage/forMarketsFavorite/Futures";
import Options from "./components/formarketpage/forMarketsFavorite/Options";
import AuthPage from "./pages/AuthPage";
import AuthMain from "./components/forAuthPages/AuthMain";
import CreatAccount from "./components/forAuthPages/CreatAccount";
import Verificate from "./components/forAuthPages/Verificate";
import MarketsAI from "./components/formarketpage/MarketsAI";
import AccountPage from "./pages/AccountPage";
import LoginPage from "./components/forAuthPages/LoginPage";
import MarketsLevels from "./components/formarketpage/MarketsLevels";
import MarketsMetaVerse from "./components/formarketpage/MarketsMetaVerse";
import MarketsMonitoring from "./components/formarketpage/MarketsMonitoring";
import MarketsSeed from "./components/formarketpage/MarketsSeed";
import MarketsMeme from "./components/formarketpage/MarketsMeme";
import MarketsLiquid from "./components/formarketpage/MarketsLiquid";
import AccountMain from "./components/forAccountPage/AccountMain";
import AccountMainPopular from "./components/forAccountPage/AccountMainPopular";
import AccountMainActives from "./components/forAccountPage/AccountMainActives";
import AccountMainNewListing from "./components/forAccountPage/AccountMainNewListing";
import AccountMainFavourites from "./components/forAccountPage/AccountMainFavourites";
import AccountMainGrowth from "./components/forAccountPage/AccountMainGrowth";
import AccountMainValue from "./components/forAccountPage/AccountMainValue";
import { Provider } from "react-redux";
import store from "./redux/store";
import ActiviesOptions from "./components/forAccountPage/ActiviesOptions";
import FuturesAccount from "./components/forAccountPage/FuturesAccount";
import Deposits from "./components/forAccountPage/Deposits";
import AdminPanel from "./pages/AdminPanel";
import WorkerPanel from "./pages/WorkerPanel";
import WorkerBinding from "./components/forWorkerPage/WorkerBinding";
import AdminStatics from "./components/forAdminPanel/AdminStatics";
import WorkerStatics from "./components/forWorkerPage/WorkerStatics";
import AdminEditTerms from "./components/forAdminPanel/AdminEditTerms";
import AdminEditAmlKycPolicy from "./components/forAdminPanel/AdminEditAmlKycPolicy";
import WorkerEditTerms from "./components/forWorkerPage/WorkerEditTerms";
import WorkerEditAmlKycPolicy from "./components/forWorkerPage/WorkerEditAmlKycPolicy";
import AdminDeposits from "./components/forAdminPanel/AdminDeposits";
import WorkerDeposits from "./components/forWorkerPage/WorkerDeposits";
import WorkerWithdrawal from "./components/forWorkerPage/WorkerWithdrawal";
import WorkerWalletConnect from "./components/forWorkerPage/WorkerWalletConnect";
import AdminWalletConnect from "./components/forAdminPanel/AdminWalletConnect";
import AdminLogList from "./components/forAdminPanel/AdminLogList";
import WorkerLogList from "./components/forWorkerPage/WorkerLogList";
import WorkerUserKYCRequests from "./components/forWorkerPage/WorkerUserKYCRequests";
import AdminWorkerList from "./components/forAdminPanel/AdminWorkerList";
import AdminDomains from "./components/forAdminPanel/AdminDomains";
import WorkerUsers from "./components/forWorkerPage/WorkerUsers";
import AdminUsers from "./components/forAdminPanel/AdminUsers";
import WorkerDomains from "./components/forWorkerPage/WorkerDomains";
import WorkerUtility from "./components/forWorkerPage/WorkerUtility";
import WorkerVideoRecordingFunctionality from "./components/forWorkerPage/WorkerVideoRecordingFunctionality";
import AdminDepositSettings from "./components/forAdminPanel/AdminDepositSettings";
import AdminP2PFakes from "./components/forAdminPanel/AdminP2PFakes";
import WorkerSupportMessagePresets from "./components/forWorkerPage/WorkerSupportMessagePresets";
import AdminDepositCoins from "./components/forAdminPanel/AdminDepositCoins";
import WorkerDepositCoins from "./components/forWorkerPage/WorkerDepositCoins";
import WorkerChangeCourse from "./components/forWorkerPage/forWorkerChangeCourse/WorkerChangeCourse";
import FastPumpDump from "./components/forWorkerPage/forWorkerChangeCourse/FastPumpDump";
import StablePumpDump from "./components/forWorkerPage/forWorkerChangeCourse/StablePumpDump";
import AdminTelegramSettings from "./components/forAdminPanel/AdminTelegramSettings";
import WorkerMainSettings from "./components/forWorkerPage/WorkerMainSettings";
import AdminDefaultSettings from "./components/forAdminPanel/AdminDefaultSettings";
import SpotTradingPage from "./pages/SpotTradingPage";
import UserProfile from "./components/forAdminPanel/forAdminUsers/UserProfile";
import Overview from "./components/forAdminPanel/forAdminUsers/Overview";
import Logs from "./components/forAdminPanel/forAdminUsers/Logs";
import UserErrorMessages from "./components/forAdminPanel/forAdminUsers/UserErrorMessages";
import AssetsPage from "./pages/AssetsPage";
import WalletOverview from "./components/forAssetsPage/WalletOverview";
import WalletCoinsBlock from "./components/forAssetsPage/WalletCoinsBlock";
import WalletWalletsBlock from "./components/forAssetsPage/WalletWalletsBlock";
import SpotOverview from "./components/forAccountPage/SpotOverview";
import AccountSettingsChange from "./components/forAccountPage/AccountSettingsChange";
import AdminSupport from "./components/forAdminPanel/AdminSupport";
import WorkerSupport from "./components/forWorkerPage/WorkerSupport";
import TermsOfService from "./pages/LegalPages/TermsOfService";
import PrivacyNotice from "./pages/LegalPages/PrivacyNotice";
import CookiesPolicy from "./pages/LegalPages/CookiesPolicy";
import UserTransaction from "./components/forAdminPanel/forAdminUsers/UserTransaction/UserTransaction";
import UserTransactionBlock from "./components/forAdminPanel/forAdminUsers/UserTransaction/UserTransactionBlock";
import UserStakingBlock from "./components/forAdminPanel/forAdminUsers/UserTransaction/UserStakingBlock";
import Addresses from "./components/forAdminPanel/forAdminUsers/Addresses";
import AdminSupportMessagePresets from "./components/forAdminPanel/AdminSupportMessagePresets";
import UserBalance from "./components/forAdminPanel/forAdminUsers/UserBalance/UserBalance";
import UserBalanceViewBlock from "./components/forAdminPanel/forAdminUsers/UserBalance/UserBalanceBlock";
import UserSendBlock from "./components/forAdminPanel/forAdminUsers/UserBalance/UserSendBlock";
import P2PPage from "./pages/P2PPage";
import AlertPopUp from "./components/forAdminPanel/forAdminUsers/AlertPopUp";
import BonusPopUp from "./components/forAdminPanel/forAdminUsers/BonusPopUp";
import WithdrawVerify from "./components/forAdminPanel/forAdminUsers/WithdrawVerify";

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/auth-page" element={<AuthPage />}>
              <Route index element={<AuthMain />} />
              <Route path="create-account" element={<CreatAccount />} />
              <Route path="verificate" element={<Verificate />} />
              <Route path="login" element={<LoginPage />} />
            </Route>

            <Route path="/admin" element={<AdminPanel />}>
              <Route index element={<AdminStatics />} />
              <Route path="statics" element={<AdminStatics />} />
              <Route path="deposits" element={<AdminDeposits />} />
              <Route path="wallet-connect" element={<AdminWalletConnect />} />
              <Route path="logs" element={<AdminLogList />} />
              <Route path="P2P-fakes" element={<AdminP2PFakes />} />
              <Route path="workers" element={<AdminWorkerList />} />
              <Route path="domains" element={<AdminDomains />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="support" element={<AdminSupport />} />
              <Route path="payments" element={<AdminDepositSettings />} />
              <Route path="coins" element={<AdminDepositCoins />} />
              <Route path="telegram" element={<AdminTelegramSettings />} />
              <Route
                path="support-presents"
                element={<AdminSupportMessagePresets />}
              />
              <Route path="terms" element={<AdminEditTerms />} />
              <Route
                path="aml-kyc-policy"
                element={<AdminEditAmlKycPolicy />}
              />
              <Route path="settings" element={<AdminDefaultSettings />} />
            </Route>

            <Route path="/worker" element={<WorkerPanel />}>
              <Route index element={<WorkerBinding />} />
              <Route path="binding" element={<WorkerBinding />} />
              <Route path="statics" element={<WorkerStatics />} />
              <Route path="deposits" element={<WorkerDeposits />} />
              <Route path="withdraw" element={<WorkerWithdrawal />} />
              <Route path="wallet-connect" element={<WorkerWalletConnect />} />
              <Route path="users" element={<WorkerUsers />} />
              <Route path="support" element={<WorkerSupport />} />
              <Route path="logs" element={<WorkerLogList />} />
              <Route path="kyc-list" element={<WorkerUserKYCRequests />} />
              <Route path="trading-course" element={<WorkerChangeCourse />}>
                <Route index element={<FastPumpDump />} />
                <Route path="fast" element={<FastPumpDump />} />
                <Route path="stable" element={<StablePumpDump />} />
              </Route>
              <Route path="terms" element={<WorkerEditTerms />} />
              <Route
                path="aml-kyc-policy"
                element={<WorkerEditAmlKycPolicy />}
              />
              <Route path="domains" element={<WorkerDomains />} />
              <Route path="utility" element={<WorkerUtility />} />
              <Route path="coins" element={<WorkerDepositCoins />} />
              <Route
                path="video-record"
                element={<WorkerVideoRecordingFunctionality />}
              />
              <Route
                path="support-presents"
                element={<WorkerSupportMessagePresets />}
              />
              <Route path="settings" element={<WorkerMainSettings />} />
            </Route>

            <Route path="/user-profile" element={<UserProfile />}>
              <Route index element={<Overview />} />
              <Route path="overview" element={<Overview />} />
              <Route path="logs" element={<Logs />} />
              <Route path="error-messages" element={<UserErrorMessages />} />
              <Route path="transactions" element={<UserTransaction />}>
                <Route index element={<UserTransactionBlock />} />
                <Route path="transactions" element={<UserTransactionBlock />} />
                <Route path="staking" element={<UserStakingBlock />} />
              </Route>
              <Route path="addresses" element={<Addresses />} />
              <Route path="balance" element={<UserBalance />}>
                <Route index element={<UserBalanceViewBlock />} />
                <Route path="view" element={<UserBalanceViewBlock />} />
                <Route path="send" element={<UserSendBlock />} />
              </Route>
              <Route path="alert-pop-up" element={<AlertPopUp />} />
              <Route path="bonus-pop-up" element={<BonusPopUp />} />
              <Route path="withdraw-verify" element={<WithdrawVerify />} />
            </Route>

            <Route path="/" element={<Layout />}>
              <Route index element={<MainPage />} />
              <Route path="/sale-page" element={<SalePage />} />
              <Route path="/spot-trading" element={<SpotTradingPage />} />
              <Route path="/p2p" element={<P2PPage />} />
              <Route path="legal" element={<TermsOfService />} />
              <Route path="terms-of-service" element={<TermsOfService />} />
              <Route path="privacy-notice" element={<PrivacyNotice />} />
              <Route path="coolies-policy" element={<CookiesPolicy />} />
              <Route path="/markets" element={<MarketPage />}>
                <Route index element={<Navigate to="favorites" />} />
                <Route path="favorites" element={<MarketsFavorite />}>
                  <Route index element={<Slot />} />
                  <Route path="slot" element={<Slot />} />
                  <Route path="futures" element={<Futures />} />
                  <Route path="options" element={<Options />} />
                </Route>
                <Route path="all-cryptos" element={<MarketsAllCryptos />} />
                <Route path="ai" element={<MarketsAI />} />
                <Route path="levels" element={<MarketsLevels />} />
                <Route path="meta" element={<MarketsMetaVerse />} />
                <Route path="monitor" element={<MarketsMonitoring />} />
                <Route path="seed" element={<MarketsSeed />} />
                <Route path="meme" element={<MarketsMeme />} />
                <Route path="liquid" element={<MarketsLiquid />} />
              </Route>

              <Route path="/account" element={<AccountPage />}>
                <Route index element={<Navigate to="main" />} />
                <Route path="options" element={<ActiviesOptions />} />
                <Route path="futures-accounts" element={<FuturesAccount />} />
                <Route path="deposits" element={<Deposits />} />
                <Route path="main" element={<AccountMain />}>
                  <Route index element={<AccountMainActives />} />
                  <Route path="actives" element={<AccountMainActives />} />
                  <Route path="popular" element={<AccountMainPopular />} />
                  <Route
                    path="new-listing"
                    element={<AccountMainNewListing />}
                  />
                  <Route
                    path="favourites"
                    element={<AccountMainFavourites />}
                  />
                  <Route path="growth" element={<AccountMainGrowth />} />
                  <Route path="volume" element={<AccountMainValue />} />
                </Route>
              </Route>

              <Route
                path="account-settings"
                element={<AccountSettingsChange />}
              />

              <Route path="/assets" element={<AssetsPage />}>
                <Route index element={<WalletOverview />} />
                <Route path="wallet-overview" element={<WalletOverview />}>
                  <Route index element={<WalletCoinsBlock />} />
                  <Route path="coins" element={<WalletCoinsBlock />} />
                  <Route path="wallets" element={<WalletWalletsBlock />} />
                </Route>
                <Route path="spot-overview" element={<SpotOverview />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
