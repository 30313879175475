import React from "react";
import ContainerV from "../../../styles/containers/ContainerV";
import MarketSubNav from "../../../styles/navs/MarketSubNav";
import NoData from "../NoData";

const Futures: React.FC = () => {
  return(
    <ContainerV>
        <MarketSubNav/>
        <NoData/>
        <p>Futures</p>
    </ContainerV>
  );
};
export default Futures;