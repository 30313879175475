import React from "react";
import UserProfileNav from "../../../styles/navs/UserProfileNav";
import UserProfileLogListTable from "../../../styles/tables/forAdmin/UserProfileLogListTable";


const Logs:React.FC = () => {
    const testData = [
        {
            id: 38,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Stavropol",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 39,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Moscow",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 40,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Saint Petersburg",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 41,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Novosibirsk",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 42,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Kazan",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 43,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Nizhny Novgorod",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 44,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Perm",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 45,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Khabarovsk",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 46,
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Vladivostok",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        }
    ];


  return <>
      <UserProfileNav initialActiveButton={2}/>
      <UserProfileLogListTable data={testData}/>

  </>;
};
export default Logs;