import styled from "styled-components";

const ButtonST = styled.button`
  color: #191B21;
  background: #EFB608;
  border: 1px solid #EFB608;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  width: 199px;
  height: 60px;
  align-self: center;
  justify-self: center;
  
  &:hover {
    background-color: #FCD535;
  }
    `;
export default ButtonST;