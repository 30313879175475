import React from "react";
import AssetsWalletOverviewCoinsTable from "../../styles/tables/forAccount/AssetsWalletOverviewCoinsTable";

const WalletCoinsBlock = () => {

    const tableData = [
        {
            iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
            icon: "BTC",
            coinType: "Bitcoin",
            sumDollar: "380,1",
            sumRubels: "₽ 34 985",
            coinCost: "₽ 92,16",
            action: "Покупка за фиат",
        },
        {
            iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
            icon: "BTC",
            coinType: "Bitcoin",
            sumDollar: "380,1",
            sumRubels: "₽ 34 985",
            coinCost: "₽ 92,16",
            action: "Покупка за фиат",
        },
        {
            iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
            icon: "BTC",
            coinType: "Bitcoin",
            sumDollar: "380,1",
            sumRubels: "₽ 34 985",
            coinCost: "₽ 92,16",
            action: "Покупка за фиат",
        },
        {
            iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
            icon: "BTC",
            coinType: "Bitcoin",
            sumDollar: "380,1",
            sumRubels: "₽ 34 985",
            coinCost: "₽ 92,16",
            action: "Покупка за фиат",
        },
        {
            iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
            icon: "BTC",
            coinType: "Bitcoin",
            sumDollar: "380,1",
            sumRubels: "₽ 34 985",
            coinCost: "₽ 92,16",
            action: "Покупка за фиат",
        },
    ];
    return <>
        <AssetsWalletOverviewCoinsTable data={tableData} />
    </>
};
export default WalletCoinsBlock;