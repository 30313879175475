import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import { URL_AND_NAME_EXPLANATION } from "../../constants/constants";
import {
  useGetTermsAndAmlKycPoliciesRetrieveOneQuery,
  useUpdateTermsAndAmlKycPoliciesMutation,
} from "../../redux/apiService";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  align-self: flex-start;
  min-width: 100%;
`;

const SaveButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 10rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #ffff66;
  }
`;

const TextArea = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 35rem;
  color: white;
`;
const AdminEditTerms: React.FC = () => {
  const {
    data: terms,
    error,
    isLoading,
    refetch,
  } = useGetTermsAndAmlKycPoliciesRetrieveOneQuery();

  const [updateTermsAndAmlKycPolicies] =
    useUpdateTermsAndAmlKycPoliciesMutation();
  const [termsValue, setTermsValue] = useState<string | undefined>(undefined); // Изначально `undefined`

  useEffect(() => {
    if (terms?.body.terms) {
      setTermsValue(terms.body.terms); // Устанавливаем значение только после загрузки данных
    }
  }, [terms]); // Выполняется при изменении terms
  // Обработчик изменения текста в TextArea
  const handleTermsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTermsValue(event.target.value);
  };

  // Обработчик сохранения изменений
  const handleSaveChanges = async () => {
    try {
      await updateTermsAndAmlKycPolicies({
        terms: termsValue,
        //aml_kyc_policy: terms?.body.aml_kyc_policy,
      });
      refetch(); // Обновите данные после успешного обновления
    } catch (error) {
      console.error("Error updating terms:", error);
      // Здесь можно добавить обработку ошибки, например, показать уведомление
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading terms: {error.toString()}</div>;
  }
  console.log("terms data:", terms);

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        maxWidth: "100%",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock>Edit Terms</HeadBlock>
      <BorderBlock>
        {/*<TextArea defaultValue={TERMS}></TextArea>*/}
        <TextArea value={termsValue} onChange={handleTermsChange}></TextArea>
        <BlockGreyText style={{ fontSize: "14px" }}>
          {URL_AND_NAME_EXPLANATION}
        </BlockGreyText>
        <SaveButton onClick={handleSaveChanges}>Save changes</SaveButton>
      </BorderBlock>
    </ContainerV>
  );
};
export default AdminEditTerms;
