import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #502530;
  color: white;
  font-weight: 600;
  //border: 1px solid #F6465D;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;

  &:hover {
    //background-color: #603030;
    background-color: white;
    color: #f6465d;
    font-weight: 600;
  }
`;

interface DeleteButtonProps {
  onClick?: () => void; // Опциональный проп для onClick
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => {
  return <StyledButton onClick={onClick}>Delete</StyledButton>;
};
export default DeleteButton;
