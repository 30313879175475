 import React from 'react';
import styled from 'styled-components';

const AccountDepositTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  width: 100%;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: auto auto repeat(3, 1fr) auto auto;
  gap: 2rem 0;
  padding: 10px;
  min-width: 100%;
  align-items: center;

  &:hover {
    background-color: #1E2329;
  }
`;

interface TableCellProps {
    isImage?: boolean;
    isSecondFromStart?: boolean;
    isThird?: boolean;
    isFourth?: boolean;
}

const TableCell = styled.div<TableCellProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ isThird }) => (isThird ? 'flex-start' : 'center')};
  padding: 8px;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;

  ${({ isImage }) =>
          isImage &&
          `
    grid-column: 1;
  `}

  ${({ isSecondFromStart }) =>
          isSecondFromStart &&
          `
    color: #848E9C;
    font-weight: 500;
    margin-right: 3rem;
    padding-left: 0;
  `}

  ${({ isThird }) =>
          isThird &&
          `
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  
`;

const Image = styled.img`
  width: 30px;
  margin-right: 0.5rem;
`;

const ImageSmall = styled.img`
  margin-left: 10rem;
`;

const ArrowIcon = styled.img`
  width: 20px;
  margin-left: 7px;
`;

const GrayText = styled.span`
  color: #848E9C;
`;

interface AccountPopularProps {
    data: {
        image: string;
        cells: string[];
        image5: string;
        grayText?: string;
    }[];
}

const AccountDepositTable: React.FC<AccountPopularProps> = ({ data }) => {
    return (
        <AccountDepositTableContainer>
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => (
                        <TableCell
                            key={cellIndex}
                            isImage={cellIndex === 0}
                            isSecondFromStart={cellIndex === 1}
                            isThird={cellIndex === 2}

                        >
                            {cellIndex === 0 && <Image src={row.image} alt="Image" />}
                            {cellIndex === 2 && (
                                <>
                                    <span>{cell}</span>
                                    <GrayText>{row.grayText || 'Серый текст по умолчанию'}</GrayText>
                                </>
                            )}

                            {cellIndex !== 2 && cellIndex !== 3 && <span>{cell}</span>}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </AccountDepositTableContainer>
    );
};

export default AccountDepositTable;
