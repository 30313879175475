import React from "react";
import styled from "styled-components";
import ImageChangingButton from "../functional/buttons/ButtonSubMenuMarket";

const AssetsSubNavContainer = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 2.6fr repeat(3, 1fr);
  align-items: flex-start;
  text-align: start;
  gap: 1.5rem;
  margin-top: 2rem;
`;

interface AssetsSubNavProps {
  names: string[];
}

const AccountSubNav: React.FC<AssetsSubNavProps> = ({ names }) => {
  return (
    <AssetsSubNavContainer>
      {names.map((name, index) => (
        <ImageChangingButton key={index} name={name} />
      ))}
    </AssetsSubNavContainer>
  );
};
export default AccountSubNav;
