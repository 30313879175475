import React, { useState } from "react";
import styled from "styled-components";

interface TableNavDropdownProps {
    options: string[];
    buttonText: string;
}

const TableNavDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #1E2329;
  justify-content: end;
  color: #848E9C;
  border: 1px solid #1E2329;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  width: 5rem;
  &:hover {
    color: #ffffff;
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 5%;
  background-color: #1E2329;
  display: none;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  min-width: 100px;
  background-color: #1E2329;
  &:hover {
    background-color: #191B21;
  }
`;

const ArrowImg = styled.img<{ isOpen: boolean }>`
  margin-left: 0.5rem;
  align-self: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const TableNavDropdown: React.FC<TableNavDropdownProps & { onOptionSelect?: (option: string) => void }> = ({ options, buttonText, onOptionSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(buttonText);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        if (onOptionSelect) {
            onOptionSelect(option); // Вызываем функцию обратного вызова с выбранным значением
        }
        setIsOpen(false);
    };

    return (
        <TableNavDropdownContainer>
            <DropdownButton onClick={toggleDropdown}>
                {selectedOption}
                <ArrowImg src="/assets/images/icons/grey-down-arrow.svg" alt="Arrow" isOpen={isOpen} />
            </DropdownButton>
            <DropdownList style={{ display: isOpen ? "block" : "none" }}>
                {options.map((option, index) => (
                    <DropdownItem key={index} onClick={() => handleOptionClick(option)}>
                        {option}
                    </DropdownItem>
                ))}
            </DropdownList>
        </TableNavDropdownContainer>
    );
};

export default TableNavDropdown;
