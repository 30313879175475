import React, { useState } from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";
import { useCreateP2PFakeMutation } from "../../redux/apiService";

const BorderBlock = styled.div`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  //gap: 3rem;
`;

const TitleMiddle = styled.div`
  color: #848e9c;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 16px;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191b21;
  border: none;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 20%;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #ffff66;
  }
`;

const AvatarButton = styled.button`
  background-color: #524926;
  border: 1px solid #fcd535;
  padding: 10px 20px;
  cursor: pointer;
  width: 15rem;
  font-weight: 700;
  border-radius: 0.25rem;
  margin-top: 2rem;
  align-self: center;
  justify-self: flex-start;
  color: #fcd535;
  align-content: center;
  margin-left: 8rem;

  &:hover {
    background-color: #ffff66;
  }
`;

interface AddNewFakeProps {
  refetch: () => void;
}

const AddNewFake: React.FC<AddNewFakeProps> = ({ refetch }) => {
  const [userName, setUserName] = useState("");
  const [orders, setOrders] = useState("");
  const [limits, setLimits] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [avatar, setAvatar] = useState("");

  const [createNewP2PFake] = useCreateP2PFakeMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await createNewP2PFake({
        username: userName,
        orders: orders,
        limit: limits,
        payment_method: paymentMethod,
        //avatar: avatar,
      }).unwrap();
      console.log("MessagePreset created successfully:", response);
      // Очистить форму или обновить состояние после успешного создания пресета
      setUserName("");
      setOrders("");
      setLimits("");
      setPaymentMethod("");
      setAvatar("");
      refetch();
    } catch (error) {
      console.error("Failed to create MessagePreset:", error);
    }
  };

  return (
    <BorderBlock style={{ paddingBottom: "2rem" }}>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TitleMiddle>Добавление фейк нового продавца.</TitleMiddle>
        <ContainerH style={{ margin: "0", flexWrap: "wrap", width: "100%" }}>
          <BorderBlock style={{ width: "30%" }}>
            <TitleLittle>Username</TitleLittle>
            <CustomInput
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="250"
            />
          </BorderBlock>

          <BorderBlock style={{ width: "30%" }}>
            <TitleLittle>Orders</TitleLittle>
            <CustomInput
              type="text"
              value={orders}
              onChange={(e) => setOrders(e.target.value)}
              placeholder="250"
            />
          </BorderBlock>
          <BorderBlock style={{ width: "30%" }}>
            <TitleLittle>Limits</TitleLittle>
            <CustomInput
              type="text"
              value={limits}
              onChange={(e) => setLimits(e.target.value)}
              placeholder="250"
            />
          </BorderBlock>
          <div style={{ width: "50%", display: "flex", flexWrap: "nowrap" }}>
            <BorderBlock style={{ width: "100%" }}>
              <TitleLittle>Payment method:</TitleLittle>
              <CustomInput
                type="text"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                placeholder="250"
              />
            </BorderBlock>
            <AvatarButton>
              <img
                src="/assets/images/forpages/accountpage/download.svg"
                alt="Download"
                style={{ marginRight: "0.5rem" }}
              />
              Avatar
            </AvatarButton>
          </div>
        </ContainerH>
        <SubmitButton type="submit">Add a worker</SubmitButton>
      </form>
    </BorderBlock>
  );
};
export default AddNewFake;
