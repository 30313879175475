import React, { useState } from "react";
import styled from "styled-components";

interface OptionWithImage {
  text: string;
  image: string;
}

interface SaleTradeDropdownProps {
  options: OptionWithImage[];
  buttonText: string;
  imgPath: string;
}

const TestTestCustomDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #1e2329;
  color: #fff;
  border: 1px solid #1e2329;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  &:hover {
    color: #efb608;
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 5%;
  background-color: #1e2329;
  display: none;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  min-width: 150px;
  background-color: #1e2329;
  &:hover {
    background-color: #191b21;
  }
`;

const CoinImg = styled.img`
  margin-right: 5px;
  width: 28px;
  height: 28px;
`;

const ArrowImg = styled.img<{ isOpen: boolean }>`
  margin-left: 1rem;
  align-self: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const SaleTradeDropdown: React.FC<SaleTradeDropdownProps> = ({
  options,
  buttonText,
  imgPath,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(buttonText);
  const [selectedImagePath, setSelectedImagePath] = useState(imgPath); // Initialize with the initial image path

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: OptionWithImage) => {
    setSelectedOption(option.text);
    setSelectedImagePath(option.image);
    setIsOpen(false);
  };

  return (
    <TestTestCustomDropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <CoinImg src={selectedImagePath} alt={buttonText} />
        {selectedOption}
        <ArrowImg
          src="/assets/images/icons/arrow.svg"
          alt="Arrow"
          isOpen={isOpen}
        />
      </DropdownButton>
      <DropdownList style={{ display: isOpen ? "block" : "none" }}>
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={() => handleOptionClick(option)}>
            <CoinImg src={option.image} alt={option.text} />{" "}
            {/* Use the image property */}
            {option.text}
          </DropdownItem>
        ))}
      </DropdownList>
    </TestTestCustomDropdownContainer>
  );
};

export default SaleTradeDropdown;
