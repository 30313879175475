import React from "react";
import styled from "styled-components";

const CustomTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Cell = styled.div`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;

  &.gray-text {
    color: #848E9C;
  }

  &.main-text {
    font-size: 20px;
    font-weight: 700;
  }

  &.red-text {
    color: #F6465D;
  }

  &.green-text {
    color: #10BA7B;
  }
`;

const Image = styled.img`
  margin-left: 8px;
`;

interface InformTableProps {
    header_first: string;
    header_second: string;
    content_first: string;
    content_second: string;
}

const InformTable: React.FC<InformTableProps> = ({ header_first, header_second, content_first, content_second }) => {
    const isNegative = (str: string) => str.trim().startsWith("-");
    const isPositive = (str: string) => str.trim().startsWith("+");

    return (
        <CustomTableContainer>
            <Row>
                <Cell className={`gray-text ${isNegative(header_first) ? "red-text" : ""} ${isPositive(header_first) ? "green-text" : ""}`}>
                    {header_first}
                    <Image src="/assets/images/forpages/salepage/inform.svg" alt="Image 1" />
                </Cell>
                <Cell className={`gray-text ${isNegative(header_second) ? "red-text" : ""} ${isPositive(header_second) ? "green-text" : ""}`}>
                    {header_second}
                    <Image src="/assets/images/forpages/salepage/inform.svg" alt="Image 2" />
                </Cell>
            </Row>
            <Row>
                <Cell className={`main-text ${isNegative(content_first) ? "red-text" : ""} ${isPositive(content_first) ? "green-text" : ""}`}>
                    {content_first}
                </Cell>
                <Cell className={`main-text ${isNegative(content_second) ? "red-text" : ""} ${isPositive(content_second) ? "green-text" : ""}`}>
                    {content_second}
                </Cell>
            </Row>
        </CustomTableContainer>
    );
};

export default InformTable;
