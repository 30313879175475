import React from "react";
import styled from "styled-components";
import IndexButton from "../../functional/buttons/IndexButton";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
    min-width: 100%;
    
`;


const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  min-width: 10rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7,1fr); 
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;


interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const WorkerWithdrawalTable:React.FC<TableProps> = ({ data }) => {

    return (
        <BorderBlock>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>User</TableHeader>
                    <TableHeader>Address / Memo</TableHeader>
                    <TableHeader>Coin / Network</TableHeader>
                    <TableHeader>Amount</TableHeader>
                    <TableHeader>Date</TableHeader>
                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                            <IndexButton text = {item.status}/>
                        </TableCell>
                        <TableCell>{item.user}</TableCell>
                        <TableCell>{item.address}</TableCell>
                        <TableCell>{item.coin}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.date}</TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default WorkerWithdrawalTable;