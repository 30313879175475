import styled from "styled-components";
import React from "react";

const CustomButton  = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    
    `;

const ButtonSRC: React.FC = () => {
    return (
        <CustomButton>
            <img src="/assets/images/icons/magnifier.svg" alt="Magnifer"/>
        </CustomButton>
    );
};
export default ButtonSRC;