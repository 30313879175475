import styled from "styled-components";

const HeaderFAC = styled.header`
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    //margin-top: 15rem;
    margin-bottom: 3rem;
     
    `;
export default HeaderFAC;