import React, { useEffect, useState } from "react";
import ButtonSTL from "../../styles/functional/buttons/ButtonSTL";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderLogo from "../../styles/headers/HeaderLogo";
import CustomLink from "../../styles/functional/links/CustomLink";
import BlockH from "../../styles/blosks/BlockH";
import Login from "../modals/LogIn";
import MainCustomDropdown from "../../styles/functional/dropdowns/MainCustomDropdown";
import ButtonSRC from "../../styles/functional/buttons/ButtonSRC";
import ButtonPlanet from "../../styles/functional/buttons/ButtonPlanet";
import ButtonProfile from "../../styles/functional/buttons/ButtonProfile";
import { useGetUserProfileQuery } from "../../redux/apiService";

const Header: React.FC = () => {
  const { data, error, isLoading } = useGetUserProfileQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const marketOptions = ["Option 1", "Option 2", "Option 3"];
  const marketText = "Торговля";
  const futuresText = "Фьючерсы";
  const squareText = "Square";
  const aboutText = "Подробнее";
  const authLink = "/auth-page";
  const authText = "Регистрация";

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (error) {
      // Обрабатываем ошибку 401 или другую логику
      console.error("Ошибка при запросе профиля:", error);
    }
  }, [error]);

  const isAuthenticated = !error && data;

  return (
    <ContainerH
      style={{
        width: "100%",
        position: "fixed",
        backgroundColor: "#191B21",
        marginTop: "0",
        zIndex: "999",
        marginLeft: "0",
        marginRight: "0",
        padding: "1rem 1rem 0 1rem",
      }}
    >
      <ContainerH>
        <BlockH>
          <img
            src="/assets/images/forpages/mainpage/main_logo.svg"
            alt="Main Logo"
          />
        </BlockH>
        <BlockH>
          <HeaderLogo to="/">WAVECURRENCY</HeaderLogo>
        </BlockH>
        {/*<BlockH>*/}
        {/*  <CustomLink to={"/sale-page"}>Купить криптовалюту</CustomLink>*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <CustomLink to={"/markets"}>Рынки</CustomLink>*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <CustomLink to={"/spot-trading"}>Spot trading</CustomLink>*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <MainCustomDropdown options={marketOptions} buttonText={marketText} />*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <MainCustomDropdown*/}
        {/*    options={marketOptions}*/}
        {/*    buttonText={futuresText}*/}
        {/*  />*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <CustomLink to={"/"}>Earn</CustomLink>*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <MainCustomDropdown options={marketOptions} buttonText={squareText} />*/}
        {/*</BlockH>*/}
        {/*<BlockH>*/}
        {/*  <MainCustomDropdown options={marketOptions} buttonText={aboutText} />*/}
        {/*</BlockH>*/}
        <BlockH>
          <CustomLink to={"/trading"}>Trading</CustomLink>
        </BlockH>
        <BlockH>
          <CustomLink to={"/p2p"}>P2P</CustomLink>
        </BlockH>
        <BlockH>
          <CustomLink to={"/staking"}>Staking</CustomLink>
        </BlockH>
        <BlockH>
          <CustomLink to={"/wallet"}>Wallet</CustomLink>
        </BlockH>
        <BlockH>
          <CustomLink to={"/swap"}>Swap</CustomLink>
        </BlockH>
      </ContainerH>
      <ContainerH>
        <BlockH>
          <ButtonSRC />
        </BlockH>
        {isAuthenticated ? (
          <BlockH>
            <ButtonProfile />
          </BlockH>
        ) : (
          <>
            <BlockH>
              <CustomLink to={"/auth-page/login"} onClick={openModal}>
                Войти
              </CustomLink>
            </BlockH>
            <BlockH>
              <ButtonSTL href={authLink} buttonText={authText} />
            </BlockH>
            <BlockH>
              <ButtonPlanet />
            </BlockH>
          </>
        )}
      </ContainerH>
      <Login isOpen={isModalOpen} onClose={closeModal} />
    </ContainerH>
  );
};
export default Header;
