import React from "react";
import { Outlet } from "react-router-dom";
import ContainerH from "../styles/containers/ContainerH";
import NavLeftAccount from "../components/forAccountPage/NavLeftAccount";
import ContainerV from "../styles/containers/ContainerV";

const AssetsPage = () => {
  return (
    <>
      <ContainerH
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <NavLeftAccount />
        <ContainerV
          style={{
            marginTop: "10rem",
            marginLeft: "20rem",
          }}
        >
          <Outlet />
        </ContainerV>
      </ContainerH>
    </>
  );
};
export default AssetsPage;
