import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerUsersTable from "../../styles/tables/forWorker/WorkerUsersTable";






const WorkerUsers:React.FC = () => {
    const testData = [
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-5",
            status: "Offline",
            username: "Intale",
            domain: "sweblink.com",
            deposits: "1",
            registered: "394.79$",
            location: "RU, Russia, Stavropol",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10, Chrome 12",
            ip: "178.34.150.156"
        },
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-6",
            status: "Online",
            username: "User123",
            domain: "user123.com",
            deposits: "3",
            registered: "1500.00$",
            location: "US, United States, California",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Linux, Firefox 88",
            ip: "203.0.113.0"
        },
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-7",
            status: "Offline",
            username: "AdminUser",
            domain: "adminuser.com",
            deposits: "4",
            registered: "2500.00$",
            location: "RU, Russia, Moscow",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10, Edge 91",
            ip: "192.168.1.2"
        },
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-8",
            status: "Online",
            username: "GuestUser",
            domain: "guestuser.com",
            deposits: "5",
            registered: "3000.00$",
            location: "US, United States, Texas",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Mac OS, Safari 15",
            ip: "203.0.113.1"
        },
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-9",
            status: "Offline",
            username: "RegularUser",
            domain: "regularuser.com",
            deposits: "6",
            registered: "3500.00$",
            location: "RU, Russia, Novosibirsk",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Linux, Chrome 91",
            ip: "192.168.1.3"
        },
        {
            imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
            user: "Anonymous-User-10",
            status: "Online",
            username: "PremiumUser",
            domain: "premiumuser.com",
            deposits: "7",
            registered: "4000.00$",
            location: "US, United States, New Jersey",
            imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Mac OS, Firefox 92",
            ip: "203.0.113.2"
        }
    ];
  return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Users</HeadBlock>

          <WorkerUsersTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerUsers;