import React, { useEffect } from "react";
import { HeadBlock } from "../../forWorkerPage/WorkerBinding";
import ContainerV from "../../../styles/containers/ContainerV";
import AdminHeader from "../AdminHeader";
import { Outlet } from "react-router-dom";
import UserProfileHeadBlock from "./UserProfileHeadBlock";
import AdminNavPanel from "../AdminNavPanel";

import { useRetrieveUserByIdQuery } from "../../../redux/apiService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const UserProfile: React.FC = () => {
  // const { data: profile, error, isLoading } = useGetUserProfileQuery();

  const userId = useSelector((state: RootState) => state.selectedUser.id);

  useEffect(() => {
    console.log("userId: ", userId);
  }, [userId]);

  const {
    data: profile,
    error,
    isLoading,
    isSuccess,
  } = useRetrieveUserByIdQuery(
    { id: userId, search: "", limit: 10, offset: 0 },
    { skip: !userId }, // Запрос не выполнится, если userId пустой
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading profile: {error.toString()}</div>;
  }
  console.log("Profile data:", profile);

  return (
    <>
      <AdminHeader />
      <ContainerV
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding: "0 5rem 0 20rem",
        }}
      >
        <HeadBlock>User Profile</HeadBlock>
        <UserProfileHeadBlock
          username={profile?.body.username}
          avatar_path="/assets/images/forpages/adminpanel/default-avatar 1.svg"
          user_mail_address={profile?.body.email}
          user_location="RU, Russia, St Petersburg"
          flag_path="/assets/images/forpages/adminpanel/ru 1.svg"
          last_activity="7 сек. назад / Windows 10, Chrome 12"
          total_balance="$0"
          deposits="$0"
          auth_count={77}
          today_logs={2}
          uses_a_domain="https:// 185.103.102.71"
        />
        <AdminNavPanel />
        <div style={{ width: "100%", alignSelf: "center" }}>
          {" "}
          {/* Добавляем контейнер для <Outlet/> */}
          <Outlet />
        </div>
      </ContainerV>
    </>
  );
};
export default UserProfile;
