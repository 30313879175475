import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import AdminHeaderLogo from "../../styles/headers/HeaderLogo";
import BlockH from "../../styles/blosks/BlockH";
import ButtonProfile from "../../styles/functional/buttons/ButtonProfile";

const AdminHeader: React.FC = () => {

    return (
        <ContainerH
            style={{
                position:"fixed",
                top:"0",
                width:"100%",
                marginTop:"0",
                zIndex:"999",
                backgroundColor:"#191B21",
                padding:"0.75rem 1rem 0.5rem 0 ",
        }}>
            <ContainerH>
                <BlockH>
                    <img src="/assets/images/forpages/mainpage/main_logo.svg" alt="Main Logo"/>
                </BlockH>
                <BlockH>
                    <AdminHeaderLogo to="/">WAVECURRENCY</AdminHeaderLogo>
                </BlockH>

            </ContainerH>
            <ContainerH>
                <BlockH>
                    <ButtonProfile/>
                </BlockH>
            </ContainerH>
        </ContainerH>
    );
};
export default AdminHeader;