import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import WorkerUtilityTable from "../../styles/tables/forWorker/WorkerUtilityTable";

const WorkerUtility:React.FC = () => {
    const testData = [
        {
            title: "Enable/Disable Fake Withdraw PENDING for ALL users",
            describe: "При нажатии на кнопку, у всех ваших пользователей включится функция \"Fake Withdraw Pending\" в настройках пользователя."
        },
        {
            title: "Feature X Activation for Selected Users",
            describe: "Activate Feature X for a subset of users who meet specific criteria."
        },
        {
            title: "Update User Permissions",
            describe: "Adjust permissions for users based on their role within the system."
        },
        {
            title: "Introduce New Security Measures",
            describe: "Implement enhanced security protocols to protect user data."
        },
        {
            title: "Rollout New UI Design",
            describe: "Deploy a redesigned user interface for improved usability and aesthetics."
        },
        {
            title: "Schedule System Maintenance",
            describe: "Plan and execute maintenance tasks to ensure optimal system performance."
        }
    ];


    return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Utility</HeadBlock>
          <BlockGreyText>Enable/Disable Features for all users</BlockGreyText>
          <WorkerUtilityTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerUtility;