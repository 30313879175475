import React, { useEffect, useState, useCallback } from 'react';
import styled from "styled-components";

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  gap: 1rem;
  font-size: 20px;
  `;

const CountdownItem = styled.p`
  display: flex;
  align-items: flex-end; /* Выравнивание по нижнему краю */
  margin: 0; /* Убираем внешние отступы */
  font-weight: 700;
  font-size: 20px;
`;

const Subscript = styled.sub`
  margin-bottom: 7px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  
`;

interface CountdownProps {
    targetDate: Date;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
    const calculateTimeRemaining = useCallback(() => {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        if (difference <= 0) {
            // Handle the case when the target date is in the past
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        return {
            days: days,
            hours: hours % 24,
            minutes: minutes % 60,
            seconds: seconds % 60,
        };
    }, [targetDate]);

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeRemaining]);

    return (
        <CountdownContainer>
            <CountdownItem>
                {timeRemaining.days}
                <Subscript>D</Subscript>
            </CountdownItem>
            <CountdownItem>
                {timeRemaining.hours}
                <Subscript>H</Subscript>
            </CountdownItem>
            <CountdownItem>
                {timeRemaining.minutes}
                <Subscript>M</Subscript>
            </CountdownItem>
            <CountdownItem>
                {timeRemaining.seconds}
                <Subscript>S</Subscript>
            </CountdownItem>
        </CountdownContainer>
    );
};

export default Countdown;
