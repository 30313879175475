import React, { useState } from "react";
import styled from "styled-components";

const SelectWithSearchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  border: 1px solid #293039;
  border-radius: 20px;
  padding: 0.5rem;

  align-items: center;
`;

const SelectWithSearchButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #191b21;
  color: #fff;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
  &:hover {
    color: #efb608;
  }
`;

const SelectWithSearchItem = styled.li`
  padding: 10px;
  cursor: pointer;
  min-width: 100%;
  max-width: 100%;
  background-color: #1e2329;
  &:hover {
    background-color: #191b21;
  }
`;

const ArrowImg = styled.img<{ isOpen: boolean }>`
  margin-left: 5px;
  align-self: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const SelectWithSearchList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  //left: 5%;
  background-color: #1e2329;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
  display: ${({ isOpen }) =>
    isOpen ? "block" : "none"}; // Управление видимостью
`;

const SearchInput = styled.input`
  max-width: 90%;
  min-width: 90%;
  padding: 0.5rem;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #293039;
  background-color: #1e2329;
  color: white;
  font-size: 16px;
`;

const ScrollableList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px; // Ограничение по высоте
  overflow-y: auto; // Включение вертикальной прокрутки
`;

interface SelectOptions {
  value: string;
}

interface SelectWithSearchProps {
  options: SelectOptions[];
}

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState<SelectOptions>(
    options[0],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: SelectOptions) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <SelectWithSearchContainer>
      <SelectWithSearchButton onClick={toggleDropdown}>
        {selectedOption.value}
        <ArrowImg
          src="/assets/images/icons/arrow.svg"
          alt="arrow"
          isOpen={isOpen}
        />
      </SelectWithSearchButton>
      <SelectWithSearchList isOpen={isOpen}>
        <li>
          <SearchInput
            type="text"
            placeholder="Поиск..."
            value={searchValue}
            onChange={handleSearchChange}
          />
        </li>
        <ScrollableList>
          {filteredOptions.map((option) => (
            <SelectWithSearchItem
              key={option.value}
              onClick={() => handleSelect(option)}
            >
              {option.value}
            </SelectWithSearchItem>
          ))}
        </ScrollableList>
      </SelectWithSearchList>
    </SelectWithSearchContainer>
  );
};
export default SelectWithSearch;
