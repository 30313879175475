import styled from "styled-components";

const BasicDiv = styled.div`
  color: #fff;
  margin: 3% 18.5% 5% 18.5%!important;
  //display: flex;
  //justify-content: center;
  //flex-direction: column;
  
  `;
export default BasicDiv;

  
