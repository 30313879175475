import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface MessagePresetsDropdownProps {
  options: string[];
  buttonText: string;
}

const MessagePresetsDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #1e2329;
  justify-content: center;
  color: #848e9c;
  border: 1px solid #1e2329;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  width: 25rem;
  height: 3rem;
  text-align: center;
  //min-width: 100%;
  &:hover {
    color: #ffffff;
  }
`;

const DropdownList = styled.ul<{ isAbove: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  //top: 100%;
  top: ${({ isAbove }) => (isAbove ? "auto" : "100%")};
  bottom: ${({ isAbove }) => (isAbove ? "100%" : "auto")};
  left: 0;
  background-color: #1e2329;
  display: none;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  min-width: 100px;
  background-color: #1e2329;
  &:hover {
    background-color: #191b21;
  }
`;

const ArrowImg = styled.img<{ isOpen: boolean }>`
  margin-left: 0.5rem;
  align-self: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const MessagePresetsDropdown: React.FC<
  MessagePresetsDropdownProps & { onSelectPreset?: (option: string) => void }
> = ({ options, buttonText, onSelectPreset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(buttonText);
  const [isAbove, setIsAbove] = useState(false); // состояние для контроля позиции выпадающего списка
  const dropdownRef = useRef<HTMLUListElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    if (onSelectPreset) {
      onSelectPreset(option); // Вызываем функцию обратного вызова с выбранным значением
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const { bottom } = dropdownRef.current.getBoundingClientRect();
      if (bottom > window.innerHeight) {
        setIsAbove(true); // если низ списка выходит за пределы экрана, позиционируем список сверху
      } else {
        setIsAbove(false);
      }
    }
  }, [isOpen]);

  return (
    <MessagePresetsDropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {selectedOption}
        <ArrowImg
          src="/assets/images/icons/grey-down-arrow.svg"
          alt="Arrow"
          isOpen={isOpen}
        />
      </DropdownButton>
      <DropdownList
        ref={dropdownRef}
        isAbove={isAbove}
        style={{ display: isOpen ? "block" : "none" }}
      >
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={() => handleOptionClick(option)}>
            {option}
          </DropdownItem>
        ))}
      </DropdownList>
    </MessagePresetsDropdownContainer>
  );
};

export default MessagePresetsDropdown;
