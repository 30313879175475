import React from "react";
import styled from "styled-components";

const CustomTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%; /* Ширина строки (можете настроить по вашему усмотрению) */
  align-items: center;
`;

const LeftCell = styled.div`
  flex: 1;
  padding: 8px;
  font-weight: 700;
  font-size: 20px;
  text-align: left;
`;

const RightCell = styled.div`
  flex: 1;
  padding: 8px;
  text-align: right;
  font-weight: 700;
    &.green-text {
    color: #10BA7B;
    font-size: 20px;
  }
  &.big-text  {
    font-size: 32px;
  }
`;

const GraficHeaderTable = () => {
    return (
        <CustomTableContainer>
            <Row>
                <LeftCell><img src="/assets/images/forpages/salepage/btc-eth.svg" alt=""/></LeftCell>
                <RightCell className="green-text">+105.23%</RightCell>
            </Row>

            <Row>
                <LeftCell>BTC/ETH</LeftCell>
                <RightCell className="big-text">13,229.46$</RightCell>
            </Row>
        </CustomTableContainer>
    );
};

export default GraficHeaderTable;
