import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const CustomBlockContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
`;

const CustomButton = styled(Link)<{ isActive: boolean }>`
  position: relative;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({isActive}) => (isActive ? "#ffffff" : "#848E9C")};
  text-decoration: none;

  &:hover {
    //color: ${({ isActive }) => (isActive ? "#848E9C" : "#EFB608")};
    color: #EFB608;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ isActive }) => (isActive ? "#EFB608" : "transparent")};
  }

    `;

interface AccountPageNavProps {
    initialActiveButton: number;
}

const AccountPageNav: React.FC<AccountPageNavProps> = ({initialActiveButton}) => {
    // По умолчанию activeButton равна 1
    const [activeButton, setActiveButton] = useState(initialActiveButton);

    const handleButtonClick = (buttonNumber: number) => {
        setActiveButton(buttonNumber);
    };

    return (
        <CustomBlockContainer>
            <CustomButton to="/account/main/actives" isActive={activeButton === 1} onClick={() => handleButtonClick(1)}>
                Активы
            </CustomButton>
            <CustomButton to="/account/main/popular" isActive={activeButton === 2} onClick={() => handleButtonClick(2)}>
                Популярное
            </CustomButton>
            <CustomButton to="/account/main/new-listing" isActive={activeButton === 3} onClick={() => handleButtonClick(3)}>
                Новый листинг
            </CustomButton>
            <CustomButton to="/account/main/favourites" isActive={activeButton === 4} onClick={() => handleButtonClick(4)}>
                Избранное
            </CustomButton>
            <CustomButton  to="/account/main/growth" isActive={activeButton === 5} onClick={() => handleButtonClick(5)}>
                Показывают рост
            </CustomButton>
            <CustomButton to="/account/main/volume" isActive={activeButton === 6} onClick={() => handleButtonClick(6)}>
                Объём за 24ч
            </CustomButton>
        </CustomBlockContainer>
    );
};

export default AccountPageNav;
