import styled from "styled-components";
import React from "react";

const ButtonBackContainer = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #848e9c;
  align-self: flex-start;
`;
const ImgContainer = styled.img`
  margin-right: 1.5rem;
`;

interface ButtonBackProps {
  onClick?: () => void;
  style?: React.CSSProperties;
}
const ButtonBack: React.FC<ButtonBackProps> = ({ onClick, style }) => {
  return (
    <ButtonBackContainer onClick={onClick} style={style}>
      <ImgContainer
        src="/assets/images/icons/arrow-left-grey.svg"
        alt="Arrow-Left"
      />
      Назад
    </ButtonBackContainer>
  );
};
export default ButtonBack;
