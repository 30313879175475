import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import StaticsCard from "../../styles/cards/StaticsCard";
import ContainerH from "../../styles/containers/ContainerH";
import styled from "styled-components";
import HeaderALF from "../../styles/headers/HeaderALF";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import HeaderALS from "../../styles/headers/HeaderALS";
import {HeadBlock} from "./WorkerBinding";


const GraficImage = styled.img`
  max-width: 98%;
`;


const WorkerStatics:React.FC = () => {

    const header_today ="Today profit";
    const amount_today  =0.00;
    const image_path_today ="/assets/images/forpages/adminpanel/Today.svg";
    const index_text_today = "+1.23%"
    const header_month ="Month profit";
    const amount_month  =0.00;
    const image_path_month ="/assets/images/forpages/adminpanel/Month.svg";
    const index_text_month = "+1.23%"
    const header_time ="All time profit";
    const amount_time  =0.00;
    const image_time ="/assets/images/forpages/adminpanel/All time.svg";
    const index_time = "+1.23%"
    const header_users ="Binded users";
    const amount_users  =0.00;
    const image_users ="/assets/images/forpages/adminpanel/Users.svg";
    const index_users = "+1.23%"


  return (
    <ContainerV
        style={{
            alignSelf: "flex-start",
            justifyContent: "flex-start",
            alignContent:"center",
            padding:"0 5rem 0 5rem",
    }}>
        <HeadBlock>Statistics</HeadBlock>
        <BlockGreyText>Ваша статистика обновляется раз в 3 минуты, общая - раз в 5 минут.
        </BlockGreyText>
        <ContainerH style={{
            marginLeft:"0",
            justifyContent: "center",
            gap: "4%",
            minWidth:"100%",
            maxWidth:"100%"
        }}>
            <StaticsCard
                header={header_today}
                amount={amount_today}
                image_path={image_path_today}
                index_text={index_text_today}
            />
            <StaticsCard
                header={header_month}
                amount={amount_month}
                image_path={image_path_month}
                index_text={index_text_month}
            />
            <StaticsCard
                header={header_time}
                amount={amount_time}
                image_path={image_time}
                index_text={index_time}
            />
            <StaticsCard
                header={header_users}
                amount={amount_users}
                image_path={image_users}
                index_text={index_users}
            />
        </ContainerH>
        <ContainerH
            style={{
                margin:"0",
                justifyContent:"flex-start",
                gap: "4%",
                marginTop:"3rem"}}>
            <ContainerV style={{
                backgroundColor:"#191B21",
                border:"1px solid #293039",
                borderRadius:"1rem",
                padding:"1rem",
                width:"48%",
            }}>
                <ContainerH style={{margin:"0"}}>
                    <HeaderALF>Total deposits</HeaderALF>
                    <HeaderALTH style={{display:"flex",alignItems:"flex-end"}}>13,229.46<HeaderALS>$</HeaderALS></HeaderALTH>
                </ContainerH>
                <GraficImage src="/assets/images/forpages/salepage/market-grafic.svg" alt="Market grafic"/>
            </ContainerV>
            <ContainerV style={{
                backgroundColor:"#191B21",
                border:"1px solid #293039",
                borderRadius:"1rem",
                padding:"1rem",
                width:"48%",
            }}>
                <ContainerH>
                    <HeaderALF>Total users</HeaderALF>
                    <HeaderALTH style={{display:"flex",alignItems:"flex-end"}}>59<HeaderALS>users</HeaderALS></HeaderALTH>
                </ContainerH>
                <GraficImage src="/assets/images/forpages/salepage/market-grafic.svg" alt="Market grafic"/>
            </ContainerV>
        </ContainerH>
    </ContainerV>
  );
};

export default WorkerStatics;