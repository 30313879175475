import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import AuthHeaderLogo from "../../styles/headers/HeaderLogo";
import BlockH from "../../styles/blosks/BlockH";
import ButtonPlanet from "../../styles/functional/buttons/ButtonPlanet";

const AuthHeader: React.FC = () => {

    return (
        <ContainerH>
            <ContainerH>
                <BlockH>
                    <img src="/assets/images/forpages/mainpage/main_logo.svg" alt="Main Logo"/>
                </BlockH>
                <BlockH>
                    <AuthHeaderLogo to="/">WAVECURRENCY</AuthHeaderLogo>
                </BlockH>

            </ContainerH>
            <ContainerH>
                <BlockH>
                    <ButtonPlanet/>
                </BlockH>
            </ContainerH>
        </ContainerH>
    );
};
export default AuthHeader;