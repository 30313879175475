import React, { useState } from "react";
import styled from "styled-components";
import ButtonSTL from "../functional/buttons/ButtonSTL";
import SaleTradeDropdown from "../functional/dropdowns/SaleTradeDropdown";
import HeaderALS from "../headers/HeaderALS";
import ContainerH from "../containers/ContainerH";

interface ButtonProps {
    active: boolean;
}

const CustomBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #1E2329;
  border-radius: 20px;
  padding: 1.5rem;
  max-width: 100%;
  //margin-left: -10rem;
  align-self: flex-start; 
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Button = styled.button<ButtonProps>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#191B21" : "#1E2329")};
  color: ${({ active }) => (active ? "#fff" : "#848E9C")};
  font-weight: 700;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  width: 50%;
`;

const ContentContainer = styled.div`
  padding: 10px;
`;

const CurrencyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #293039;
  border-radius: 15px;
  padding: 1rem;
  margin-top: 1rem;
  gap: 1rem;
  

  `;

const TradeBlock = () => {
    const [activeButton, setActiveButton] = useState("button1");


    const options= [
        { text: "ETH", image: "/assets/images/cryptoicons/ETH-2.svg" },
        { text: "BTC", image: "/assets/images/cryptoicons/Bitcoin Coin.svg" },
        // Add more options as needed
    ];

    const ETH = "ETH";
    const ETHPath ="/assets/images/cryptoicons/ETH-2.svg";
    const authLink = "/auth-page";
    const authText = "Войти/Зарегистрироваться";


    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName);
    };

    return (
        <CustomBlockContainer style={{marginTop:"7rem"}}>
            <ButtonContainer>
                <Button
                    active={activeButton === "button1"}
                    onClick={() => handleButtonClick("button1")}
                >
                    Купить
                </Button>
                <Button
                    active={activeButton === "button2"}
                    onClick={() => handleButtonClick("button2")}
                >
                    Продать
                </Button>
            </ButtonContainer>
            <ContentContainer>
                {activeButton === "button1" &&
                    <div>
                    <CurrencyContainer>
                        <HeaderALS style={{color:"white"}}>Списать</HeaderALS>
                        <ContainerH style={{margin: "0", gap: "1rem"}}>
                         <HeaderALS>Пожалуйста, введите сумму</HeaderALS>
                        <SaleTradeDropdown buttonText={ETH} options={options} imgPath={ETHPath} />
                        </ContainerH>
                    </CurrencyContainer>
                    <CurrencyContainer>
                        <HeaderALS style={{color:"white"}}>Получить</HeaderALS>
                        <ContainerH style={{margin: "0"}}>
                        <HeaderALS>0.00</HeaderALS>
                            <SaleTradeDropdown buttonText={ETH} options={options} imgPath={ETHPath} />
                        </ContainerH>
                        </CurrencyContainer>
                    </div>}
                {activeButton === "button2" &&
                    <div>
                        <CurrencyContainer>
                            <HeaderALS style={{color:"white"}}>Списать</HeaderALS>
                            <ContainerH style={{margin: "0", gap: "1rem"}}>
                                <HeaderALS>Пожалуйста, введите сумму</HeaderALS>
                                <SaleTradeDropdown buttonText={ETH} options={options} imgPath={ETHPath} />
                            </ContainerH>
                        </CurrencyContainer>
                        <CurrencyContainer>
                            <HeaderALS style={{color:"white"}}>Получить</HeaderALS>
                            <ContainerH style={{margin: "0"}}>
                                <HeaderALS>0.00</HeaderALS>
                                <SaleTradeDropdown buttonText={ETH} options={options} imgPath={ETHPath} />
                            </ContainerH>
                        </CurrencyContainer>
                    </div>}
            </ContentContainer>
            <div style={{
                width: "98%",
                marginTop: "8rem",
                display:"flex",
                justifyContent:"center"
            }}>
                <ButtonSTL href={authLink} buttonText={authText} style={{width:"100%"}}/>
            </div>
        </CustomBlockContainer>
    );
};

export default TradeBlock;
