import React from "react";
import UserProfileNav from "../../../styles/navs/UserProfileNav";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerH from "../../../styles/containers/ContainerH";
import styled from "styled-components";
import ContainerV from "../../../styles/containers/ContainerV";
import HeaderALTH from "../../../styles/headers/HeaderALTH";

const CustomInput = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  color: white;
  padding: 1rem 0.5rem 0.5rem 0.5rem; // Увеличено значение padding-left до 0.5rem
  margin-bottom: 0.5rem;
  width: 100%;
  height: 15rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
  text-align: left;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 80%;

  &:hover {
    background-color: #ffff66;
  }
`;

const AlertPopUp: React.FC = () => {
  return (
    <>
      <UserProfileNav initialActiveButton={7} />
      <BorderBlock>
        <HeaderALTH>Send alert pop-up</HeaderALTH>
        <ContainerH style={{ margin: "0", marginTop: "3%" }}>
          <div
            style={{
              width: "50%",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Title>Message</Title>
            <CustomInput defaultValue="Type a message" />
          </div>
          <ContainerV style={{ width: "50%" }}>
            <SubmitButton>Send alert</SubmitButton>
            <SubmitButton>Send to all users</SubmitButton>
          </ContainerV>
        </ContainerH>
      </BorderBlock>
    </>
  );
};
export default AlertPopUp;
