import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerWithdrawalTable from "../../styles/tables/forWorker/WorkerWithdrawalTable";

const WorkerWithdrawal:React.FC = () => {
    const testData = [
        {
            id: "38",
            status: "Completed",
            user: "toomaxprop2p@gmail.com",
            address: "TQ31V8NvvhL7cX5sjQh4P5jQepuriK8ipk",
            coin: "USDTTRC20",
            amount: "4423.22567238",
            date: "2024/03/22 14:39:44"
        },
        {
            id: "39",
            status: "Pending",
            user: "anotheruser@example.com",
            address: "9j8k7h6g5f4e3d2",
            coin: "BTC",
            amount: "0.00123456",
            date: "2024/03/21 15:30:00"
        },
        {
            id: "40",
            status: "Failed",
            user: "thirduser@domain.com",
            address: "4e3d2c1b0a9f8e7d6c5b4a3b2",
            coin: "ETH",
            amount: "0.00012345",
            date: "2024/03/20 16:45:23"
        },
        {
            id: "41",
            status: "Completed",
            user: "fourthuser@domain.net",
            address: "b2a1c9d8e7f6g5h4i3j2",
            coin: "LTC",
            amount: "10.00000000",
            date: "2024/03/19 17:30:45"
        },
        {
            id: "42",
            status: "Pending",
            user: "fifthuser@domain.org",
            address: "j2i1h9g8f7e6d5c4b3a2",
            coin: "XRP",
            amount: "0.00000001",
            date: "2024/03/18 18:15:12"
        },
        {
            id: "43",
            status: "Failed",
            user: "sixthuser@domain.com",
            address: "a1b2c3d4e5f6g7h8i9j0",
            coin: "BCH",
            amount: "0.00000002",
            date: "2024/03/17 19:00:30"
        },
        {
            id: "44",
            status: "Completed",
            user: "seventhuser@domain.net",
            address: "k1l2m3n4o5p6q7r8s9t0",
            coin: "EOS",
            amount: "0.00000003",
            date: "2024/03/16 20:45:45"
        },
        {
            id: "45",
            status: "Pending",
            user: "eighthuser@domain.org",
            address: "v1u2i3o4p5q6r7s8t9u0",
            coin: "ADA",
            amount: "0.00000004",
            date: "2024/03/15 21:30:00"
        },
        {
            id: "46",
            status: "Failed",
            user: "ninthuser@domain.com",
            address: "x1y2z3a4b5c6d7e8f9g0",
            coin: "LINK",
            amount: "0.00000005",
            date: "2024/03/14 22:15:15"
        }
    ];


    return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Fake Withdrawal List</HeadBlock>
          <WorkerWithdrawalTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerWithdrawal;