import React from "react";
import styled from "styled-components";
import ImageChangingButton from "../functional/buttons/ButtonSubMenuMarket";


const AccountSubNavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: 2rem;

  `;

interface AccountSubNavProps{
    names: string[];
}

const AccountSubNav: React.FC<AccountSubNavProps> = ({names}) => {

  return (
      <AccountSubNavContainer>
          {names.map((name, index) => (
              <ImageChangingButton key = {index} name={name}/>
          ))}


      </AccountSubNavContainer>


  );
};
export default AccountSubNav;