import React from "react";
import styled from "styled-components";
import ContainerV from "../containers/ContainerV";
import ContainerH from "../containers/ContainerH";



const StaticsCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  max-width: 100%;
  min-width: 22%;
  overflow: hidden;
  
`;

const CardHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #848E9C;
  justify-content: flex-start;
  align-self: flex-start;
  
`;

const Amount = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: white;
  justify-content: flex-start;
  align-self: flex-start;
`;

const Subscript = styled.sub`
  margin-bottom: 7px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-left: -4rem;
`;

// const IndexBlock = styled.div<{ isNegative: boolean }>`
//
//   font-size: 16px;
//   font-weight: 700;
//   text-align: left;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//
//   color: ${props => (props.isNegative? '#F6465D' : '#10BA7B')};
//   &.red-text {
//     color: #F6465D;
//   }
//
//   &.green-text {
//     color: #10BA7B;
//   }
//
//   .arrow-icon {
//     width: 20px;
//     margin-left: 5px;
//   }
// `;
//
// const ArrowIcon = styled.img`
//   width: 20px;
//   margin-left: 5px;
// `;
//
// const BlockIndexText = styled.div`
//   font-size: 16px;
//   font-weight: 500;
//   color: #848E9C;
//   white-space: nowrap;
//   margin-left: 1rem;
//
//
// `;

interface StaticsCardProps {
    header: string;
    amount: number;
    image_path: string;
    index_text: string;

}

const StaticsCard: React.FC<StaticsCardProps> = ({header, amount, image_path, index_text}) => {
  //const isNegative = index_text.includes('-');
    return <>
          <StaticsCardContainer>
              <ContainerV>
                  <CardHeader>{header}</CardHeader>
                  <Amount>{amount.toFixed(2)}
                  <Subscript>$</Subscript>
                  </Amount>
                  {/*<ContainerH>*/}
                  {/*    <IndexBlock isNegative={isNegative}>{index_text}*/}
                  {/*        {isNegative? (*/}
                  {/*            <>*/}
                  {/*                  <ArrowIcon src="/assets/images/icons/fall.svg" alt="fall"/>*/}
                  {/*                  <BlockIndexText> Down from yesterday</BlockIndexText>*/}
                  {/*            </>*/}
                  {/*            ):(*/}
                  {/*             <>*/}
                  {/*                  <ArrowIcon src="/assets/images/icons/growth.svg" alt="grow"/>*/}
                  {/*                  <BlockIndexText> Up from yesterday</BlockIndexText>*/}
                  {/*             </>*/}
                  {/*            )}*/}
                  {/*    </IndexBlock>*/}
                  {/*</ContainerH>*/}

              </ContainerV>
              <Image src={image_path} alt={header}/>
          </StaticsCardContainer>
  </>;
};

export default StaticsCard;
