import React, { useState } from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";

// Step 1: Define a TypeScript interface for your custom props
interface MenuItemProps {
    isActive: boolean;
}

// Step 2: Extend the props of your styled component
const MenuItem = styled.div<MenuItemProps>`
  padding: 1rem 4rem 1rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #848E9C;
  //align-self: flex-start;
  min-width: 15rem;
  text-align: left;



  &:hover {
    background-color: #293039;
    color: white;
  }

  ${props => props.isActive && `
    background-color: #293039;
    color: white;
 `}
`;

const SideBar = styled.div`
  position: fixed;
  top: 0; /* Устанавливаем верхнюю границу элемента на 0 */
  left: 0; /* Устанавливаем левую границу элемента на 0 */
  padding-top: 7rem;
  background-color: #191B21;
  margin-bottom: 5rem;
  padding-right: 1rem;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 12rem;
  max-width: 20rem;
  overflow-y: hidden; /* Изменено на hidden для начала */

  &.scrollable {
    overflow-y: auto; /* Класс для включения скролла */
    max-height: 90vh; /* Максимальная высота, после которой появится полоса прокрутки */
  }
`;

const SideButton = styled.button`
    background-color: #FCD535;
    font-weight: 700;
    font-size: 16px;
    margin: 2rem 0 2rem 0;
    padding: 1rem 3rem 1rem 3rem;  
    border-radius: 4px;
  
  &:hover {
    background-color: #FFFF66;
  }
`;

const MenuItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  //align-self: flex-start; 
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  &:hover{
    color: #FCD535;
  }
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: ${({ isOpen }) => (isOpen? 'block' : 'none')};
  background-color: #191B21;
  width: 100%;
  z-index: 1;
`;

const DropdownItem = styled(MenuLink)`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #848E9C;
  &:hover {
    background-color: #293039;
    color: white;
  }
`;


const WorkerNavPanel = () => {
    const [activeItem, setActiveItem] = useState<number | null>(null);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const points = [
        {text: 'Binding',  icon: "/assets/images/forpages/adminpanel/Binding.svg", path: "binding"},
        {text: 'Statics', icon: "/assets/images/forpages/adminpanel/Statics.svg", path: "statics"},
        {text: 'Deposits', icon: "/assets/images/forpages/adminpanel/Deposits.svg", path: "deposits"},
        {text: 'Withdraw', icon: "/assets/images/forpages/adminpanel/Withdraw.svg", path: "withdraw"},
        {text: 'Wallet connect', icon: "/assets/images/forpages/adminpanel/Wallet connect.svg", path: "wallet-connect"},
        {text: 'Users', icon: "/assets/images/forpages/adminpanel/Users.svg", path: "users"},
        {text: 'Logs', icon: "/assets/images/forpages/adminpanel/Logs.svg", path: "logs"},
        {text: 'KYC List', icon: "/assets/images/forpages/adminpanel/KYC List.svg", path: "kyc-list"},
        {text: 'Support', icon: "/assets/images/forpages/adminpanel/Support.svg", path: "support"},
        {text: 'Trading course', icon: "/assets/images/forpages/adminpanel/Trading course.svg", path: "trading-course"},
        {text: 'Edit', subItems: [
                {text: 'Terms', path: "terms"},
                {text: 'Aml-kyc-policy', path: "aml-kyc-policy"}
            ], icon: "/assets/images/forpages/adminpanel/Edit.svg"},
        {text: 'Domains', icon: "/assets/images/forpages/adminpanel/Domains.svg", path: "domains"},
        {text: 'Utility', icon: "/assets/images/forpages/adminpanel/Utility.svg", path: "utility"},
        {text: 'Coins', icon: "/assets/images/forpages/adminpanel/Coins.svg", path: "coins"},
        {text: 'Video record', icon: "/assets/images/forpages/adminpanel/Video record.svg", path: "video-record"},
        {text: 'Support presents', icon: "/assets/images/forpages/adminpanel/Support presents.svg", path: "support-presents"},
        {text: 'Settings', icon: "/assets/images/forpages/adminpanel/Settings.svg", path: "settings"},]

    const handleClick = (index: number, event?: React.MouseEvent<HTMLDivElement>) => {
        if (points[index].subItems) {
            event?.preventDefault(); // Предотвращаем действие по умолчанию
            setIsOpen(!isOpen); // Переключаем состояние дропдауна
        } else {
            setActiveItem(index);
        }
    };


    return (
        <SideBar
            className={isMouseOver? 'scrollable' : ''}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}>
            {points.map((item, index) => (
                <React.Fragment key={index}>
                    <MenuLink to={item.path || '/'} key={`${item.text}-link`} onClick={(e) => e.stopPropagation()}>
                        <MenuItem
                            isActive={activeItem === index}
                            onClick={(e) => handleClick(index, e)} // Передаем событие
                        >
                            <MenuItemIcon src={item.icon} alt={item.text} />
                            {item.text}
                        </MenuItem>
                    </MenuLink>

                    {item.subItems && (
                        <DropdownMenu isOpen={isOpen}>
                            {item.subItems.map((subItem, subIndex) => (
                                <DropdownItem key={subIndex} to={subItem.path}>
                                    {subItem.text}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    )}
                </React.Fragment>
            ))}
            <SideButton>Go to profile</SideButton>
        </SideBar>
    );
};

export default WorkerNavPanel;
