import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerDepositsTable from "../../styles/tables/forWorker/WorkerDepositsTable";

const WorkerDeposits:React.FC = () => {
    const testData = [
        {
            transactionId: "6207632",
            user: "zhosikovich@mail.ru",
            address: "LX3H2Them4S6MpJMqDkW1Ra2r9DQxLwNW5",
            realStatus: "Failed",
            fakeStatus: "Completed",
            coinType: "USDTTRC20",
            amount: "2.32636031 / 202.42$",
            date: "23ч. назад",
            paidOut: "23ч. назад"
        },
        {
            transactionId: "1234567",
            user: "anotheruser@example.com",
            address: "9j8k7h6g5f4e3d2",
            realStatus: "Completed",
            fakeStatus: "Pending",
            coinType: "BTC",
            amount: "0.00123456",
            date: "1д. назад",
            paidOut: "1д. назад"
        },
        {
            transactionId: "9876543",
            user: "thirduser@domain.com",
            address: "4e3d2c1b0a9f8e7d6c5b4a3b2",
            realStatus: "Pending",
            fakeStatus: "Failed",
            coinType: "ETH",
            amount: "0.00012345",
            date: "2д. назад",
            paidOut: "2д. назад"
        },
        {
            transactionId: "4567890",
            user: "fourthuser@domain.net",
            address: "b2a1c9d8e7f6g5h4i3j2",
            realStatus: "Completed",
            fakeStatus: "Completed",
            coinType: "LTC",
            amount: "10.00000000",
            date: "3д. назад",
            paidOut: "3д. назад"
        },
        {
            transactionId: "2345678",
            user: "fifthuser@domain.org",
            address: "j2i1h9g8f7e6d5c4b3a2",
            realStatus: "Pending",
            fakeStatus: "Pending",
            coinType: "XRP",
            amount: "0.00000001",
            date: "4д. назад",
            paidOut: "4д. назад"
        },
        {
            transactionId: "8901234",
            user: "sixthuser@domain.com",
            address: "a1b2c3d4e5f6g7h8i9j0",
            realStatus: "Failed",
            fakeStatus: "Failed",
            coinType: "BCH",
            amount: "0.00000002",
            date: "5д. назад",
            paidOut: "5д. назад"
        },
        {
            transactionId: "5678901",
            user: "seventhuser@domain.net",
            address: "k1l2m3n4o5p6q7r8s9t0",
            realStatus: "Completed",
            fakeStatus: "Pending",
            coinType: "EOS",
            amount: "0.00000003",
            date: "6д. назад",
            paidOut: "6д. назад"
        },
        {
            transactionId: "3210987",
            user: "eighthuser@domain.org",
            address: "v1u2i3o4p5q6r7s8t9u0",
            realStatus: "Pending",
            fakeStatus: "Completed",
            coinType: "ADA",
            amount: "0.00000004",
            date: "7д. назад",
            paidOut: "7д. назад"
        },
        {
            transactionId: "7654321",
            user: "ninthuser@domain.com",
            address: "x1y2z3a4b5c6d7e8f9g0",
            realStatus: "Failed",
            fakeStatus: "Failed",
            coinType: "LINK",
            amount: "0.00000005",
            date: "8д. назад",
            paidOut: "8д. назад"
        }
    ];

    return  (
      <ContainerV style={{ alignSelf: "flex-start", justifyContent: "flex-start", padding:"0 5rem 0 5rem"}}>
          <HeadBlock>Deposits</HeadBlock>
          <WorkerDepositsTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerDeposits;