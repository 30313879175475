import React from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import {HeadBlock} from "../../forWorkerPage/WorkerBinding";
import AdminMoneyForDepositTable from "../../../styles/tables/forAdmin/AdminMoneyForDepositTable";
import AdminAddedCoinsTable from "../../../styles/tables/forAdmin/AdminAddedCoinsTable";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;

export const GreyButton = styled.button`
  background-color: #2F323C;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10rem;

  &:hover {
    background-color: #3c404c;
  }
`;

const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;




const MoneyForDeposit:React.FC = () => {
    const testData = [
        {
            id: 38,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "ETH",
            coinType: "ETH",
            title: "Title",
            minReceive: 0.0005,
            minDeposit: 0.0005,
            switch: false // Assuming the switch is off by default
        },
        {
            id: 39,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "BTC",
            coinType: "BTC",
            title: "Another Title",
            minReceive: 0.001,
            minDeposit: 0.001,
            switch: true // Assuming the switch is off by default
        },
        {
            id: 40,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "LTC",
            coinType: "LTC",
            title: "Yet Another Title",
            minReceive: 0.002,
            minDeposit: 0.002,
            switch: false // Assuming the switch is off by default
        },
        {
            id: 41,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "XRP",
            coinType: "XRP",
            title: "Fourth Title",
            minReceive: 0.003,
            minDeposit: 0.003,
            switch: true // Assuming the switch is off by default
        },
        {
            id: 42,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "ADA",
            coinType: "ADA",
            title: "Fifth Title",
            minReceive: 0.004,
            minDeposit: 0.004,
            switch: false // Assuming the switch is off by default
        },
        {
            id: 43,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "DOGE",
            coinType: "DOGE",
            title: "Sixth Title",
            minReceive: 0.005,
            minDeposit: 0.005,
            switch: true // Assuming the switch is off by default
        },
        {
            id: 44,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "USDT",
            coinType: "USDT",
            title: "Seventh Title",
            minReceive: 0.006,
            minDeposit: 0.006,
            switch: false // Assuming the switch is off by default
        },
        {
            id: 45,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "BNB",
            coinType: "BNB",
            title: "Eighth Title",
            minReceive: 0.007,
            minDeposit: 0.007,
            switch: true // Assuming the switch is off by default
        },
        {
            id: 46,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            symbol: "LINK",
            coinType: "LINK",
            title: "Ninth Title",
            minReceive: 0.008,
            minDeposit: 0.008,
            switch: false // Assuming the switch is off by default
        }
    ];



  return <>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>
          <HeadBlock>Монеты, которые будут доступны для депозита</HeadBlock>
          <GrayText style={{fontSize:"20px"}}>
              Монеты, которые будут доступны для депозита. <br/>
              Если Min deposit amount не указан, то значение будет высчитываться автоматически из Minimal deposit amount,<br/>
              который настраивается выше.<br/> Min receive amount - минимальное количество монеты, которое принимает платежная система. <br/>Если будет транзакция меньше Min receive amount, то она не будет замечена.
          </GrayText>
         <AdminMoneyForDepositTable data={testData}/>
          <BorderBlock style={{
              width:"100%",
              justifyContent:"flex-start",
              alignSelf:"flex-start"
          }}>
              <Title>Minimal withdraw amount</Title>
              <GrayText>
                  Минимальная сумма вывода.<br/>
                  Используется для вычисления суммы в определенной валюте, если для этой валюты не указан конкретный Min withdraw amount в таблице All coins.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Minimum amount</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="number" placeholder="250"/>
                      <GreyButton>USD</GreyButton>
                  </ContainerH>
              </BorderBlock>
              <GrayText>Сумма в долларах, которая будет автоматически конвертироваться в валюту.</GrayText>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{
              width:"100%",
              justifyContent:"flex-start",
              alignSelf:"flex-start"
          }}>
              <Title>Withdraw Coin limits</Title>
              <GrayText>
                  Установите лимит вывода для определенных валют.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Minimum amount</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="number" placeholder="250"/>
                      <GreyButton>USD</GreyButton>
                  </ContainerH>
              </BorderBlock>
              <GrayText>Сумма в долларах, которая будет автоматически конвертироваться в валюту.</GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>COIN</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="text" placeholder="Пожалуйста, выберите криптовалюту"/>
                      <GreyButton>
                          <img src="/assets/images/forpages/adminpanel/dropdownVector.svg" alt="Vector"/>
                      </GreyButton>
                  </ContainerH>
              </BorderBlock>
              <SubmitButton>Add coin</SubmitButton>
          </BorderBlock>
      </ContainerV>
  </>;
};
export default MoneyForDeposit;