import styled from "styled-components";

const BlockGreyText = styled.div`
    text-align: start;
    color: #848E9C;
    font-weight: 500;
    font-size: 16px;
    max-width: 95%;
    align-self: flex-start;

    `;
export default BlockGreyText;