import React from "react";
import styled from "styled-components";
const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-right: 1rem;
`;

const ButtonPlanet: React.FC = () => {
  return (
    <CustomButton>
      <img src="/assets/images/icons/planet.svg" alt="Planet" />
    </CustomButton>
  );
};
export default ButtonPlanet;
