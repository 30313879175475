import React from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderFAC from "../../styles/headers/HeaderFAC";
import ButtonSwitchPCMob from "../../styles/functional/buttons/ButtonSwitchPCMob";
import Block from "../../styles/blosks/Block";


const UnionTrade: React.FC = () => {
  return (
      <BasicDiv>
        <ContainerV>
            <Block>
            <HeaderFAC>Торгуйте на ходу. Где и когда угодно.</HeaderFAC>
            </Block>
            <Block>
            <img src="/assets/images/forpages/mainpage/pc-version.svg" alt="PC"/>
            </Block>
            <Block>
            <ButtonSwitchPCMob/>
            </Block>
        </ContainerV>
      </BasicDiv>
  );
};
export default UnionTrade;