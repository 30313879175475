import React from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderFAL from "../../styles/headers/HeaderFAL";
import TableNavDropdown from "../../styles/functional/dropdowns/TableNavDropdown";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import { useGetUserProfileQuery } from "../../redux/apiService";

const StyledBlock = styled.div`
  width: 300px;
  border: 1px solid #293039;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 100%;
`;

const Header = styled.div`
  color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const HeaderText = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 20px;
`;

const HeaderImage = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 10px;
`;

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TextLine = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 2rem;
`;

export const Button = styled.button`
  background-color: #2f323c;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3c404c;
  }
`;

interface BalanceBlockProps {
  style?: React.CSSProperties; // Добавляем пропс style
}

const BalanceBlock: React.FC<BalanceBlockProps> = ({ style }) => {
  const { data: profile, error, isLoading } = useGetUserProfileQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading profile: {error.toString()}</div>;
  }
  console.log("Profile data:", profile);

  const dropdownText = "BTC";
  const dropdownOptions = ["BTC", "USDT", "ETH", "BNB"];

  return (
    <StyledBlock style={style}>
      <ContainerH>
        <Header>
          <HeaderText>Ориентировочный баланс</HeaderText>
          <HeaderImage
            src="/assets/images/forpages/accountpage/eye.svg"
            alt="Your Image"
          />
        </Header>
        <ButtonsContainer>
          <Button>Ввод</Button>
          <Button>Вывод</Button>
          <Button>Покупка за фиат</Button>
        </ButtonsContainer>
      </ContainerH>
      <Content>
        <TextLine>
          {/*<HeaderFAL>{profile?.body.balance}</HeaderFAL>*/}
          <HeaderFAL>0.00</HeaderFAL>
          {/*  .toFixed(2)*/}
          <TableNavDropdown
            options={dropdownOptions}
            buttonText={dropdownText}
          />
        </TextLine>
        <TextLine>≈ ₽0.00</TextLine>
        <TextLine>
          PnL за сегодня +<BlockGreyText> &nbsp;₽0,00(0.00%)</BlockGreyText>
        </TextLine>
      </Content>
    </StyledBlock>
  );
};

export default BalanceBlock;
