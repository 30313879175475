import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import AllCryptosNav from "../../styles/navs/AllCryptosNav";
import MarketSubNav from "../../styles/navs/MarketSubNav";
import AllCryptosMainTable from "../../styles/tables/AllCryptosMainTable";
import AIAccordion from "../../styles/accordions/AIAccordion";
import {
    MONITOR_CRYPTOS_ACCORDION_TEXT,
    MONITOR_CRYPTOS_ACCORDION_TITLE,
} from "../../constants/constants";

const MarketsMonitoring: React.FC = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
    ];

    // Разбиваем текст на части, чтобы выделить красным
    const parts = MONITOR_CRYPTOS_ACCORDION_TEXT.split('более высокая волатильность и риски');
    // Объединяем части с выделенными словами
    const highlightedText = (
        <>
            {parts[0]}
            <span
                style={{
                    color: '#F6465D',
                    fontWeight: '700',
            }}>
                более высокую волатильность и риски
            </span>
            {parts[1]}
        </>
    );
    return(
        <ContainerV>
            <AllCryptosNav initialActiveButton={5}/>
            <AIAccordion title={MONITOR_CRYPTOS_ACCORDION_TITLE}>{highlightedText}</AIAccordion>
            <MarketSubNav/>
            <AllCryptosMainTable data={tableData}/>
        </ContainerV>
    );
};
export default MarketsMonitoring;