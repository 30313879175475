import React from "react";
import UserTransactionsTable from "../../../../styles/tables/forAdmin/forAdminUsers/UserTransactionsTable";

const UserTransactionBlock = () => {
  const tableData = [
    {
      id: 95,
      type: "Stake",
      status: "Completed",
      coin: "BTC",
      amount: "1000",
      address: "14asdfdsaftwAFasdfasgfaehgahaeh",
      date: "2024/06/07 01:21:29",
      // action: "delete",
    },
    {
      id: 95,
      type: "Stake",
      status: "Pending",
      coin: "BTC",
      amount: "1000",
      address: "14asdfdsaftwAFasdfasgfaehgahaeh",
      date: "2024/06/07 01:21:29",
      // action: "delete",
    },
    {
      id: 95,
      type: "Stake",
      status: "Failed",
      coin: "BTC",
      amount: "1000",
      address: "14asdfdsaftwAFasdfasgfaehgahaeh",
      date: "2024/06/07 01:21:29",
      // action: "delete",
    },
    {
      id: 95,
      type: "Stake",
      status: "Completed",
      coin: "BTC",
      amount: "1000",
      address: "14asdfdsaftwAFasdfasgfaehgahaeh",
      date: "2024/06/07 01:21:29",
      // action: "delete",
    },
  ];
  return (
    <>
      <UserTransactionsTable data={tableData} />
    </>
  );
};
export default UserTransactionBlock;
