import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";
import SupportMessagePresets from "./SupportMessagePresets";
import WorkerSupportMessagePresetsTable from "../../styles/tables/forWorker/WorkerSupportMessagePresetsTable";


const WorkerSupportMessagePresets:React.FC = () => {
    const testData = [
        {
            id: 43,
            title: "Приветственное сообщение",
            message: "Hello. Since you are a new user, you need to verify your account. To do so, go to \"Account Settings\" - \"KYC Verification\" and go through the 2nd level of verification. After completing these steps, your account will be considered verified."
        },
        {
            id: 44,
            title: "Сообщение об успешной верификации",
            message: "Congratulations Your account has been successfully verified. You can now enjoy full access to our services."
        },
        {
            id: 45,
            title: "Информация о сбросе пароля",
            message: "We noticed that you requested a password reset. Please follow the link below to set a new password and regain access to your account."
        },
        {
            id: 46,
            title: "Уведомление о новой транзакции",
            message: "A new transaction has been initiated. Please review the details and confirm if everything is correct."
        },
        {
            id: 47,
            title: "Важное уведомление",
            message: "Please note that due to recent security concerns, we recommend updating your account information immediately."
        },
        {
            id: 48,
            title: "Подтверждение регистрации",
            message: "Thank you for registering with us. Your account is now active, and you can start exploring our platform."
        }
    ];
  return  (
      <ContainerV style={{ alignSelf: "flex-start", justifyContent: "flex-start", padding:"0 5rem 0 5rem"}}>
          <HeadBlock>Support message presets</HeadBlock>
          <SupportMessagePresets/>
          <WorkerSupportMessagePresetsTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerSupportMessagePresets;