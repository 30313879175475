import React from "react";

import BasicDiv from "../../styles/blosks/BasicDiv";
import CustomAccordion from "../../styles/accordions/CustomAccordion";
import ContainerV from "../../styles/containers/ContainerV";
import Block from "../../styles/blosks/Block";


const ExpTraders: React.FC = () => {
    const titleB = "Что такое криптовалютная биржа";
  return (
      <BasicDiv>
          <CustomAccordion number={1} title={titleB}>Binance — ведущая мировая биржа криптовалют, обслуживающая 150 миллионов зарегистрированных пользователей в более чем 180 странах. Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, Binance является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.
            <ContainerV>
                <Block>
                    С помощью Binance пользователи могут:
                </Block>
                <Block>
                    Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                    Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                    Покупайте и продавайте криптовалюту с помощью Binance P2P.<br/>
                    Зарабатывайте проценты от своих криптовалют с помощью Binance Earn.<br/>
                    Покупайте или зарабатывайте новые токены на Binance Launchpad.<br/>
                    Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе Binance NFT.
                </Block>
            </ContainerV>
          </CustomAccordion>
          <CustomAccordion number={2} title={titleB}>Binance — ведущая мировая биржа криптовалют, обслуживающая 150 миллионов зарегистрированных пользователей в более чем 180 странах. Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, Binance является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.
              <ContainerV>
                  <Block>
                      С помощью Binance пользователи могут:
                  </Block>
                  <Block>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Покупайте и продавайте криптовалюту с помощью Binance P2P.<br/>
                      Зарабатывайте проценты от своих криптовалют с помощью Binance Earn.<br/>
                      Покупайте или зарабатывайте новые токены на Binance Launchpad.<br/>
                      Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе Binance NFT.
                  </Block>
              </ContainerV>
          </CustomAccordion>
          <CustomAccordion number={3} title={titleB}>Binance — ведущая мировая биржа криптовалют, обслуживающая 150 миллионов зарегистрированных пользователей в более чем 180 странах. Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, Binance является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.
              <ContainerV>
                  <Block>
                      С помощью Binance пользователи могут:
                  </Block>
                  <Block>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Покупайте и продавайте криптовалюту с помощью Binance P2P.<br/>
                      Зарабатывайте проценты от своих криптовалют с помощью Binance Earn.<br/>
                      Покупайте или зарабатывайте новые токены на Binance Launchpad.<br/>
                      Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе Binance NFT.
                  </Block>
              </ContainerV>
          </CustomAccordion>
          <CustomAccordion number={4} title={titleB}>Binance — ведущая мировая биржа криптовалют, обслуживающая 150 миллионов зарегистрированных пользователей в более чем 180 странах. Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, Binance является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.
              <ContainerV>
                  <Block>
                      С помощью Binance пользователи могут:
                  </Block>
                  <Block>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Покупайте и продавайте криптовалюту с помощью Binance P2P.<br/>
                      Зарабатывайте проценты от своих криптовалют с помощью Binance Earn.<br/>
                      Покупайте или зарабатывайте новые токены на Binance Launchpad.<br/>
                      Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе Binance NFT.
                  </Block>
              </ContainerV>
          </CustomAccordion>
          <CustomAccordion number={5} title={titleB}>Binance — ведущая мировая биржа криптовалют, обслуживающая 150 миллионов зарегистрированных пользователей в более чем 180 странах. Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, Binance является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.
              <ContainerV>
                  <Block>
                      С помощью Binance пользователи могут:
                  </Block>
                  <Block>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами.<br/>
                      Покупайте и продавайте криптовалюту с помощью Binance P2P.<br/>
                      Зарабатывайте проценты от своих криптовалют с помощью Binance Earn.<br/>
                      Покупайте или зарабатывайте новые токены на Binance Launchpad.<br/>
                      Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе Binance NFT.
                  </Block>
              </ContainerV>
          </CustomAccordion>
      </BasicDiv>

  );
};
export default ExpTraders;