import styled from "styled-components";
import {Link} from "react-router-dom";

const HeaderLogo = styled(Link)`
    font-size: 16px;
    font-weight: 700;
    color: #EFB608;
    align-self: flex-start;
    justify-content: flex-start;
    margin-right: 1rem;
    text-decoration: none;
  
    &:hover {
      color: white;
    }
    
    
    
    `;
export default HeaderLogo;