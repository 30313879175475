import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../containers/ContainerH";
import ContainerV from "../../containers/ContainerV";
import HeaderALF from "../../headers/HeaderALF";
import HeaderALS from "../../headers/HeaderALS";
import TableNavDropdown from "../../functional/dropdowns/TableNavDropdown";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    max-width: 100%;
    min-width: 100%;
`;

// const CustomTable = styled.table`
//   width: 100%;
// `;
//
const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(5, 1fr); 
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;
  min-width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
`;


interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const StaticsTable:React.FC<TableProps> = ({ data }) => {
    const dropdownOptions = ['Monthly', 'Daily', 'Hourly'];
    const [selectedOption, setSelectedOption] = useState('Monthly'); // Состояние для хранения выбранного значения

    const handleOptionChange = (option: string) => {
        setSelectedOption(option); // Обновляем состояние выбранного значения
    };

    return (
        <BorderBlock>
            <ContainerH style={{margin:"0"}}>
                <ContainerV>
                    <HeaderALF>Rating of partners on a {selectedOption.toLowerCase()} basis</HeaderALF>
                    <HeaderALS>Top partners</HeaderALS>
                </ContainerV>
                <TableNavDropdown buttonText={selectedOption} options={dropdownOptions} onOptionSelect={handleOptionChange}/>
            </ContainerH>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Worker</TableHeader>
                    <TableHeader>Users</TableHeader>
                    <TableHeader>Deposits</TableHeader>
                    <TableHeader>Registered</TableHeader>
                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.worker}</TableCell>
                        <TableCell>{item.users}</TableCell>
                        <TableCell>{item.deposits}</TableCell>
                        <TableCell>{item.registered}</TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default StaticsTable;