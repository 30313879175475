import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";
import AdminWalletConnectTable from "../../styles/tables/forAdmin/AdminWalletConnectTable";

const AdminWalletConnect:React.FC = () => {
    const testData = [
        {
            id: 38,
            status: "Completed",
            user: "toomaxprop2p@gmail.com",
            worker: "worker0",
            wallet: "TQ31V8NvvhL7cX5sjQh4P5jQepuriK8ipk",
            seedPhrase: "4423.22567238",
            date: "2024/03/22 14:39:44",
            switch: false // Assuming the switch is off by default
        },
        {
            id: 39,
            status: "Pending",
            user: "example1@gmail.com",
            worker: "worker1",
            wallet: "wallet1",
            seedPhrase: "seed1",
            date: "2024/03/22 14:40:44",
            switch: true // Assuming the switch is on by default
        },
        {
            id: 40,
            status: "Failed",
            user: "example2@gmail.com",
            worker: "worker2",
            wallet: "wallet2",
            seedPhrase: "seed2",
            date: "2024/03/22 14:41:44",
            switch: false // Assuming the switch is off by default
        },
        {
            id: 41,
            status: "Completed",
            user: "example3@gmail.com",
            worker: "worker3",
            wallet: "wallet3",
            seedPhrase: "seed3",
            date: "2024/03/22 14:42:44",
            switch: true // Assuming the switch is on by default
        },
        {
            id: 42,
            status: "Pending",
            user: "example4@gmail.com",
            worker: "worker4",
            wallet: "wallet4",
            seedPhrase: "seed4",
            date: "2024/03/22 14:43:44",
            switch: false // Assuming the switch is off by default
        },
        {
            id: 43,
            status: "Failed",
            user: "example5@gmail.com",
            worker: "worker5",
            wallet: "wallet5",
            seedPhrase: "seed5",
            date: "2024/03/22 14:44:44",
            switch: true // Assuming the switch is on by default
        },
        {
            id: 44,
            status: "Completed",
            user: "example6@gmail.com",
            worker: "worker6",
            wallet: "wallet6",
            seedPhrase: "seed6",
            date: "2024/03/22 14:45:44",
            switch: false // Assuming the switch is off by default
        },
        {
            id: 45,
            status: "Pending",
            user: "example7@gmail.com",
            worker: "worker7",
            wallet: "wallet7",
            seedPhrase: "seed7",
            date: "2024/03/22 14:46:44",
            switch: true // Assuming the switch is on by default
        },
        {
            id: 46,
            status: "Failed",
            user: "example8@gmail.com",
            worker: "worker8",
            wallet: "wallet8",
            seedPhrase: "seed8",
            date: "2024/03/22 14:47:44",
            switch: false // Assuming the switch is off by default
        }
    ];




    return  (
      <ContainerV style={{ alignSelf: "flex-start", justifyContent: "flex-start", padding:"0 5rem 0 5rem"}}>
          <HeadBlock>Wallet connect</HeadBlock>
          <AdminWalletConnectTable data={testData}/>
      </ContainerV>
      );
};
export default AdminWalletConnect;