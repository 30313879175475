import React, { useState } from "react";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerH from "../../../styles/containers/ContainerH";
import { SubmitButton } from "../forAdminUsers/Overview";
import ContainerV from "../../../styles/containers/ContainerV";
import styled from "styled-components";
import AttachFilesButton from "../../../styles/functional/buttons/AttachFilesButton";
import MessagePresetsDropdown from "../../../styles/functional/dropdowns/MessagePresetsDropdown";
import {
  useGetMessageListByChatQuery,
  useGetMessagePresetsListQuery,
  useGetUserInfoQuery,
} from "../../../redux/apiService";
import MessagesList from "./MessagesList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { GrayText } from "../../forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";
import { clearSelectedSupportChat } from "../../../redux/slices/selectedSupportChatSlice";

const CustomInput = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  color: white;
  padding: 1rem 0.5rem 0.5rem 0.5rem; // Увеличено значение padding-left до 0.5rem
  margin-bottom: 0.5rem;
  width: 100%;
  height: 15rem;
  //margin-top: 8rem;
`;
const Messenger = () => {
  const dispatch = useDispatch();

  // Функция для очистки чата
  const handleClearChat = () => {
    dispatch(clearSelectedSupportChat());
  };

  const {
    data: messagePresetsList,
    error,
    isLoading,
  } = useGetMessagePresetsListQuery();
  const SelectedChat = useSelector(
    (state: RootState) => state.selectedSupportChat.id,
  );

  const {
    data: messageListByChat,
    error: messageListByChatError,
    isLoading: messageListByChatIsLoading,
  } = useGetMessageListByChatQuery(SelectedChat);

  const {
    data: userInfo,
    error: userInfoError,
    isLoading: userInfoIsLoading,
  } = useGetUserInfoQuery();
  const currentUserId = userInfo?.body.user_id as string; // ID текущего пользователя

  const DefaultMessage = "Message presets";
  const MessagePresets = ["Привет!", "Как дела?", "Что случилось?"];
  const [isChoosenPreset, setIsChoosenPreset] = useState<{
    title: string;
    message: string;
  } | null>(null);

  if (isLoading || messageListByChatIsLoading || userInfoIsLoading) {
    return <div>Loading...</div>;
  }

  if ((error || messageListByChatError || userInfoError) && SelectedChat) {
    return (
      <div>
        Error loading data:
        {error && <div>Error loading message presets: {error.toString()}</div>}
        {messageListByChatError && (
          <div>
            Error loading chat messages: {messageListByChatError.toString()}
          </div>
        )}
        {userInfoError && (
          <div>Error loading user info: {userInfoError.toString()}</div>
        )}
      </div>
    );
  }
  console.log("messagePresetsList data:", messagePresetsList);

  const handleChangePreset = (selectedTitle: string) => {
    const selectedPreset = messagePresetsList?.body?.data.find(
      (item: any) => item.title === selectedTitle,
    );
    if (selectedPreset) {
      setIsChoosenPreset({
        title: selectedPreset.title,
        message: selectedPreset.message,
      });
    }
  };

  const formattedData =
    messagePresetsList?.body?.data?.map((item: any) => item.title) ||
    MessagePresets;

  const chatHistory =
    messageListByChat?.body.data.map((item: any) => ({
      id: item.id,
      message: item.message,
      user: item.user,
      is_read: item.is_read,
      is_support: item.is_support,
      created_at: item.created_at,
      updated_at: item.updated_at,
    })) || [];

  return (
    <>
      <BorderBlock
        style={{
          backgroundColor: "#191b21",
          minWidth: "55%",
          flex: "1",
          marginTop: "5rem",
          height: "650px",
          position: "relative",
        }}
      >
        {SelectedChat && (
          <button
            onClick={handleClearChat}
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "1.5rem",
              color: "white",
            }}
          >
            &times;
          </button>
        )}
        <ContainerV style={{ height: "100%" }}>
          {!SelectedChat ? (
            <>
              <img
                src="/assets/images/forpages/adminpanel/Rocket.svg"
                alt="Rocket"
                style={{ marginTop: "auto" }}
              />
              <GrayText style={{ alignSelf: "center" }}>
                Please, select the dialog
              </GrayText>
            </>
          ) : (
            <MessagesList
              messages={chatHistory}
              currentUserId={currentUserId}
            />
          )}
          <CustomInput
            value={isChoosenPreset?.message || "Type a message"}
            onChange={(e) =>
              setIsChoosenPreset((prev) =>
                prev ? { ...prev, message: e.target.value } : null,
              )
            }
            style={{ marginTop: "auto" }}
          />
          <ContainerH style={{ margin: "0", justifySelf: "flex-end" }}>
            <AttachFilesButton />
            <MessagePresetsDropdown
              options={formattedData}
              buttonText={DefaultMessage}
              onSelectPreset={handleChangePreset}
            />
            <SubmitButton style={{ margin: "0" }}>Send</SubmitButton>
          </ContainerH>
        </ContainerV>
      </BorderBlock>
    </>
  );
};
export default Messenger;
