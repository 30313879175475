import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import AllCryptosNav from "../../styles/navs/AllCryptosNav";
import AllCryptosAccordion from "../../styles/accordions/AllCryptosAccordion";
import MarketSubNav from "../../styles/navs/MarketSubNav";
import AllCryptosMainTable from "../../styles/tables/AllCryptosMainTable";
import {ALL_CRYPTOS_ACCORDION_TEXT, ALL_CRYPTOS_ACCORDION_TITLE} from "../../constants/constants";

const MarketsAllCryptos: React.FC = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
    ];
  return(
     <ContainerV>
       <AllCryptosNav initialActiveButton={1}/>
         <AllCryptosAccordion title={ALL_CRYPTOS_ACCORDION_TITLE}>{ALL_CRYPTOS_ACCORDION_TEXT}</AllCryptosAccordion>
         <MarketSubNav/>
         <AllCryptosMainTable data={tableData}/>
     </ContainerV>
  );
};
export default MarketsAllCryptos;