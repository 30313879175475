import React from "react";
import ContainerV from "../../../styles/containers/ContainerV";
import {HeadBlock} from "../WorkerBinding";
import styled from "styled-components";
import {Outlet} from "react-router-dom";




const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const TitleMiddle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 16px;
  font-weight: 700;
`;

const GreyText = styled.div`
  color: #848E9C;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 16px;
  font-weight: 500;
`;




const WorkerChangeCourse:React.FC = () => {

  return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Change course</HeadBlock>
          <BorderBlock>
              <TitleMiddle>
                  Edit trading course
              </TitleMiddle>
              <GreyText>
                  Visible only to your linked users
              </GreyText>
              <Outlet/>
          </BorderBlock>
      </ContainerV>
      );
};
export default WorkerChangeCourse;