import React, { useState } from "react";
import styled from "styled-components";
import HeaderALS from "../headers/HeaderALS";

const AllCryptosAccordionContainer = styled.div`
  border: 1px solid #1E2329;
  border-radius: 20px;
  margin-bottom: 10px;
  width: 100% ;
`;

const AccordionFooter = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ isOpen }) => (isOpen ? "20px 20px 0 0" : "20px")};
`;

const AccordionContentExpanded = styled.div`
  padding: 15px;
  background-color: transparent;
  text-align: left;
  color: #848E9C;
  max-width: 95%;
`;

const AccordionContentCollapsed = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: transparent;
  text-align: left;
  color: #848E9C;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.img`
  margin-left: 1rem;
`;

interface AllCryptosAccordionProps {
    title: string;
    children?: React.ReactNode;
}

const AllCryptosAccordion: React.FC<AllCryptosAccordionProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AllCryptosAccordionContainer>
            <HeaderALS style={{ color: "#ffffff", margin: "1rem", fontSize: "20px" }}>{title}</HeaderALS>
            {isOpen ? (
                <AccordionContentExpanded>{children}</AccordionContentExpanded>
            ) : (
                <AccordionContentCollapsed>
                    <span>{typeof children === 'string' ? `${children.slice(0, 133)}...` : children}</span>
                </AccordionContentCollapsed>

            )}
            <AccordionFooter onClick={toggleAccordion} isOpen={isOpen}>
                <ToggleButton onClick={toggleAccordion}>
                    {isOpen ? (
                        <>
                            <span>Свернуть</span>
                            <ImgContainer src="/assets/images/icons/arrow-up.svg" alt="up" />
                        </>
                    ) : (
                        <>
                            <span>Показать</span>
                            <ImgContainer src="/assets/images/icons/arrow.svg" alt="down" />
                        </>
                    )}
                </ToggleButton>
            </AccordionFooter>
        </AllCryptosAccordionContainer>
    );
};

export default AllCryptosAccordion;
