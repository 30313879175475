import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import BalanceBlock from "./BalanceBlock";
import UserIDBlock from "./UserIDBlock";
import SquareBlock from "./SquareBlock";
import NewsBlock from "./NewsBlock";
import {Outlet} from "react-router-dom";
import { useGetUserProfileQuery } from '../../redux/apiService';




const AccountMain = () => {

    const { data: profile, error, isLoading } = useGetUserProfileQuery();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading profile: {error.toString()}</div>;
    }




    const data = [
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 1',
            grayText: 'Company 1',
            buttonLink: 'https://example.com/subscribe1',
        },
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 2',
            grayText: 'Company 2',
            buttonLink: 'https://example.com/subscribe2',
        },
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 3',
            grayText: 'Company 3',
            buttonLink: 'https://example.com/subscribe1',
        },
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 4',
            grayText: 'Company 4',
            buttonLink: 'https://example.com/subscribe2',
        },
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 5',
            grayText: 'Company 5',
            buttonLink: 'https://example.com/subscribe1',
        },
        {
            imageSrc: '/assets/images/forpages/accountpage/default-avatar-square.svg',
            whiteText: 'UserName 6',
            grayText: 'Company 6',
            buttonLink: 'https://example.com/subscribe2',
        },
    ];

    const newsData = [
        {text: 'Основные альткойны будут разблокированы токенами в ближайшие дни', date: '2024-02-21',},
        {text: 'Основные альткойны будут разблокированы токенами в ближайшие дни', date: '2024-02-21',},
    ];

  return <>
    <UserIDBlock/>
    <BalanceBlock/>
    <Outlet/>
      <ContainerH>
          <SquareBlock data={data}/>
          <NewsBlock data={newsData}/>
      </ContainerH>
  </>;
};
export default AccountMain;