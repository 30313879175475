import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  `;

const ButtonOption =styled(Link)<{isActive: boolean}>`
  background-color: ${({isActive}) => (isActive ? "#293039" : "transparent")};
  color: ${({isActive}) => (isActive ? "#ffffff" : "#848E9C")};
  font-weight: ${({isActive}) => (isActive ? "700" : "500")};
  font-size: 14px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px;
  //margin-right: 1rem;
  &:hover{
    color: white;
    //font-weight: 700;
    background-color: transparent ;
  }
  
  
  `;
const FavorOptions: React.FC = () => {
    const [activeButton, setActiveButton] = useState(1);

        const handleButtonClick = (buttonNumber: number) => {
          setActiveButton(buttonNumber);
        };
  return(
    <OptionsContainer>
        <ButtonOption to="/markets/favorites/slot" isActive={activeButton === 1} onClick={() => handleButtonClick(1)}>
            Слот(0)
        </ButtonOption>
        <ButtonOption to="/markets/favorites/futures" isActive={activeButton === 2} onClick={() => handleButtonClick(2)}>
            Фьючерсы(0)
        </ButtonOption>
        <ButtonOption to="/markets/favorites/options" isActive={activeButton === 3} onClick={() => handleButtonClick(3)}>
            Опционные(0)
        </ButtonOption>
    </OptionsContainer>
  );
};
export default FavorOptions;