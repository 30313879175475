import React from 'react';
import styled from 'styled-components';
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import ContainerH from "../../styles/containers/ContainerH";

const NewsBlockContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  
`;


const BlockData = styled.div`
  font-weight: 700;
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
`;

const BlockDate = styled.div`
  color: #77808C;
  padding: 10px;

  text-align: left;
`;

interface NewsBlockProps {
    data: {
        text: string;
        date: string;
    }[]
}
const NewsBlock: React.FC<NewsBlockProps> = ({ data }) => {
    return (
        <div style={{
            border:"2px solid #293039",
            borderRadius:"15px",
            maxWidth:"30%",
            padding:"1rem",
        }}>
            <ContainerH>
                <HeaderALTH style={{fontSize:"20px"}}>Новости</HeaderALTH>
                <GreyLinkArrowR to="/" children="Подробнее"/>
            </ContainerH>
            {data.map((item, index) => (
                <NewsBlockContainer key={index}>
                    <BlockData>{item.text}</BlockData>
                    <BlockDate>{item.date}</BlockDate>
                </NewsBlockContainer>
            ))}
        </div>
    );
};
export default NewsBlock;