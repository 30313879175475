import React, {useEffect} from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import ContainerH from "../../styles/containers/ContainerH";
import ButtonSTL from "../../styles/functional/buttons/ButtonSTL";
import ContainerV from "../../styles/containers/ContainerV";
import CustomInput from "../../styles/functional/inputs/CustomInput";
import HeaderFAL from "../../styles/headers/HeaderFAL";
import ContainerTC from "../../styles/containers/ContainerTC";
import BlockText from "../../styles/blosks/BlockText";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import HeaderALS from "../../styles/headers/HeaderALS";
import Countdown from "../../styles/functional/Timer";
import TCTable from "../../styles/tables/TCTable";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";
import {retrieveOne} from "../../api/requests";
import {setUserProfile} from "../../redux/actions";
const IntroPart: React.FC = () => {
  const buttonLink = "/";
  const buttonText = "Начать";
  const targetDate = new Date('2024-12-31T23:59:59');

  const dispatch = useDispatch();
  //const accessToken = useSelector((state: RootState) => state.tokens.access);
 // const profileDataR = useSelector((state: RootState) => state.userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedTokens = localStorage.getItem('tokens');
        if (storedTokens) {
          const tokens = JSON.parse(storedTokens);
          const accessToken = tokens.access;

          // Проверяем, что accessToken не пустой и не null
          if (accessToken) {
            // Вызываем функцию для получения данных профиля
            const profileData = await retrieveOne(accessToken);

            // Выводим полученные данные в консоль для проверки
            console.log("Данные профиля:", profileData);
            console.log("Токен: ", accessToken);

            // Диспатчим полученные данные в Redux-стейт
            dispatch(setUserProfile(profileData));

            //console.log('Данные из Redux store', profileDataR);
          } else {
            console.log("Access token is empty or null");
          }
        } else {
          console.log("Токены не найдены в локальном хранилище.");
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных профиля:", error);
      }
    };

    fetchData();
  }, []);

  const cryptoData = [
    {
      image: "/assets/images/cryptoicons/BTC.svg",
      abbreviation: "BTC",
      name: "Bitcoin",
      price: "$42521.18",
      growth: "-10.23%",
    },
    {
      image: "/assets/images/cryptoicons/ETH.svg",
      abbreviation: "ETH",
      name: "Ethereum",
      price: "$42521.18",
      growth: "+105.23%",
    },
    {
      image: "/assets/images/cryptoicons/BTC.svg",
      abbreviation: "BTC",
      name: "Bitcoin",
      price: "$42521.18",
      growth: "+10.23%",
    },
    {
      image: "/assets/images/cryptoicons/BTC.svg",
      abbreviation: "BTC",
      name: "Bitcoin",
      price: "$42521.18",
      growth: "+10.23%",
    },


  ];

  return (
    <BasicDiv>
    <ContainerH style={{marginTop:"8rem", display:"grid", gridTemplateColumns:"repeat(2,1fr)", gap:"7rem"}}>
        <ContainerV style={{width:"50%"}}>
            <HeaderFAL style={{marginTop:"-10rem"}}>
              Большинство<br/>
              опытных<br/> трейдеров уже<br/>
              на Binance
            </HeaderFAL>
            <ContainerH>
                <CustomInput  placeholder="Эл.почта / номер телефона"/>
                <ButtonSTL href={buttonLink} buttonText={buttonText}/>
            </ContainerH>
            <ContainerH style={{justifyContent:"flex-start"}}>
              <img src="/assets/images/icons/present.svg" alt='Present'/>
              <HeaderALS style={{color:"#ffffff", marginLeft:"1rem"}}>
                Зарегистрируйтесь сейчас и получите до 100 USDT в качестве<br/> вознаграждения
              </HeaderALS>
            </ContainerH>
        </ContainerV>
      <ContainerV style={{width:"95%"}}>
        <ContainerTC style={{marginTop:"0"}}>
          <TCTable data={cryptoData}/>
          <BlockText>
            <GreyLinkArrowR to="/">Просмотреть все 350+ монет</GreyLinkArrowR>
          </BlockText>
        </ContainerTC>
        <ContainerTC>
          <BlockText>
            Основные альткойны будут разблокированы токенами в ближайшие дни
          </BlockText>
          <BlockText>
            Основные альткойны будут разблокированы токенами в ближайшие дни
          </BlockText>
          <BlockText>
            Основные альткойны будут разблокированы токенами в ближайшие дни
          </BlockText>
          <BlockText>
          <GreyLinkArrowR to="/">Просмотреть все новости</GreyLinkArrowR>
          </BlockText>
        </ContainerTC>
        <ContainerTC>
          <BlockText style={{fontWeight: "700"}}>
            Отсчет до халвинга биткоина
          </BlockText>
          <Countdown targetDate={targetDate}/>
        </ContainerTC>
      </ContainerV>
    </ContainerH>
    </BasicDiv>
  );
};
export default IntroPart;