import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import { URL_AND_NAME_EXPLANATION } from "../../constants/constants";
import {
  useGetTermsAndAmlKycPoliciesRetrieveOneQuery,
  useUpdateTermsAndAmlKycPoliciesMutation,
} from "../../redux/apiService";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  align-self: flex-start;
  min-width: 100%;
`;

const SaveButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 10rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #ffff66;
  }
`;

const TextArea = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 35rem;
  color: white;
`;

const AdminEditAmlKycPolicy: React.FC = () => {
  const {
    data: amlKycPolicy,
    error,
    isLoading,
    refetch,
  } = useGetTermsAndAmlKycPoliciesRetrieveOneQuery();

  const [updateTermsAndAmlKycPolicies] =
    useUpdateTermsAndAmlKycPoliciesMutation();
  const [amlKycPolicyValue, setAmlKycPolicyValue] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (amlKycPolicy?.body.aml_kyc_policy) {
      setAmlKycPolicyValue(amlKycPolicy.body.aml_kyc_policy); // Устанавливаем значение только после загрузки данных
    }
  }, [amlKycPolicy]); // Выполняется при изменении terms
  // Обработчик изменения текста в TextArea
  const handleTermsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAmlKycPolicyValue(event.target.value);
  };

  // Обработчик сохранения изменений
  const handleSaveChanges = async () => {
    try {
      await updateTermsAndAmlKycPolicies({
        aml_kyc_policy: amlKycPolicyValue,
      });
      refetch(); // Обновите данные после успешного обновления
    } catch (error) {
      console.error("Error updating terms:", error);
      // Здесь можно добавить обработку ошибки, например, показать уведомление
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading AmlKycPolicy: {error.toString()}</div>;
  }
  console.log("amlKycPolicy data:", amlKycPolicy);

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock>Edit Aml-kyc-policy</HeadBlock>
      <BorderBlock>
        <TextArea
          value={amlKycPolicyValue}
          onChange={handleTermsChange}
        ></TextArea>
        <BlockGreyText style={{ fontSize: "14px" }}>
          {URL_AND_NAME_EXPLANATION}
        </BlockGreyText>
        <SaveButton onClick={handleSaveChanges}>Save changes</SaveButton>
      </BorderBlock>
    </ContainerV>
  );
};
export default AdminEditAmlKycPolicy;
