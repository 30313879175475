import React from "react";
import BalanceBlock from "./BalanceBlock";
import ContainerV from "../../styles/containers/ContainerV";
import AccountDepositTable from "../../styles/tables/forAccount/AccountDepositTable";
import styled from "styled-components";
import AccountSubNav from "../../styles/navs/AccountSubNav";

export const BorderBlock = styled.div`
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 3rem;
`;

const Deposits: React.FC = () => {
  const tableData = [
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "+10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "-10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "+10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "-10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "+10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
    {
      image: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      cells: ["BTC", "Bitcoin", "380,1", "-10.83%", ""],
      image5: "/assets/images/icons/bag.svg",
      grayText: "₽ 34 985",
    },
  ];

  const names = ["Монета", "Сумма", "Доступно", "Заморожено", "Действия"];
  return (
    <ContainerV>
      <BalanceBlock style={{ width: "75%" }} />
      <BorderBlock>
        <AccountSubNav names={names} />
        <AccountDepositTable data={tableData} />
      </BorderBlock>
    </ContainerV>
  );
};
export default Deposits;
