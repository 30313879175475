import React, { useState } from "react";
import UserProfileNav from "../../../styles/navs/UserProfileNav";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerH from "../../../styles/containers/ContainerH";
import styled from "styled-components";
import ContainerV from "../../../styles/containers/ContainerV";
import HeaderALTH from "../../../styles/headers/HeaderALTH";
import { GrayText } from "../../forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";
import CustomInput2 from "../../../styles/functional/inputs/CustomInput2";
import { GreyButton } from "../forAdminDepositCoins/FiveBlocksAtTop";

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
  text-align: left;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 20%;

  &:hover {
    background-color: #ffff66;
  }
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

const WithdrawVerify: React.FC = () => {
  const [switchState, setSwitchState] = useState(false);
  const [secondSwitch, setSecondSwitch] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the switch state at the given index
    setSwitchState(event.target.checked);
    console.log("Switch state 1 changed to:", event.target.checked);
  };

  const handleSecondSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSecondSwitch(event.target.checked);
    console.log("Switch state 2 changed to:", event.target.checked);
  };

  return (
    <>
      <UserProfileNav initialActiveButton={9} />
      <BorderBlock>
        <HeaderALTH>Verification (in withdraw page)</HeaderALTH>
        <ContainerH
          style={{ width: "100%", margin: "0", marginTop: "1%", gap: "4%" }}
        >
          <ContainerV style={{ width: "48%" }}>
            <ContainerH style={{ width: "100%", margin: "0", gap: "2rem" }}>
              <GrayText style={{ whiteSpace: "wrap" }}>
                <Title>Verification requirement Modal</Title>
                Если включено, то у пользователя при попытке вывода будет
                открываться страница с верификацией (обязательно внести
                депозит).
              </GrayText>
              <div>
                <Switch
                  id="switch1"
                  checked={switchState}
                  onChange={handleSwitchChange}
                />
                <SwitchLabel htmlFor="switch1"></SwitchLabel>
              </div>
            </ContainerH>
            <br />
            <ContainerH style={{ width: "100%", margin: "0", gap: "2rem" }}>
              <GrayText style={{ whiteSpace: "wrap" }}>
                <Title>AML Check: High Risk Score Modal</Title>
                Если включено, то у пользователя при попытке вывода будет
                открываться страница с AML Check (обязательно внести депозит).
              </GrayText>
              <div>
                <Switch
                  id="switch2"
                  checked={secondSwitch}
                  onChange={handleSecondSwitchChange}
                />
                <SwitchLabel htmlFor="switch2"></SwitchLabel>
              </div>
            </ContainerH>
          </ContainerV>
          <ContainerV style={{ width: "48%", margin: "0" }}>
            <ContainerV style={{ gap: "0" }}>
              <Title>Verification deposit amount</Title>
            </ContainerV>
            <ContainerV style={{ gap: "0" }}>
              <Title>Minimum amount</Title>

              <ContainerH style={{ margin: "0", width: "100%", gap: "0" }}>
                <CustomInput2
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  defaultValue={0}
                />
                <GreyButton>USD</GreyButton>
              </ContainerH>
            </ContainerV>
            <GrayText style={{ whiteSpace: "wrap" }}>
              Минимальная сумма депозита для прохождения верификации.
              <br />
              Используется для вычисления суммы в определенной валюте, если для
              этой валюты не указан конкретный{" "}
              <strong>Currency verification deposit amount.</strong>
            </GrayText>
          </ContainerV>
        </ContainerH>
        <SubmitButton>Save all changes</SubmitButton>
      </BorderBlock>
    </>
  );
};
export default WithdrawVerify;
