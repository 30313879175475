import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import ButtonST from "../../styles/functional/buttons/ButtonST";
import {Link, useNavigate} from "react-router-dom";
import BothSideLinesHeader from "../../styles/headers/BothSideLinesHeader";
import ContainerH from "../../styles/containers/ContainerH";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import AuthSmollLink from "../../styles/functional/links/AuthSmollLink";

const AuthMain: React.FC = () => {
    const buttonText = "Регистрация по телефону/эл.почте";
    const googleText = "Продолжить с Google";
    const googleLink = "https://www.google.com/";
    const singUp = "/auth-page/create-account";
    const login = "/auth-page/login";
    const navigate = useNavigate();


    const handleButtonClick = () => {
      navigate(singUp)
    }
  return<>
      <ContainerH>
          <ContainerV>
              <HeaderALTH>Добро пожаловать на <br/>WAVECURRENCY!</HeaderALTH>
              {/*<ButtonSTL href={singUp} buttonText={buttonText}></ButtonSTL>*/}
              <ButtonST
                  style={{
                  width:"410px",
                  height:"60px",
                  // borderRadius: "20px",
              }}
              onClick={handleButtonClick}>
                  <img
                      src="/assets/images/icons/account-line.svg"
                      alt="account"
                      style={{
                          alignSelf:"center",
                          marginRight:"1rem",
                          marginTop:"-0.4rem",
                      }}/>

                      {buttonText}
              </ButtonST>
              <BothSideLinesHeader>или</BothSideLinesHeader>
              <Link
                  to={googleLink}
                  style={{
                      display:"flex",
                      whiteSpace:"nowrap",
                      color:"#ffffff",
                      textDecoration:"none",
                      alignItems:"center",
                      alignContent:"center",

                  }}>
              <ButtonST style={{
                  width:"410px",
                  height:"60px",
                  backgroundColor:"#474D57",
                  borderColor:"#474D57",
                  color:"white",
                  display:"flex",
                  flexDirection: "row",
                  alignItems:"center",
                  justifyContent:"center",
                  paddingLeft:"1rem",
              }}>
                  <img
                      src="/assets/images/icons/google.svg"
                      alt="account"
                      style={{

                      }}/>
                  <div style={{
                      flex: 1,
                      marginLeft:"-1rem"
                  }}>
                      {googleText}
                  </div>
              </ButtonST>
              </Link>
              <ContainerH style={{justifyContent:"flex-start"}}>
                  <BlockGreyText>Уже есть аккаунт?</BlockGreyText>
                  <AuthSmollLink to={login}>Войти</AuthSmollLink>
              </ContainerH>
              <ContainerH style={{justifyContent:"flex-start"}}>
                  <BlockGreyText>Создать аккаунт?</BlockGreyText>
                  <AuthSmollLink to="/auth-page/create-account">Регистрация</AuthSmollLink>
              </ContainerH>
          </ContainerV>
          <ContainerV>
              <img src="/assets/images/forpages/auth/welcome.svg" alt="meet"/>
          </ContainerV>
      </ContainerH>
  </>;
};

export default AuthMain;