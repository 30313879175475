import React, { useState } from "react";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import { GrayText } from "../forWorkerMainSettings/EightBlocksAtTop";
import styled from "styled-components";
import AttachFilesButton from "../../../styles/functional/buttons/AttachFilesButton";
import MessagePresetsDropdown from "../../../styles/functional/dropdowns/MessagePresetsDropdown";
import { SubmitButton } from "../../forAdminPanel/forAdminUsers/Overview";

const CustomInput = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  color: white;
  padding: 1rem 0.5rem 0.5rem 0.5rem; // Увеличено значение padding-left до 0.5rem
  margin-bottom: 0.5rem;
  width: 100%;
  height: 15rem;
  //margin-top: 8rem;
`;
const Messager = () => {
  const DefaultMessage = "Message presets";
  const MessagePresets = ["Привет!", "Как дела?", "Что случилось?"];
  const [isChoosenPreset, setIsChoosenPreset] = useState("");

  const handleChangePreset = (selectedPreset: string) => {
    setIsChoosenPreset(selectedPreset);
  };

  return (
    <>
      <BorderBlock
        style={{
          backgroundColor: "#191b21",
          minWidth: "55%",
          flex: "1",
          marginTop: "5rem",
          height: "650px",
        }}
      >
        <ContainerV style={{ height: "100%" }}>
          <img
            src="/assets/images/forpages/adminpanel/Rocket.svg"
            alt="Rocket"
            style={{ marginTop: "auto" }}
          />
          <GrayText style={{ alignSelf: "center" }}>
            Please, select the dialog
          </GrayText>
          <CustomInput
            value={isChoosenPreset || "Type a message"}
            onChange={(e) => setIsChoosenPreset(e.target.value)}
            style={{ marginTop: "auto" }}
          />

          <ContainerH style={{ margin: "0", justifySelf: "flex-end" }}>
            <AttachFilesButton />
            <MessagePresetsDropdown
              options={MessagePresets}
              buttonText={DefaultMessage}
              onSelectPreset={handleChangePreset}
            />
            <SubmitButton style={{ margin: "0" }}>Send</SubmitButton>
          </ContainerH>
        </ContainerV>
      </BorderBlock>
    </>
  );
};
export default Messager;
