import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "../../redux/apiService";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import ContainerV from "../../styles/containers/ContainerV";
import ContainerH from "../../styles/containers/ContainerH";
import { GrayText } from "../forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";
import { RedText } from "../forAdminPanel/forAdminTelegramSettings/FiveBlocksAtTop";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import UserProfileHeadBlock from "../forAdminPanel/forAdminUsers/UserProfileHeadBlock";

import { useNavigate } from "react-router-dom";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  min-width: 100%;
`;

const InputField = styled.input`
  width: 100%;
  min-width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  background-color: #191b21;
  border: 1px solid #293039;
  margin-bottom: 1rem;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  padding-top: 0.5rem;
  font-weight: 550;
`;

interface CheckboxProps {
  isChecked: boolean;
}

const CheckboxContainer = styled.label<CheckboxProps>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.isChecked ? "#FCD535" : "#1E2329")};
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Ensure the container hides its children */
  transition: background-color 0.3s ease;
  margin-right: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -35%); /* Adjust positioning */
    width: 15px; /* Width of the checkmark */
    height: 25px; /* Height of the checkmark */
    background-image: url("/assets/images/forpages/adminpanel/checkmark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${(props) => (props.isChecked ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid
      ${(props) => (props.isChecked ? "transparent" : "#848E9C")}; /* Рамка появляется, когда чекбокс не выбран */
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
`;

export const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 12rem;

  &:hover {
    background-color: #ffff66;
  }
`;

const AccountSettingsChange: React.FC = () => {
  // const [checkboxStates, setCheckboxStates] = useState([true, true, true, true, true, true, true, true, true, true,   true]);
  // const [bottomCheckboxStates, setBottomCheckboxStates] = useState([true, true, true]);
  const [checkboxStates, setCheckboxStates] = useState<boolean[]>(
    Array(11).fill(true),
  );
  const [bottomCheckboxStates, setBottomCheckboxStates] = useState<boolean[]>(
    Array(3).fill(true),
  );

  const Options = [
    "Trading",
    "Staking",
    "Transfer",
    "Swap",
    "Support",
    "Global Ban",
    "Wallet connect",
    "Premium",
    "Enable 2FA",
    "Email confirmed",
    "Fake KYC verified",
  ];
  const BottomOptions = [
    "Active first deposit bonus",
    "Fake Withdraw [Pending modal]",
    "Fake Withdraw [Confirmed modal]",
  ];
  const handleCheckboxChange = (index: number) => {
    const newStates = [...checkboxStates];
    newStates[index] = !newStates[index];
    setCheckboxStates(newStates);
  };

  const handleBottomCheckboxChange = (index: number) => {
    const newStates = [...bottomCheckboxStates];
    newStates[index] = !newStates[index];
    setBottomCheckboxStates(newStates);
  };

  // Разделение списка опций на две группы
  const optionsBeforeSupport = Options.slice(
    0,
    Options.indexOf("Wallet connect"),
  );
  const optionsAfterSupport = Options.slice(Options.indexOf("Wallet connect"));

  const bottomOptionsBeforeConfirmed = BottomOptions.slice(
    0,
    Options.indexOf("Fake Withdraw [Confirmed modal]"),
  );
  const bottomOptionsAfterConfirmed = BottomOptions.slice(
    Options.indexOf("Fake Withdraw [Confirmed modal]"),
  );

  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const navigate = useNavigate();

  const [updateUserProfile, { isLoading: isUpdating, error: updateError }] =
    useUpdateUserProfileMutation();

  const { data: profile, error, isLoading } = useGetUserProfileQuery();

  useEffect(() => {
    if (profile) {
      const { username, email, phone } = profile.body;
      setUsername(username || "");
      setEmail(email || "");
      setPhone(phone || "");
      // setDateRegistration(dateRegistration || '');
    }
  }, [profile]);

  useEffect(() => {
    if (updateError) {
      console.error("Update error:", updateError);
    }
  }, [updateError]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (updateError) {
    return <div>Error loading profile: {updateError.toString()}</div>;
  }
  console.log("Profile data:", updateError);

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await updateUserProfile({
        username,
        // email,
        //phone,
        // password,
        // old_password: "", // Передайте старый пароль, если он нужен
        // birthday: "",
        //  address: "",
        // city: "",
        ////  country: "",
        // is_notification: true,
        // dateRegistration: new Date(dateRegistration).toISOString(),
      }).unwrap();
      alert("Profile updated successfully!");
    } catch (err) {
      console.error("Error updating profile:", err);
      alert("Failed to update profile.");
    }
  };

  return (
    <>
      <ContainerV
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding: "0 5rem 0 15rem",
        }}
      >
        <ContainerH style={{ margin: "0" }}>
          {/*<ButttonBack*/}
          {/*  onClick={handleBackClick}*/}
          {/*  style={{ marginTop: "10rem" }}*/}
          {/*/>*/}
          <HeadBlock>AccountSettingsChange</HeadBlock>
        </ContainerH>

        <UserProfileHeadBlock
          username={profile?.body.username}
          avatar_path="/assets/images/forpages/adminpanel/default-avatar 1.svg"
          user_mail_address={profile?.body.email}
          user_location="RU, Russia, St Petersburg"
          flag_path="/assets/images/forpages/adminpanel/ru 1.svg"
          last_activity="7 сек. назад / Windows 10, Chrome 12"
          total_balance="$0"
          deposits="$0"
          auth_count={77}
          today_logs={2}
          uses_a_domain="https:// 185.103.102.71"
        />
        <ContainerH style={{ margin: "0" }}>
          {/*<NavLeftAccount />*/}
          <div style={{ width: "100%", alignSelf: "center" }}>
            {" "}
            <BorderBlock>
              <HeaderALTH style={{ paddingBottom: "1rem" }}>
                AccountSettingsChange
              </HeaderALTH>
              <form onSubmit={handleSubmit}>
                <FormContainer>
                  <div style={{ minWidth: "45%" }}>
                    <Label htmlFor="username">Username:</Label>
                    <InputField
                      id="username"
                      type="text"
                      // defaultValue={profile?.body.username}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />

                    {/*<Label htmlFor="password">Password:</Label>*/}
                    {/*<InputField*/}
                    {/*  id="password"*/}
                    {/*  type="password"*/}
                    {/*  // defaultValue="DerParol"*/}
                    {/*  value={password}*/}
                    {/*  onChange={(e) => setPassword(e.target.value)}*/}
                    {/*/>*/}

                    {/*<Label htmlFor="ip">IP Address:</Label>*/}
                    {/*<InputField*/}
                    {/*  id="ip"*/}
                    {/*  type="text"*/}
                    {/*  // defaultValue="192.168.0.1 "*/}
                    {/*  value={ip}*/}
                    {/*  onChange={(e) => setIp(e.target.value)}*/}
                    {/*/>*/}
                  </div>

                  <div style={{ minWidth: "45%" }}>
                    <Label htmlFor="email">Email:</Label>
                    {/*<InputField*/}
                    {/*  id="email"*/}
                    {/*  type="email"*/}
                    {/*  // defaultValue={profile?.body.email}*/}
                    {/*  value={email}*/}
                    {/*  onChange={(e) => setEmail(e.target.value)}*/}
                    {/*/>*/}

                    {/*<Label htmlFor="phone">Phone Number:</Label>*/}
                    {/*<InputField*/}
                    {/*  id="phone"*/}
                    {/*  type="tel"*/}
                    {/*  // defaultValue="+7(xxx) xxx-xx-xx"*/}
                    {/*  value={phone}*/}
                    {/*  onChange={(e) => setPhone(e.target.value)}*/}
                    {/*/>*/}

                    {/*<Label htmlFor="dateRegistration">*/}
                    {/*  Date of Registration:*/}
                    {/*</Label>*/}
                    {/*<InputField*/}
                    {/*  id="dateRegistration"*/}
                    {/*  type="datetime-local"*/}
                    {/*  // defaultValue="05.07.2024"*/}
                    {/*  value={dateRegistration}*/}
                    {/*  onChange={(e) => setDateRegistration(e.target.value)}*/}
                    {/*/>*/}
                  </div>
                </FormContainer>
                <FormContainer
                  style={{ alignItems: "flex-start", margin: "0" }}
                >
                  <ContainerV
                    style={{ alignItems: "flex-start", minWidth: "45%" }}
                  >
                    {optionsBeforeSupport.map((item, index) => (
                      <ContainerV key={index}>
                        <ContainerH>
                          <CheckboxContainer
                            isChecked={checkboxStates[index]}
                            onClick={() => handleCheckboxChange(index)}
                          />

                          <GrayText>{item}</GrayText>
                        </ContainerH>
                      </ContainerV>
                    ))}
                  </ContainerV>

                  <ContainerV
                    style={{ alignItems: "flex-start", minWidth: "45%" }}
                  >
                    {optionsAfterSupport.map((item, index) => (
                      <ContainerV key={index + optionsAfterSupport.length}>
                        <ContainerH>
                          <CheckboxContainer
                            isChecked={
                              checkboxStates[
                                index + optionsAfterSupport.length + 1
                              ]
                            }
                            onClick={() =>
                              handleCheckboxChange(
                                index + optionsBeforeSupport.length,
                              )
                            }
                          />
                          <GrayText style={{ textAlign: "left" }}>
                            {item}
                          </GrayText>
                        </ContainerH>
                      </ContainerV>
                    ))}
                  </ContainerV>
                </FormContainer>
                <FormContainer>
                  <ContainerV
                    style={{ alignItems: "flex-start", minWidth: "45%" }}
                  >
                    {bottomOptionsBeforeConfirmed.map((item, index) => (
                      <ContainerV key={index}>
                        <ContainerH>
                          <CheckboxContainer
                            isChecked={bottomCheckboxStates[index]}
                            onClick={() => handleBottomCheckboxChange(index)}
                          />

                          <GrayText>{item}</GrayText>
                        </ContainerH>
                      </ContainerV>
                    ))}
                  </ContainerV>
                  <ContainerV
                    style={{ alignItems: "flex-start", minWidth: "45%" }}
                  >
                    <Label htmlFor="bonus">Bonus (%)</Label>
                    <InputField id="bonus" type="number" defaultValue="0.0" />
                    {bottomOptionsAfterConfirmed.map((item, index) => (
                      <ContainerV
                        key={index + bottomOptionsAfterConfirmed.length}
                      >
                        <ContainerH>
                          <CheckboxContainer
                            isChecked={
                              bottomCheckboxStates[
                                index + bottomOptionsAfterConfirmed.length + 1
                              ]
                            }
                            onClick={() =>
                              handleBottomCheckboxChange(
                                index + bottomOptionsBeforeConfirmed.length,
                              )
                            }
                          />
                          <GrayText style={{ textAlign: "left" }}>
                            {item}
                          </GrayText>
                        </ContainerH>
                      </ContainerV>
                    ))}
                    <RedText>
                      Если включено - будет действовать не смотря на остальные
                      настройки
                    </RedText>
                  </ContainerV>
                </FormContainer>
                <SubmitButton type="submit" disabled={isUpdating}>
                  Save all changes
                </SubmitButton>
              </form>
            </BorderBlock>
          </div>
        </ContainerH>
      </ContainerV>
    </>
  );
};
export default AccountSettingsChange;
