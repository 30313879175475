import React, { useState } from "react";
import styled from "styled-components";
import {
  UsersManageGoldButton,
  UsersManageGreenButton,
} from "../../functional/buttons/UsersManageButtons";
import EditDomainModal from "../../../components/forWorkerPage/Modals/EditDomainModal";
import EditDomainMailModal from "../../../components/forWorkerPage/Modals/EditDomainMailModal";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
  min-width: 6rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr repeat(8, 2fr);
  gap: 1rem;
  align-items: center;
  max-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const WorkerDomainsTable: React.FC<TableProps> = ({ data }) => {
  const [isEditDomainModalOpen, setIsEditDomainModalOpen] = useState(false);
  const [isEditDomainMailModalOpen, setIsEditDomainMailModalOpen] =
    useState(false);

  const handleEditDomainModalClick = () => {
    setIsEditDomainModalOpen(true);
  };

  const handleCloseEditDomainModal = () => {
    setIsEditDomainModalOpen(false);
  };

  const handleEditDomainMailModalClick = () => {
    setIsEditDomainMailModalOpen(true);
  };

  const handleCloseEditMailDomainModal = () => {
    setIsEditDomainMailModalOpen(false);
  };

  return (
    <BorderBlock>
      <table>
        <TableRow style={{ borderBottom: "none" }}>
          <TableHeader>ID</TableHeader>
          <TableHeader>Domain</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Title</TableHeader>
          <TableHeader>Icon</TableHeader>
          <TableHeader>Added</TableHeader>
          <TableHeader>Users</TableHeader>
          <TableHeader>Deposits</TableHeader>
          <TableHeader>Action</TableHeader>
        </TableRow>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.domain}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <img src={item.iconPath} alt="Location" />
                {item.icon}
              </TableCell>
              <TableCell>{item.added}</TableCell>
              <TableCell>{item.users}</TableCell>
              <TableCell>{item.deposits}</TableCell>
              <TableCell>
                <UsersManageGreenButton onClick={handleEditDomainModalClick}>
                  Edit
                </UsersManageGreenButton>
                <UsersManageGoldButton
                  style={{ marginTop: "1rem" }}
                  onClick={handleEditDomainMailModalClick}
                >
                  Edit email
                </UsersManageGoldButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
      <EditDomainModal
        isOpen={isEditDomainModalOpen}
        onClose={handleCloseEditDomainModal}
      />
      <EditDomainMailModal
        isOpen={isEditDomainMailModalOpen}
        onClose={handleCloseEditMailDomainModal}
      />
    </BorderBlock>
  );
};
export default WorkerDomainsTable;
