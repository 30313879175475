import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import AdminStakingPlansTable from "../../../styles/tables/forAdmin/AdminStakingPlansTable";
import CustomLinkGrey from "../../../styles/functional/links/CustomLinkGrey";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const CustomTextArea = styled.textarea`
  background-color: #191B21;
  border: none;
  color: #848E9C;
  padding: 0.5rem 0.5rem 0 0;
  //margin-bottom: 0.5rem;
  width: 100%;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;



const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;


export const GreyButton = styled.button`
  background-color: #2F323C;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10rem;

  &:hover {
    background-color: #3c404c;
  }
`;




interface CheckboxProps {
    isChecked: boolean;
}

const CheckboxContainer = styled.label<CheckboxProps>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${props => props.isChecked? '#FCD535' : '#1E2329'};
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Ensure the container hides its children */
  transition: background-color 0.3s ease;
  margin-right: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -35%); /* Adjust positioning */
    width: 15px; /* Width of the checkmark */
    height: 25px; /* Height of the checkmark */
    background-image: url('/assets/images/forpages/adminpanel/checkmark.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isChecked? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${props => props.isChecked? 'transparent' : '#848E9C'}; /* Рамка появляется, когда чекбокс не выбран */
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
`;

const AvatarButton = styled.button`
  background-color: #524926;
  border: 1px solid #FCD535;
  padding: 10px 20px;
  cursor: pointer;
  width: 15rem;
  font-weight: 700;
  border-radius: 0.25rem;
  margin-top: 2rem;
  align-self: center;
  justify-self: flex-start;
  color: #FCD535;
  align-content: center;
  margin-left: 8rem;
  min-height: 100%;

  &:hover {
    background-color: #FFFF66;
  }
`;


const TenBlocksAtTop:React.FC = () => {
    // Step 1: Define the switchStates state
    const [switchState, setSwitchState] = useState(false);
    const [secondSwitch, setSecondSwitch] = useState(false);
    const [thirdSwitch, setThirdSwitch] = useState(false);
    const [fourthSwitch, setFourthSwitch] = useState(false);
    const [fifthSwitch, setFifthSwitch] = useState(false);
    const [sixthSwitch, setSixthSwitch] = useState(false);


    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Toggle the switch state at the given index
        setSwitchState(event.target.checked);
        console.log("Switch state 1 changed to:", event.target.checked);
    };

    const handleSecondSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondSwitch(event.target.checked);
        console.log("Switch state 2 changed to:", event.target.checked);
    };

    const handleThirdSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThirdSwitch(event.target.checked);
        console.log("Switch state 3 changed to:", event.target.checked);
    };

    const handleFourthSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFourthSwitch(event.target.checked);
        console.log("Switch state 3 changed to:", event.target.checked);
    };

    const handleFifthSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFifthSwitch(event.target.checked);
        console.log("Switch state 3 changed to:", event.target.checked);
    };

    const handleSixthSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSixthSwitch(event.target.checked);
        console.log("Switch state 3 changed to:", event.target.checked);
    };


    const [bottomCheckboxStates, setBottomCheckboxStates] = useState([true, true, true, true, true]); // Array of booleans for each checkbox

    const handleBottomCheckboxChange = (index: number) => {
        setBottomCheckboxStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] =!newStates[index]; // Toggle the state of the clicked checkbox
            return newStates;
        });
    };



    const Features = [
        {
            bigText:"Enable Fake Withdraw Pending Modal for NEW users",
            smallText:"Если включено, то при вывод средств у новых пользователей будет со статусом \"In processing\" и соответствующим модальным окном."
        },
        {
            bigText:"Enable Fake Withdraw Pending Modal for NEW users",
            smallText:"Если включено, то при вывод средств у новых пользователей будет со статусом \"In processing\" и соответствующим модальным окном."
        },
        {
            bigText:"Enable Fake Withdraw Pending Modal for NEW users",
            smallText:"Если включено, то при вывод средств у новых пользователей будет со статусом \"In processing\" и соответствующим модальным окном."
        },
        {
            bigText:"Enable Fake Withdraw Pending Modal for NEW users",
            smallText:"Если включено, то при вывод средств у новых пользователей будет со статусом \"In processing\" и соответствующим модальным окном."
        },
        {
            bigText:"Enable Fake Withdraw Pending Modal for NEW users",
            smallText:"Если включено, то при вывод средств у новых пользователей будет со статусом \"In processing\" и соответствующим модальным окном."
        },
    ];

    const TestData = [
        {
            id:38,
            title:"7 days",
            days:7,
            percents:1.3,
        },
        {
            id:39,
            title:"8 days",
            days:8,
            percents:1.4,
        },
        {
            id:40,
            title:"9 days",
            days:9,
            percents:1.5,
        },
    ];


  return <>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>

          <BorderBlock style={{width:"100%",}}>
              <Title>Site settings</Title>
              <GrayText>
                  Стандартное название сайта, тайтл и иконка.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
                  }}>
                  <TitleLittle>
                      Name
                  </TitleLittle>
                  <CustomInput type="name" placeholder="name"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Title
                  </TitleLittle>
                  <CustomInput type="title" placeholder="title"/>
              </BorderBlock>
              <ContainerH style={{margin:"0", width:"100%", gap:"1rem"}}>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>
                        <img
                            src="/assets/images/forpages/adminpanel/User_domain_image.svg"
                            alt="icon"
                            style={{marginRight:"0.5rem"}}
                        />
                          Icon
                      </TitleLittle>
                      <CustomInput type="icon" placeholder="icon"/>
                  </BorderBlock>
                  <AvatarButton>
                      <img src="/assets/images/forpages/accountpage/download.svg" alt="Download" style={{marginRight:"0.5rem"}}/>
                      Avatar
                  </AvatarButton>
              </ContainerH>
              <ContainerH style={{margin:"0", gap:"2rem", paddingTop:"1rem"}}>
                  <img
                      src="/assets/images/forpages/adminpanel/ForDefaultSettings.svg"
                      alt="big"
                  />
                  <img
                      src="/assets/images/forpages/adminpanel/ForDefaultSettings.svg"
                      alt="big"
                  />
                  <img
                      src="/assets/images/forpages/adminpanel/ForDefaultSettings.svg"
                      alt="big"
                  />
              </ContainerH>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>

          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Promo settings</Title>
              <ContainerH style={{width:"100%", margin:"0"}}>
                  <GrayText>
                      Включение/Отключение отображения формы для ввода промокода на странице &nbsp;
                      <CustomLinkGrey
                          to="/"
                          style={{
                              fontSize:"14px"
                      }}>
                          <u>/profile/wallet.</u>
                      </CustomLinkGrey><br/>
                      Если включено, то на главной странице профиля будет отображаться форма для ввода промокодов.
                  </GrayText>
                  <div>
                      <Switch id="switch1" checked={switchState} onChange={handleSwitchChange} />
                      <SwitchLabel htmlFor="switch1"></SwitchLabel>
                  </div>
              </ContainerH>
              <br/>
              <ContainerH style={{width:"100%", margin:"0"}}>
                  <GrayText>
                      Включение/Отключение исчезновения формы для ввода промокодов на странице
                      &nbsp;
                      <CustomLinkGrey
                          to="/"
                          style={{
                              fontSize:"14px"
                          }}>
                          <u>/profile/wallet</u>
                      </CustomLinkGrey> после активации.<br/>
                      Если включено, то после активации промокода пользователю больше не будет показываться форма для их ввода на главной странице профиля.
                  </GrayText>
                  <div>
                      <Switch id="switch2" checked={secondSwitch} onChange={handleSecondSwitchChange} />
                      <SwitchLabel htmlFor="switch2"></SwitchLabel>
                  </div>
              </ContainerH>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{width:"100%",}}>
              <Title>Support welcome message</Title>
              <GrayText>
                  Данное сообщения будет установлено воркеру в настройках автоматически.<br/>
                  Сообщение, которое будет отправляться в саппорт новым пользователям после регистрации.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="text" placeholder="Welcome to {domain_name}, if you have any questions you can ask here, our 24/7 support team will respond within a minute. We are always happy to help you."/>
                      <div>
                          <Switch id="switch3" checked={thirdSwitch} onChange={handleThirdSwitchChange} />
                          <SwitchLabel htmlFor="switch3"></SwitchLabel>
                      </div>
                  </ContainerH>
              </BorderBlock>
              <GrayText>
                  {'{domain_name}'} - заменяется на название биржи, указаное в настройках (воркера или админа).<br/>
                  {'{domain_url}'} - заменяется на домен сайта, на котором находится пользователь.
              </GrayText>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{width:"100%",}}>
              <Title>Exchange API</Title>
              <GrayText>
                  Ключ для доступа к API биржи, которое используется для создания своих telegram ботов.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Key
                  </TitleLittle>
                  <CustomInput type="Key" placeholder="LsHnTzPUeNAQeAv9LgzswSuLXPhFkm9V"/>
              </BorderBlock>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Worker panel functionality</Title>
                  <GrayText>
                      Настройки функционала в воркер панели.
                  </GrayText>
              <br/>
              <ContainerH style={{width:"100%", margin:"0"}}>
                  <GrayText>
                      Включение/Отключение отображения топа воркеров на странице
                      &nbsp;
                      <CustomLinkGrey
                          to="/"
                          style={{
                              fontSize:"14px"
                          }}>
                          <u>/worker-panel/statistics</u>
                      </CustomLinkGrey>.<br/>
                      Если включено, то в воркер панели, на странице Statistics будет отобраться топ работников по времени.
                  </GrayText>
                  <div>
                      <Switch id="switch4" checked={fourthSwitch} onChange={handleFourthSwitchChange} />
                      <SwitchLabel htmlFor="switch4"></SwitchLabel>
                  </div>
              </ContainerH>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Email settings</Title>
              <GrayText>
                  Настройки функционала в воркер панели.
              </GrayText>
              <br/>
              <ContainerH style={{width:"100%", margin:"0"}}>
                  <GrayText>
                      Включение/Отключение отображения топа воркеров на странице
                      &nbsp;
                      <CustomLinkGrey
                          to="/"
                          style={{
                              fontSize:"14px"
                          }}>
                          <u>/profile/wallet</u>
                      </CustomLinkGrey>.<br/>
                      Если включено, то в воркер панели, на странице Statistics будет отобраться топ работников по времени.
                  </GrayText>
                  <div>
                      <Switch id="switch5" checked={fifthSwitch} onChange={handleFifthSwitchChange} />
                      <SwitchLabel htmlFor="switch5"></SwitchLabel>
                  </div>
              </ContainerH>
              <br/>
              <ContainerH style={{width:"100%", margin:"0"}}>
                  <GrayText>
                      Включение/Отключение обязательного подтверждения почты.<br/>
                      Если включено, то регистрация будет завершена только после подтверждения аккаунта по почте.<br/>
                      Если выключено - то почту можно будет подтвердить позже, а у аккаунта пользователя будет статус [Email unconfirmed]
                  </GrayText>
                  <div>
                      <Switch id="switch6" checked={sixthSwitch} onChange={handleSixthSwitchChange} />
                      <SwitchLabel htmlFor="switch6"></SwitchLabel>
                  </div>
              </ContainerH>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Server
                  </TitleLittle>
                  <CustomInput type="server"  defaultValue="mail.privateemail.com"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Port
                  </TitleLittle>
                  <CustomInput type="number"  defaultValue="465"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Email
                  </TitleLittle>
                  <CustomInput type="email"  defaultValue="test@mail.com"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>
                      Password
                  </TitleLittle>
                  <CustomTextArea  defaultValue="LsHnTzPUeNAQeAv9LgzswSuLXPhFkm9V"/>
              </BorderBlock>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>

          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Default Email settings</Title>
              <GrayText>
                  Стандартные SMTP, которые будут показываться в настройках домена у воркера.<br/>
                  По умолчанию установлены для корпоративных почт
                  &nbsp;
                  <CustomLinkGrey
                      to="/"
                      style={{
                          fontSize:"14px"
                      }}>
                      <u>namecheap.com</u>
                  </CustomLinkGrey>, которые можно регистрировать бесплатно.<br/>
                  Чтобы узнать SMTP какого то почтого сервиса, ищите в поисковике, например, вот так: mail.ru smtp.
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Server</TitleLittle>
                  <CustomInput type="server"  defaultValue="mail.privateemail.com"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Port</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="number" defaultValue="465"/>
                      <GreyButton>
                          <img src="/assets/images/forpages/adminpanel/dropdownVector.svg" alt="Vector"/>
                      </GreyButton>
                  </ContainerH>
              </BorderBlock>
              <ContainerH
                  style={{margin:"0",
                      width:"100%",
                      gap:"2rem",
                      alignItems:"flex-start",
                      paddingTop:"2rem"
              }}>
                  <ContainerV>
                      <Title>Email Registration</Title>
                      <BorderBlock style={{
                          width:"100%"
                      }}>
                          <TitleLittle>Title</TitleLittle>
                          <CustomInput type="title" defaultValue="mail.privateemail.com"/>
                      </BorderBlock>
                      <BorderBlock style={{
                          width:"100%"
                      }}>
                          <CustomTextArea
                              style={{ height: "15rem", width: "100%" }}
                              defaultValue={
                                  '!DOCTYPE html>\n' +
                                  '<html><head>\n' +
                                  '    <meta charset="utf-8">\n' +
                                  '    <meta http-equiv="x-ua-compatible" content="ie=edge">\n' +
                                  '    <title>Password recovery</title>\n' +
                                  '    <meta name="viewport" content="w">'
                              }
                          />
                      </BorderBlock>
                      <GrayText>
                          {'{domain_url}'} - заменяется на домен сайта, с которого отправляется письмо.<br/>
                          {'{domain_exchange_name}'} - заменяется на название биржи, которое указано в настройках <br/>(воркера или админа).<br/>
                          {'{confirm_url}'} - заменяется на ссылку, для подтверждения почты аккаунта.<br/>
                      </GrayText>
                  </ContainerV>
                  <ContainerV>
                      <Title>Email Password recovery</Title>
                      <BorderBlock style={{
                          width:"100%"
                      }}>
                          <TitleLittle>Title</TitleLittle>
                          <CustomInput type="title" defaultValue="mail.privateemail.com"/>
                      </BorderBlock>
                      <BorderBlock style={{
                          width:"100%"
                      }}>
                          <CustomTextArea
                              style={{ height: "15rem", width: "100%" }}
                              defaultValue={
                                  '!DOCTYPE html>\n' +
                                  '<html><head>\n' +
                                  '    <meta charset="utf-8">\n' +
                                  '    <meta http-equiv="x-ua-compatible" content="ie=edge">\n' +
                                  '    <title>Password recovery</title>\n' +
                                  '    <meta name="viewport" content="w">'
                              }
                          />
                      </BorderBlock>
                      <GrayText>
                          {'{domain_url}'} - заменяется на домен сайта, с которого отправляется письмо.<br/>
                          {'{domain_exchange_name}'} - заменяется на название биржи, которое указано в настройках <br/>(воркера или админа).<br/>
                          {'{confirm_url}'} - заменяется на ссылку, для подтверждения почты аккаунта.<br/>
                          {'{password}'} - заменяется на сгенерированный пароль, который будет установлен аккаунту после перехода по ссылке в письме.
                      </GrayText>
                  </ContainerV>
              </ContainerH>

              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>

          <BorderBlock style={{width:"100%",}}>
              <Title>Staking plans</Title>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Title</TitleLittle>
                  <CustomInput type="days" defaultValue="7 Days"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Days</TitleLittle>
                  <CustomInput type="number" defaultValue="7"/>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Percent</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="number" defaultValue="1.3"/>
                      <GreyButton>%</GreyButton>
                  </ContainerH>
              </BorderBlock>
              <GrayText>
                  Процент указывается за 1 день (процент автоматически умножается на количество дней).
              </GrayText>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Table “Staking plans”</Title>
              <AdminStakingPlansTable data={TestData}/>
          </BorderBlock>
          <BorderBlock style={{
                  width:"100%",
              }}>
                  <Title>Promo settings</Title>
                      <GrayText>
                          Минимальная сумма депозита.<br/>
                          Используется для вычисления суммы в определенной валюте, если для этой валюты не указан конкретный Min deposit amount в таблице Deposit coins.
                      </GrayText>
                  {Features.map((item, index) => (
                      <ContainerV style={{ margin: "0", alignContent:"flex-start", alignSelf:"flex-start", }} key={index}>
                          <ContainerH style={{margin:"0", marginTop:"1rem"}}>
                              <CheckboxContainer isChecked={bottomCheckboxStates[index]} onClick={() => handleBottomCheckboxChange(index)} />
                              <ContainerV style={{margin:"0", alignContent:"flex-start", alignSelf:"flex-start", }}>
                                  <GrayText style={{fontSize:"16px", fontWeight:"700", marginBottom:"-1rem", paddingBottom:"0"}}>
                                      {item.bigText}
                                  </GrayText>
                                  <GrayText>
                                      {item.smallText}
                                  </GrayText>
                              </ContainerV>
                          </ContainerH>
                      </ContainerV>
                  ))}
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>


      </ContainerV>
  </>;
};
export default TenBlocksAtTop;