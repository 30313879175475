import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import AllCryptosNav from "../../styles/navs/AllCryptosNav";
import MarketSubNav from "../../styles/navs/MarketSubNav";
import AllCryptosMainTable from "../../styles/tables/AllCryptosMainTable";

import AIAccordion from "../../styles/accordions/AIAccordion";
import {AI_ACCORDION_TEXT, AI_ACCORDION_TITLE} from "../../constants/constants";

const MarketsAI: React.FC = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '+10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '-10.83%', '$42521.18', '$42521.18', '', ''], image6: '/assets/images/icons/papper.svg', image7: '/assets/images/icons/bag.svg'},
    ];
  return(
     <ContainerV>
       <AllCryptosNav initialActiveButton={2}/>
         <AIAccordion title={AI_ACCORDION_TITLE}>{AI_ACCORDION_TEXT}</AIAccordion>
         <MarketSubNav/>
         <AllCryptosMainTable data={tableData}/>
     </ContainerV>
  );
};
export default MarketsAI;