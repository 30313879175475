//import React from "react";
import styled from "styled-components";


export const RedButton = styled.button`
  background-color: #502530;
  color: #F6465D;
  border: 1px solid #F6465D;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
  
  &:hover {
    background-color: #603030;
  }
`;

export const GreenButton = styled.button`
  background-color: #174238;
  color: #10BA7B;
  border: 1px solid #10BA7B;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
  
  &:hover {
    background-color: #008080;
  }
`;

// interface ButtonProps {
//     text:string;
// }
// const RedGreenButtons:React.FC<ButtonProps> = ({text}) => {
//   return (
//       <StyledButton>
//           {text}
//       </StyledButton>
//   );
// };
//export default RedGreenButtons;