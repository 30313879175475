import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import AdminUsersTable from "../../styles/tables/forAdmin/AdminUsersTable";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import { useGetUserListAllQuery } from "../../redux/apiService";

const AdminUsers: React.FC = () => {
  const { data: profile, error, isLoading } = useGetUserListAllQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading profile: {error.toString()}</div>;
  }
  console.log("Profile data:", profile);

  const testData = [
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-5",
      status: "Offline",
      username: "Intale",
      worker: "111111@mail.ru",
      domain: "sweblink.com",
      deposits: "1 / 394.79$",
      registered: "2024/02/01 15:58:40",
      location: "RU, Russia, Stavropol",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "178.34.150.156",
      lastActivity: "58 мин. назад",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-6",
      status: "Online",
      username: "Zephyr",
      worker: "222222@mail.ru",
      domain: "zephyrlink.com",
      deposits: "2 / 789.12$",
      registered: "2024/02/02 16:19:20",
      location: "US, United States, New York",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "192.168.1.1",
      lastActivity: "1 час назад",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-7",
      status: "Offline",
      username: "Vortex",
      worker: "333333@mail.ru",
      domain: "vortexlink.com",
      deposits: "3 / 1594.34$",
      registered: "2024/02/03 17:40:00",
      location: "CA, Canada, Toronto",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "203.0.113.0",
      lastActivity: "2 часа назад",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-8",
      status: "Online",
      username: "Quasar",
      worker: "444444@mail.ru",
      domain: "quasarlink.com",
      deposits: "4 / 2698.56$",
      registered: "2024/02/04 18:01:30",
      location: "GB, United Kingdom, London",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "198.51.100.0",
      lastActivity: "3 часа назад",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-9",
      status: "Offline",
      username: "Pulsar",
      worker: "555555@mail.ru",
      domain: "pulsarlink.com",
      deposits: "5 / 3799.78$",
      registered: "2024/02/05 19:22:10",
      location: "DE, Germany, Berlin",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "203.0.113.1",
      lastActivity: "4 часа назад",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-10",
      status: "Online",
      username: "Nova",
      worker: "666666@mail.ru",
      domain: "nova.link",
      deposits: "6 / 4890.90$",
      registered: "2024/02/06 20:43:40",
      location: "FR, France, Paris",
      imagePathLocation: "/assets/images/forpages/adminpanel/ru 1.svg",
      ip: "198.51.100.1",
      lastActivity: "5 часов назад",
    },
  ];

  const formattedData =
    profile?.body.data.map((user: any) => ({
      imagePath:
        user.avatar ||
        "/assets/images/forpages/adminpanel/default-avatar 1.svg", // Подставляем аватар или дефолтную картинку
      id: user.id, // Идентификатор пользователя
      status: user.isOnline ? "Online" : "Offline", // Онлайн статус
      username: user.username, // Имя пользователя
      worker: user.email, // Email пользователя
      domain: user.domain, // Домен, если есть
      deposits: `${user.depositCount} / ${user.totalDeposit}$`, // Количество депозитов и сумма
      registered: user.created_at, // Дата регистрации
      location: `${user.country}, ${user.city}`, // Локация
      imagePathLocation: `/assets/images/forpages/adminpanel/${user.countryCode}.svg`, // Путь до иконки страны
      ip: user.ip, // IP адрес
      lastActivity: `${user.lastActivity} мин. назад`, // Последняя активность
    })) || testData;

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock>Users</HeadBlock>

      <AdminUsersTable data={formattedData} />
    </ContainerV>
  );
};
export default AdminUsers;
