import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedSupportChatState {
  id: string;
}

const initialState: SelectedSupportChatState = {
  id: localStorage.getItem("selectedSupportChat") as string,
};

const selectedSupportChatSlice = createSlice({
  name: "selectedSupportChat",
  initialState,
  reducers: {
    setSelectedSupportChat: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
      localStorage.setItem("selectedSupportChat", state.id);
      state.id = localStorage.getItem("selectedSupportChat") as string;
    },
    clearSelectedSupportChat: (state) => {
      state.id = ""; // очищаем ID выбранного чата
      localStorage.removeItem("selectedSupportChat"); // удаляем из localStorage
    },
  },
});

export const { setSelectedSupportChat, clearSelectedSupportChat } =
  selectedSupportChatSlice.actions;
export default selectedSupportChatSlice.reducer;
