import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import GraficHeaderTable from "../../styles/tables/GraficHeaderTable";
import InformTable from "../../styles/tables/InformTable";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import HeaderALTH from "../../styles/headers/HeaderALTH";

const Markets: React.FC = () => {
    const header_one = "Популярность";
    const header_two = "Популярность";
    const content_one = "#1";
    const content_two = "259.13$";
    const header_three = "Объем";
    const header_four = "Объем в обращении";
    const content_three = "4.38$";
    const content_four = "19.62M";

    const header_five = "Обменный курс за 7 дней";
    const header_six = "Обменный курс за 24 часа";
    const content_five = "+1.69%";
    const content_six = "+0.04%";
    const header_seven = "Обменный курс за 1 месяц";
    const header_eight = "Обменный курс за 3 месяца";
    const content_seven = "-1.65%";
    const content_eight = "+23.17%";

  return (
      <div className="basic-div">
          <ContainerH style={{alignItems:"flex-start"}}>
              <ContainerV>
                  <GraficHeaderTable/>
                  <img src="/assets/images/forpages/salepage/market-grafic.svg" alt="Market grafic"/>
              </ContainerV>
              <ContainerV style={{marginLeft:"7rem", marginTop:"4rem"}}>
                  <HeaderALTH>Рынки Bitcoin</HeaderALTH>
                  <InformTable
                      header_first={header_one}
                      header_second={header_two}
                      content_first={content_one}
                      content_second={content_two}
                  />
                  <InformTable
                      header_first={header_three}
                      header_second={header_four}
                      content_first={content_three}
                      content_second={content_four}
                  />
                  <BlockGreyText>Bitcoin растет в цене на этой неделе.</BlockGreyText>
                  <BlockGreyText>В настоящее время цена на Bitcoin составляет ك.د13,229.46 за BTC, а количество в обращении составляет 19.62M BTC. В результате чего общая рыночная капитализация составляет ك.د259.55B. За последние 24 часа объем торгов Bitcoin увеличился на ك.د125.28B, что соответствует увеличению на 26.1896%. Кроме того, за прошедший день было совершено сделок с BTC на сумму ك.د4.78B.</BlockGreyText>
                  <HeaderALTH style={{marginTop:"7rem"}}>Таблицы конвертации</HeaderALTH>
                  <InformTable
                      header_first={header_five}
                      header_second={header_six}
                      content_first={content_five}
                      content_second={content_seven}
                  />
                  <InformTable
                      header_first={header_seven}
                      header_second={header_eight}
                      content_first={content_six}
                      content_second={content_eight}
                  />
                  <BlockGreyText>Обменный курс Bitcoin снижается.</BlockGreyText>
                  <BlockGreyText>В настоящее время стоимость 1 BTC составляет 13,227.36 KWD, т. е. покупка 5 BTC будет стоить 66,136.81 KWD. И наоборот, 1.00 KWD можно обменять на 0.0000756 BTC, а 50.00 KWD можно обменять на 0.00378 BTC без учета комиссии платформы и платы за газ.</BlockGreyText>
              </ContainerV>
          </ContainerH>
      </div>
  );
};
export default Markets;