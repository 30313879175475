import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";

interface CheckboxProps {
    isChecked: boolean;
}

const CheckboxContainer = styled.label<CheckboxProps>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${props => props.isChecked? '#FCD535' : '#1E2329'};
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Ensure the container hides its children */
  transition: background-color 0.3s ease;
  margin-right: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -35%); /* Adjust positioning */
    width: 15px; /* Width of the checkmark */
    height: 25px; /* Height of the checkmark */
    background-image: url('/assets/images/forpages/adminpanel/checkmark.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isChecked? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  }
`;

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 2rem 2rem 4rem 2rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    align-items: flex-start;
    max-width: 100%;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 7rem;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #FFFF66;
  }
`;

const WhiteText = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: white;
  text-align: left;
  margin-bottom: 3rem;
`;

const GrayText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #848E9C;
`;

const AccountRegistrationSettings = () => {
    // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // State for the checkbox
    //
    // const handleCheckboxChange = () => {
    //     setIsCheckboxChecked(!isCheckboxChecked); // Toggle the checkbox state
    // };
    const [checkboxStates, setCheckboxStates] = useState([false, false, false, false, false]); // Array of booleans for each checkbox

    const handleCheckboxChange = (index: number) => {
        setCheckboxStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] =!newStates[index]; // Toggle the state of the clicked checkbox
            return newStates;
        });
    };

    const Options = ['Fake Withdraw [Pending]', 'Fake Withdraw [Confirmed]', 'Premium', 'Wallet connect', 'Fake KYC verified']

    return (
    <BorderBlock>

      <WhiteText>
          Account registration settings
      </WhiteText>
      <GrayText style={{marginBottom:"1rem"}}>
          Минимальная сумма депозита.
      </GrayText>
        <GrayText style={{marginBottom:"2rem"}}>
            Используется для вычисления суммы в определенной валюте, если для этой валюты не указан конкретный Min deposit amount в таблице Deposit coins.
        </GrayText>
        <ContainerH style={{margin:"0", width:"100%"}}>

            {Options.map((item, index) => (
                <ContainerH style={{ margin: "0" }} key={index}>
                    <CheckboxContainer isChecked={checkboxStates[index]} onClick={() => handleCheckboxChange(index)} />
                    <GrayText>
                        {item}
                    </GrayText>
                </ContainerH>
            ))}
        </ContainerH>
      <SubmitButton>Add</SubmitButton>
    </BorderBlock>
  );
};
export default AccountRegistrationSettings;