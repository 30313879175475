import React, { useState } from "react";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import ButtonST from "../../styles/functional/buttons/ButtonST";
import { useNavigate } from "react-router-dom";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import AuthSmollLink from "../../styles/functional/links/AuthSmollLink";
import ButtonBack from "../../styles/functional/buttons/ButttonBack";
import styled from "styled-components";
import { useConfirmUserMutation } from "../../redux/apiService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

const InputContainer = styled.div`
  position: relative;
  border: 1px solid #2F323C;
  border-radius: 5px;
  width: 410px;
  height: 60px;
  align-self: flex-start;
`;

const CustomInput = styled.input`
  display: flex;
  font-size: 16px;
  outline: none;
  color: #ffffff;
  background-color: #191B21;
  border: none;
  margin-right: 2rem;
  padding: 10px;
  align-items: center;
  justify-content: center;
  transform: translateY(+15%);
`;

const CustomButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #FCD535;
  background-color: transparent;
  border: none;
  &:hover {
    color: white;
  }
`;

const Verificate: React.FC = () => {
    const [verificationCode, setVerificationCode] = useState({ code: "" });
    const buttonText = "Отправить";
    const verificate = "/auth-page/sing-up";
   // const userContact = useSelector((state: RootState) => state.email);
    const login = "/auth-page/login";
    const navigate = useNavigate();
    const returnToForm = useNavigate();

    const [confirmUser, { isLoading, isError }] = useConfirmUserMutation();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode({
            ...verificationCode,
            [e.target.name]: e.target.value,
        });
    };

    const handleButtonClick = async () => {
        try {
            const response = await confirmUser({ token: verificationCode.code }).unwrap();
            console.log("Успешный ответ:", response);
            navigate(login);
        } catch (error) {
            console.error('Ошибка подтверждения:', error);
        }
    };

    const handleBackClick = () => {
        returnToForm("/auth-page/create-account");
    };

    return (
        <>
            <ContainerH>
                <ContainerV>
                    <ButtonBack onClick={handleBackClick} />
                    <HeaderALTH>Верификация по эл. почте</HeaderALTH>
                    <InputContainer>
                        <CustomInput
                            name="code"
                            placeholder="Код верификации"
                            onChange={handleInputChange}
                        />
                        <CustomButton>Отправить код ещё раз</CustomButton>
                    </InputContainer>
                    <ButtonST
                        style={{
                            width: "410px",
                            height: "60px",
                            alignSelf: "flex-start",
                        }}
                        onClick={handleButtonClick}
                        disabled={isLoading}
                    >
                        {buttonText}
                    </ButtonST>
                    {isError && <p style={{ color: 'red' }}>Ошибка подтверждения</p>}
                    <ContainerH style={{ justifyContent: "flex-start" }}>
                        <BlockGreyText style={{ whiteSpace: "nowrap" }}>
                            Не нужен личный аккаунт?
                            <AuthSmollLink to={verificate} style={{ whiteSpace: "wrap" }}>
                                Зарегистрируйте корпоративный аккаунт
                            </AuthSmollLink>
                        </BlockGreyText>
                    </ContainerH>
                </ContainerV>
                <ContainerV>
                    <img src="/assets/images/forpages/auth/two-brokers.svg" alt="meet" />
                </ContainerV>
            </ContainerH>
        </>
    );
};

export default Verificate;
