import React from "react";
import styled from "styled-components";
import DeleteButton from "../../functional/buttons/DeleteButton";




const TableHeader = styled.td`
  flex: 1;
  padding: 8px 8px 8px 0;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  //max-width: 9rem;
  min-width: 9rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(5,auto) ; 
  gap: 1rem;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px 8px 8px 0;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  //max-width: 9rem;
  min-width: 9rem;
`;

interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
    style?: React.CSSProperties; // Опциональный пропс для стилей
}


const AdminStakingPlansTable:React.FC<TableProps> = ({ data }) => {

    return <>
            <table style={{minWidth:"100%"}}>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Days</TableHeader>
                    <TableHeader>Percent</TableHeader>
                    <TableHeader>Action</TableHeader>
                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.days}</TableCell>
                        <TableCell>{item.percents}</TableCell>
                        <TableCell>
                            <DeleteButton/>
                        </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
    </>;
};
export default AdminStakingPlansTable;