import React, { useState } from "react";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import AdminWorkerListTable from "../../styles/tables/forAdmin/AdminWorkerListTable";
import styled from "styled-components";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import {
  useAddWorkerToDomainMutation,
  useGetUserToDomainListQuery,
} from "../../redux/apiService";
import { AddWorker } from "../../redux/types/apiRequestsTypes";

const BorderBlock = styled.div`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const TitleMiddle = styled.h2`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
`;
const TitleLittle = styled.h5`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
`;

const EmailInput = styled.input`
  background-color: #191b21;
  border: none;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 20%;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
`;

const RedText = styled.sup`
  color: darkred;
`;

const AdminWorkerList: React.FC = () => {
  const [workerEmail, setWorkerEmail] = useState("");
  const {
    data: userToDomainList,
    error,
    isLoading: isAddingWorker,
    refetch,
  } = useGetUserToDomainListQuery();

  const [addNewWorker] = useAddWorkerToDomainMutation();

  const handleAddWorker = async () => {
    try {
      const newWorkerData: AddWorker = {
        email: workerEmail,
        role: {
          code: "worker", // Жестко заданное значение для поля role.code
        },
      };
      if (workerEmail) {
        await addNewWorker(newWorkerData).unwrap(); // Отправка email на сервер
        console.log("Worker added successfully");
        setWorkerEmail(""); // Очистить поле email после добавления
        refetch(); // Обновить список воркеров
      } else {
        console.error("Please enter a worker email");
      }
    } catch (error) {
      console.error("Failed to add worker:", error);
    }
  };

  if (isAddingWorker) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading userToDomainList: {error.toString()}</div>;
  }
  console.log("userToDomainList data:", userToDomainList);
  const testData = [
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random123@example.com",
      status: "Offline",
      username: "Intale",
      password: "Zgz0RUrORg",
      users: "10",
      deposits: "0 / 0$",
      registered: "2024/03/05 20:01:27",
      domain: "lunaxylink.com, www.lunaxylink.com",
      lastActivity: "2024/03/20 22:00:09",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random456@example.com",
      status: "Online",
      username: "Zephyr",
      password: "aBcDeFgHiJk",
      users: "15",
      deposits: "100 / 500$",
      registered: "2024/03/06 15:30:45",
      domain: "zephyrlink.com, www.zephyrlink.com",
      lastActivity: "2024/03/21 10:15:30",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random789@example.com",
      status: "Offline",
      username: "Vortex",
      password: "kLmNoPqRsTu",
      users: "20",
      deposits: "200 / 1000$",
      registered: "2024/03/07 12:45:10",
      domain: "vortexlink.com, www.vortexlink.com",
      lastActivity: "2024/03/22 18:30:00",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random101112@example.com",
      status: "Online",
      username: "Quasar",
      password: "vWxYzAbCDeFg",
      users: "25",
      deposits: "300 / 1500$",
      registered: "2024/03/08 09:00:25",
      domain: "quasarlink.com, www.quasarlink.com",
      lastActivity: "2024/03/23 06:45:15",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random131415@example.com",
      status: "Offline",
      username: "Pulsar",
      password: "gHijKlMnOp",
      users: "30",
      deposits: "400 / 2000$",
      registered: "2024/03/09 17:20:40",
      domain: "pulsarlink.com, www.pulsarlink.com",
      lastActivity: "2024/03/24 14:10:50",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random161718@example.com",
      status: "Online",
      username: "Nova",
      password: "qRstUvWxYz",
      users: "35",
      deposits: "500 / 2500$",
      registered: "2024/03/10 23:35:05",
      domain: "nova.link, www.nova.link",
      lastActivity: "2024/03/25 08:25:20",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random192021@example.com",
      status: "Offline",
      username: "Orion",
      password: "aBcDeFgHiJk",
      users: "40",
      deposits: "600 / 3000$",
      registered: "2024/03/11 05:50:30",
      domain: "orion.link, www.orion.link",
      lastActivity: "2024/03/26 16:40:45",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random222324@example.com",
      status: "Online",
      username: "Astra",
      password: "kLmNoPqRsTu",
      users: "45",
      deposits: "700 / 3500$",
      registered: "2024/03/12 13:55:50",
      domain: "astra.link, www.astra.link",
      lastActivity: "2024/03/27 07:05:10",
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "random252627@example.com",
      status: "Offline",
      username: "Nebula",
      password: "vWxYzAbCDeFg",
      users: "50",
      deposits: "800 / 4000$",
      registered: "2024/03/13 21:10:15",
      domain: "nebula.link, www.nebula.link",
      lastActivity: "2024/03/28 19:20:30",
    },
  ];

  const formattedData =
    userToDomainList?.body.data.map((userToDomain: any) => ({
      // id: preset.id,
      imagePath:
        userToDomain.user.avatar ||
        "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: userToDomain.user.email,
      status: "Online",
      username: userToDomain.user.username,
      users: userToDomain.users_count,
      password: "vWxYzAbCDeFg",
      deposits: "800 / 4000$",
      // registered: "2024/03/13 21:10:15",
      // registered: userToDomain.created_at,
      registered: "2024/03/13 21:10:15",
      domain: "nebula.link, www.nebula.link",
      lastActivity: "2024/03/28 19:20:30",
    })) || testData;

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock>Worker List</HeadBlock>
      <BorderBlock style={{ width: "100%" }}>
        <TitleMiddle>Adding a worker</TitleMiddle>
        <BorderBlock>
          <TitleLittle>
            Worker email <RedText>*</RedText>
          </TitleLittle>
          <EmailInput
            type="email"
            value={workerEmail}
            onChange={(e) => setWorkerEmail(e.target.value)}
            placeholder="Введите адрес почты"
          />
        </BorderBlock>
        <BlockGreyText>Для добавления воркера, укажите его email</BlockGreyText>
        <SubmitButton onClick={handleAddWorker}>
          {isAddingWorker ? "Adding..." : "Add a worker"}
        </SubmitButton>
      </BorderBlock>
      <AdminWorkerListTable data={formattedData} refetch={refetch} />
    </ContainerV>
  );
};
export default AdminWorkerList;
