import React from "react";
import styled from "styled-components";
import AssetsSubNav from "../../navs/AssetsSubNav";
import AssetsWalletOverviewNav from "../../navs/AssetsWalletOverviewNav";
import ContainerH from "../../containers/ContainerH";

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.6fr 2fr repeat(3, 1fr);
  gap: 1rem;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: #1e2329;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  //width: 8rem;
`;

const WhiteText = styled.text`
  font-weight: 700;
  font-size: 16px;
  color: white;
`;

const GrayText = styled.text`
  font-weight: 500;
  font-size: 16px;
  color: #848e9c;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const AssetsWalletOverviewWalletsTable: React.FC<TableProps> = ({ data }) => {
  const names = ["Монета", "Сумма", "Стоимость монеты", "Действия"];
  return (
    <>
      <ContainerH style={{ margin: "0", marginTop: "1rem" }}>
        <AssetsWalletOverviewNav initialActiveButton={2} />
      </ContainerH>
      <table>
        <AssetsSubNav names={names} />
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                {" "}
                <img src={item.iconPath} alt="Coin" />
                <strong>{item.icon}</strong>
              </TableCell>
              <TableCell>
                <GrayText>{item.coinType}</GrayText>
              </TableCell>
              <TableCell>
                <WhiteText>{item.sumDollar}</WhiteText>
                <br />
                <GrayText>{item.sumRubels}</GrayText>
              </TableCell>
              <TableCell>{item.coinCost}</TableCell>
              <TableCell>{item.action}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </>
  );
};
export default AssetsWalletOverviewWalletsTable;
