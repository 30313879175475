import React from "react";
import BasicDiv from "../styles/blosks/BasicDiv";
import MarketHeaderView from "../components/formarketpage/MarketHeaderView";
import {Outlet} from "react-router-dom";


const MarketPage: React.FC = () => {
  return <>
    <BasicDiv>
      <MarketHeaderView/>
      <Outlet/>
    </BasicDiv>
  </>;
};
export default MarketPage;