import React from "react";
import styled from "styled-components";

const CustomTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #293039;
  border-radius: 20px;
  justify-content: space-between;
  align-self: center;
  overflow: hidden;
  width: 80%;
`;
const CustomTableRow = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  //gap: 10px;
  
`;

const CustomTableCell = styled.div`
  //border: 1px solid #293039;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 0 #293039; /* Имитация границы между ячейками */

`;

const LeftCellPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TopText = styled.span`
  color: #fff;
  font-size: 16px;
`;

const BottomText = styled.span`
  color: #848E9C;
  font-size: 16px;
`;

const RightCellPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.img`

  `;

const ConvertTable = () => {
    return (
        <CustomTableContainer>
        <CustomTableRow>
            <CustomTableCell>
                <LeftCellPart>
                    <TopText>С BTC на USD</TopText>
                    <BottomText>1 BTC = 43,143.38 USD</BottomText>
                </LeftCellPart>
                <RightCellPart>
                    <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
                </RightCellPart>
            </CustomTableCell>
            <CustomTableCell>
                <LeftCellPart>
                    <TopText>С BTC на USD</TopText>
                    <BottomText>1 BTC = 43,143.38 USD</BottomText>
                </LeftCellPart>
                <RightCellPart>
                    <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
                </RightCellPart>
            </CustomTableCell>
            <CustomTableCell>
                <LeftCellPart>
                    <TopText>С BTC на USD</TopText>
                    <BottomText>1 BTC = 43,143.38 USD</BottomText>
                </LeftCellPart>
                <RightCellPart>
                    <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
                </RightCellPart>
            </CustomTableCell>
        </CustomTableRow>
    <CustomTableRow>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
    </CustomTableRow>
    <CustomTableRow>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
        <CustomTableCell>
            <LeftCellPart>
                <TopText>С BTC на USD</TopText>
                <BottomText>1 BTC = 43,143.38 USD</BottomText>
            </LeftCellPart>
            <RightCellPart>
                <ImgContainer src="/assets/images/forpages/salepage/conv-btc-to-eth.svg" alt="Icons" />
            </RightCellPart>
        </CustomTableCell>
    </CustomTableRow>
        </CustomTableContainer>
    );
};

export default ConvertTable;
