import React, { useState } from "react";
import styled from "styled-components";
import ContainerH from "../../containers/ContainerH";
import ContainerV from "../../containers/ContainerV";
import { GrayText } from "../../../components/forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.5fr 12fr;
  gap: 0;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
`;

const AvatarImg = styled.img`
  height: 62px;
  width: 62px;
  padding-right: 1rem;
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

const SearchInput = styled.input.attrs({ type: "text" })`
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
  align-self: center;
  color: #848e9c;
`;

const SearchIcon = styled.img`
  height: 15.8px;
  width: 15.42px;
  margin-right: 1rem;
  align-self: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: #fcd535;
  color: #1e2329;
  cursor: pointer;
  border-radius: 4px;

  &:disabled {
    background-color: #848e9c;
    cursor: not-allowed;
  }
`;

const PageNumber = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border: 2px solid #fcd535;
  border-radius: 4px;
  color: #fcd535;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
  font-weight: bold;

  //&:hover {
  //  background-color: #fcd535;
  //  color: #1e2329;
  //}
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const WorkerSupportTable: React.FC<TableProps> = ({ data }) => {
  // Step 1: Define the switchStates state
  const [switchState, setSwitchState] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Step 2: Implement the handleSwitchChange function
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the switch state at the given index
    setSwitchState(event.target.checked);
    console.log("Switch state changed to:", event.target.checked);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = !switchState
    ? data
    : data.filter(
        (item) => item.unreviewedCounter && item.unreviewedCounter > 0,
      );
  const searchedData = searchTerm
    ? data.filter((item) =>
        item.user.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : filteredData;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedData = searchedData.slice(
    startIndex,
    startIndex + itemsPerPage,
  );
  const totalPages = Math.ceil(searchedData.length / itemsPerPage);

  // const handlePreviousPage = () => {
  //   setCurrentPage((prev) => Math.max(prev - 1, 1));
  // };
  //
  // const handleNextPage = () => {
  //   setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  // };

  return (
    <BorderBlock
      style={{ minWidth: "40%", width: "40%", flex: "1", height: "650px" }}
    >
      <BorderBlock
        style={{
          padding: "0.5rem",
          minWidth: "0",
          marginTop: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <ContainerH
          style={{
            margin: "0",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon
            src="/assets/images/forpages/adminpanel/ZoomOut.svg"
            alt="ZoomOut"
          />
          <SearchInput
            placeholder="Поиск"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </ContainerH>
      </BorderBlock>
      <ContainerH
        style={{
          margin: "0",
          paddingTop: "1.5rem",
          paddingBottom: "1rem",
          minWidth: "100%",
        }}
      >
        <GrayText>Все сообщения/непрочитанные</GrayText>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "1rem",
          }}
        >
          <Switch
            id="switch"
            checked={switchState}
            onChange={handleSwitchChange}
          />
          <SwitchLabel htmlFor="switch"></SwitchLabel>
        </div>
      </ContainerH>
      <table>
        <tbody>
          {displayedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <AvatarImg src={item.imagePath} alt="Avatar" />
              </TableCell>
              <TableCell>
                <ContainerV>
                  <ContainerH style={{ margin: "0" }}>
                    <div style={{ justifySelf: "flex-start" }}>{item.user}</div>
                    <GrayText style={{ justifySelf: "flex-end" }}>
                      {item.date}
                    </GrayText>
                  </ContainerH>
                  <ContainerH style={{ margin: "0" }}>
                    <GrayText style={{ justifySelf: "flex-start" }}>
                      {item.lastMessage.length >= 48
                        ? `${item.lastMessage.slice(0, 48)}...`
                        : item.lastMessage}
                    </GrayText>
                    {item.unreviewedCounter && (
                      <div style={{ justifySelf: "flex-end" }}>
                        {item.unreviewedCounter}
                      </div>
                    )}
                  </ContainerH>
                </ContainerV>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
      <PaginationContainer>
        {/*<PaginationButton*/}
        {/*  onClick={handlePreviousPage}*/}
        {/*  disabled={currentPage === 1}*/}
        {/*>*/}
        {/*  Previous*/}
        {/*</PaginationButton>*/}
        {/*<PaginationButton*/}
        {/*  onClick={handleNextPage}*/}
        {/*  disabled={currentPage === totalPages}*/}
        {/*>*/}
        {/*  Next*/}
        {/*</PaginationButton>*/}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              style={{
                borderColor: currentPage === index + 1 ? "#fcd535" : "#1e2329",
              }}
            >
              {index + 1}
            </PageNumber>
          ))}
        </div>
      </PaginationContainer>
    </BorderBlock>
  );
};

export default WorkerSupportTable;
