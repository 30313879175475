import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedUserState {
  id: string;
}

const initialState: SelectedUserState = {
  id: localStorage.getItem("selectedUser") as string,
};

const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
      localStorage.setItem("selectedUser", state.id);
      state.id = localStorage.getItem("selectedUser") as string;
    },
  },
});

export const { setSelectedUser } = selectedUserSlice.actions;

export default selectedUserSlice.reducer;
