import React from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import HeaderALTH from "../../../styles/headers/HeaderALTH";
import UserProfileNav from "../../../styles/navs/UserProfileNav";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;

const CustomInput = styled.textarea`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;;
  color: white;
  padding: 1rem 0.5rem 0.5rem 0.5rem; // Увеличено значение padding-left до 0.5rem
  margin-bottom: 0.5rem;
  width: 100%;
  height: 15rem;


`;



const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;



const UserErrorMessages:React.FC = () => {


  return <>
      <UserProfileNav initialActiveButton={3}/>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>
          <HeaderALTH style={{ paddingTop:"2rem"}}>
              Error messages
          </HeaderALTH>
          <ContainerH style={{width:"100%", gap:"4%", flexWrap:"wrap", margin:"0"}}>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Spot Trading error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Swap error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Staking error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Transfer error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>P2P error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Support error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Withdraw error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Withdraw Verification error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Withdraw AML Error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Other Error</Title>
                  <CustomInput  defaultValue="Type a message"/>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
          </ContainerH>
      </ContainerV>
  </>;
};
export default UserErrorMessages;