import styled from "styled-components";

const BothSideLinesHeader = styled.div`
  margin: 1rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #848E9C;

  &:after,
  &:before {
    content: '';
    margin-top: 0.7em;
    height: 0;
    border-top: 1px groove #848E9C;
    width: 100%;
    position: absolute;
    margin-right: -100%;
    margin-left: 1%;
    color: #848E9C;
    background-color: #848E9C;
  }

  &:before {
    margin-left: -101%;
  }

    `;
export default BothSideLinesHeader;