import { combineReducers } from "@reduxjs/toolkit";
import { profilesApi, supportApi } from "./apiService";
import { domainsApi } from "./apiService";
import authSlice from "./slices/authSlice";
import userProfileSlice from "./slices/userProfileSlice";
import selectedUserSlice from "./slices/selectedUserSlice";
import selectedSupportChatSlice from "./slices/selectedSupportChatSlice";

const rootReducer = combineReducers({
  userProfile: userProfileSlice,
  auth: authSlice,
  [profilesApi.reducerPath]: profilesApi.reducer, // Подключаем profilesApi
  [domainsApi.reducerPath]: domainsApi.reducer, // Подключаем domainsApi
  [supportApi.reducerPath]: supportApi.reducer, // Подключаем supportApi
  selectedUser: selectedUserSlice,
  selectedSupportChat: selectedSupportChatSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
