import React from 'react';
import styled from 'styled-components';

const AllCryptosMainTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  //margin: 20px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: auto auto repeat(4, 1fr) auto auto;  
  gap: 2rem 0;
  padding: 10px;
  //border: 1px solid #ddd; 
  
  &:hover{
    background-color: #1E2329;
  }
`;

interface TableCellProps {
    isImage?: boolean;
    isSecondFromStart?: boolean;
    isFourth?: boolean;

}
const TableCell = styled.div<TableCellProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;

  ${({ isImage }) => isImage && `
    grid-column: 1;
  `}

  ${({ isSecondFromStart }) => isSecondFromStart && `
    color: #848E9C;
    font-weight: 500;
    margin-right: 7rem;
    padding-left: 0;
  `}

  ${({ isFourth }) => isFourth && `
    display: flex;
    align-items: center;
    &.red-text {
      color: #F6465D;
    }

    &.green-text {
      color: #10BA7B;
    }

    .arrow-icon {
      width: 20px;
      margin-left: 5px;
    }
  `}
`;

const Image = styled.img`
  width: 30px; 
  margin-right: 0.5rem;
`;

const ImageSmoll = styled.img`
  
`;

const ArrowIcon = styled.img`
  width: 20px;
  margin-left: 5px;
`;
interface AllCryptosMainTableProps {
    data: {
        image: string;
        cells: string[];
        image6: string;
        image7: string;
    }[];
}
const AllCryptosMainTable: React.FC<AllCryptosMainTableProps> = ({data }) => {
    return (
        <AllCryptosMainTableContainer>
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => (
                        <TableCell
                            key={cellIndex}
                            isImage={cellIndex === 0}
                            isSecondFromStart={cellIndex === 1}
                            isFourth={cellIndex === 3}
                            className={cellIndex === 3 ? (cell.includes("-") ? "red-text" : "green-text") : ""}>
                            {cellIndex === 0 && <Image src={row.image} alt="Image" />}
                            {(cellIndex === 6 || cellIndex === 7) &&
                                <ImageSmoll
                                    src={cellIndex === 6 ? row.image6 : row.image7}
                                    alt={`Image ${cellIndex}`} />}
                            {cellIndex === 3 && (
                                <>
                                    {cell}
                                    <ArrowIcon
                                        className="arrow-icon"
                                        src={cell.includes("-") ?
                                            "/assets/images/icons/fall.svg"
                                            :
                                            "/assets/images/icons/growth.svg"} alt="Arrow"
                                    />
                                </>
                            )}
                            {cellIndex !== 3 && cell}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </AllCryptosMainTableContainer>
    );
};
export default AllCryptosMainTable;