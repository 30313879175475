import React from "react";
import styled from "styled-components";
import ImageChangingButton from "../functional/buttons/ButtonSubMenuMarket";
import TableNavDropdown from "../functional/dropdowns/TableNavDropdown";


const MarketSubNavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;

  `;

const MarketSubNav: React.FC = () => {
    const named = "Название";
    const price = "Цена";
    const change = "Изменить";
    const max_min = "Макс 24ч / Мин за 24ч";
    const volume = "Объем за 24ч";
    const capitalization = "Капитализация";
    const actions = "Действия";
    const dropdownText = "24 ч";
    const dropdownOptions = ['1 ч', '4 ч', '24 ч'];
  return (
      <MarketSubNavContainer style={{paddingBottom:"2rem"}}>
          <ImageChangingButton name={named}/>
          <MarketSubNavContainer>
          <MarketSubNavContainer style={{gap:"0.5rem", marginLeft:"5rem"}}>
          <ImageChangingButton name={price}/>
          <TableNavDropdown buttonText={dropdownText}  options={dropdownOptions}/>
          <ImageChangingButton name={change}/>
          </MarketSubNavContainer>
          <ImageChangingButton name={max_min}/>
          <ImageChangingButton name={volume}/>
          <ImageChangingButton name={named}/>
          <ImageChangingButton name={capitalization}/>
          <ImageChangingButton name={actions}/>
          </MarketSubNavContainer>
      </MarketSubNavContainer>


  );
};
export default MarketSubNav;