import React from "react";
import styled from "styled-components";
import ContainerH from "../../../containers/ContainerH";
import UserTransactionNav from "../../../navs/UserTransactionNav";
import HeaderFAL from "../../../headers/HeaderFAL";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 3rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  width: 100%;

  &:hover {
    background-color: #1e2329;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const UserStakingTable: React.FC<TableProps> = ({ data }) => {
  return (
    <>
      <BorderBlock>
        <ContainerH
          style={{
            margin: "0",
            marginTop: "1rem",
            marginBottom: "1rem",
            //justifyContent: "flex-end",
          }}
        >
          <HeaderFAL style={{ fontSize: "32px" }}>Staking</HeaderFAL>
          <UserTransactionNav initialActiveButton={2} />
        </ContainerH>
        <table>
          <thead>
            <TableRow style={{ borderBottom: "none" }}>
              <TableHeader>Asset</TableHeader>
              <TableHeader>Plan</TableHeader>
              <TableHeader>Percents</TableHeader>
              <TableHeader>Left</TableHeader>
              <TableHeader>Realtime Profit</TableHeader>
              <TableHeader>Invested</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img src={item.imagePath} alt={item.asset} />
                  {item.asset}
                </TableCell>
                <TableCell>{item.plan}</TableCell>
                <TableCell>text={item.percents}</TableCell>
                <TableCell>{item.left}</TableCell>
                <TableCell>{item.realTimeProfit}</TableCell>
                <TableCell>{item.invested}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </table>
      </BorderBlock>
    </>
  );
};
export default UserStakingTable;
