import React from "react";
import styled from "styled-components";

const CustomLinkContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-bottom: 1rem;
  color: #ffffff;
  &:hover{
  color: white;
}
`;

const Icon = styled.img`
  margin-right: 8px;
`;

const Text = styled.span`
  margin-left: 8px;
 
`;

const RegistrBlock = () => {
    return (
        <>
            <CustomLinkContainer href="/">
                <Icon src="/assets/images/icons/add-account.svg" alt="Link Icon" />
                <Text>Регистрация</Text>
            </CustomLinkContainer>
            <CustomLinkContainer href="/">
                <Icon src="/assets/images/icons/account.svg" alt="Link Icon" />
                <Text>Верифицировать аккаунт</Text>
            </CustomLinkContainer>
            <CustomLinkContainer href="/">
                <Icon src="/assets/images/icons/credit-card.svg" alt="Link Icon" />
                <Text>Верифицировать аккаунт</Text>
            </CustomLinkContainer>
        </>
    );
};

export default RegistrBlock;
