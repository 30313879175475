import React, {useState} from "react";
import styled from "styled-components";

const ButtonsContainer = styled.div`
display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`;

const CustomButton = styled.button<{isActive: boolean}>`
  border: none;
  background-color: transparent;
  font-weight: 700;
  color: ${({isActive}) => (isActive ? "#fff" : "#848E9C")};
  font-size: ${({isActive}) => (isActive ? "32px" : "20px")};
  
  &:hover {
    color: #EFB608;
  }

`;

const ReviewDataButtons:React.FC = () => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber: number) => {
    setActiveButton(buttonNumber);

  }
  return(
    <ButtonsContainer>
      <CustomButton isActive={activeButton === 1} onClick={() => handleButtonClick(1)}>
        Обзор рынков
      </CustomButton>
      <CustomButton isActive={activeButton === 2} onClick={() => handleButtonClick(2)}>
        Торговые данные
      </CustomButton>
    </ButtonsContainer>
  );
};
export default ReviewDataButtons;