import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import AccountPageNav from "../../styles/navs/AccountPageNav";
import AccountSubNav from "../../styles/navs/AccountSubNav";
import styled from "styled-components";
import AccountPopularTable from "../../styles/tables/forAccount/AccountPopularTable";

const BorderBlock = styled.div`
  border: 1px solid #293039;
  border-radius: 20px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;
const AccountMainGrowth = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','+10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
        {image: '/assets/images/cryptoicons/ETH-2.svg', cells: ['ETH','Ethereum',  '380,1','-10.83%', ''], image5: '/assets/images/icons/bag.svg', grayText: '₽ 34 985'},
    ];

    const names = ['Популярные криптовалюты', 'Сумма', 'Стоимость монеты', 'Рост (за последние 3 года)', 'Действия'];
  return (
      <BorderBlock>
          <ContainerH>
              <HeaderALTH style={{fontSize:"20px"}}> Рынки</HeaderALTH>
              <GreyLinkArrowR to={"/"}  customStyles={{color:"#fff"}}>Подробнее</GreyLinkArrowR>
          </ContainerH>
          <AccountPageNav initialActiveButton={5}/>
          <AccountSubNav names={names}/>
          <AccountPopularTable data={tableData}/>
      </BorderBlock>
  );
};
export default AccountMainGrowth;