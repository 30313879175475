import React from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import ContainerH from "../../styles/containers/ContainerH";
import BlockH from "../../styles/blosks/BlockH";
import HeaderLogo from "../../styles/headers/HeaderLogo";
import HeaderALS from "../../styles/headers/HeaderALS";
import CustomLink from "../../styles/functional/links/CustomLink";
import CustomLinkGrey from "../../styles/functional/links/CustomLinkGrey";
import ContainerF from "../../styles/containers/ContainerF";

const Footer: React.FC = () => {
  return (
    <BasicDiv>
      <ContainerH style={{ display: "flex", alignItems: "flex-start" }}>
        <ContainerF>
          <ContainerH>
            <BlockH>
              <img
                src="/assets/images/forpages/mainpage/main_logo.svg"
                alt="Main Logo"
              />
            </BlockH>
            <BlockH>
              <HeaderLogo to="/">WAVECURRENCY</HeaderLogo>
            </BlockH>
          </ContainerH>
          <HeaderALS>
            WaveCurrency is a simple, elegant,
            <br /> and secure platform to build your
            <br /> crypto portfolio.
          </HeaderALS>
        </ContainerF>
        <ContainerF>
          <CustomLink to="/">Features</CustomLink>
          <CustomLinkGrey to="/">Home</CustomLinkGrey>
          <CustomLinkGrey to="/">Trading</CustomLinkGrey>
          <CustomLinkGrey to="/">Wallet</CustomLinkGrey>
          <CustomLinkGrey to="/">Invest</CustomLinkGrey>
          <CustomLinkGrey to="/">Instant swap</CustomLinkGrey>
          <CustomLinkGrey to="/">P2P</CustomLinkGrey>
          <CustomLinkGrey to="/">Support</CustomLinkGrey>
        </ContainerF>
        <ContainerF>
          <CustomLink to="/">Market tools</CustomLink>
          <CustomLinkGrey to="/">Crypto market cap</CustomLinkGrey>
          <CustomLinkGrey to="/">Market screener</CustomLinkGrey>
          <CustomLinkGrey to="/">Technical analysis</CustomLinkGrey>
          <CustomLinkGrey to="/">Cross rates</CustomLinkGrey>
          <CustomLinkGrey to="/">Currency heat map</CustomLinkGrey>
        </ContainerF>
        <ContainerF>
          <CustomLink to="/legal">Legal</CustomLink>
          <CustomLinkGrey to="/terms-of-service">
            Terms of service
          </CustomLinkGrey>
          <CustomLinkGrey to="/privacy-notice">Privacy notice</CustomLinkGrey>
          <CustomLinkGrey to="/coolies-policy">Cookies policy</CustomLinkGrey>
          <CustomLinkGrey to="/">AML & KYC policy</CustomLinkGrey>
          <CustomLinkGrey to="/">Fees</CustomLinkGrey>
          <CustomLinkGrey to="/">Bug Bounty</CustomLinkGrey>
        </ContainerF>
        <ContainerF>
          <CustomLink to="/">Exchange Pair</CustomLink>
          <CustomLinkGrey to="/">Terms of service</CustomLinkGrey>
          <CustomLinkGrey to="/">Privacy notice</CustomLinkGrey>
          <CustomLinkGrey to="/">Cookies policy</CustomLinkGrey>
          <CustomLinkGrey to="/">AML & KYC policy</CustomLinkGrey>
        </ContainerF>
      </ContainerH>
    </BasicDiv>
  );
};
export default Footer;
