import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderFAC from "../../styles/headers/HeaderFAC";
import HeaderACS from "../../styles/headers/HeaderACS";
import ButtonSTL from "../../styles/functional/buttons/ButtonSTL";


const OutroPart: React.FC = () => {
    const authLink = "/auth-page";
    const authText = "Регистрация";
    return (
        <div style={{backgroundColor: "#1E2329"}}>
            <ContainerV>
                <HeaderACS>Для верифицированных пользователей</HeaderACS>
                <HeaderFAC style={{marginTop: "1rem", whiteSpace: "nowrap"}}>Получите до <p style={{color:"#FCD535", display:"inline"}}>100 USDT</p> в качестве вознаграждения</HeaderFAC>
                <div style={{marginBottom: "3rem", display:"flex", alignItems:"center", alignSelf:"center" }}><ButtonSTL  href={authLink} buttonText={authText}/></div>
            </ContainerV>
        </div>
    );
};
export default OutroPart;