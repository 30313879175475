import React from 'react';
import styled from 'styled-components';
import { useGetUserProfileQuery } from '../../redux/apiService';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledLeftSection = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
`;

const StyledRightSection = styled.div`
  flex: 1;
  padding: 16px;
  border-left: 2px solid #293039;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledImage = styled.img`
  margin-right: 1rem;
`;

const StyledText = styled.p`
  color: #fff;
  margin: 0;
`;

const StyledGrayText = styled.p`
  color: #777;
  margin: 0;
  max-width: 33%;
`;

const StyledWhiteText = styled.p`
  color: #fff;
  margin: 0;
  max-width: 33%;
  align-self: flex-start;
  justify-self: center;
  flex-wrap: wrap;
  text-align: center;
`;

const StyledTable = styled.div`
  display: grid;
  flex-direction: column;
  margin-bottom: 16px;
`;

const UserIDBlock = () => {
    const { data: profile, error, isLoading } = useGetUserProfileQuery();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading profile: {error.toString()}</div>;
    }
    console.log("Profile data:", profile);

    return (
        <StyledContainer>
            <StyledLeftSection>
                <StyledImage src="/assets/images/forpages/accountpage/default-avatar.svg" alt="Icon" />
                <StyledText>{profile?.body.username}</StyledText>
            </StyledLeftSection>
            <StyledRightSection>
                <StyledTable>
                    <StyledRow>
                        <StyledGrayText>ID пользователя</StyledGrayText>
                        <StyledGrayText>VIP</StyledGrayText>
                        <StyledGrayText>Тип пользователя</StyledGrayText>
                    </StyledRow>
                    <StyledRow>
                        <StyledWhiteText>{profile?.body.id}</StyledWhiteText>
                        <StyledWhiteText style={{ marginLeft:"-4rem" }}>Обычный пользователь</StyledWhiteText>
                        <StyledWhiteText>Профиль</StyledWhiteText>
                    </StyledRow>
                </StyledTable>
                <StyledTable>
                    <StyledRow>
                        <StyledGrayText>Подписки</StyledGrayText>
                        <StyledGrayText>Подписчики</StyledGrayText>
                    </StyledRow>
                    <StyledRow>
                        <StyledWhiteText>0</StyledWhiteText>
                        <StyledWhiteText>0</StyledWhiteText>
                    </StyledRow>
                </StyledTable>
            </StyledRightSection>
        </StyledContainer>
    );
};

export default UserIDBlock;
