import React from "react";
import styled from "styled-components";
import DeleteButton from "../functional/buttons/DeleteButton";
import ContainerH from "../containers/ContainerH";
import ContainerV from "../containers/ContainerV";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
  max-width: 9rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 2fr repeat(4, 1fr);
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const P2PTable: React.FC<TableProps> = ({ data }) => {
  return (
    <BorderBlock>
      <table>
        <TableRow style={{ borderBottom: "none" }}>
          <TableHeader>Trader</TableHeader>
          <TableHeader>Payment method</TableHeader>
          <TableHeader>Price</TableHeader>
          <TableHeader>Limits</TableHeader>
          <TableHeader>Action</TableHeader>
        </TableRow>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <ContainerH style={{ minWidth: "100%", gap: "1rem" }}>
                  <img src={item.imagePath} alt={item.username} />
                  <ContainerV style={{ minWidth: "100%" }}>
                    <div>{item.username}</div>
                    <div>{item.orders}</div>
                  </ContainerV>
                </ContainerH>
              </TableCell>
              <TableCell>{item.paymentMethod}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{item.limits}</TableCell>
              <TableCell>
                <DeleteButton />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </BorderBlock>
  );
};
export default P2PTable;
