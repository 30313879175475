import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import ContainerH from "../../styles/containers/ContainerH";
import styled from "styled-components";
import { HeadBlockWithBottom } from "./PrivacyNotice";

const LegalGrayText = styled.div`
  color: #848e9c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;

const LegalWhiteText = styled.div`
  color: #efeee9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;
const LegalWhiteTextLetter = styled.text`
  color: #efeee9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;

const CookiesPolicy: React.FC = () => {
  return (
    <>
      <ContainerV
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding: "0 10rem 0 10rem",
        }}
      >
        <ContainerH style={{ margin: "0" }}>
          <HeadBlockWithBottom>
            Cookies Policy
            <br />
            <LegalGrayText>
              This Cookies Policy explains what Cookies are and how We use them.
            </LegalGrayText>
          </HeadBlockWithBottom>
        </ContainerH>
        <LegalGrayText>
          This Cookies Policy explains what Cookies are and how We use them. You
          should read this policy so You can understand what type of cookies We
          use, or the information We collect using Cookies and how that
          information is used.
          <br /> <br /> Cookies do not typically contain any information that
          personally identifies a user, but personal information that we store
          about You may be linked to the information stored in and obtained from
          Cookies. For further information on how We use, store and keep your
          personal data secure, see our Privacy Policy. <br /> <br />
          We do not store sensitive personal information, such as mailing
          addresses, account passwords, etc. in the Cookies We use.
        </LegalGrayText>
        <LegalWhiteText>Interpretation and Definitions</LegalWhiteText>
        <LegalGrayText>
          Interpretation <br /> <br />
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </LegalGrayText>
        <LegalWhiteText> Definitions </LegalWhiteText>
        <LegalGrayText>
          For the purposes of this Cookies Policy:
          <br />
          <ol>
            <li>
              <LegalWhiteTextLetter>Company</LegalWhiteTextLetter> (referred to
              as either "the Company", "We", "Us" or "Our" in this Cookies
              Policy) refers to SWEBLINK.
            </li>
            <li>
              <LegalWhiteTextLetter>Cookies</LegalWhiteTextLetter> means small
              files that are placed on Your computer, mobile device or any other
              device by a website, containing details of your browsing history
              on that website among its many uses.
            </li>
            <li>
              <LegalWhiteTextLetter>Website</LegalWhiteTextLetter> refers to
              SWEBLINK, accessible from www.sweblink.com
            </li>
            <li>
              <LegalWhiteTextLetter>You</LegalWhiteTextLetter> means the
              individual accessing or using the Website, or a company, or any
              legal entity on behalf of which such individual is accessing or
              using the Website, as applicable.
            </li>
          </ol>
        </LegalGrayText>
        <LegalWhiteText>The use of the Cookies</LegalWhiteText>
        <LegalGrayText>
          Type of Cookies
          <br />
          <br />
          We Use Cookies can be "Persistent" or "Session" Cookies. Persistent
          Cookies remain on your personal computer or mobile device when You go
          offline, while Session Cookies are deleted as soon as You close your
          web browser. <br />
          <br />
          We use both session and persistent Cookies for the purposes set out
          below:
        </LegalGrayText>
        <LegalWhiteText>1. Necessary / Essential Cookies</LegalWhiteText>
        <LegalGrayText>
          Type: Session Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
          <br />
        </LegalGrayText>
        <LegalWhiteText> Functionality Cookies</LegalWhiteText>
        <LegalGrayText>
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </LegalGrayText>
        <LegalWhiteText>Your Choices Regarding Cookies</LegalWhiteText>
        <LegalGrayText>
          If You prefer to avoid the use of Cookies on the Website, first You
          must disable the use of Cookies in your browser and then delete the
          Cookies saved in your browser associated with this website. You may
          use this option for preventing the use of Cookies at any time. <br />
          <br />
          If You do not accept Our Cookies, You may experience some
          inconvenience in your use of the Website and some features may not
          function properly. <br />
          <br />
          If You'd like to delete Cookies or instruct your web browser to delete
          or refuse Cookies, please visit the help pages of your web browser.
          <br />
          <br />
          <ol>
            <li>
              For the Chrome web browser, please visit this page from Google:
              https://support.google.com/accounts/answer/32050
            </li>
            <br />
            <li>
              For the Internet Explorer web browser, please visit this page from
              Microsoft: http://support.microsoft.com/kb/278835
            </li>
            <br />
            <li>
              For the Firefox web browser, please visit this page from Mozilla:
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </li>
            <br />
            <li>
              For the Safari web browser, please visit this page from Apple:
              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </li>
          </ol>
          For any other web browser, please visit your web browser's official
          web pages.
        </LegalGrayText>
      </ContainerV>
    </>
  );
};
export default CookiesPolicy;
