import React from 'react';
import styled from 'styled-components';

const AccountStatTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  width: 100%; 
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: auto auto repeat(4, 1fr);
  gap: 2rem 0;
  padding: 10px;
  min-width: 100%;
  align-items: center; 

  &:hover {
    background-color: #1E2329;
  }
`;

interface TableCellProps {
    isImage?: boolean;
    isSecondFromStart?: boolean;
    isFifth?: boolean;
}

const TableCell = styled.div<TableCellProps>`
  display: flex;
  justify-content: ${({ isFifth }) => (isFifth ? 'flex-end' : 'flex-start')}; /*  flex-end для правого выравнивания */
  align-items: center;
  padding: 8px;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;

  ${({ isImage }) =>
          isImage &&
          `
    grid-column: 1;
  `}

  ${({ isSecondFromStart }) =>
          isSecondFromStart &&
          `
    color: #848E9C;
    font-weight: 500;
    margin-right: 7rem;
    padding-left: 0;
  `}
  
  

  ${({ isFifth }) =>
          isFifth &&
          `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.red-text {
      color: #F6465D;
    }

    &.green-text {
      color: #10BA7B;
    }

    .arrow-icon {
      width: 20px;
      margin-left: 5px;
    }
  `}
`;

const Image = styled.img`
  width: 30px;
  margin-right: 0.5rem;
`;

const ImageSmall = styled.img`
    margin-left: 7rem;
`;

const ArrowIcon = styled.img`
  width: 20px;
  margin-left: 5px;
`;

interface AccountStatTableProps {
    data: {
        image: string;
        cells: string[];
        image6: string;
    }[];
}

const AccountStatTable: React.FC<AccountStatTableProps> = ({ data }) => {
    return (
        <AccountStatTableContainer>
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => (
                        <TableCell
                            key={cellIndex}
                            isImage={cellIndex === 0}
                            isSecondFromStart={cellIndex === 1}
                            isFifth={cellIndex === 4}
                            className={cellIndex === 4 ? (cell.includes('-') ? 'red-text' : 'green-text') : ''}
                        >
                            {cellIndex === 0 && <Image src={row.image} alt="Image" />}
                            {cellIndex === 5 && <ImageSmall src={cellIndex === 5 ? row.image6 : ''} alt={`Image ${cellIndex}`} />}
                            {cellIndex === 4 && (
                                <>
                                    {cell}
                                    <ArrowIcon
                                        className="arrow-icon"
                                        src={cell.includes('-') ? '/assets/images/icons/fall.svg' : '/assets/images/icons/growth.svg'}
                                        alt="Arrow"
                                    />
                                </>
                            )}
                            {cellIndex !== 4 && cell}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </AccountStatTableContainer>
    );
};

export default AccountStatTable;
