import React from "react";
import SaleIntro from "../components/forsalepage/SaleIntro";
import Markets from "../components/forsalepage/Markets";
import PopularConvertation from "../components/forsalepage/PopularConvertation";
import TradeInfTables from "../components/forsalepage/TradeInfTables";

const SalePage: React.FC = () => {

    return<>
        <SaleIntro/>
        <Markets/>
        <TradeInfTables/>
        <PopularConvertation/>
    </>;
};
export default SalePage;