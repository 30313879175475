import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerDomainsTable from "../../styles/tables/forWorker/WorkerDomainsTable";

const WorkerDomains:React.FC = () => {
    const testData = [
        {
            id: 38,
            domain: "sweblink.com",
            name: "SWEBLINK",
            title: "SwebLink.com | Cryptocurrency trading and invest platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/02/23 21:18:50",
            users: 20,
            deposits: "11 / 1464.17$"
        },
        {
            id: 39,
            domain: "example2.com",
            name: "EXAMPLE2",
            title: "Example2.com | Another cryptocurrency platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/03/01 12:34:56",
            users: 15,
            deposits: "22 / 2345.67$"
        },
        {
            id: 40,
            domain: "anotherlink.com",
            name: "ANOTHERLINK",
            title: "AnotherLink.com | Yet another crypto platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/04/05 09:00:00",
            users: 25,
            deposits: "33 / 3456.89$"
        },
        {
            id: 41,
            domain: "yetanother.com",
            name: "YETANOTHER",
            title: "YetAnother.com | A different crypto platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/05/10 18:45:30",
            users: 30,
            deposits: "44 / 4567.12$"
        },
        {
            id: 42,
            domain: "finalone.com",
            name: "FINALONE",
            title: "FinalOne.com | Last crypto platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/06/15 13:20:15",
            users: 35,
            deposits: "55 / 5678.34$"
        },
        {
            id: 43,
            domain: "firstone.com",
            name: "FIRSTONE",
            title: "FirstOne.com | First crypto platform",
            iconPath: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            icon: "/assets/images/forpages/adminpanel/User_domain_image.svg",
            added: "2024/07/20 08:05:45",
            users: 40,
            deposits: "66 / 6789.56$"
        }
    ];


    return  (
      <ContainerV style={{ alignSelf: "flex-start", justifyContent: "flex-start", padding:"0 5rem 0 5rem"}}>
          <HeadBlock>Domains</HeadBlock>
          <WorkerDomainsTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerDomains;