import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-top: -1rem;
`;

const ButtonProfile: React.FC = () => {
  return (
    <Link to="/account">
      <CustomButton>
        <img
          src="/assets/images/forpages/adminpanel/default-avatar 1.svg"
          alt="Profile"
        />
      </CustomButton>
    </Link>
  );
};
export default ButtonProfile;
