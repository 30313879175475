import React from "react";
import styled from "styled-components";

interface CustomModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ModalContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalTitle = styled.h5`
  margin: 0;
`;

const ModalBody = styled.div`
  margin-bottom: 15px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled.button`
  margin-left: 10px;
`;


const Login: React.FC<CustomModalProps> = ({ isOpen, onClose }) => {
    return (
        <ModalContainer style={{ display: isOpen ? "flex" : "none" }}>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Modal title</ModalTitle>
                    <button type="button" onClick={onClose}>
                        Close
                    </button>
                </ModalHeader>
                <ModalBody>
                    <p>Modal body text goes here.</p>
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={onClose}>Close</ModalButton>
                    <ModalButton>Save changes</ModalButton>
                </ModalFooter>
            </ModalContent>
        </ModalContainer>
    );
};

export default Login;
