import React from "react";
import styled from "styled-components";
import DeleteButton from "../../functional/buttons/DeleteButton";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    min-width: 100%;
    max-width: 100%;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 0.25rem;
  color: white;
  padding: 0.5rem;
  //margin-bottom: 0.25rem;
  min-width: 100%;
  justify-self: flex-start;
  resize: none; // Запрещаем изменение размера
  overflow: hidden; // Скрываем переполненный текст
  text-overflow: ellipsis; // Обрезаем текст до трех точек
  white-space: nowrap; // Предотвращаем перенос слов
  
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  //max-width: 9rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto 4fr 6fr 1fr; 
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  margin: 0.25rem 0 0.25rem 0;
  //max-width: 20rem;
`;

interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const WorkerSupportMessagePresetsTable:React.FC<TableProps> = ({ data }) => {

    return (
        <BorderBlock>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Message</TableHeader>
                    <TableHeader>Action</TableHeader>
                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                            <CustomInput type="message" placeholder={item.title} />
                        </TableCell>
                        <TableCell>
                            <CustomInput type="message" placeholder={item.message} />
                        </TableCell>
                        <TableCell>
                            <DeleteButton/>
                        </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default WorkerSupportMessagePresetsTable;