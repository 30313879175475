import styled from "styled-components";
import { Link } from "react-router-dom";

const AuthSmollLink = styled(Link)`
  color: #EFB608;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 0.5rem;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;

export default AuthSmollLink;
