import React from "react";
import styled from "styled-components";
import NLAccountAccordion from "../../styles/accordions/NLAccountAccordion";
import {
  domainsApi,
  profilesApi,
  supportApi,
  useGetUserInfoQuery,
} from "../../redux/apiService";
import { useDispatch } from "react-redux";
import { clearTokens } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const NavLeftAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: transparent;
  color: #ffffff;
  margin-top: 5rem;
  font-weight: 500;
  font-size: 16px;
  margin-left: -1.7rem;
`;

const NavLeftAccount = () => {
  const atives_img = "/assets/images/forpages/accountpage/actives.svg";
  const bonus_img = "/assets/images/forpages/accountpage/bonus.svg";
  const orders_img = "/assets/images/forpages/accountpage/orders.svg";
  const referal_img = "/assets/images/forpages/accountpage/referal.svg";
  const user_img = "/assets/images/forpages/accountpage/user.svg";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: info, error, isLoading, refetch } = useGetUserInfoQuery();

  const buttonsWithLinks = [
    { text: "Обзор кошелька", link: "/assets/wallet-overview" },
    { text: "Спотовый", link: "/assets/spot-overview" },
    { text: "Маржиальный аккаунт", link: "https://example.com/payment" },
    { text: "Фьючерский аккаунт", link: "/account/futures-accounts" },
    { text: "Опционы", link: "/account/options" },
    { text: "Торговые боты", link: "https://example.com/payment" },
    { text: "Earn", link: "https://example.com/payment" },
    { text: "Пополнения", link: "/account/deposits" },
  ];
  if (info?.body.is_superuser === "True") {
    buttonsWithLinks.push(
      { text: "Панель администратора", link: "/admin" },
      { text: "Панель воркера", link: "/worker" },
    );
  }
  console.log("Внутренние данные, ", info);

  const handleLogout = () => {
    dispatch(clearTokens());
    dispatch(profilesApi.util.resetApiState()); // Очистка кэша запросов
    dispatch(domainsApi.util.resetApiState());
    dispatch(supportApi.util.resetApiState());
    console.log("Logging out");
    navigate("/auth-page/login");
    refetch();
  };
  return (
    <NavLeftAccountContainer>
      <a
        href="/account"
        style={{
          backgroundColor: "#293039",
          textDecoration: "none",
          color: "#fff",
          padding: "1rem",
          border: "1px solid #293039",
          borderRadius: "0 12px 12px 0",
          minWidth: "100%",
        }}
      >
        <img
          src="/assets/images/forpages/accountpage/house.svg"
          alt="house"
          style={{ marginRight: "0.5rem" }}
        />{" "}
        Панель инструментов
      </a>
      <NLAccountAccordion
        title="Активы"
        image_path={atives_img}
        buttonsWithLinks={buttonsWithLinks}
      />
      <NLAccountAccordion
        title="Ордера"
        image_path={bonus_img}
        buttonsWithLinks={buttonsWithLinks}
      />
      <NLAccountAccordion
        title="Бонусный центр"
        image_path={orders_img}
        buttonsWithLinks={buttonsWithLinks}
      />
      <NLAccountAccordion
        title="Реферальная программа"
        image_path={referal_img}
        buttonsWithLinks={buttonsWithLinks}
      />
      <NLAccountAccordion
        title="Аккаунт"
        image_path={user_img}
        buttonsWithLinks={buttonsWithLinks}
      />
      <a
        href="/"
        style={{
          textDecoration: "none",
          color: "#848E9C",
          padding: "1rem",
        }}
      >
        <img
          src="/assets/images/forpages/accountpage/subaccounts.svg"
          alt="house"
          style={{ marginRight: "0.5rem" }}
        />
        Субаккаунты
      </a>
      <a
        href="/account-settings"
        style={{
          textDecoration: "none",
          color: "#848E9C",
          padding: "1rem",
        }}
      >
        <img
          src="/assets/images/forpages/accountpage/options.svg"
          alt="house"
          style={{ marginRight: "0.5rem" }}
        />
        Настройки
      </a>
      <button
        onClick={handleLogout}
        style={{
          textDecoration: "none",
          color: "#fff",
          padding: "1rem",
          backgroundColor: "#d9534f",
          border: "none",
          borderRadius: "0 12px 12px 0",
          cursor: "pointer",
          marginTop: "1rem",
          minWidth: "100%",
          textAlign: "left",
        }}
      >
        <img
          src="/assets/images/forpages/accountpage/house.svg"
          alt="logout"
          style={{ marginRight: "0.5rem" }}
        />
        Logout
      </button>
    </NavLeftAccountContainer>
  );
};
export default NavLeftAccount;
