import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerWalletConnectTable from "../../styles/tables/forWorker/WorkerWalletConnectTable";

const WorkerWalletConnect:React.FC = () => {
    const testData = [
        {
            id: "38",
            status: "Completed",
            user: "toomaxprop2p@gmail.com",
            worker: "worker1",
            wallet: "TQ31V8NvvhL7cX5sjQh4P5jQepuriK8ipk",
            seedPhrase: "4423.22567238",
            date: "2024/03/22 14:39:44"
        },
        {
            id: "39",
            status: "Pending",
            user: "anotheruser@example.com",
            worker: "worker2",
            wallet: "wallet2",
            seedPhrase: "seedPhrase2",
            date: "2024/03/21 15:30:00"
        },
        {
            id: "40",
            status: "Failed",
            user: "thirduser@domain.com",
            worker: "worker3",
            wallet: "wallet3",
            seedPhrase: "seedPhrase3",
            date: "2024/03/20 16:45:23"
        },
        {
            id: "41",
            status: "Completed",
            user: "fourthuser@domain.net",
            worker: "worker4",
            wallet: "wallet4",
            seedPhrase: "seedPhrase4",
            date: "2024/03/19 17:30:45"
        },
        {
            id: "42",
            status: "Pending",
            user: "fifthuser@domain.org",
            worker: "worker5",
            wallet: "wallet5",
            seedPhrase: "seedPhrase5",
            date: "2024/03/18 18:15:12"
        },
        {
            id: "43",
            status: "Failed",
            user: "sixthuser@domain.com",
            worker: "worker6",
            wallet: "wallet6",
            seedPhrase: "seedPhrase6",
            date: "2024/03/17 19:00:30"
        },
        {
            id: "44",
            status: "Completed",
            user: "seventhuser@domain.net",
            worker: "worker7",
            wallet: "wallet7",
            seedPhrase: "seedPhrase7",
            date: "2024/03/16 20:45:45"
        },
        {
            id: "45",
            status: "Pending",
            user: "eighthuser@domain.org",
            worker: "worker8",
            wallet: "wallet8",
            seedPhrase: "seedPhrase8",
            date: "2024/03/15 21:30:00"
        },
        {
            id: "46",
            status: "Failed",
            user: "ninthuser@domain.com",
            worker: "worker9",
            wallet: "wallet9",
            seedPhrase: "seedPhrase9",
            date: "2024/03/14 22:15:15"
        }
    ];



    return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Wallet connect</HeadBlock>
          <WorkerWalletConnectTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerWalletConnect;