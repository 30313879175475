import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import AccountPageNav from "../../styles/navs/AccountPageNav";
import AccountSubNav from "../../styles/navs/AccountSubNav";
import AccountStatTable from "../../styles/tables/forAccount/AccountStatTable";
import styled from "styled-components";

const BorderBlock = styled.div`
  border: 1px solid #293039;
  border-radius: 20px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;
const AccountMainActives = () => {
    const tableData = [
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','+10.83%', ''], image6: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','-10.83%', ''], image6: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','+10.83%', ''], image6: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','-10.83%', ''], image6: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','+10.83%', ''], image6: '/assets/images/icons/bag.svg'},
        {image: '/assets/images/cryptoicons/Bitcoin Coin.svg', cells: ['BTC','Bitcoin', '$42521.18', '$42521.18','-10.83%', ''], image6: '/assets/images/icons/bag.svg'},
    ];

    const names = ['Популярные криптовалюты', 'Сумма', 'Стоимость монеты', 'Рост (за последние 3 года)', 'Действия'];
  return (
      <BorderBlock>
          <ContainerH>
              <HeaderALTH style={{fontSize:"20px"}}> Рынки</HeaderALTH>
              <GreyLinkArrowR to={"/"}  customStyles={{color:"#fff"}}>Подробнее</GreyLinkArrowR>
          </ContainerH>
          <AccountPageNav initialActiveButton={1}/>
          <AccountSubNav names={names}/>
          <AccountStatTable data={tableData}/>
      </BorderBlock>
  );
};
export default AccountMainActives;