import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { profilesApi, supportApi } from "./apiService";
import { domainsApi } from "./apiService";
import { setAccessToken, setRefreshToken } from "./slices/authSlice";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profilesApi.middleware,
      domainsApi.middleware,
      supportApi.middleware,
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");
//const selectedUserId = localStorage.getItem("selectedUserId");
if (accessToken) {
  store.dispatch(setAccessToken(accessToken));
}
if (refreshToken) {
  store.dispatch(setRefreshToken(refreshToken));
}
export default store;
