import React from "react";
import UserProfileNav from "../../../../styles/navs/UserProfileNav";
import { Outlet } from "react-router-dom";

const UserBalance = () => {
  return (
    <>
      <UserProfileNav initialActiveButton={6} />
      <Outlet />
    </>
  );
};
export default UserBalance;
