// Для аккордеонов на странице "ВСЕ КРИПТОВАЛЮТЫ"
export const AI_ACCORDION_TITLE =
  "Самые популярные ИИ-токены по рыночной капитализации";
export const AI_ACCORDION_TEXT =
  "Ниже представлены лучшие криптовалютные токены ИИ. Токены ИИ — это категория цифровых активов, специально разработанных для облегчения развития и поддержки проектов, приложений и услуг на основе ИИ. Эти криптовалюты предоставляют различные сервисы, связанные с ИИ, включая, помимо прочего, децентрализованные маркетплейсы ИИ, торговые алгоритмы на основе ИИ и децентрализованные автономные организации, управляемые ИИ.";
export const ALL_CRYPTOS_ACCORDION_TITLE =
  "Самые популярные токены по рыночной капитализации";
export const ALL_CRYPTOS_ACCORDION_TEXT =
  "Получите исчерпывающую информацию обо всех криптовалютах, доступных на платформе Binance. На этой странице показаны текущие цены, объем торгов за 24 часа, изменения цен и рыночная капитализация для всех криптовалют, доступных на платформе Binance. Здесь пользователи могут быстро получить доступ к самой важной информации об этих цифровых активах и торговой странице.";
export const LEVELS_CRYPTOS_ACCORDION_TITLE =
  "Самые популярные токены первого/второго уровня по рыночной капитализации";
export const LEVELS_CRYPTOS_ACCORDION_TEXT =
  "Ниже представлены лучшие криптовалютные токены Уровня 1 и Уровня 2. Криптопроекты первого уровня относятся к первичным блокчейн-сетям, в которых размещаются децентрализованные приложения. Криптопроекты второго уровня относятся к масштабируемым решениям, которые работают поверх существующей основной сети.";
export const META_CRYPTOS_ACCORDION_TITLE =
  "Самые популярные токены метавселенной по рыночной капитализации";
export const META_CRYPTOS_ACCORDION_TEXT =
  "Ниже представлены самые популярные токены в Метавселенной. Метавселенная — это виртуальная вселенная, открытая для всех, созданная для отражения реального мира. В ней пользователи могут работать, делать покупки и играть вместе в 3D пространстве.";
export const MONITOR_CRYPTOS_ACCORDION_TITLE =
  "Токены «Мониторинг» по рыночной капитализации";
export const MONITOR_CRYPTOS_ACCORDION_TEXT =
  "Токены, помеченные как «Мониторинг», являются токенами, для которых характерны более высокая волатильность и риски по сравнению с другими размещенными токенами. Эти токены строго контролируются, и в отношении них проводятся периодические проверки. Обратите внимание, что эти токены могут быть удалены с нашей платформы, если перестанут соответствовать нашим критериям. Не забывайте всегда проводить собственное исследование. Список обновляется на 1-й неделе каждого квартала (январь, апрель, июль, октябрь).";
export const SEED_CRYPTOS_ACCORDION_TITLE =
  "Seed-токены по рыночной капитализации";
export const SEED_CRYPTOS_ACCORDION_TEXT =
  "Токены, помеченные как «Seed», относятся к инновационным проектам, которые могут иметь более высокую волатильность и риски по сравнению с другими размещенными токенами. Не забывайте всегда проводить собственное исследование.";
export const MEME_CRYPTOS_ACCORDION_TITLE =
  "Самые популярные мем-токены по рыночной капитализации";
export const MEME_CRYPTOS_ACCORDION_TEXT =
  "Ниже представлены самые популярные мем-токены. Мем-токены, как правило, не имеют практической пользы, и их ценность во многом зависит от продвижения в социальных сетях, юмора и созданных пользователями онлайн-сообществ. Поскольку социальные настроения меняются изо дня в день, эти токены могут быть очень волатильными.";
export const LIQUID_CRYPTOS_ACCORDION_TITLE =
  "Самые популярные токены для стейкинга ликвидности по рыночной капитализации";
export const LIQUID_CRYPTOS_ACCORDION_TEXT =
  "Стейкинг ликвидности — это способ стейкинга криптовалюты, позволяющий пользователям использовать размещенные в стейкинге токены, получая при этом вознаграждения. Это достигается за счет использования протоколов ликвидного стейкинга, которые предоставляют пользователям деривативы ликвидного стейкинга, подтверждающие их право собственности на пул стейкинга и доходность.";
export const OPTION_TEXT =
  "Опцион Binance — это тип европейского опционного контракта, который, по сравнению с американскими опционами, может быть исполнен только в дату окончания срока его действия, но может быть куплен или продан по текущей рыночной цене в любое время до окончания срока действия.\n" +
  "Торговля опционами — это очень рискованный вид торговли, который может принести как большую прибыль, так и значительные убытки.\n" +
  "Максимально возможный убыток покупателя опциона равен стоимости премии, заплаченной за покупку опциона. Однако убыток продавца может быть равен всей сумме маржи, внесенной в качестве залога для обеспечения позиции, а если маржа окажется недостаточной, продавцу грозит ликвидация.\n" +
  "Торговать опционами могут только пользователи из определенных регионов. Не все пользователи могут выступать в качестве продавцов опционов.\n" +
  "Каждый, кто торгует опционами на Binance, обязуется соблюдать Соглашение Binance о торговле опционами.";
export const PRIVACY =
  "Я прочитал(а) и принимаю <u>Соглашение Wavecurrency о торговле опционами</u>.";
export const FURURES_TEXT =
  "Торговля фьючерсами сопряжена с крайне высоким риском. Этот инструмент может принести как большую прибыль, так и значительные убытки. В случае экстремального движения цены, существует вероятность полной ликвидации маржинального баланса на вашем фьючерсном кошельке. Для пользователей из определенных регионов торговля фьючерсами недоступна.";
export const TERMS =
  "Updated: 05-19-2023\n" +
  "\n" +
  'This User Agreement for the service "{domain_name}" (hereinafter the "Agreement") is concluded between the owner of the software package "{domain_name}" (hereinafter "CRYPTO ARBY TRADE LIMITED") and a person who successfully registered on the service "{domain_name}" (hereinafter "Service") and started using the Service ( hereinafter referred to as "User").\n' +
  "\n" +
  "The User is obliged to study the text of this Agreement in detail. Acceptance of this Agreement by successfully registering on the Service and starting to use it means full and unconditional acceptance the Agreement terms and other information, links to which are provided in the Agreement.\n" +
  "\n" +
  "In case of disagreement with this terms, the User is obliged to immediately leave the Service.\n" +
  "\n" +
  "The User is granted any proprietary intellectual rights in relation to the Service and its software under no circumstances.\n" +
  "\n" +
  " \n" +
  "\n" +
  "Definitions and term interpretations.\n" +
  "\n" +
  " \n" +
  "\n" +
  "The terms given below are used in this document with the following meanings:\n" +
  "\n" +
  "«User» - the person who has reached the age of majority, the visitor of the Service, who acquaints himself with the types of Services presented by CRYPTO ARBY TRADE LIMITED, uses the reference and information resources of the Service, is going to make the Order of Services, or already implements the Order in the real-time mode, or who is already using Services purchased from CRYPTO ARBY TRADE LIMITED.\n" +
  "\n" +
  "«Service» is a software complex «{domain_name}», located at https://{domain_url} and owned by CRYPTO ARBY TRADE LIMITED, containing all technical and functional features for use by the User following the terms and conditions described in the Agreement. The service is a simulator of an online crypto exchange (analogous to a crypto exchange), where the User has the opportunity to learn trading making crypto-coins exchange transactions.\n" +
  "\n" +
  '"Services" - services provided by CRYPTO ARBY TRADE LIMITED to Users, including the provision for using of Service functionality for a certain period under the Subscriptions selected by the User, Agreement terms.\n' +
  "\n" +
  "«Subscription» - the totality of functions provided to the User during the paid period of the Service using. Details of the current subscriptions are listed on the Service page https://{domain_url}\n" +
  "\n" +
  '"Agreement" - a service agreement concluded between CRYPTO ARBY TRADE LIMITED and the User by registering the User on the Service and creating a PA.\n' +
  "\n" +
  '"Order" - an application of the User for the Services receipt, issued through the Service and representing the free and independent intention and will of the User to purchase the Service selected by the User from CRYPTO ARBY TRADE LIMITED under the Subscription.\n' +
  "\n" +
  '"Personal Account" ("PA") is a personalized Service section, closed for public access. Access to the personal account is carried out by entering authentication data on the Service login page: login and password.\n' +
  "\n" +
  "Terms that are not defined in Agreement clause 1.1 may be used in the Agreement. In these cases, the interpretation of the terms is made under the text and meaning of the Agreement. In the absence of an unambiguous term interpretation in the text of the Agreement, you should be guided, firstly, by the interpretation of the terms used on the Service, including in the legal documentation posted on the Service; secondly, by international law and business customs in the relevant field of activity.\n" +
  "\n" +
  " \n" +
  "\n" +
  "Subject of the Agreement.\n" +
  "\n" +
  " \n" +
  "\n" +
  "Under this Agreement, CRYPTO ARBY TRADE LIMITED, within the framework of the Subscription chosen by the User, grants the User the right to access and use the Service in the manner described in the Agreement. By accepting the terms of this Agreement and creating a Personal Account on the Service, the User agrees to follow and comply with all the rules and requirements for using the Service specified in the Agreement.\n" +
  "\n" +
  "Searching, ordering a service, using the Service, including viewing those posted in the sections of the Service, providing reference and information resources of the Service, also implies full consent of the User with this Agreement.";

export const URL_AND_NAME_EXPLANATION =
  "{domain_url} - заменяется на домен сайта, на котором находится пользователь. {domain_name} - заменяется на название биржи, указанное в настройках домена.";
