import React from "react";
import AssetsWalletOverviewWalletsTable from "../../styles/tables/forAccount/AssetsWalletOverviewWalletsTable";

const WalletWalletsBlock = () => {
  const tableData = [
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
    {
      iconPath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      icon: "BTC",
      coinType: "Bitcoin",
      sumDollar: "380,1",
      sumRubels: "₽ 34 985",
      coinCost: "₽ 92,16",
      action: "Покупка за фиат",
    },
  ];
  return (
    <>
      <AssetsWalletOverviewWalletsTable data={tableData} />
    </>
  );
};
export default WalletWalletsBlock;
