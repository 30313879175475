import React from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";


const BorderBlock  = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
    min-width: 100%;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(6,1fr); 
  gap: 1rem;
  align-items: center;
  //width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  font-size: 14px;
  //min-width: 10rem;
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  //max-width: 9rem;
  font-size: 16px;
`;

interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}

const TopLongBlock:React.FC<TableProps> = ({data}) => {
  return (
      <BorderBlock>
          <ContainerH style={{flexWrap:"nowrap"}}>
              <ContainerH style={{
                  fontSize:"20px",
                  fontWeight:"700",
                  whiteSpace:"nowrap",
                  flexWrap:"nowrap",
                  alignItems:"center",
                  paddingRight:"1rem"
              }}>
                <img src="/assets/images/cryptoicons/Bitcoin Coin.svg" alt="Bitcoin" style={{paddingRight:"1rem"}}/>
                  <p style={{color:"white", margin:"0"}}>BTC</p>
                  <p style={{color:"#848E9C", margin:"0"}}>/USDT</p>
              </ContainerH>
          </ContainerH>
          <ContainerH>
            <table>
                <TableRow>
                    <TableHeader>
                        68000,1
                    </TableHeader>
                    <TableHeader>
                        24h Change
                    </TableHeader>
                    <TableHeader>
                        24h High
                    </TableHeader>
                    <TableHeader>
                        24h Low
                    </TableHeader>
                    <TableHeader>
                        24h Volume (BTC)
                    </TableHeader>
                    <TableHeader>
                        24h Amount (BTC)
                    </TableHeader>
                </TableRow>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.sum}
                        </TableCell>
                        <TableCell>
                            {item.percents}
                        </TableCell>
                        <TableCell>
                            {item.high}
                        </TableCell>
                        <TableCell>
                            {item.low}
                        </TableCell>
                        <TableCell>
                            {item.volume}
                        </TableCell>
                        <TableCell>
                            {item.amount}
                        </TableCell>
                    </TableRow>
                ))}
            </table>
          </ContainerH>
      </BorderBlock>
  );

};
export default TopLongBlock;