import React from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import WorkerFastPumpDumpTable from "../../../styles/tables/forWorker/WorkerFastPumpDumpTable";
import PumpDumpPageNav from "../../../styles/navs/PumpDumpPageNav";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;


const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;

  &:hover {
    background-color: #FFFF66;
  }
`;

export const GreyButton = styled.button`
  background-color: #2F323C;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10rem;

  &:hover {
    background-color: #3c404c;
  }
`;

const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;



const RedText = styled.div`
  color:#F6465D;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;


const FastPumpDump:React.FC = () => {

    const testData = [
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
        {
            id: 38,
            coin: "BTC",
            realPrice: "62585.99$",
            pumpedPrice: "62585.99$",
            realChange: "-2.365%",
            pumpedChange: "-2.365%"
        },
    ];


  return <>
          <PumpDumpPageNav initialActiveButton={1}/>
          <ContainerH
              style={{
                  margin:"0",
                  gap:"4%",
                  flexWrap:"nowrap",
                  width:"100%",
          }}>
              <ContainerV style={{
                  width:"48%",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start",
                  alignItems:"flex-start",
                  justifyItems:"flex-start"

              }}>

                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Minimum amount</TitleLittle>
                      <ContainerH style={{margin:"0", width:"100%"}}>
                          <CustomInput type="number" placeholder="250"/>
                          <GreyButton>USD</GreyButton>
                      </ContainerH>
                  </BorderBlock>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>COIN</TitleLittle>
                      <ContainerH style={{margin:"0", width:"100%"}}>
                          <CustomInput type="text" placeholder="Пожалуйста, выберите криптовалюту"/>
                          <GreyButton>
                              <img src="/assets/images/forpages/adminpanel/dropdownVector.svg" alt="Vector"/>
                          </GreyButton>
                      </ContainerH>
                  </BorderBlock>
                  <SubmitButton>Add Fast PUMP/DUMP</SubmitButton>
                  <GrayText>
                      Значение заполнять строго по такому формату:<br/>
                      -2.47 (DUMP / красный)<br/>
                      или 2.47 (PUMP / зеленый)<br/>
                      <br/>
                      Учтите, что, цена будет меняться на указанный<br/>
                      процент который будет прибавляться<br/>
                      к текущему значению<br/>
                  </GrayText>

                  <RedText>
                      ВАЖНО! Цена и Процент на самой бирже<br/>
                      будут изменяться по факту достижения свечей<br/>
                      Здесь же вы видите уже финальные значения
                  </RedText>
              </ContainerV>

          <WorkerFastPumpDumpTable data={testData} style={{maxWidth:"48%"}}/>
          </ContainerH>
  </>;
};
export default FastPumpDump;