import React from "react";
import UserBalanceViewTable from "../../../../styles/tables/forAdmin/forAdminUsers/UserBalanceViewTable";

const UserBalanceViewBlock = () => {
  const tableData = [
    {
      id: 95,
      coin: "BTC",
      imagePath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      balance: "1000",

    },

  ];
  return (
    <>
      <UserBalanceViewTable data={tableData} />
    </>
  );
};
export default UserBalanceViewBlock;
