import styled from "styled-components";

const CustomInput2 = styled.input`
  font-size: 16px;
  border: 1px solid #2f323c;
  border-radius: 20px;
  outline: none;
  color: #ffffff;
  background-color: #191b21;
  width: 50%;
  align-self: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  //&:focus {
  //  border-color: #3498db;
  //  box-shadow: 0 0 5px rgba(52, 152, 219, 0.7);
  //}
`;

export default CustomInput2;
