import React from "react";
import HeaderGreyCenter from "../../styles/headers/HeaderGreyCenter";
import ContainerV from "../../styles/containers/ContainerV";
import ButtonST from "../../styles/functional/buttons/ButtonST";

const NoData: React.FC = () => {
  return(
      <ContainerV style={{gap: "2rem", marginTop:" 5rem"}}>
      <img src="/assets/images/forpages/marketpage/not-found-data-dark 1.svg" alt=""/>
    <HeaderGreyCenter>Нет результатов. Перейдите на спотовый рынок, чтобы добавить криптовалюты в избранное.</HeaderGreyCenter>
    <ButtonST style={{height: "48px", width: "199px"}}>Добавить в избранное</ButtonST>
      </ContainerV>
  );
};
export default NoData;