import React from "react";
import BalanceBlock from "../forAccountPage/BalanceBlock";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import { BorderBlock } from "../forAccountPage/Deposits";
import HeaderGreyCenter from "../../styles/headers/HeaderGreyCenter";
import ContainerV from "../../styles/containers/ContainerV";
import { Outlet } from "react-router-dom";

const WalletOverview: React.FC = () => {
  return (
    <>
      <BalanceBlock />
      <BorderBlock>
        <ContainerH>
          <HeaderALTH style={{ fontSize: "20px" }}> Мои активы</HeaderALTH>
          <GreyLinkArrowR to={"/"} customStyles={{ color: "#fff" }}>
            Посмотреть все 350+ монет
          </GreyLinkArrowR>
        </ContainerH>

        <Outlet />
      </BorderBlock>
      <BorderBlock style={{ marginTop: "1rem" }}>
        <ContainerH>
          <HeaderALTH style={{ fontSize: "20px" }}>
            {" "}
            Последние транзакции
          </HeaderALTH>
          <GreyLinkArrowR to={"/"} customStyles={{ color: "#fff" }}>
            Подробнее
          </GreyLinkArrowR>
        </ContainerH>
        <ContainerV style={{ gap: "2rem", marginTop: " 5rem" }}>
          <img
            src="/assets/images/forpages/marketpage/not-found-data-dark%201.svg"
            alt=""
          />
          <HeaderGreyCenter>Нет записей</HeaderGreyCenter>
        </ContainerV>
      </BorderBlock>
    </>
  );
};
export default WalletOverview;
