import React, { useState } from "react";
import styled from "styled-components";

const NLAccountAccordionContainer = styled.div`
  //border: 1px solid #1E2329;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const AccordionHeader = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ isOpen }) => (isOpen ? "20px 20px 0 0" : "20px")};
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  background-color: transparent;
  text-align: left;
  //border-bottom: 1px solid #1E2329;
  border-radius: 0 0 20px 20px;
  margin-top: -2rem;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  color: #000;
  font-weight: 700;
  font-size: 30px;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleBlock = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const ImageBlock = styled.img`
  //width: 40px;
  //height: 40px;
  margin-right: 10px;
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0; 
`;

const Point = styled.a`
  text-decoration :none;
  color: #848E9C;
  text-align: left;
  margin-left: 1.5rem;
  margin-top: 3rem;
  &:hover {
    color: #FCD535;
  }
`;

interface LinkButton {
    text: string;
    link: string;
}
interface NLAccountAccordionProps {
    title: string;
    buttonsWithLinks?: LinkButton[];
    image_path: string;
}

const NLAccountAccordion: React.FC<NLAccountAccordionProps> = ({ title, buttonsWithLinks, image_path }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <NLAccountAccordionContainer>
            <AccordionHeader onClick={toggleAccordion} isOpen={isOpen}>
                <TitleBlock>
                    <ImageBlock src={image_path} alt="Image" />
                    {title}
                </TitleBlock>
                <ToggleButton onClick={toggleAccordion}>{isOpen ? <img src="/assets/images/icons/arrow-up.svg" alt="-" /> : <img src="/assets/images/icons/arrow.svg" alt="+" />}</ToggleButton>
            </AccordionHeader>
            <AccordionContent isOpen={isOpen}>
                {buttonsWithLinks && (
                    <LinksColumn>
                        {buttonsWithLinks.map(({text, link}, index) => (
                            <Point key={index} href={link}  rel="noopener noreferrer">

                                {text}
                            </Point>
                        ))}
                    </LinksColumn>
                )}
            </AccordionContent>
        </NLAccountAccordionContainer>
    );
};

export default NLAccountAccordion;
