import React, { useState } from "react";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import ButtonST from "../../styles/functional/buttons/ButtonST";
import { useNavigate } from "react-router-dom";
import ContainerH from "../../styles/containers/ContainerH";
import CustomInput from "../../styles/functional/inputs/CustomInput";
import ButtonBack from "../../styles/functional/buttons/ButttonBack";
import { useGetTokensMutation } from "../../redux/apiService";
import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken } from "../../redux/slices/authSlice";
import { AppDispatch } from "../../redux/store";

const LoginPage: React.FC = () => {
  const buttonText = "Войти";
  const account = "/account";
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [getTokens, { isLoading, isError, error }] = useGetTokensMutation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleButtonClick = async () => {
    try {
      // Получите ответ от сервера
      const response = await getTokens({
        email: formData.email,
        password: formData.password,
      }).unwrap();

      console.log("Ответ сервера:", response);

      // Извлекаем токены из body
      const accessToken = response.body.access;
      const refreshToken = response.body.refresh;

      if (accessToken && refreshToken) {
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshToken));

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        console.log("Токены сохранены в store:", { accessToken, refreshToken });
        navigate(account);
      } else {
        throw new Error("Токены отсутствуют в ответе");
      }
    } catch (err) {
      console.error("Ошибка авторизации:", err);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <ContainerV>
      <ContainerV style={{ gap: "3rem" }}>
        <ButtonBack onClick={handleBackClick} />
        <HeaderALTH style={{ marginBottom: "-3rem" }}>
          Войти в аккаунт
        </HeaderALTH>
        <CustomInput
          type="email"
          name="email"
          placeholder="Эл.почта / номер телефона"
          style={{
            alignSelf: "flex-start",
            width: "410px",
            height: "60px",
          }}
          onChange={handleInputChange}
          value={formData.email}
        />
        <CustomInput
          type="password"
          name="password"
          placeholder="Пароль"
          style={{
            alignSelf: "flex-start",
            width: "410px",
            height: "60px",
          }}
          onChange={handleInputChange}
          value={formData.password}
        />
        <ButtonST
          style={{
            width: "410px",
            height: "60px",
            alignSelf: "flex-start",
          }}
          onClick={handleButtonClick}
          disabled={isLoading}
        >
          {isLoading ? "Загрузка..." : buttonText}
        </ButtonST>
        {isError && <div>Ошибка авторизации: {JSON.stringify(error)}</div>}
      </ContainerV>
      <ContainerH>
        <img src="/assets/images/forpages/auth/two-brokers.svg" alt="meet" />
      </ContainerH>
    </ContainerV>
  );
};

export default LoginPage;
