import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "./WorkerBinding";
import ContainerH from "../../styles/containers/ContainerH";
import Messager from "./forSupport/Messager";
import WorkerSupportTable from "../../styles/tables/forWorker/WorkerSupportTable";

const WorkerSupport: React.FC = () => {
  const testData = [
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-1",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-2",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 3,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-3",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-4",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-5",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 4,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-6",
      status: "Offline",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-7",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-8",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 5,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-9",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
  ];
  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock style={{ marginTop: "7rem" }}>Support</HeadBlock>
      <ContainerH
        style={{ margin: "0", alignItems: "flex-start", height: "100%" }}
      >
        <WorkerSupportTable data={testData} />
        <Messager />
      </ContainerH>
    </ContainerV>
  );
};
export default WorkerSupport;
