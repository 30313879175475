import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #1e2329;
  border-radius: 4px;
  border: none;
  padding: 10px;
  align-self: center;

  &:hover {
    background-color: #2a333d;
  }
`;
const AttachFilesButton = () => {
  return (
    <StyledButton>
      <img
        src="/assets/images/forpages/adminpanel/attachFiles.svg"
        alt="Attach"
      />
    </StyledButton>
  );
};
export default AttachFilesButton;
