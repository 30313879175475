import React from 'react';
import styled from 'styled-components';
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import ContainerV from "../../styles/containers/ContainerV";

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledItem = styled.div`
  //padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  
  &:hover {
    background-color: #1E2329;
  }
`;

const StyledImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 16px;
`;

const StyledTextBlock = styled.div`
  text-align: left;
`;

const StyledWhiteText = styled.p`
  color: #fff;
  margin: 0;
`;

const StyledGrayText = styled.p`
  color: #777;
  margin: 0;
`;

const StyledButton = styled.a`
  padding: 8px 16px;
  background-color: #293039;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    color: #fff;
  }
`;

interface SquareBlockProps {
    data: {
        imageSrc: string,
        whiteText: string,
        grayText: string,
        buttonLink: string,
    }[];

}
const SquareBlock: React.FC<SquareBlockProps> = ({ data }) => {
    return (
        <ContainerV
            style={{
                flexWrap:"nowrap",
                border:"2px solid #293039",
                borderRadius:"15px",
                padding:"1rem",
                width:"65%",
        }}>
            <ContainerH>
                <HeaderALTH>Square</HeaderALTH>
                <GreyLinkArrowR to="/" children="Подробнее"/>
            </ContainerH>

        <StyledContainer>

            {data.map((item, index) => (
                <StyledItem key={index}>
                    <ContainerH style={{}}>
                    <StyledImage src={item.imageSrc} alt="Item" />
                    <StyledTextBlock>
                        <StyledWhiteText>{item.whiteText}</StyledWhiteText>
                        <StyledGrayText>{item.grayText}</StyledGrayText>
                    </StyledTextBlock>
                    </ContainerH>
                    <StyledButton href={item.buttonLink} target="_blank" rel="noopener noreferrer">
                        Подписаться
                    </StyledButton>
                </StyledItem>
            ))}
        </StyledContainer>
        </ContainerV>
    );
};

export default SquareBlock;
