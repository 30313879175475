import React from "react";
import {HeadBlock} from "./WorkerBinding";
import ContainerV from "../../styles/containers/ContainerV";
import EightBlocksAtTop from "./forWorkerMainSettings/EightBlocksAtTop";
import ErrorMessages from "./forWorkerMainSettings/ErrorMessages";

const WorkerMainSettings:React.FC = () => {

  return <>
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Main settings</HeadBlock>
          <EightBlocksAtTop/>
          <ErrorMessages/>
      </ContainerV>
  </>;
};
export default WorkerMainSettings;