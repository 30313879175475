import React, {useState} from "react";
import styled from "styled-components";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";


const BorderBlock = styled.div`
  background-color: #191B21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191B21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 10rem;

  &:hover {
    background-color: #FFFF66;
  }
`;

export const GreyButton = styled.button`
  background-color: #2F323C;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10rem;

  &:hover {
    background-color: #3c404c;
  }
`;

const GrayText =styled.div`
  text-align: start;
  color: #848E9C;
  font-weight: 500;
  font-size: 14px;
  //max-width: 95%;
  align-self: flex-start;
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;


const FiveBlocksAtTop:React.FC = () => {
    // Step 1: Define the switchStates state
    const [switchState, setSwitchState] = useState(false);
    const [secondSwitch, setSecondSwitch] = useState(false);

    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Toggle the switch state at the given index
        setSwitchState(event.target.checked);
        console.log("Switch state 1 changed to:", event.target.checked);
    };

    const handleSecondSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondSwitch(event.target.checked);
        console.log("Switch state 2 changed to:", event.target.checked);
    }


  return <>
      <ContainerV style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              width:"100%"
      }}>
          <ContainerH
              style={{
                  margin:"0",
                  gap:"4%",
                  flexWrap:"wrap",
                  width:"100%"
          }}>
              <BorderBlock style={{
                  width:"48%",
                  height:"25rem",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Minimal deposit amount</Title>
                  <GrayText>
                      Минимальная сумма депозита.<br/>
                      Используется для вычисления суммы в определенной валюте, если для этой валюты не указан конкретный Min deposit amount в таблице Deposit coins.
                  </GrayText>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Minimum amount</TitleLittle>
                      <ContainerH style={{margin:"0", width:"100%"}}>
                          <CustomInput type="number" placeholder="250"/>
                          <GreyButton>USD</GreyButton>
                      </ContainerH>
                  </BorderBlock>
                  <GrayText>Сумма в долларах, которая будет автоматически конвертироваться в валюту.</GrayText>
                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  height:"25rem",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Deposit transaction commission</Title>
                  <GrayText style={{marginBottom:"1.45rem"}}>
                      Комиссия в процентах, которая будет вычитаться от совершенного депозита при зачислении на счет пользователя.
                  </GrayText>
                  <BorderBlock style={{
                      width:"100%"
                  }}>
                      <TitleLittle>Deposit commission:</TitleLittle>
                      <ContainerH style={{margin:"0", width:"100%"}}>
                          <CustomInput type="number" placeholder="250"/>
                          <GreyButton>%</GreyButton>
                      </ContainerH>
                  </BorderBlock>
                  <SubmitButton style={{marginTop:"3.5rem"}}>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  height:"13rem",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Verification requirement</Title>
                  <ContainerH style={{width:"100%", margin:"0"}}>
                      <GrayText>
                          Если включено - то при попытке вывода для новых пользователей будет появляться окно верификации (обязательно внести депозит).
                      </GrayText>
                      <div>
                          <Switch id="verificationRequirementSwitch" checked={switchState} onChange={handleSwitchChange} />
                          <SwitchLabel htmlFor="verificationRequirementSwitch"></SwitchLabel>
                      </div>
                  </ContainerH>

                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
              <BorderBlock style={{
                  width:"48%",
                  height:"13rem",
                  justifyContent:"flex-start",
                  alignSelf:"flex-start"
              }}>
                  <Title>Verification AML Modal</Title>
                  <ContainerH style={{width:"100%", margin:"0"}}>
                      <GrayText>
                          Если включено - то при попытке вывода для новых пользователей будет появляться окно AML check: High Risk Score (внесение депозита)
                      </GrayText>
                      <div>
                          <Switch id="verificationAmLOnlySwitch" checked={secondSwitch} onChange={handleSecondSwitchChange} />
                          <SwitchLabel htmlFor="verificationAmLOnlySwitch"></SwitchLabel>
                      </div>
                  </ContainerH>

                  <SubmitButton>Save changes</SubmitButton>
              </BorderBlock>
          </ContainerH>
          <BorderBlock style={{
              width:"100%",
              // height:"25rem",
              justifyContent:"flex-start",
              alignSelf:"flex-start"
          }}>
              <Title>Verification Deposit Amount</Title>
              <GrayText>
                  Минимальная сумма депозита для прохождения верификации на странице Withdraw. Используется для вычисления суммы в определенной валюте.<br/>
                  <strong>Важно! Не путайте с Min deposit amount.</strong>
              </GrayText>
              <BorderBlock style={{
                  width:"100%"
              }}>
                  <TitleLittle>Minimum amount</TitleLittle>
                  <ContainerH style={{margin:"0", width:"100%"}}>
                      <CustomInput type="number" placeholder="250"/>
                      <GreyButton>USD</GreyButton>
                  </ContainerH>
              </BorderBlock>
              <GrayText>Сумма в долларах, которая будет автоматически конвертироваться в валюту.</GrayText>
              <SubmitButton>Save changes</SubmitButton>
          </BorderBlock>
      </ContainerV>
  </>;
};
export default FiveBlocksAtTop;