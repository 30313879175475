import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import ContainerH from "../../styles/containers/ContainerH";
import { HeadBlock } from "../../components/forWorkerPage/WorkerBinding";
//import styled from "styled-components";
import { useGetTermsAndAmlKycPoliciesRetrieveOneQuery } from "../../redux/apiService";

// const LegalGrayText = styled.div`
//   color: #848e9c;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 28px;
//   text-align: left;
// `;
//
// const LegalWhiteText = styled.div`
//   color: #efeee9;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 28px;
//   text-align: left;
// `;

const AccountSettingsChange: React.FC = () => {
  const {
    data: terms,
    error,
    isLoading,
  } = useGetTermsAndAmlKycPoliciesRetrieveOneQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading terms: {error.toString()}</div>;
  }
  console.log("terms data:", terms);
  return (
    <>
      <ContainerV
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding: "0 10rem 0 10rem",
        }}
      >
        <ContainerH style={{ margin: "0" }}>
          <HeadBlock>Terms of Service</HeadBlock>
        </ContainerH>
        {terms?.body.terms}
        {/*<LegalGrayText>*/}
        {/*  Updated: 05-19-2023*/}
        {/*  <br /> <br /> This User Agreement for the service "SWEBLINK"*/}
        {/*  (hereinafter the "Agreement") is concluded between the owner of the*/}
        {/*  software package "SWEBLINK" (hereinafter "CRYPTO ARBY*/}
        {/*  <br /> <br />*/}
        {/*  TRADE LIMITED") and a person who successfully registered on the*/}
        {/*  service "SWEBLINK" (hereinafter "Service") and started using the*/}
        {/*  Service ( hereinafter referred to as "User").*/}
        {/*  <br /> <br />*/}
        {/*  The User is obliged to study the text of this Agreement in detail.*/}
        {/*  Acceptance of this Agreement by successfully registering on the*/}
        {/*  Service and starting to use it means full and unconditional acceptance*/}
        {/*  the Agreement terms and other information, links to which are provided*/}
        {/*  in the Agreement.*/}
        {/*  <br /> <br />*/}
        {/*  In case of disagreement with this terms, the User is obliged to*/}
        {/*  immediately leave the Service.*/}
        {/*  <br /> <br />*/}
        {/*  The User is granted any proprietary intellectual rights in relation to*/}
        {/*  the Service and its software under no circumstances. <br />*/}
        {/*  <br />*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>*/}
        {/*  1. Definitions and term interpretations.*/}
        {/*</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1.1 The terms given below are used in this document with the following*/}
        {/*  meanings:*/}
        {/*  <br /> <br />*/}
        {/*  «User» - the person who has reached the age of majority, the visitor*/}
        {/*  of the Service, who acquaints himself with the types of Services*/}
        {/*  presented by CRYPTO ARBY TRADE LIMITED, uses the reference and*/}
        {/*  information resources of the Service, is going to make the Order of*/}
        {/*  Services, or already implements the Order in the real-time mode, or*/}
        {/*  who is already using Services purchased from CRYPTO ARBY TRADE*/}
        {/*  LIMITED.*/}
        {/*  <br /> <br /> «Service» is a software complex «SWEBLINK», located at*/}
        {/*  https://WWW.SWEBLINK.COM and owned by CRYPTO ARBY TRADE LIMITED,*/}
        {/*  containing all technical and functional features for use by the User*/}
        {/*  following the terms and conditions described in the Agreement. The*/}
        {/*  service is a simulator of an online crypto exchange (analogous to a*/}
        {/*  crypto exchange), where the User has the opportunity to learn trading*/}
        {/*  making crypto-coins exchange transactions.*/}
        {/*  <br /> <br />*/}
        {/*  "Services" - services provided by CRYPTO ARBY TRADE LIMITED to Users,*/}
        {/*  including the provision for using of Service functionality for a*/}
        {/*  certain period under the Subscriptions selected by the User, Agreement*/}
        {/*  terms.*/}
        {/*  <br /> <br />*/}
        {/*  «Subscription» - the totality of functions provided to the User during*/}
        {/*  the paid period of the Service using. Details of the current*/}
        {/*  subscriptions are listed on the Service page https://WWW.SWEBLINK.COM*/}
        {/*  <br />*/}
        {/*  <br />*/}
        {/*  "Agreement" - a service agreement concluded between CRYPTO ARBY TRADE*/}
        {/*  LIMITED and the User by registering the User on the Service and*/}
        {/*  creating a PA.*/}
        {/*  <br /> <br />*/}
        {/*  "Order" - an application of the User for the Services receipt, issued*/}
        {/*  through the Service and representing the free and independent*/}
        {/*  intention and will of the User to purchase the Service selected by the*/}
        {/*  User from CRYPTO ARBY TRADE LIMITED under the Subscription.*/}
        {/*  <br /> <br />*/}
        {/*  "Personal Account" ("PA") is a personalized Service section, closed*/}
        {/*  for public access. Access to the personal account is carried out by*/}
        {/*  entering authentication data on the Service login page: login and*/}
        {/*  password. <br /> <br />*/}
        {/*  1.1 Terms that are not defined in Agreement clause 1.1 may be used in*/}
        {/*  the Agreement. In these cases, the interpretation of the terms is made*/}
        {/*  under the text and meaning of the Agreement. In the absence of an*/}
        {/*  unambiguous term interpretation in the text of the Agreement, you*/}
        {/*  should be guided, firstly, by the interpretation of the terms used on*/}
        {/*  the Service, including in the legal documentation posted on the*/}
        {/*  Service; secondly, by international law and business customs in the*/}
        {/*  relevant field of activity.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Subject of the Agreement.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1.1 Under this Agreement, CRYPTO ARBY TRADE LIMITED, within the*/}
        {/*  framework of the Subscription chosen by the User, grants the User the*/}
        {/*  right to access and use the Service in the manner described in the*/}
        {/*  Agreement. By accepting the terms of this Agreement and creating a*/}
        {/*  Personal Account on the Service, the User agrees to follow and comply*/}
        {/*  with all the rules and requirements for using the Service specified in*/}
        {/*  the Agreement. <br /> <br />*/}
        {/*  1.2 Searching, ordering a service, using the Service, including*/}
        {/*  viewing those posted in the sections of the Service, providing*/}
        {/*  reference and information resources of the Service, also implies full*/}
        {/*  consent of the User with this Agreement. <br /> <br />*/}
        {/*  1.3 CRYPTO ARBY TRADE LIMITED, after the registration procedure and*/}
        {/*  creation of the PA, provides the User with the following Service*/}
        {/*  functionality: <br /> <br />*/}
        {/*  1. studying the operation principles of crypto-exchanges, <br />*/}
        {/*  2. depositing crypto-coins (bitcoin, ethereum, or litecoin) to your*/}
        {/*  private account (wallet) located on the Service, and withdrawing them*/}
        {/*  from the Service at your discretion, <br />*/}
        {/*  3. trading on a demo account,*/}
        {/*  <br /> 4. tracking balance indicators, transactions history in the*/}
        {/*  User's PA, <br />*/}
        {/*  5. using a simulator of investment plans, where the User can invest*/}
        {/*  his/her coins at a certain percentage and freeze them for the period*/}
        {/*  specified in the plan. <br /> <br />*/}
        {/*  1.1 The user has the right to use the Service without restrictions on*/}
        {/*  any territory, on the terms, and in the manner provided for in this*/}
        {/*  Agreement. <br />*/}
        {/*  <br />*/}
        {/*  1.2 A full description of the Services, the method of ordering them, a*/}
        {/*  list of valid Subscriptions, as well as other information, are*/}
        {/*  described on the corresponding web page of the Service and/or in the*/}
        {/*  User's private account. <br />*/}
        {/*  <br />*/}
        {/*  1.3 The use of the Service is carried out by the User independently*/}
        {/*  under the instructions posted in the PA. <br />*/}
        {/*  <br />*/}
        {/*  1.4 CRYPTO ARBY TRADE LIMITED has the right to amend the Agreement*/}
        {/*  without notifying the User. The user is personally responsible for*/}
        {/*  checking the Agreement for changes in it. If the User disagrees with*/}
        {/*  the relevant changes, the User is obliged to stop using the Service*/}
        {/*  and refuse the Services provided by CRYPTO ARBY TRADE LIMITED.*/}
        {/*  Otherwise, the continued use of the Service by the User means that the*/}
        {/*  User agrees with the terms of the Agreement in the new edition. <br />*/}
        {/*  <br />*/}
        {/*  1.5 The Agreement does not require seals and/or signing by CRYPTO ARBY*/}
        {/*  TRADE LIMITED and the User on paper to maintain full legal force. 1.6*/}
        {/*  By registering on the Service and thereby creating a Personal Account,*/}
        {/*  the User guarantees that he/she has the legal rights to enter into*/}
        {/*  legal relations with CRYPTO ARBY TRADE LIMITED. In this case, the User*/}
        {/*  agrees that: the User's data during registration and authorization to*/}
        {/*  enter the Service are indicated by him/her voluntarily; other data*/}
        {/*  voluntarily specified by the User are transmitted to CRYPTO ARBY TRADE*/}
        {/*  LIMITED in electronic form via Internet communication channels; the*/}
        {/*  data is transferred by User to CRYPTO ARBY TRADE LIMITED for the*/}
        {/*  implementation of the purposes specified in this Agreement; The*/}
        {/*  consent given by the User to the processing of his/her data is*/}
        {/*  indefinite and can be revoked by the User or his/her legal*/}
        {/*  representative by submitting a written application to CRYPTO ARBY*/}
        {/*  TRADE LIMITED. <br />*/}
        {/*  <br />*/}
        {/*  1.6 By registering on the Service and thereby creating a Personal*/}
        {/*  Account, the User guarantees that he/she has the legal rights to enter*/}
        {/*  into legal relations with CRYPTO ARBY TRADE LIMITED. In this case, the*/}
        {/*  User agrees that: <br />*/}
        {/*  <br />*/}
        {/*  1. the User's data during registration and authorization to enter the*/}
        {/*  Service are indicated by him/her voluntarily; <br />*/}
        {/*  2. other data voluntarily specified by the User are transmitted to*/}
        {/*  CRYPTO ARBY TRADE LIMITED in electronic form via Internet*/}
        {/*  communication channels; <br />*/}
        {/*  3. the data is transferred by User to CRYPTO ARBY TRADE LIMITED for*/}
        {/*  the implementation of the purposes specified in this Agreement; <br />*/}
        {/*  4. The consent given by the User to the processing of his/her data is*/}
        {/*  indefinite and can be revoked by the User or his/her legal*/}
        {/*  representative by submitting a written application to CRYPTO ARBY*/}
        {/*  TRADE LIMITED.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Registration.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. The provision of the Services to the User is possible subject to*/}
        {/*  the creation of an appropriate account by the User on the Service for*/}
        {/*  the subsequent entry into the PA in order to gain access to the*/}
        {/*  functionality of the Service. <br /> 2. Viewing information posted on*/}
        {/*  the Service in the public domain does not require registration and/or*/}
        {/*  User authorization, however, when performing such actions, the User is*/}
        {/*  in any case obliged to comply with the provisions of the Agreement.*/}
        {/*  <br /> 3. The registration account must contain the User's login,*/}
        {/*  email address, password. <br /> 4. Account registration is carried out*/}
        {/*  by filling out the registration form. In the registration form, it is*/}
        {/*  necessary to indicate only authentic data. Mandatory forms are*/}
        {/*  indicated with a "*". <br /> 5. When registering, the User is assigned*/}
        {/*  a login and password, and the User gains access to his/her PA. <br />{" "}*/}
        {/*  6. The user is solely responsible for his/her login and password*/}
        {/*  safety. CRYPTO ARBY TRADE LIMITED is not responsible for the*/}
        {/*  disclosure of the User's login and password to third parties and in*/}
        {/*  the event of unauthorized access by third parties to the User's*/}
        {/*  account.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>*/}
        {/*  1. The Procedure of Ordering and Service using.*/}
        {/*</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. After completing the registration procedure, the User has the right*/}
        {/*  to place an Order for the Service under the Subscription chosen by*/}
        {/*  him/her, presented on the Service. Thus, the User, implementing the*/}
        {/*  algorithm proposed by the Service, independently creates an Order*/}
        {/*  through the appropriate web interface.*/}
        {/*  <br /> 2. Choosing a Subscription, placing an Order, and all*/}
        {/*  subsequent User's actions described in this section are carried out*/}
        {/*  independently, without the participation of CRYPTO ARBY TRADE LIMITED.*/}
        {/*  <br /> 3. The payment method and the performance actions of other*/}
        {/*  related necessary in connection with the requirements of the*/}
        {/*  corresponding selected payment system are the responsibility of the*/}
        {/*  User. <br /> 4. The User assumes full responsibility for providing*/}
        {/*  incorrect data, which can make it impossible for CRYPTO ARBY TRADE*/}
        {/*  LIMITED to properly execute this Agreement. <br /> 5. The User has the*/}
        {/*  right to deposit crypto-coins (bitcoin, ethereum, or litecoin) to*/}
        {/*  his/her personal account (wallet) located on the Service and withdraw*/}
        {/*  them from the Service at his/her own discretion. <br /> 6. Trading is*/}
        {/*  carried out on the User's demo account. <br /> 7. The deposited*/}
        {/*  crypto-coins can be used by the User to study the principles of the*/}
        {/*  crypto-exchange (create orders for sale/purchase). <br /> 8. The User*/}
        {/*  can track balance indicators, the transactions history in the PA, as*/}
        {/*  well as use an investment plans simulator, where he/she can invest*/}
        {/*  his/her coins at a certain percentage and freeze them for the period*/}
        {/*  specified in the plan.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Financial terms.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. The cost of the Services is determined by CRYPTO ARBY TRADE LIMITED*/}
        {/*  independently by introducing fees and their descriptions on the page*/}
        {/*  https://WWW.SWEBLINK.COM/fees <br /> 2. CRYPTO ARBY TRADE LIMITED has*/}
        {/*  the right to unilaterally change the types and cost of Subscriptions*/}
        {/*  without notifying the User about it at any time. <br /> 3. Payment for*/}
        {/*  fees is carried out in the ways presented on the Service. <br /> 4.*/}
        {/*  The User voluntarily and informed disposes of his/her funds for the*/}
        {/*  personal use of the Service and pays for fees at own risk and peril.*/}
        {/*  Unless the User proves otherwise, any actions performed by him/her*/}
        {/*  using the Service are considered to have been committed by the*/}
        {/*  relevant User. <br /> 5. The Agreement concluded between the parties*/}
        {/*  has the force of an act on the provision of Services. Acceptance and*/}
        {/*  transfer of the Goods are made without signing the relevant acts*/}
        {/*  between the Parties. If within 5 (five) working days from the end of*/}
        {/*  the Subscription, CRYPTO ARBY TRADE LIMITED does not receive claims*/}
        {/*  from the User related to the provision of the Services, their volume,*/}
        {/*  and quality, the Parties will consider that the Services were provided*/}
        {/*  to the User in full and properly. CRYPTO ARBY TRADE LIMITED doesn't*/}
        {/*  accept subsequent claims from the User. <br /> 6. Users with a balance*/}
        {/*  of more than 500$ must activate the premium status. In any other case,*/}
        {/*  their balance will be frozen until the premium account is activated.*/}
        {/*  <br /> 7. Deposit for purchasing premium status and insurance payment*/}
        {/*  should be done by one payment. <br /> 8. To use the procedure for*/}
        {/*  withdrawing funds from your account, the user needs to verify the*/}
        {/*  wallet for withdrawal.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>Rights and obligations of the Parties</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. CRYPTO ARBY TRADE LIMITED undertakes:*/}
        {/*  <br />*/}
        {/*  <br />*/}
        {/*  &emsp; 1. Accept the User's Order under the forms established on the*/}
        {/*  Service.*/}
        {/*  <br /> &emsp; 2. Provide accurate information about the Services.{" "}*/}
        {/*  <br /> &emsp; 3. Provide the relevant Service based on the Order.{" "}*/}
        {/*  <br /> &emsp; 4. Provide oral and written advice to the User on*/}
        {/*  additional Users' questions. Ensure the timely provision of the*/}
        {/*  Services. <br />*/}
        {/*  &emsp; 5. Ensure timely provision of Services. <br /> &emsp; 6. Use*/}
        {/*  the information provided by the User only for the provision of the*/}
        {/*  Services. <br /> &emsp; 7. Consider and verify motivated claims from*/}
        {/*  the User. <br /> <br /> 2.CRYPTO ARBY TRADE LIMITED is entitled to:{" "}*/}
        {/*  <br /> <br /> &emsp; 1. Request any documents, personal data necessary*/}
        {/*  to confirm the user's identity, as well as requests to clarify the*/}
        {/*  legitimacy of funds origin that the User has deposited into his/her*/}
        {/*  account. <br /> &emsp; 2. Freeze the User's funds withdrawal in case*/}
        {/*  of illegal User's actions and the illegitimacy of funds deposited by*/}
        {/*  User into his/her account located on the Service.*/}
        {/*  <br /> &emsp; 3. Refuse to fulfill the Agreement unilaterally if the*/}
        {/*  User infringes the conditions specified in the Agreement. <br />{" "}*/}
        {/*  &emsp; 4. Expand and reduce the Service's offers, regulate access to*/}
        {/*  ordering Services, as well as suspend or terminate the implementation*/}
        {/*  of any Services at its sole discretion. <br /> &emsp; 5. Change the*/}
        {/*  Agreement terms unilaterally. <br /> &emsp; 6. Receive from the User*/}
        {/*  any information necessary to fulfill their obligations under the*/}
        {/*  Agreement. <br /> &emsp; 7. Suspend the operation of the Service at*/}
        {/*  any time without prior notice to update the Service, carry out*/}
        {/*  preventive maintenance, in the event of technical failures, by the*/}
        {/*  action and/or inaction of third parties, and for other reasons beyond*/}
        {/*  the control of CRYPTO ARBY TRADE LIMITED.*/}
        {/*  <br /> &emsp; 8. For infringe of the terms of the Agreement, block the*/}
        {/*  User's access to the Service without explaining the reasons. <br />*/}
        {/*  <br /> 3.The User undertakes: <br /> &emsp; 1. To get acquainted with*/}
        {/*  information about the Services, the terms of their purchase,*/}
        {/*  Subscriptions, and other information before placing an Order. <br />*/}
        {/*  &emsp; 2. Provide only reliable data during registration. <br />*/}
        {/*  &emsp; 3. To act in good faith and not abuse your rights, in*/}
        {/*  particular, not to purchase the Services to commit unfair actions that*/}
        {/*  cause damage, loss, or cost to CRYPTO ARBY TRADE LIMITED. <br />*/}
        {/*  &emsp; 4. Do not attempt to gain unauthorized access to the Service,*/}
        {/*  accounts registered with other people or other computers, Servers or*/}
        {/*  networks connected to the Service in any other way than the user*/}
        {/*  interface of the Service, including but not limited to bypassing or*/}
        {/*  modifying, trying to bypass or change, or encourage or help any other*/}
        {/*  person to circumvent or change the security software, technology,*/}
        {/*  device or software that is part of the Service. <br /> &emsp; 5. Do*/}
        {/*  not interfere with or attempt to interfere with the proper functioning*/}
        {/*  of the Service or connect to the Service or use the Service in any way*/}
        {/*  not expressly permitted by this Agreement. <br /> &emsp; 6. Do not use*/}
        {/*  the information received from CRYPTO ARBY TRADE LIMITED in ways that*/}
        {/*  could damage the interests of CRYPTO ARBY TRADE LIMITED. <br /> &emsp;*/}
        {/*  7. Do not copy, modify or distribute the rights to the content*/}
        {/*  available in the Service, as well as the copyrights or trademarks of*/}
        {/*  CRYPTO ARBY TRADE LIMITED, or use in any way, copy or distribute the*/}
        {/*  content of the Service. <br /> &emsp; 8. Immediately notify CRYPTO*/}
        {/*  ARBY TRADE LIMITED of any claims to the Service (if any). <br />*/}
        {/*  &emsp; 9. Do not publicly distribute information (correspondence in*/}
        {/*  whole or in part) obtained as a result of communication with the*/}
        {/*  technical support group of CRYPTO ARBY TRADE LIMITED. <br /> 4. The*/}
        {/*  user has the right to: <br /> &emsp; 1. Demand from CRYPTO ARBY TRADE*/}
        {/*  LIMITED the proper and timely Services provision. <br /> &emsp; 2.*/}
        {/*  Select Subscriptions. <br /> &emsp; 3. Contact CRYPTO ARBY TRADE*/}
        {/*  LIMITED for all questions related to the provision of the Services.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Documentation disclaimer.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  <br /> &emsp; 1. CRYPTO ARBY TRADE LIMITED does not force the User in*/}
        {/*  any way, does not insist to do any actions through the Service. <br />{" "}*/}
        {/*  &emsp; 2. CRYPTO ARBY TRADE LIMITED does not provide the User with any*/}
        {/*  financial, investment, or legal advice through the Service, providing*/}
        {/*  its Services and managing the Service at its current stage of*/}
        {/*  development. Although measures have been taken to ensure the accuracy*/}
        {/*  of the information on the Service, CRYPTO ARBY TRADE LIMITED assumes*/}
        {/*  no responsibility. <br /> &emsp; 3. The Service is not a virtual*/}
        {/*  currency exchange house, it is a simulator (game resource), and*/}
        {/*  therefore the Service and the results of the Services have nothing to*/}
        {/*  do with real financial processes and cryptocurrency exchange*/}
        {/*  platforms. <br /> &emsp; 4. CRYPTO ARBY TRADE LIMITED does not*/}
        {/*  guarantee or make any representations regarding the Service security.*/}
        {/*  The User acknowledges that any information sent to him/her can be*/}
        {/*  intercepted. CRYPTO ARBY TRADE LIMITED does not warrant that the*/}
        {/*  Service or the servers that make this Service available are free of*/}
        {/*  viruses or any other harmful elements. <br /> &emsp; 5. CRYPTO ARBY*/}
        {/*  TRADE LIMITED does not guarantee that the User will receive profit or*/}
        {/*  loss similar to those shown on the Service. Actual percentage*/}
        {/*  gains/losses may vary depending on many factors, including but not*/}
        {/*  limited to: opening balances (deposits and withdrawals), market*/}
        {/*  behavior, user account settings on the applicable cryptocurrency*/}
        {/*  exchange. Because of this, the actual profit/loss incurred by the User*/}
        {/*  may differ materially from the percentage gain/loss presented on the*/}
        {/*  Service during the User's receipt of the Services. <br /> &emsp; 6. No*/}
        {/*  part of the information provided and/or available on the Service is*/}
        {/*  not intended to be provided and should not be construed as providing*/}
        {/*  any investment, tax, or other financial advice of any kind. The User*/}
        {/*  should not consider any such content and/or any such function as a*/}
        {/*  substitute for professional financial advice. If the User decides to*/}
        {/*  participate in any transactions on the exchanges based on the content*/}
        {/*  and results obtained on the Service, then such a decision, operations,*/}
        {/*  and their consequences are solely the User's responsibility. <br />*/}
        {/*  &emsp; 7. The User acknowledges and agrees, that: <br /> &emsp; &nbsp;*/}
        {/*  a. The Service using is carried out by the User at own risk and peril.*/}
        {/*  CRYPTO ARBY TRADE LIMITED, as well as its employees and agents, are*/}
        {/*  not investment or financial advisers who guarantee the receipt of*/}
        {/*  income by the User through the use of the Service. If the User makes*/}
        {/*  investment decisions relying on the information that is available on*/}
        {/*  the Service, the User does so at his/her peril and risk, and CRYPTO*/}
        {/*  ARBY TRADE LIMITED, its employees, and agents will not be liable for*/}
        {/*  any losses that the User may get. <br /> &emsp; &nbsp; b. The User*/}
        {/*  should not make any investment decisions in real financial*/}
        {/*  transactions on exchanges without conducting his/her own research. The*/}
        {/*  user is exclusively and solely responsible for determining whether any*/}
        {/*  investment, strategy, or any other product or service is appropriate*/}
        {/*  for him/her based on the User's investment goals and personal and*/}
        {/*  financial situation. <br /> &emsp; &nbsp; c. The User is responsible*/}
        {/*  for any damage caused to his/her device, data loss, or other harm that*/}
        {/*  results from using the Service. <br /> &emsp; &nbsp; d. No oral or*/}
        {/*  written advice or information received by the User from CRYPTO ARBY*/}
        {/*  TRADE LIMITED or the Service does not create any guarantees not*/}
        {/*  expressly specified in the Agreement. <br /> &emsp; &nbsp; e. The User*/}
        {/*  pays for Subscriptions to obtain the result of the Services on a*/}
        {/*  voluntary basis. <br /> &emsp; &nbsp; f. The User is responsible for*/}
        {/*  his/her actions performed on the Service or account on the Exchange.*/}
        {/*  <br /> &emsp; &nbsp; g. The content of the Service and the Services*/}
        {/*  purchased by the User are provided on an "as is" basis. At the same*/}
        {/*  time, CRYPTO ARBY TRADE LIMITED is not responsible in any form for the*/}
        {/*  discrepancy between the result of the Services and the goals,*/}
        {/*  objectives, ideas, or desires of the User. <br /> &emsp; &nbsp; h.*/}
        {/*  Nothing in the Agreement can guarantee the complete satisfaction of*/}
        {/*  User interests and needs related to the purchase of the Services.*/}
        {/*  <br /> &emsp; 8. CRYPTO ARBY TRADE LIMITED is not responsible for:*/}
        {/*  <br /> &emsp; &nbsp; a. Risks, actions/inactions of the User in the*/}
        {/*  cryptocurrency market. <br /> &emsp; &nbsp; b. For losses and expenses*/}
        {/*  incurred by the User, in particular, losses and expenses caused by the*/}
        {/*  actions/inaction of third parties and/or incurred in connection with*/}
        {/*  failures and interruptions in the operation of the Service and/or*/}
        {/*  incurred in connection with the impact of computer viruses, "Trojans",*/}
        {/*  "Worms", etc. and/or related to the blocking of the User using the*/}
        {/*  Services result after ordering it, regardless of the reason for*/}
        {/*  blocking. <br /> &emsp; &nbsp; c. For any direct, indirect,*/}
        {/*  consequential, punitive, special or incidental damages (including,*/}
        {/*  without limitation, damages from business loss, contract, income,*/}
        {/*  data, information or business interruption), according to any theory*/}
        {/*  of liability, as a result, arising from or in connection with the use*/}
        {/*  or inability to use the Service or the content, products, services,*/}
        {/*  statements or behavior of any third party on the Service or through*/}
        {/*  this Service. <br /> &emsp; &nbsp; d. For the correct settings,*/}
        {/*  practical application, and results of the trading bot used by the User*/}
        {/*  through the Service; <br /> &emsp; &nbsp; e. For the User's account on*/}
        {/*  the Exchange. <br /> &emsp; &nbsp; f. For non-receipt of the Services*/}
        {/*  result by the User for reasons beyond the control of CRYPTO ARBY TRADE*/}
        {/*  LIMITED (the User does not have the necessary software, the ability to*/}
        {/*  access the Internet, the actions of providers, energy companies, the*/}
        {/*  Exchange, the impact of any computer virus or other similar software*/}
        {/*  code downloaded by the User to his/her device from the Service or in*/}
        {/*  connection with any Service offered through the Service, etc.). <br />*/}
        {/*  &emsp; &nbsp; g. For the practical applicability and value of*/}
        {/*  information related to the Services. <br /> &emsp; &nbsp; h. For*/}
        {/*  failure to obtain the Services results below the User's expectations,*/}
        {/*  since the success of the User's use of the knowledge, skills, and*/}
        {/*  abilities reflected in the analytical information depends on many*/}
        {/*  known and unknown CRYPTO ARBY TRADE LIMITED factors, which is accepted*/}
        {/*  by both Parties. The Service may contain links to other sites that are*/}
        {/*  not under the control of CRYPTO ARBY TRADE LIMITED. CRYPTO ARBY TRADE*/}
        {/*  LIMITED is not responsible for the content of such sites.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Settlement of disputes.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. All disputes, disagreements, and claims of the Parties must strive*/}
        {/*  to resolve through negotiations. The Party that has claims and/or*/}
        {/*  disagreements must send a message to the other Party indicating the*/}
        {/*  claims and/or disagreements that have arisen. <br /> 2. The message*/}
        {/*  must be sent by the User in e-mail to the address contact@SWEBLINK The*/}
        {/*  message must contain the essence of the requirement, evidence*/}
        {/*  supporting the requirement. <br /> 3. Within 10 (ten) days from the*/}
        {/*  date of the original message receipt, the Party that received it is*/}
        {/*  obliged to send a response to this message. <br /> 4. If the reply to*/}
        {/*  the message is not received by the Party that sent the message within*/}
        {/*  10 (ten) days from the date of sending the corresponding message, or*/}
        {/*  if the Parties do not come to an agreement on the claims and/or*/}
        {/*  disagreements that have arisen, the dispute must be considered in*/}
        {/*  court under the law. <br /> 5. The applicable law under this Agreement*/}
        {/*  is the substantive and procedural law of the United Kingdom of Great*/}
        {/*  Britain.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalWhiteText>1. Duration of Agreement.</LegalWhiteText>*/}
        {/*<LegalGrayText>*/}
        {/*  1. The Agreement comes into force from the moment it is posted on the*/}
        {/*  Internet on the Service, for the User - from the moment of his/her*/}
        {/*  joining the terms (acceptance of the terms) of the Agreement. <br />*/}
        {/*  2. The agreement has been posted for an indefinite period. The*/}
        {/*  Agreement continues to operate for the User until the moment he/she*/}
        {/*  stops using the Service. The User has the right to terminate access to*/}
        {/*  the Service and its use at will at any time. <br /> 3. Any changes to*/}
        {/*  the Agreement made by CRYPTO ARBY TRADE LIMITED unilaterally come into*/}
        {/*  force on the day following the day such changes are published on the*/}
        {/*  Service. <br /> 4. The User agrees and acknowledges that making*/}
        {/*  changes to the Agreement entails the introduction of these changes*/}
        {/*  into the Agreement concluded and in force between the User and CRYPTO*/}
        {/*  ARBY TRADE LIMITED, and these changes come into force simultaneously*/}
        {/*  with such changes to the Agreement. <br /> 5. CRYPTO ARBY TRADE*/}
        {/*  LIMITED may decide to terminate the Agreement unilaterally at any time*/}
        {/*  with the immediate termination of access and the ability to use the*/}
        {/*  Service by Users in the event of the Service closure, and in case of*/}
        {/*  violation by the User of any Agreement provision.*/}
        {/*</LegalGrayText>*/}
        {/*<br />*/}
        {/*<LegalWhiteText>1. Additional terms and conditions.</LegalWhiteText>*/}
        {/*<br />*/}
        {/*<LegalGrayText>*/}
        {/*  a. Intellectual rights to the Service, including but not limited to*/}
        {/*  programs, databases, interface, technical developments, logo,*/}
        {/*  trademark, and other means of individualization used on the Service*/}
        {/*  and allowing to implement the Service functionality, belong to CRYPTO*/}
        {/*  ARBY TRADE LIMITED. The Service and its components cannot be copied*/}
        {/*  (reproduced), processed, distributed, published, downloaded,*/}
        {/*  transferred, sold, or otherwise used in whole or in part without the*/}
        {/*  prior written permission of CRYPTO ARBY TRADE LIMITED. Any other use*/}
        {/*  of the Service is strictly prohibited. <br />*/}
        {/*  b. CRYPTO ARBY TRADE LIMITED has no rights to trademarks, service*/}
        {/*  marks, trade names, logos, copyrights, patents, domain names, or other*/}
        {/*  intellectual property belonging to third parties. All intellectual*/}
        {/*  property items mentioned above belong to their respective owners. The*/}
        {/*  rights to third-party content also belong to the respective third*/}
        {/*  parties. <br /> c. CRYPTO ARBY TRADE LIMITED has the right to assign*/}
        {/*  or in any other way transfer its rights and obligations arising from*/}
        {/*  its relationship with the User to third parties. <br /> d. The Service*/}
        {/*  and the goods provided may be temporarily partially or completely*/}
        {/*  unavailable due to preventive, other work, or for any other technical*/}
        {/*  reasons. The Technical Service has the right to periodically carry out*/}
        {/*  the necessary preventive or other work with or without prior*/}
        {/*  notification of the Users.*/}
        {/*</LegalGrayText>*/}
        {/*<LegalGrayText style={{ textAlign: "right" }}>*/}
        {/*  {" "}*/}
        {/*  CRYPTO ARBY TRADE LIMITED*/}
        {/*  <br />*/}
        {/*  Company number: 13491880 Registered company office address:*/}
        {/*  <br />5 Woodwells Road, Birmingham, England, B8 2TH*/}
        {/*</LegalGrayText>*/}
      </ContainerV>
    </>
  );
};
export default AccountSettingsChange;
