import React from "react";
import NavLeftAccount from "../components/forAccountPage/NavLeftAccount";
import ContainerH from "../styles/containers/ContainerH";
import ContainerV from "../styles/containers/ContainerV";
import {Outlet} from "react-router-dom";

const AccountPage: React.FC = () => {

  return <>
    <ContainerH
    style={{
      justifyContent:"flex-start",
      alignItems:"flex-start"
    }}>

      <NavLeftAccount/>
      <ContainerV
          style={{
            marginTop:"10rem",
            marginLeft:"20rem"
          }}>
          <Outlet/>
      </ContainerV>
    </ContainerH>

  </>;
};
export default AccountPage;