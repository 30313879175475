import styled from "styled-components";

const ContainerF = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
    
    
    `;
export default ContainerF;