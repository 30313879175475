import React from "react";
import styled from "styled-components";
import DeleteButton from "../../functional/buttons/DeleteButton";
import { useDeleteP2PFakeMutation } from "../../../redux/apiService";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
  max-width: 9rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto repeat(6, 1fr);
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
  refetch: () => void;
}

const AdminP2PFakesTable: React.FC<TableProps> = ({ data, refetch }) => {
  const [deleteP2PFake] = useDeleteP2PFakeMutation();

  const handleDelete = async (id: string) => {
    try {
      await deleteP2PFake(id).unwrap();
      console.log("P2PFake deleted successfully");
      refetch();
    } catch (error) {
      console.error("Failed to delete MessagePreset:", error);
    }
  };

  return (
    <BorderBlock>
      <table>
        <TableRow style={{ borderBottom: "none" }}>
          <TableHeader>ID</TableHeader>
          <TableHeader>Avatar</TableHeader>
          <TableHeader>Username</TableHeader>
          <TableHeader>Orders</TableHeader>
          <TableHeader>Limit</TableHeader>
          <TableHeader>Payment method</TableHeader>
          <TableHeader>Action</TableHeader>
        </TableRow>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.id}</TableCell>
              <TableCell>
                <img src={item.imagePath} alt={item.username} />
              </TableCell>
              <TableCell>{item.username}</TableCell>
              <TableCell>{item.orders}</TableCell>
              <TableCell>{item.limit}</TableCell>
              <TableCell>{item.paymentMethod}</TableCell>
              <TableCell>
                <DeleteButton onClick={() => handleDelete(item.id_true)} />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </BorderBlock>
  );
};
export default AdminP2PFakesTable;
