import React, { useState } from "react";
import styled from "styled-components";

const CustomBlockContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const CustomButton = styled.button<{ isActive: boolean }>`
  position: relative;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({isActive}) => (isActive ? "#ffffff" : "#848E9C")};
  text-decoration: none;

  &:hover {
    //color: ${({ isActive }) => (isActive ? "#848E9C" : "#EFB608")};
    color: #EFB608;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ isActive }) => (isActive ? "#EFB608" : "transparent")};
  }

    `;

const ButtonSwitchPCMob: React.FC = () => {
    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonNumber: number) => {
        setActiveButton(buttonNumber);
    };

    return (
        <CustomBlockContainer>
            <CustomButton isActive={activeButton === 1} onClick={() => handleButtonClick(1)}>
                ПК версия
            </CustomButton>
            <CustomButton isActive={activeButton === 2} onClick={() => handleButtonClick(2)}>
                Мобильная версия
            </CustomButton>
        </CustomBlockContainer>
    );
};

export default ButtonSwitchPCMob;
