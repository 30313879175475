import React from "react";
import styled from "styled-components";
import GreyLinkArrowR from "../functional/links/GreyLinkArrowR";

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #293039;
  font-size: 14px;
  border-radius: 20px;
  padding: 1rem;
  color: white;
  white-space: nowrap;
`;

const Table = styled.div`
  width: 90%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
  
  
`;

const Col = styled.div`
  flex: 1;
  margin-right: 1rem;
  align-content: center;
  
`;

const Col3 = styled.div`
  flex: 3;
`;

const TextGreen = styled.div`
  color: #10BA7B;
  font-weight: 700;
`;

interface MarketViewCardProps {
    img_adress: string;
    value: string;
    header: string;
    percents: string;
    linkText: string;
    name: string;
}

const ImgContainer = styled.img`
  margin-right: 0.5rem;

  `;

const MarketViewCard: React.FC<MarketViewCardProps> = ({img_adress, value, header, percents, linkText, name,}) => {
    return (
        <CardContainer>
            <Table>
                <Row>
                    <Col style={{ color: "white", fontSize: "12px", fontWeight: "700" }}>
                        {header}
                    </Col>
                    <Col></Col>
                    <Col>
                        <GreyLinkArrowR to="/">{linkText}</GreyLinkArrowR>
                    </Col>
                </Row>
                <Row>
                    <Col3>
                        <ImgContainer src={img_adress} alt="" />
                        {name}
                    </Col3>
                    <Col></Col>
                    <Col>
                        {value}
                    </Col>
                    <Col></Col>
                    <Col3>
                        <TextGreen>{percents}</TextGreen>
                    </Col3>
                </Row>
                <Row>
                    <Col3>
                        <ImgContainer src={img_adress} alt="" />
                        {name}
                    </Col3>
                    <Col></Col>
                    <Col>
                        {value}
                    </Col>
                    <Col></Col>
                    <Col3>
                        <TextGreen>{percents}</TextGreen>
                    </Col3>
                </Row>
                <Row>
                    <Col3>
                        <ImgContainer src={img_adress} alt="" />
                        {name}
                    </Col3>
                    <Col></Col>
                    <Col>
                        {value}
                    </Col>
                    <Col></Col>
                    <Col3>
                        <TextGreen>{percents}</TextGreen>
                    </Col3>
                </Row>
            </Table>
        </CardContainer>
    );
};

export default MarketViewCard;
