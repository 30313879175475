import React, {useState} from "react";
import styled from "styled-components";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
    min-width: 100%;
  
`;


const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  min-width: 7rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2,4fr) 1fr; 
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;

const GrayText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #848E9C;
  text-align: left;
  margin-bottom: 2rem;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #FCD535; // Green background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196F3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(26px); // Move the slider
  }

  &:checked + label:after {
    opacity: 1;
    transform: translateX(12px);
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039; 
  border-radius: 1rem;

  &:before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    bottom: 0;
    right: 0;
    background-color: #1E2329;
    transition:.4s;
    transform: translateX(0);
    border: 2px solid #000;
  }


`;


interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const WorkerVideoRecordingFunctionalityTable:React.FC<TableProps> = ({ data }) => {

    // Step 1: Define the switchStates state
    const [switchStates, setSwitchStates] = useState(data.map(item => item.switch));

    // Step 2: Implement the handleSwitchChange function
    const handleSwitchChange = (index: number) => {
        // Toggle the switch state at the given index
        setSwitchStates(switchStates.map((state, i) => i === index?!state : state));
    };

    return (
        <BorderBlock>
            <GrayText>
                При добавлении чисел из Email end в конец почты при регистрации, аккаунту будут установлены указанные настройки. <br/> Например, если Email end - 123675, то вам необходимо регистрировать аккаунты с данным числом в конце почты: userok123765@gmail.com
            </GrayText>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader>Email end</TableHeader>
                    <TableHeader>Settings</TableHeader>
                    <TableHeader>Action</TableHeader>


                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.endings}</TableCell>
                        <TableCell>
                            <img src={item.image_path} alt={item.settings} style={{marginRight:"0.5 rem"}}/>
                            {item.settings}
                        </TableCell>
                        <TableCell>
                            <Switch id={`switch-${index}`} checked={switchStates[index]} onChange={() => handleSwitchChange(index)} />
                            <SwitchLabel htmlFor={`switch-${index}`}></SwitchLabel>
                        </TableCell>
                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default WorkerVideoRecordingFunctionalityTable;