// IndexButton.tsx
import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
    text: string;
}

const StyledButton = styled.button<{ status: 'completed' | 'failed' | 'pending' }>`
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 8rem;

  background-color: ${props => props.status === 'completed' ? '#174238' : props.status === 'failed' ? '#502530' : '#FFA500'}; // Оранжевый цвет для 'pending'
  color: ${props => props.status === 'completed' ? '#10BA7B' : props.status === 'failed' ? '#F6465D' : '#FFFFFF'}; // Белый цвет для 'pending'
  border: 1px solid ${props => props.status === 'completed' ? '#10BA7B' : props.status === 'failed' ? '#F6465D' : '#FFFFFF'};

`;

const IndexButton: React.FC<ButtonProps> = ({ text }) => {
    // Определяем статус кнопки на основе текста
    const status = text === 'Completed'? 'completed' : text === 'Pending'? 'pending' : 'failed';

    return (
        <StyledButton
            status={status}
        >
            {text}
        </StyledButton>
    );
};

export default IndexButton;
