import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerUserKYCRequestsTable from "../../styles/tables/forWorker/WorkerUserKYCRequestsTable";

const WorkerUserKYCRequests:React.FC = () => {
    const testData = [
        {
            id: 38,
            email: "toomaxprop2p@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Stavropol",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 39,
            email: "example1@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Moscow",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 40,
            email: "example2@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Saint Petersburg",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 41,
            email: "example3@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Novosibirsk",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 42,
            email: "example4@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Kazan",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 43,
            email: "example5@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Nizhny Novgorod",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 44,
            email: "example6@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Perm",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 45,
            email: "example7@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Khabarovsk",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        },
        {
            id: 46,
            email: "example8@gmail.com",
            action: "Go to the /profile/wallet",
            location: "RU, Russia, Vladivostok",
            imagePath: "/assets/images/forpages/adminpanel/ru 1.svg",
            platform: "Windows 10,\n" +
                "Chrome 12",
            ip: "178.34.150.156",
            date: "23 мин. назад"
        }
    ];


    return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>User KYC Requests</HeadBlock>
          <WorkerUserKYCRequestsTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerUserKYCRequests;