import React from "react";
import UserStakingTable from "../../../../styles/tables/forAdmin/forAdminUsers/UserStakingTable";

const UserStakingBlock = () => {
  const tableData = [
    {
      imagePath: "/assets/images/cryptoicons/Bitcoin Coin.svg",
      asset: "BTC",
      plan: "7 days",
      percents: 1.3,
      left: "2024/06/07 01:21:29",
      realTimeProfit: "0.03597095",
      invested: 0.1,
    },
  ];
  return (
    <>
      <UserStakingTable data={tableData} />
    </>
  );
};
export default UserStakingBlock;
