import React from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import BlockGreyText from "../../styles/blosks/BlockGreyText";
import {FURURES_TEXT} from "../../constants/constants";
import HeaderACS from "../../styles/headers/HeaderACS";

export const HeadBlock = styled.div`
  width: 75rem;
  height: 5rem;
  border-radius: 20px;
  background-color: #0E0E11;
  color: white;
  font-weight: 700;
  font-size: 32px;
  align-content: center;
  margin-left: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  margin-top: -5rem;
`;

export const FuturesButton = styled.button`
  background-color: #FCD535;
  color: #191B21;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  justify-self: center;
  align-self: center;
  width: 18rem;
  height: 3rem;
  border-radius: 4px;
`;


const FuturesAccount: React.FC = () => {
  return (
      <ContainerV style={{marginLeft:"-21rem"}}>
        <HeadBlock>Фьючерский аккаунт</HeadBlock>
        <img
            src="/assets/images/forpages/accountpage/alarm.svg"
            alt="alarm"
            style={{
              marginTop:"2rem",
              justifySelf:"center",
            }}

        />
        <HeaderACS style={{
            fontSize:"20px",
            marginTop:"1rem",

        }}>
            Открыть фьючерсный аккаунт
        </HeaderACS>
        <BlockGreyText style={{
            paddingRight:"17rem",
            paddingLeft:"17rem",
            whiteSpace: "pre-wrap",
            textAlign: "center",
            justifyContent: "center",
        }}>
            {FURURES_TEXT}
        </BlockGreyText>
        <FuturesButton>Открыть фьючерсный аккаунт</FuturesButton>
      </ContainerV>
  );
};
export default FuturesAccount;