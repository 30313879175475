//import React from 'react';
import styled from 'styled-components';

// interface ButtonProps {
//     text: string;
// }

export const UsersManageGreenButton = styled.button`
      padding: 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 7.75rem;
      //height: 2rem;
      text-align: center;
      vertical-align: center;
    
      background-color:  #174238 ; 
      color: #10BA7B; 
      border: 1px solid #10BA7B;
      
      &:hover{
        background-color:#008080;
      }

`;

export const UsersManageGoldButton = styled.button`
      padding: 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 7.75rem;
    
      background-color:  #524926 ; 
      color: #FCD535; 
      border: 1px solid #FCD535;
      
      &:hover{
        background-color:#615835;
      }

`;

// const UsersManageButton: React.FC<ButtonProps> = ({ text }) => {
//     // Определяем статус кнопки на основе текста
//
//     return (
//         <StyledButton
//         >
//             {text}
//         </StyledButton>
//     );
// };
//
// export default UsersManageButton;
