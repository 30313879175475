import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { setAccessToken, setRefreshToken } from "./slices/authSlice";
import {
  AddWorker,
  AuthResponse,
  CreateMessage,
  MessageListByChat,
  MessagePresetsList,
  P2PFakesCreate,
  P2PFakesList,
  ProfileData,
  SubjectList,
  SubjectListAllRecords,
  SubjectRetrieveById,
  SupportChatList,
  SupportChatPreviewList,
  TermsAndAmlKycPolicies,
  UpdateAllUserProfileRequest,
  UpdateProfileData,
  UpdateProfileResponse,
  UserInfoParams,
  UserListData,
  UserToDomainList,
} from "./types/apiRequestsTypes";

const BASE_API_URL = "https://back.sdfsdfsdfsdfsfsfsghsdgw.ru/api/v1/";

const baseQueryProfiles = fetchBaseQuery({
  // baseUrl: "http://83.136.30.248:3044/api/v1/profiles/",
  baseUrl: `${BASE_API_URL}profiles/`,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryDomains = fetchBaseQuery({
  // baseUrl: "http://83.136.30.248:3044/api/v1/other/",
  baseUrl: `${BASE_API_URL}other/`,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQuerySupport = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}support/`,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauthProfiles: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQueryProfiles(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const refreshResult = await baseQueryProfiles(
        {
          url: "token/refresh/",
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { body } = refreshResult.data as AuthResponse;
        const accessToken = body.access;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          api.dispatch(setAccessToken(accessToken));
          result = await baseQueryProfiles(args, api, extraOptions);
        } else {
          console.error(
            "Ошибка получения токенов: отсутствуют токены в ответе",
          );
        }
      }
    }
  }

  return result;
};

const baseQueryWithReauthDomains: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQueryDomains(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const refreshResult = await baseQueryProfiles(
        {
          url: "token/refresh/",
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { body } = refreshResult.data as AuthResponse;
        const accessToken = body.access;
        // const newRefreshToken = body.refresh;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          // localStorage.setItem("refreshToken", newRefreshToken);
          api.dispatch(setAccessToken(accessToken));
          // api.dispatch(setRefreshToken(newRefreshToken));
          result = await baseQueryDomains(args, api, extraOptions);
        } else {
          console.error(
            "Ошибка получения токенов: отсутствуют токены в ответе",
          );
        }
      }
    }
  }

  return result;
};

const baseQueryWithReauthSupport: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuerySupport(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const refreshResult = await baseQueryProfiles(
        {
          url: "token/refresh/",
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { body } = refreshResult.data as AuthResponse;
        const accessToken = body.access;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          api.dispatch(setAccessToken(accessToken));
          result = await baseQuerySupport(args, api, extraOptions);
        } else {
          console.error(
            "Ошибка получения токенов: отсутствуют токены в ответе",
          );
        }
      }
    }
  }

  return result;
};

export const profilesApi = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauthProfiles,
  endpoints: (builder) => ({
    getUserProfile: builder.query<ProfileData, void>({
      query: () => ({
        url: "user/retrieve_one/",
        method: "GET",
      }),
    }),
    getUserInfo: builder.query<UserInfoParams, void>({
      query: () => ({
        //url: "user/user_info/?domain=all",
        url: "user/user_info/",
        method: "GET",
      }),
    }),
    getUserListAll: builder.query<UserListData, void>({
      query: () => ({
        url: "user/list/?domain=all",
        method: "GET",
      }),
    }),
    getUserListOne: builder.query<UserListData, void>({
      query: () => ({
        url: "user/list/?domain=one",
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation<
      UpdateProfileResponse,
      Partial<UpdateProfileData>
    >({
      query: (profileData) => ({
        url: "user/update_one/",
        method: "PATCH",
        body: profileData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateUserProfileAll: builder.mutation<any, UpdateAllUserProfileRequest>({
      query: (userData) => ({
        url: `user/update_all/${userData.id}/`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: userData,
      }),
    }),
    refreshToken: builder.mutation<any, { refresh: string }>({
      query: ({ refresh }) => ({
        url: "token/refresh/",
        method: "POST",
        body: { refresh },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;
            if (accessToken && refreshToken) {
              dispatch(setAccessToken(accessToken));
              dispatch(setRefreshToken(refreshToken));
            }
          }
        } catch (error) {
          console.error("Ошибка при обновлении токенов:", error);
        }
      },
    }),
    registerUser: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "user/registration/",
        method: "POST",
        body: formData,
      }),
    }),
    confirmUser: builder.mutation<any, { token: string }>({
      query: ({ token }) => ({
        url: "user/confirm/",
        method: "POST",
        body: { token },
      }),
    }),
    getTokens: builder.mutation<
      AuthResponse,
      { email: string; password: string }
    >({
      query: (credentials) => ({
        url: "token/",
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const accessToken = data.body.access;
            const refreshToken = data.body.refresh;
            if (accessToken && refreshToken) {
              dispatch(setAccessToken(accessToken));
              dispatch(setRefreshToken(refreshToken));
            } else {
              console.error(
                "Ошибка получения токенов: отсутствуют токены в ответе",
              );
            }
          }
        } catch (error) {
          console.error("Ошибка при получении токенов:", error);
        }
      },
    }),
    listPermissions: builder.query<
      any,
      { search?: string; limit?: number; offset?: number }
    >({
      query: ({ search, limit, offset }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (limit) params.append("limit", limit.toString());
        if (offset) params.append("offset", offset.toString());

        return {
          url: `permission/list/?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    createPermissionAccess: builder.mutation<
      any,
      { role: string; permission: string[] }
    >({
      query: ({ role, permission }) => ({
        url: "permission_access/create/",
        method: "POST",
        body: { role, permission },
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    deletePermissionAccess: builder.mutation<void, string>({
      query: (id) => ({
        url: `permission_access/destroy/${id}/`,
        method: "DELETE",
      }),
    }),
    listPermissionAccess: builder.query({
      query: ({ search = "", limit = 10, offset = 0 }) => ({
        url: "permission_access/list/",
        method: "GET",
        params: {
          search,
          limit,
          offset,
        },
      }),
    }),
    retrieveUserById: builder.query({
      query: ({ id, search = "", limit = 10, offset = 0 }) => ({
        url: `user/retrieve_all/${id}/`,
        method: "GET",
        params: {
          search,
          limit,
          offset,
        },
      }),
    }),
    getUserToDomainList: builder.query<UserToDomainList, void>({
      query: () => ({
        url: "user_to_domain/list/",
        method: "GET",
      }),
    }),
    addWorkerToDomain: builder.mutation<any, AddWorker>({
      query: (worker) => ({
        url: "user/update_user_role/",
        method: "PATCH",
        body: worker,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const domainsApi = createApi({
  reducerPath: "domainsApi",
  baseQuery: baseQueryWithReauthDomains,
  endpoints: (builder) => ({
    createDomain: builder.mutation<any, { email: string; url_domain: string }>({
      query: (domainData) => ({
        url: "domain/create/",
        method: "POST",
        body: domainData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    deleteDomain: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `domain/destroy/${id}/`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    listDomains: builder.query<
      any,
      { search?: string; limit?: number; offset?: number }
    >({
      query: ({ search, limit, offset }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (limit) params.append("limit", limit.toString());
        if (offset) params.append("offset", offset.toString());

        return {
          url: `domain/list/?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    retrieveDomain: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `domain/retrieve/${id}/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateDomain: builder.mutation<
      any,
      { id: string; status: string; url_domain: string }
    >({
      query: ({ id, status, url_domain }) => ({
        url: `domain/update/${id}/`,
        method: "PUT",
        body: { status, url_domain },
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    getTermsAndAmlKycPoliciesRetrieveOne: builder.query<
      TermsAndAmlKycPolicies,
      void
    >({
      query: () => ({
        url: "terms_and_aml_kyc_policies/retrieve_one/",
        method: "GET",
      }),
    }),
    createTermsAndAmlKycPolicies: builder.mutation<
      TermsAndAmlKycPolicies,
      void
    >({
      query: () => ({
        url: "terms_and_aml_kyc_policies/create/",
        method: "POST",
      }),
    }),
    updateTermsAndAmlKycPolicies: builder.mutation<
      TermsAndAmlKycPolicies,
      { terms?: string; aml_kyc_policy?: string }
    >({
      query: (updateData) => ({
        url: "terms_and_aml_kyc_policies/update/",
        method: "PATCH",
        body: updateData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    getP2PFakeList: builder.query<P2PFakesList, void>({
      query: () => ({
        url: "p2p_fakes/list/",
        method: "GET",
      }),
    }),

    createP2PFake: builder.mutation<any, P2PFakesCreate>({
      query: (createData) => ({
        url: "p2p_fakes/create/",
        method: "POST",
        body: createData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    deleteP2PFake: builder.mutation<any, string>({
      query: (id) => ({
        url: `p2p_fakes/delete/${id}/`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    // Другие endpoints для доменов
  }),
});

export const supportApi = createApi({
  reducerPath: "support",
  baseQuery: baseQueryWithReauthSupport,
  endpoints: (builder) => ({
    getChatList: builder.query<SupportChatList, void>({
      query: () => ({
        url: "chat/list/",
        method: "GET",
      }),
    }),
    getChatPreviewList: builder.query<SupportChatPreviewList, void>({
      query: () => ({
        url: "chat/preview_list/",
        method: "GET",
      }),
    }),
    getMessageListByChat: builder.query<MessageListByChat, string>({
      query: (id) => ({
        url: `message/list_by_chat/${id}`,
        method: "GET",
      }),
    }),
    getMessagePresetsList: builder.query<MessagePresetsList, void>({
      query: () => ({
        url: "message_presets/list/",
        method: "GET",
      }),
    }),
    getSubjectList: builder.query<SubjectList, void>({
      query: () => ({
        url: "subject/list/",
        method: "GET",
      }),
    }),
    getSubjectListAllRecords: builder.query<SubjectListAllRecords, void>({
      query: () => ({
        url: "subject/list_all_records/",
        method: "GET",
      }),
    }),
    getSubjectRetrieveById: builder.query<SubjectRetrieveById, string>({
      query: (id) => ({
        url: `subject/retrieve/${id}`,
        method: "GET",
      }),
    }),
    createMessagePreset: builder.mutation<
      any,
      { title: string; message: string; domain?: string }
    >({
      query: (messagePresetData) => ({
        url: "message_presets/create/",
        method: "POST",
        body: messagePresetData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    deleteMessagePreset: builder.mutation<any, string>({
      query: (id) => ({
        url: `message_presets/delete/${id}/`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    createMessage: builder.mutation<any, CreateMessage>({
      query: () => ({
        url: "",
        method: "",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useGetUserInfoQuery,
  useRefreshTokenMutation,
  useUpdateUserProfileMutation,
  useRegisterUserMutation,
  useConfirmUserMutation,
  useGetTokensMutation,
  useListPermissionsQuery,
  useCreatePermissionAccessMutation,
  useDeletePermissionAccessMutation,
  useListPermissionAccessQuery,
  useRetrieveUserByIdQuery,
  useGetUserListAllQuery,
  useGetUserListOneQuery,
  useUpdateUserProfileAllMutation,
  useGetUserToDomainListQuery,
  useAddWorkerToDomainMutation,
} = profilesApi;

export const {
  useCreateDomainMutation,
  useDeleteDomainMutation,
  useListDomainsQuery,
  useRetrieveDomainQuery,
  useUpdateDomainMutation,
  useGetTermsAndAmlKycPoliciesRetrieveOneQuery,
  useCreateTermsAndAmlKycPoliciesMutation,
  useUpdateTermsAndAmlKycPoliciesMutation,
  useGetP2PFakeListQuery,
  useCreateP2PFakeMutation,
  useDeleteP2PFakeMutation,
} = domainsApi;

export const {
  useGetChatListQuery,
  useGetChatPreviewListQuery,
  useGetMessageListByChatQuery,
  useGetMessagePresetsListQuery,
  useGetSubjectListQuery,
  useGetSubjectListAllRecordsQuery,
  useGetSubjectRetrieveByIdQuery,
  useCreateMessagePresetMutation,
  useDeleteMessagePresetMutation,
} = supportApi;
