import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "./WorkerBinding";
import WorkerVideoRecordingFunctionalityTable  from "../../styles/tables/forWorker/WorkerVideoRecordingFunctionalityTable";
import AccountRegistrationSettings from "./AccountRegistrationSettings";





const WorkerVideoRecordingFunctionality:React.FC = () => {
    const testData = [
        {
            endings: "@yandex.ru",
            image_path: "/assets/images/forpages/accountpage/GreenETH.svg",
            settings: "ETH",
            switch: false // Assuming the switch is off by default
        },
        {
            endings: "@google.com",
            image_path: "/assets/images/forpages/accountpage/GreenETH.svg",
            settings: "BTC",
            switch: true // Assuming the switch is off by default
        },
        {
            endings: "@hotmail.com",
            image_path: "/assets/images/forpages/accountpage/GreenETH.svg",
            settings: "USD",
            switch: false // Assuming the switch is off by default
        },
        {
            endings: "@outlook.com",
            image_path: "/assets/images/forpages/accountpage/GreenETH.svg",
            settings: "EUR",
            switch: true // Assuming the switch is off by default
        }
    ];




    return  (
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Video recording functionality</HeadBlock>
          <AccountRegistrationSettings/>
          <WorkerVideoRecordingFunctionalityTable data={testData}/>
      </ContainerV>
      );
};
export default WorkerVideoRecordingFunctionality;