import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomBlockContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
`;

const CustomButton = styled(Link)<{ isActive: boolean }>`
  position: relative;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#848E9C")};
  text-decoration: none;

  &:hover {
    //color: ${({ isActive }) => (isActive ? "#848E9C" : "#EFB608")};
    color: #efb608;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ isActive }) =>
      isActive ? "#EFB608" : "transparent"};
  }
`;

interface UserTransactionNavProps {
  initialActiveButton: number;
  style?: React.CSSProperties; // Добавляем пропс style
}

const UserTransactionNav: React.FC<UserTransactionNavProps> = ({
  initialActiveButton,
}) => {
  // По умолчанию activeButton равна 1
  const [activeButton, setActiveButton] = useState(initialActiveButton);

  const handleButtonClick = (buttonNumber: number) => {
    setActiveButton(buttonNumber);
  };

  return (
    <CustomBlockContainer>
      <CustomButton
        to="/user-profile/transactions/transactions"
        isActive={activeButton === 1}
        onClick={() => handleButtonClick(1)}
      >
        Transactions
      </CustomButton>
      <CustomButton
        to="/user-profile/transactions/staking"
        isActive={activeButton === 2}
        onClick={() => handleButtonClick(2)}
      >
        Staking
      </CustomButton>
    </CustomBlockContainer>
  );
};

export default UserTransactionNav;
