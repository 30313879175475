import React, { useState } from "react";
import styled from "styled-components";
import ContainerH from "../../styles/containers/ContainerH";
import { useCreateMessagePresetMutation } from "../../redux/apiService";

const BorderBlock = styled.div`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const TitleMiddle = styled.div`
  color: #848e9c;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 14px;
  text-align: left;
`;
const TitleLittle = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
`;

const CustomInput = styled.input`
  background-color: #191b21;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 7rem;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;

  &:hover {
    background-color: #ffff66;
  }
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

interface SupportMessagePresetsProps {
  refetch: () => void;
}

const SupportMessagePresets: React.FC<SupportMessagePresetsProps> = ({
  refetch,
}) => {
  // Step 1: Define the switchStates state
  const [switchState, setSwitchState] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [domain, setDomain] = useState("");

  // Step 2: Implement the handleSwitchChange function
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the switch state at the given index
    setSwitchState(event.target.checked);
    console.log("Switch state changed to:", event.target.checked);
  };

  const [createMessagePreset] = useCreateMessagePresetMutation(); // Используем хук

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await createMessagePreset({
        title: title,
        message: message,
        domain: domain,
      }).unwrap();
      console.log("MessagePreset created successfully:", response);
      // Очистить форму или обновить состояние после успешного создания пресета
      setTitle("");
      setMessage("");
      setDomain("");
      refetch();
    } catch (error) {
      console.error("Failed to create MessagePreset:", error);
    }
  };

  return (
    <BorderBlock style={{ paddingBottom: "2rem" }}>
      <ContainerH style={{ margin: "0", width: "100%" }}>
        <div>
          <TitleMiddle>
            Стандартные шаблоны ответов пользователям в саппорте.
          </TitleMiddle>
          <TitleMiddle style={{ margin: "0" }}>
            <strong>Включение/Отключение</strong> отображения шаблонов сообщений
            для ответа в саппорте.
          </TitleMiddle>
          <TitleMiddle>
            Если включено, то в саппорте будут отображаться шаблоны для ответов
            на сообщения пользователей.
          </TitleMiddle>
        </div>
        <div>
          <Switch
            id="switch"
            checked={switchState}
            onChange={handleSwitchChange}
          />
          <SwitchLabel htmlFor="switch"></SwitchLabel>
        </div>
      </ContainerH>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <BorderBlock>
          <TitleLittle>Title</TitleLittle>
          <CustomInput
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Приветственное сообщение"
          />
        </BorderBlock>

        <BorderBlock>
          <TitleLittle>Message</TitleLittle>
          <CustomInput
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Hello, how i can help you?"
          />
        </BorderBlock>
        <BorderBlock>
          <TitleLittle>Domain*</TitleLittle>
          <CustomInput
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Worker's domain"
          />
        </BorderBlock>

        <TitleMiddle>
          {"{domain_url}"} - будет заменяться на домен сайта пользователя,
          которому отправляется сообщение.
        </TitleMiddle>
        <SubmitButton type="submit">Add</SubmitButton>
      </form>
    </BorderBlock>
  );
};
export default SupportMessagePresets;
