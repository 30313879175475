import React from "react";
import UserProfileNav from "../../../styles/navs/UserProfileNav";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerH from "../../../styles/containers/ContainerH";
import styled from "styled-components";
import ContainerV from "../../../styles/containers/ContainerV";
import HeaderALTH from "../../../styles/headers/HeaderALTH";
import { GrayText } from "../../forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";
import SelectWithSearch from "../../../styles/functional/dropdowns/SelectWithSearch";
import CustomInput2 from "../../../styles/functional/inputs/CustomInput2";

const CustomInput = styled.textarea`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  color: white;
  padding: 1rem 0.5rem 0.5rem 0.5rem; // Увеличено значение padding-left до 0.5rem
  margin-bottom: 0.5rem;
  width: 100%;
  height: 15rem;
`;

const Title = styled.div`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
  font-size: 20px;
  text-align: left;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;
  align-self: flex-end;
  justify-self: flex-end;
  width: 80%;

  &:hover {
    background-color: #ffff66;
  }
`;

const BonusPopUp: React.FC = () => {
  const cryptoOptions = [
    { value: "Bitcoin (BTC)" },
    { value: "Ethereum (ETH)" },
    { value: "Tether (USDT)" },
    { value: "Binance Coin (BNB)" },
    { value: "Cardano (ADA)" },
    { value: "Solana (SOL)" },
    { value: "XRP (XRP)" },
    { value: "Dogecoin (DOGE)" },
    { value: "Polkadot (DOT)" },
    { value: "Shiba Inu (SHIB)" },
    { value: "USD Coin (USDC)" },
    { value: "Chainlink (LINK)" },
    { value: "Litecoin (LTC)" },
    { value: "Polygon (MATIC)" },
    { value: "Avalanche (AVAX)" },
    { value: "Uniswap (UNI)" },
    { value: "Terra (LUNA)" },
    { value: "Cosmos (ATOM)" },
    { value: "VeChain (VET)" },
    { value: "FTX Token (FTT)" },
  ];

  return (
    <>
      <UserProfileNav initialActiveButton={8} />
      <BorderBlock>
        <HeaderALTH>Send bonus pop-up</HeaderALTH>
        <ContainerV style={{ margin: "0", marginTop: "2%" }}>
          <GrayText>Отправьте бонус в выбранной монете.</GrayText>
          <ContainerV style={{ gap: "0" }}>
            <Title>Coin</Title>
            <SelectWithSearch options={cryptoOptions} />
          </ContainerV>
          <ContainerV style={{ gap: "0" }}>
            <Title>Amount</Title>
            <CustomInput2
              style={{ alignSelf: "flex-start" }}
              defaultValue={0}
            />
          </ContainerV>
          <GrayText>
            Выбранным пользователям на баланс будет начислена указанная сумма в
            выбранной монете, а так же создастся транзакция, помеченная как
            "Bonus".
          </GrayText>
        </ContainerV>
        <ContainerH style={{ margin: "0", marginTop: "3%" }}>
          <div
            style={{
              width: "50%",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Title>Bonus message</Title>
            <CustomInput defaultValue="Type a message" />
          </div>
          <ContainerV style={{ width: "50%" }}>
            <SubmitButton>Send bonus</SubmitButton>
            <SubmitButton>Send bonus to all users</SubmitButton>
          </ContainerV>
        </ContainerH>
      </BorderBlock>
    </>
  );
};
export default BonusPopUp;
