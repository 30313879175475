import React from "react";
import styled from "styled-components";
import {
  GreenButton,
  RedButton,
} from "../../functional/buttons/RedGreenButtons";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  max-width: 100%;
  min-width: 100%;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 5fr repeat(2, 1fr);
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  word-wrap: break-word;
`;

const WhiteText = styled.text`
  font-weight: 700;
  font-size: 16px;
  color: white;
`;

const GrayText = styled.text`
  font-weight: 500;
  font-size: 16px;
  color: #848e9c;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const WorkerUtilityTable: React.FC<TableProps> = ({ data }) => {
  return (
    <BorderBlock>
      <table>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <WhiteText>{item.title}</WhiteText>
                <br />
                <GrayText>{item.describe}</GrayText>
              </TableCell>
              <TableCell>
                <GreenButton>Enable ALL</GreenButton>
              </TableCell>
              <TableCell>
                <RedButton>Disable ALL</RedButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
    </BorderBlock>
  );
};
export default WorkerUtilityTable;
