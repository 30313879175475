import styled, {CSSObject} from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

interface GreyLinkContainerProps {
    customStyles?: CSSObject; // опциональные стили
}

const GreyLinkContainer = styled.div<GreyLinkContainerProps>`
  display: flex;
  align-items: center;
  align-content: center;
`;

const GreyLinkText = styled.p`
  color: #848E9C;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 3px; 
  text-decoration: none;
  margin-bottom: -1px;
  
  
`;
const GreyLink = styled(Link)`
  color: #848E9C;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;  
`;

const ArrowRightImg = styled.img`
  align-self: center;
  margin-top: 0.1rem;
  //margin-bottom: 14px;
`;

interface GreyLinkArrowRProps {
    to: string;
    children: React.ReactNode;
    customStyles?: CSSObject;
}

const GreyLinkArrowR: React.FC<GreyLinkArrowRProps> = ({ to, children, customStyles }) => {
    return (
        <GreyLink to={to}>
            <GreyLinkContainer customStyles={customStyles}>
                <GreyLinkText>{children}</GreyLinkText>
                <ArrowRightImg src="/assets/images/icons/arrow-right.svg" alt="Arrow Right" />
            </GreyLinkContainer>
        </GreyLink>
    );
};

export default GreyLinkArrowR;
