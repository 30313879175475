import React from "react";
import ContainerH from "../../../styles/containers/ContainerH";
import ContainerV from "../../../styles/containers/ContainerV";
import BlockH from "../../../styles/blosks/BlockH";
import {SubmitButton} from "./Overview";
import CustomLinkGrey from "../../../styles/functional/links/CustomLinkGrey";

interface UserProfileHeadBlockInterface {
    username?: string;
    avatar_path?: string;
    user_mail_address?: string;
    user_location?: string;
    flag_path?: string;
    last_activity?: string;
    total_balance?: string;
    deposits?: string;
    auth_count?: number;
    today_logs?: number;
    uses_a_domain?: string;

}

const UserProfileHeadBlock:React.FC<UserProfileHeadBlockInterface> = (
    {
        username,
        avatar_path,
        user_mail_address,
        user_location,
        flag_path,
        last_activity,
        total_balance,
        deposits,
        auth_count,
        today_logs,
        uses_a_domain
    }
) => {
  return <>
      <ContainerH style={{maxWidth:"100%", border:"1px solid #293039",  borderRadius:"1rem", padding:"1rem", margin:"0"}}>
          <img src={avatar_path} alt="UserAvatar" style={{width:"7rem"}}/>
          <ContainerV style={{maxWidth:"100%"}}>
              <p style={{
                  color:"white",
                  fontWeight:"500",
                  fontSize:"14px",
                  alignSelf:"flex-start"
              }}>
                  {username}
              </p>
              <ContainerH style={{maxWidth:"100%", margin:"0"}}>
                  <BlockH>
                      <img src={flag_path} alt="test"/>
                      {user_mail_address}
                  </BlockH>
                  <BlockH>
                      <img src={flag_path} alt="test"/>
                      {user_location}
                      <img src={flag_path} alt="test"/>
                  </BlockH>
                  <BlockH>
                      Последняя активность:&nbsp;
                      {last_activity}
                  </BlockH>
                  <SubmitButton style={{whiteSpace:"nowrap", width:"15rem", margin:"0"}}>
                      Send Support Message
                  </SubmitButton>
              </ContainerH>
              <ContainerH style={{maxWidth:"100%", margin:"0"}}>
                  <BlockH>
                      Total balance:&nbsp;{total_balance}
                  </BlockH>
                  <BlockH>
                      Deposits:&nbsp;{deposits}
                  </BlockH>
                  <BlockH>
                      Auth count:&nbsp;{auth_count}
                  </BlockH>
                  <BlockH>
                      Today logs:&nbsp;{today_logs}
                  </BlockH>
                  <BlockH>
                      Uses a domain:&nbsp;  <CustomLinkGrey to="https://help-ru.tilda.cc/forms/google-sheets"> {uses_a_domain}</CustomLinkGrey>
                  </BlockH>
              </ContainerH>
          </ContainerV>
      </ContainerH>
  </>;
};
export default UserProfileHeadBlock;