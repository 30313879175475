import React, { useState } from "react";
import styled from "styled-components";
import BlockGreyText from "../blosks/BlockGreyText";



const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem; 
`;

const TermsAndConditions = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <BlockGreyText>
            <CheckboxContainer>
                Создавая учетную запись, я соглашаюсь с Условиями использования Wavecurrency и Политикой конфиденциальности.
                <CheckboxInput type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />

            </CheckboxContainer>
        </BlockGreyText>
    );
};

export default TermsAndConditions;
