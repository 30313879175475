import React, { useState } from "react";
import styled from "styled-components";
import HeaderALS from "../headers/HeaderALS";
import ContainerH from "../containers/ContainerH";
import ContainerV from "../containers/ContainerV";
import HeaderFAL from "../headers/HeaderFAL";

const AIAccordionContainer = styled.div`
  border: 1px solid #1E2329;
  border-radius: 20px;
  margin-bottom: 10px;
  width: 100% ;
  padding: 1rem;
`;

const AccordionFooter = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ isOpen }) => (isOpen ? "20px 20px 0 0" : "20px")};
`;

const AccordionContentExpanded = styled.div`
  padding: 15px;
  background-color: transparent;
  text-align: left;
  color: #848E9C;
  max-width: 95%;
`;

const AccordionContentCollapsed = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: transparent;
  text-align: left;
  color: #848E9C;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.img`
  margin-left: 1rem;
`;

interface AIAccordionProps {
    title: string;
    children?: React.ReactNode;
}

const AIAccordion: React.FC<AIAccordionProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AIAccordionContainer>
            <ContainerH style={{margin:"0"}}>
            <HeaderALS style={{ color: "#ffffff", margin: "1rem", fontSize: "20px" }}>{title}</HeaderALS>
            <ContainerH
                style={{
                    margin:"0",
                    alignSelf:"flex-start",


                }}>
                <ContainerV
                    style={{
                        margin:"1rem",
                        padding:"1rem",
                        borderLeft:"1px solid #293039",
                    }}>
                    <HeaderALS style={{fontSize:"12px"}}>Объём торгов</HeaderALS>
                    <HeaderFAL style={{fontSize:"20px"}}>$42521.18</HeaderFAL>
                    <HeaderALS
                        style={{
                            fontSize:"16px",
                            color:"#10BA7B",
                        }}>
                        +1.23%
                    </HeaderALS>
                </ContainerV>
                <img src="/assets/images/forpages/marketpage/plug-green-grath.svg" alt="plug-green-grath"/>
                <ContainerV
                    style={{
                        margin:"1rem",
                        padding:"1rem",
                        borderLeft:"1px solid #293039",
                    }}>
                    <HeaderALS style={{fontSize:"12px"}}>Объём торгов</HeaderALS>
                    <HeaderFAL style={{fontSize:"20px"}}>$42521.18</HeaderFAL>
                    <HeaderALS
                        style={{
                            fontSize:"16px",
                            color:"#10BA7B",
                        }}>
                        +1.23%
                    </HeaderALS>
                </ContainerV>
                <img src="/assets/images/forpages/marketpage/plug-green-grath.svg" alt="plug-green-grath"/>
            </ContainerH>
            </ContainerH>
            {isOpen ? (
                <AccordionContentExpanded>{children}</AccordionContentExpanded>
            ) : (
                <AccordionContentCollapsed>
                    <ContainerH
                        style={{
                            margin:"0",
                            gap:"2rem",
                    }}>
                    <span>{typeof children === 'string' ? `${children.slice(0, 130)}...` : children}</span>

                    </ContainerH>
                </AccordionContentCollapsed>

            )}
            <AccordionFooter onClick={toggleAccordion} isOpen={isOpen}>
                <ToggleButton onClick={toggleAccordion}>
                    {isOpen ? (
                        <>
                            <span>Свернуть</span>
                            <ImgContainer src="/assets/images/icons/arrow-up.svg" alt="up" />
                        </>
                    ) : (
                        <>
                            <span>Показать</span>
                            <ImgContainer src="/assets/images/icons/arrow.svg" alt="down" />
                        </>
                    )}
                </ToggleButton>
            </AccordionFooter>

        </AIAccordionContainer>
    );
};

export default AIAccordion;
