import React from "react";
import styled from "styled-components";

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label`
  margin-left: 1rem;
  color: white;
`;

interface CustomRadioButtonProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  label,
  isSelected,
  onClick,
}) => {
  return (
    <RadioButtonContainer onClick={onClick}>
      {isSelected ? (
        <img
          src="/assets/images/forpages/accountpage/Radio_Active.svg"
          alt="Selected"
          width={30}
          height={30}
        />
      ) : (
        <img
          src="/assets/images/forpages/accountpage/Radio_Passive.svg"
          alt="Not Selected"
          width={30}
          height={30}
        />
      )}
      <Label>{label}</Label>
    </RadioButtonContainer>
  );
};

export default CustomRadioButton;
