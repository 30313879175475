import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import HeaderALTH from "../../styles/headers/HeaderALTH";
import GreyLinkArrowR from "../../styles/functional/links/GreyLinkArrowR";
import AccountPageNav from "../../styles/navs/AccountPageNav";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderGreyCenter from "../../styles/headers/HeaderGreyCenter";
import AccountSubNav from "../../styles/navs/AccountSubNav";
import {Link} from "react-router-dom";

const BorderBlock = styled.div`
  border: 1px solid #293039;
  border-radius: 20px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;

const ButtonOption =styled(Link)`
  background-color: #293039;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px;
  max-width: 5rem;
  //margin-right: 1rem;
  &:hover{
    color: white;
    //font-weight: 700;
    background-color: transparent ;
  }
  
  
  `;
const AccountMainFavourites = () => {

    const names = ['Монета', 'Стоимость монеты', 'Изменение за 24ч', 'Торговля',];


  return (
      <BorderBlock>
          <ContainerH>
              <HeaderALTH style={{fontSize:"20px"}}> Рынки</HeaderALTH>
              <GreyLinkArrowR to={"/"}  customStyles={{color:"#fff"}}>Подробнее</GreyLinkArrowR>
          </ContainerH>
          <AccountPageNav initialActiveButton={4}/>
          <AccountSubNav names={names}/>
          <ButtonOption to="/account/main/actives">
              Слот(0)
          </ButtonOption>
          <ContainerV
              style={{
                  gap: "2rem",
                  marginTop:" 5rem",
                  marginBottom:"3rem",
          }}>
              <img src="/assets/images/forpages/marketpage/not-found-data-dark 1.svg" alt=""/>
              <HeaderGreyCenter>Нет записей</HeaderGreyCenter>
          </ContainerV>
      </BorderBlock>
  );
};
export default AccountMainFavourites;