import React from "react";
import ContainerV from "../../../../styles/containers/ContainerV";
import ContainerH from "../../../../styles/containers/ContainerH";
import HeaderFAL from "../../../../styles/headers/HeaderFAL";
import UserBalanceNav from "../../../../styles/navs/UserBalanceNav";
import { BorderBlock } from "../../../forAccountPage/Deposits";
import SelectWithSearch from "../../../../styles/functional/dropdowns/SelectWithSearch";
import styled from "styled-components";
import Block from "../../../../styles/blosks/Block";
import CustomInput2 from "../../../../styles/functional/inputs/CustomInput2";
import { GrayText } from "../../../forWorkerPage/forWorkerMainSettings/EightBlocksAtTop";
import ButtonST from "../../../../styles/functional/buttons/ButtonST";

const Title = styled.div`
  color: white;
  margin-bottom: 10px;
  align-self: flex-start;
  font-size: 20px;
`;

const UserSendBlock = () => {
  const cryptoOptions = [
    { value: "Bitcoin (BTC)" },
    { value: "Ethereum (ETH)" },
    { value: "Tether (USDT)" },
    { value: "Binance Coin (BNB)" },
    { value: "Cardano (ADA)" },
    { value: "Solana (SOL)" },
    { value: "XRP (XRP)" },
    { value: "Dogecoin (DOGE)" },
    { value: "Polkadot (DOT)" },
    { value: "Shiba Inu (SHIB)" },
    { value: "USD Coin (USDC)" },
    { value: "Chainlink (LINK)" },
    { value: "Litecoin (LTC)" },
    { value: "Polygon (MATIC)" },
    { value: "Avalanche (AVAX)" },
    { value: "Uniswap (UNI)" },
    { value: "Terra (LUNA)" },
    { value: "Cosmos (ATOM)" },
    { value: "VeChain (VET)" },
    { value: "FTX Token (FTT)" },
  ];

  const enrollmentMethods = [
    { value: "Full-time Enrollment" },
    { value: "Part-time Enrollment" },
    { value: "Online Enrollment" },
    { value: "Dual Enrollment" },
    { value: "Transfer Enrollment" },
    { value: "Non-degree Enrollment" },
    { value: "Graduate Enrollment" },
    { value: "International Enrollment" },
    { value: "Auditing Enrollment" },
  ];

  return (
    <BorderBlock>
      <ContainerV>
        <ContainerH
          style={{
            margin: "0",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <HeaderFAL style={{ fontSize: "32px" }}>Balance</HeaderFAL>
          <UserBalanceNav initialActiveButton={2} />
        </ContainerH>
        {/*<form>*/}
        <ContainerH style={{ margin: "0", minWidth: "100%" }}>
          <ContainerV
            style={{
              width: "100%",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Block
              style={{
                textAlign: "left",
              }}
            >
              <Title>Currency</Title>
              <SelectWithSearch options={cryptoOptions} />
            </Block>
            <Block
              style={{
                textAlign: "left",
              }}
            >
              <Title>Amount</Title>
              <CustomInput2 placeholder="0.5" />
            </Block>
            <Block
              style={{
                textAlign: "left",
              }}
            >
              <Title>From/To Address (Email / ID / Address)</Title>
              <CustomInput2 placeholder="Form address*" />
            </Block>
            <GrayText style={{ marginTop: "1rem" }}>
              <strong>Необязательный</strong> параметр.
              <br />
              Адрес получателя / отправителя, который будет отображаться в
              истории транзакций / трансферах.
            </GrayText>
          </ContainerV>
          <ContainerV
            style={{
              width: "100%",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Block
              style={{
                textAlign: "left",
              }}
            >
              <Title>Method of enrollment</Title>
              <SelectWithSearch options={enrollmentMethods} />
            </Block>
            <Block
              style={{
                textAlign: "left",
              }}
            >
              <Title>Date</Title>
              <CustomInput2 value="2023/08/05 20:15:00" />
            </Block>
          </ContainerV>
        </ContainerH>
        <ButtonST
          style={{
            alignSelf: "flex-end",
            borderRadius: "20px",
            marginTop: "1rem",
          }}
        >
          Create Transaction
        </ButtonST>
        {/*</form>*/}
      </ContainerV>
    </BorderBlock>
  );
};
export default UserSendBlock;
