import styled from "styled-components";

const BlockText = styled.div`
    display: flex;
    flex: 1;
    color: #fff;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    text-align: start;
    `;
export default BlockText;