import React from "react";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";
import TenBlocksAtTop from "./forAdminDefaultSettings/TenBlocksAtTop";
import ContainerV from "../../styles/containers/ContainerV";
import ErrorMessages from "./forAdminDefaultSettings/ErrorMessages";

const AdminDefaultSettings:React.FC = () => {

  return <>
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Default settings</HeadBlock>
          <TenBlocksAtTop/>
          <ErrorMessages/>
      </ContainerV>
  </>;
};
export default AdminDefaultSettings;