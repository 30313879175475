import React, { useState } from "react";
import styled from "styled-components";

const ButtonCarousel = styled.button`
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #848E9C;
  background-color: transparent;

  `;

interface ButtonCarouselProps {
    name: string;
}

const ImageChangingButton: React.FC<ButtonCarouselProps> = ({name}) => {
    const [currentImage, setCurrentImage] = useState(1);
    const maxImages = 3; // Общее количество изображений

    const handleButtonClick = () => {
        // Увеличиваем номер текущего изображения по кругу
        setCurrentImage((prevImage) => (prevImage % maxImages) + 1);
    };

    return (
        <div>
            <ButtonCarousel onClick={handleButtonClick}>{name}</ButtonCarousel>
            <img
                src={`/assets/images/icons/menu arrows/${currentImage}.svg`}
                alt={`Image ${currentImage}`}
            />
        </div>
    );
};

export default ImageChangingButton;
