import React, { useState } from "react";
import styled from "styled-components";

interface StyledButtonProps {
    isActive: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 10px;
  background-color: ${({ isActive }) => (isActive ? "#293039" : "#1E2329")};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;

  margin: 1rem 0.25rem 1rem 0.25rem;
`;

const TextContainer = styled.div`
  margin-left: 10px;
  white-space: nowrap;
  color: #10BA7B;
`;

interface ButtonStatProps{
    period: string;
    value: string;
}

const ButtonStat:React.FC<ButtonStatProps> = ({period, value}) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive((prev) => !prev);
    };

    return (
        <StyledButton isActive={isActive} onClick={handleClick}>
            {period}
            <TextContainer>{isActive && value}</TextContainer>
        </StyledButton>
    );
};

export default ButtonStat;
