import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            console.log('setAccessToken action:', action.payload);
            state.accessToken = action.payload;
        },
        setRefreshToken: (state, action: PayloadAction<string>) => {
            console.log('setRefreshToken action:', action.payload);
            state.refreshToken = action.payload;
        },
        clearTokens: (state) => {
            console.log('clearTokens action');
            state.accessToken = "";
            localStorage.removeItem("accessToken")
            state.refreshToken = "";
            localStorage.removeItem("refreshToken")

        },
    },
});

export const { setAccessToken, setRefreshToken, clearTokens } = authSlice.actions;

export default authSlice.reducer;
