import React from "react";
import UserProfileNav from "../../../../styles/navs/UserProfileNav";
import { Outlet } from "react-router-dom";

const UserTransaction = () => {
  return (
    <>
      <UserProfileNav initialActiveButton={4} />
      <Outlet />
    </>
  );
};
export default UserTransaction;
