import React, { useState } from "react";
import styled from "styled-components";
import ContainerV from "../../../styles/containers/ContainerV";
import HeaderFAL from "../../../styles/headers/HeaderFAL";
import ContainerH from "../../../styles/containers/ContainerH";
import { GrayText } from "../forWorkerMainSettings/EightBlocksAtTop";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  width: 646px;
  height: 856px;
  background-color: #1e2329;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #848e9c;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
`;

const InputField = styled.input`
  width: 100%;
  min-width: 100%;
  padding: 10px;
  border-radius: 12px;
  color: white;
  background-color: #191b21;
  border: 1px solid #293039;
  margin-bottom: 0.4rem;
  height: 55px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  //padding-top: 0.5rem;
  font-weight: 550;
`;

const RedText = styled.sup`
  color: darkred;
`;

const AvatarButton = styled.button`
  background-color: #fcd535;
  border: 1px solid #fcd535;
  padding: 10px 20px;
  cursor: pointer;
  width: 10rem;
  font-weight: 700;
  border-radius: 12px;
  //margin-top: 2rem;
  align-self: center;
  justify-self: flex-start;
  color: #191b21;
  align-content: center;
  //margin-left: 8rem;
  white-space: nowrap;

  &:hover {
    background-color: #ffff66;
  }
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
  display: none; // Hide the actual checkbox

  &:checked + label {
    background-color: #fcd535; // Yellow background
  }

  &:focus + label {
    box-shadow: 0 0 1px #2196f3; // Blue focus
  }

  &:checked + label:before {
    transform: translateX(1.5rem); // Move the slider
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
  background-color: #293039;
  border-radius: 1rem;
  transition: background-color 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: #1e2329;
    transition: transform 0.4s;
  }
`;

interface EditDomainMailModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const EditDomainMailModal: React.FC<EditDomainMailModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [switchState, setSwitchState] = useState(false);
  const [secondSwitch, setSecondSwitch] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the switch state at the given index
    setSwitchState(event.target.checked);
    console.log("Switch state 1 changed to:", event.target.checked);
  };

  const handleSecondSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSecondSwitch(event.target.checked);
    console.log("Switch state 2 changed to:", event.target.checked);
  };
  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <HeaderFAL style={{ fontSize: "32px" }}>Edit domain mail</HeaderFAL>
          <ContainerV style={{ width: "100%", paddingTop: "2rem" }}>
            <ContainerH style={{ width: "100%", margin: "0" }}>
              <GrayText>
                <strong>Включение/Отключение отправки писем для домена</strong>.
                <br />
                Если включено, то на почту пользователей будут отправляться
                письма при <br /> регистрации и восстановлении пароля с данного
                домена.
              </GrayText>
              <div>
                <Switch
                  id="switch1"
                  checked={switchState}
                  onChange={handleSwitchChange}
                />
                <SwitchLabel htmlFor="switch1"></SwitchLabel>
              </div>
            </ContainerH>
            <ContainerH style={{ width: "100%", margin: "0" }}>
              <GrayText>
                <strong>
                  Включение/Отключение обязательного подтверждения почты.
                </strong>
                <br />
                Если включено, то регистрация будет завершена только после
                подтверждения <br /> аккаунта по почте. Если выключено - то
                почту можно будет подтвердить позже, а <br /> у аккаунта
                пользователя будет статус [Email unconfirmed]
              </GrayText>
              <div>
                <Switch
                  id="switch2"
                  checked={secondSwitch}
                  onChange={handleSecondSwitchChange}
                />
                <SwitchLabel htmlFor="switch2"></SwitchLabel>
              </div>
            </ContainerH>
            <Label htmlFor="username">
              Server name <RedText>*</RedText>
            </Label>
            <InputField
              id="serverName"
              type="serverName"
              defaultValue="smpt.privateemail.com"
              // value={username}
              placeholder="Введите значение"
            />
            <Label htmlFor="port">
              Port <RedText>*</RedText>
            </Label>
            <InputField
              id="port"
              type="port"
              defaultValue={465}
              // value={username}
              placeholder="Введите значение"
            />

            <Label htmlFor="email">
              Email <RedText>*</RedText>
            </Label>
            <InputField
              id="email"
              type="email"
              defaultValue="support@cryptobines.com"
              // value={ip}
              // placeholder="Введите значение"
            />
            <Label htmlFor="password">
              Password <RedText>*</RedText>
            </Label>
            <InputField
              id="password"
              type="password"
              defaultValue="192.168.0.1 "
              // placeholder="Введите значение"
            />
            <AvatarButton style={{ height: "4rem" }}>Save</AvatarButton>
          </ContainerV>
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

export default EditDomainMailModal;
