import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import { HeadBlock } from "../forWorkerPage/WorkerBinding";
import AdminSupportTable from "../../styles/tables/forAdmin/AdminSupportTable";
import ContainerH from "../../styles/containers/ContainerH";
import { useGetChatPreviewListQuery } from "../../redux/apiService";
import Messenger from "./forSupport/Messager";

const AdminSupport: React.FC = () => {
  const { data: previewList, error, isLoading } = useGetChatPreviewListQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading profile: {error.toString()}</div>;
  }
  console.log("previewList data:", previewList);

  const testData = [
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-1",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-2",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 3,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-3",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-4",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-5",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 4,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-6",
      status: "Offline",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-7",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-8",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: 5,
    },
    {
      imagePath: "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: "Anonymous-User-9",
      date: "04/27",
      lastMessage: "mahmudjon21.khikmatov92@gmail.com ПОМОГИТЕ БЛЯДЬ СУКА",
      unreviewedCounter: null,
    },
  ];

  const formattedData =
    previewList?.body.data.map((chat: any) => ({
      imagePath:
        chat.imagePath ||
        "/assets/images/forpages/adminpanel/default-avatar 1.svg",
      user: chat.last_message.user.username || "",
      date: "",
      lastMessage: chat.last_message.message,
      unreviewedCounter: "",
      id: chat.id,
    })) || testData;

  return (
    <ContainerV
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: "0 5rem 0 5rem",
      }}
    >
      <HeadBlock style={{ marginTop: "7rem" }}>Support</HeadBlock>
      <ContainerH
        style={{ margin: "0", alignItems: "flex-start", height: "100%" }}
      >
        <AdminSupportTable data={formattedData} />
        <Messenger />
      </ContainerH>
    </ContainerV>
  );
};
export default AdminSupport;
