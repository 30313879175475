import React from "react";
import styled from "styled-components";
import ContainerH from "../../../containers/ContainerH";
import UserTransactionNav from "../../../navs/UserTransactionNav";
import HeaderFAL from "../../../headers/HeaderFAL";
import IndexButton from "../../../functional/buttons/IndexButton";
import DeleteButton from "../../../functional/buttons/DeleteButton";
import { GreenButton } from "../../../functional/buttons/RedGreenButtons";
import CancelButton from "../../../functional/buttons/CancelButton";

const BorderBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 5rem;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 3rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.25fr 0.5fr 1fr 0.5fr 0.5fr 2fr 1.5fr 2fr;
  gap: 1rem;
  align-items: center;
  min-width: 100%;
  width: 100%;

  &:hover {
    background-color: #1e2329;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
`;

const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848e9c;
`;

interface TableProps {
  data: any[]; // Массив объектов для отображения в таблице
}

const UserTransactionsTable: React.FC<TableProps> = ({ data }) => {
  return (
    <>
      <BorderBlock>
        <ContainerH
          style={{
            margin: "0",
            marginTop: "1rem",
            marginBottom: "1rem",
            //justifyContent: "flex-end",
          }}
        >
          <HeaderFAL style={{ fontSize: "32px" }}>Transactions</HeaderFAL>
          <UserTransactionNav initialActiveButton={1} />
        </ContainerH>
        <table>
          <thead>
            <TableRow style={{ borderBottom: "none" }}>
              <TableHeader>ID</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Coin</TableHeader>
              <TableHeader>Amount</TableHeader>
              <TableHeader>Address/Memo</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  <IndexButton text={item.status} />
                </TableCell>
                <TableCell>{item.coin}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.date}</TableCell>
                <TableCell>
                  {item.status === "Completed" || item.status === "Failed" ? (
                    <DeleteButton />
                  ) : (
                    <>
                      <GreenButton>Paid Out</GreenButton> <CancelButton />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </table>
      </BorderBlock>
    </>
  );
};
export default UserTransactionsTable;
