import React from "react";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import FavorOptions from "../../styles/navs/FavorOptions";
import {Outlet} from "react-router-dom";

const MarketsFavorite: React.FC = () => {

  return(
      <ContainerV>
          <ContainerH>
            <FavorOptions/>
          </ContainerH>
          <Outlet/>
      </ContainerV>
  );
};
export default MarketsFavorite;