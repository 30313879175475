import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
  `;

const ButtonOption =styled(Link)<{isActive: boolean}>`
  background-color: ${({isActive}) => (isActive ? "#293039" : "transparent")};
  color: ${({isActive}) => (isActive ? "#ffffff" : "#848E9C")};
  font-weight: ${({isActive}) => (isActive ? "700" : "500")};
  font-size: 14px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 1.5rem;
  padding: 5px;
  &:hover{
    color: white;
    //font-weight: 700;
    background-color: #293039 ;
  }
  
  
  `;

interface AllCryptosNavProps {
    initialActiveButton: number;
}
const AllCryptosNav: React.FC<AllCryptosNavProps> = ({initialActiveButton}) => {
    const [activeButton, setActiveButton] = useState(initialActiveButton);

    const handleButtonClick = (buttonNumber: number) => {
        setActiveButton(buttonNumber);
    };
    return(
        <OptionsContainer>
            <ButtonOption to="/markets/all-cryptos" isActive={activeButton === 1} onClick={() => handleButtonClick(1)}>
                Все
            </ButtonOption>
            <ButtonOption to="/markets/ai" isActive={activeButton === 2} onClick={() => handleButtonClick(2)}>
                AI<img src="/assets/images/forpages/marketpage/fire.svg" alt="Fire"/>
            </ButtonOption>
            <ButtonOption to="/markets/levels" isActive={activeButton === 3} onClick={() => handleButtonClick(3)}>
                Уровень 1 / Уровень 2
            </ButtonOption>
            <ButtonOption to="/markets/meta" isActive={activeButton === 4} onClick={() => handleButtonClick(4)}>
                Метавселенная
            </ButtonOption>
            <ButtonOption to="/markets/monitor" isActive={activeButton === 5} onClick={() => handleButtonClick(5)}>
                Мониторинг
            </ButtonOption>
            <ButtonOption to="/markets/seed" isActive={activeButton === 6} onClick={() => handleButtonClick(6)}>
                Seed
            </ButtonOption>
            <ButtonOption to="/markets/meme" isActive={activeButton === 7} onClick={() => handleButtonClick(7)}>
                Meme
            </ButtonOption>
            <ButtonOption to="/markets/liquid" isActive={activeButton === 8} onClick={() => handleButtonClick(8)}>
                Ликвидный стейкинг
            </ButtonOption>
            <ButtonOption to="/markets/favorites/options" isActive={activeButton === 9} onClick={() => handleButtonClick(9)}>
                Другое <img src="/assets/images/icons/grey-down-arrow.svg" alt=""/>
            </ButtonOption>
        </OptionsContainer>
    );
};
export default AllCryptosNav;