// IndexOnlineButton.tsx
import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
    text: string;
}

const StyledButton = styled.button<{ status: 'online' | 'offline'  }>`
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 6rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;

  //background-color: ${props => props.status === 'online' ? '#174238' : '#502530'};
  background-color: transparent;
  color: ${props => props.status === 'online' ? '#10BA7B' : '#F6465D'};
  //border: 1px solid ${props => props.status === 'online' ? '#10BA7B' : '#F6465D'};

`;

const IndexImage = styled.img`
    padding-right: 0.2rem;
`;

const IndexOnlineButton: React.FC<ButtonProps> = ({ text }) => {
    // Определяем статус кнопки на основе текста
    const status = text === 'Online'? 'online' : 'offline';

    return (
        <StyledButton
            status={status}
        >
            {status === 'online'? (

                <IndexImage src={'/assets/images/forpages/adminpanel/Ellipse_green.svg'}/>
                    ):(
                <IndexImage src={'/assets/images/forpages/adminpanel/Ellipse_red.svg'}/>

                )}
            {text}
        </StyledButton>
    );
};

export default IndexOnlineButton;
