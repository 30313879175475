import React from "react";
import { BorderBlock } from "../../forAccountPage/Deposits";
import ContainerV from "../../../styles/containers/ContainerV";
import UserProfileNav from "../../../styles/navs/UserProfileNav";
import HeaderALTH from "../../../styles/headers/HeaderALTH";
import styled from "styled-components";

const TitleLittle = styled.div`
  color: white;
  //margin-bottom: 20px;
  align-self: flex-start;
  font-size: 12px;
  text-align: left;
`;

const CustomInput = styled.input`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 10px;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
`;

const Addresses = () => {
  const adressData = [
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
    {
      coin: "BTC",
      adress: "bc1kdfksadf;11;kj1p;kj",
    },
  ];
  return (
    <>
      <UserProfileNav initialActiveButton={5} />
      <BorderBlock>
        <HeaderALTH style={{ paddingBottom: "1rem" }}>Addresses</HeaderALTH>
        <ContainerV>
          {adressData.map((item, index) => (
            <ContainerV key={index}>
              <TitleLittle>{item.coin}</TitleLittle>
              <CustomInput type="address" placeholder={item.adress} disabled />
            </ContainerV>
          ))}
        </ContainerV>
      </BorderBlock>
    </>
  );
};
export default Addresses;
