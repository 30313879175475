import React from "react";
import ContainerV from "../../styles/containers/ContainerV";
import {HeadBlock} from "../forWorkerPage/WorkerBinding";
import AdminDepositsTable from "../../styles/tables/forAdmin/AdminDepositsTable";

const AdminDeposits:React.FC = () => {
    const testData = [
        {
            transactionId: "6207632",
            user: "zhosikovich@mail.ru",
            worker: "111111@mail.ru",
            address: "LYWgaCcrL48xvmx7JtR5Bsfr4m2qcSZYkf",
            status: "Completed",
            coinType: "LTC",
            amount: "4.53732713",
            date: "2024/04/26 18:58:36"
        },
        {
            transactionId: "6207633",
            user: "example1@mail.ru",
            worker: "222222@mail.ru",
            address: "ExampleAddress1",
            status: "Pending",
            coinType: "BTC",
            amount: "0.12345678",
            date: "2024/04/27 19:59:37"
        },
        {
            transactionId: "6207634",
            user: "example2@mail.ru",
            worker: "333333@mail.ru",
            address: "ExampleAddress2",
            status: "Completed",
            coinType: "ETH",
            amount: "1.23456789",
            date: "2024/04/28 20:60:38"
        },
        {
            transactionId: "6207635",
            user: "example3@mail.ru",
            worker: "444444@mail.ru",
            address: "ExampleAddress3",
            status: "Failed",
            coinType: "XRP",
            amount: "0.00000001",
            date: "2024/04/29 21:61:39"
        },
        {
            transactionId: "6207636",
            user: "example4@mail.ru",
            worker: "555555@mail.ru",
            address: "ExampleAddress4",
            status: "Completed",
            coinType: "ADA",
            amount: "0.98765432",
            date: "2024/04/30 22:62:40"
        },
        {
            transactionId: "6207637",
            user: "example5@mail.ru",
            worker: "666666@mail.ru",
            address: "ExampleAddress5",
            status: "Pending",
            coinType: "DOGE",
            amount: "0.76543210",
            date: "2024/05/01 23:63:41"
        },
        {
            transactionId: "6207638",
            user: "example6@mail.ru",
            worker: "777777@mail.ru",
            address: "ExampleAddress6",
            status: "Completed",
            coinType: "LINK",
            amount: "0.54321098",
            date: "2024/05/02 00:64:42"
        },
        {
            transactionId: "6207639",
            user: "example7@mail.ru",
            worker: "888888@mail.ru",
            address: "ExampleAddress7",
            status: "Failed",
            coinType: "UNI",
            amount: "0.32109876",
            date: "2024/05/03 01:65:43"
        },
        {
            transactionId: "6207640",
            user: "example8@mail.ru",
            worker: "999999@mail.ru",
            address: "ExampleAddress8",
            status: "Pending",
            coinType: "SOL",
            amount: "0.98765432",
            date: "2024/05/04 02:66:44"
        }
    ];
  return  (
      <ContainerV style={{ alignSelf: "flex-start", justifyContent: "flex-start", padding:"0 5rem 0 5rem"}}>
          <HeadBlock>Deposits</HeadBlock>
          <AdminDepositsTable data={testData}/>
      </ContainerV>
      );
};
export default AdminDeposits;