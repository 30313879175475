import React from "react";
import styled from "styled-components";
import ContainerV from "../../styles/containers/ContainerV";
import ContainerH from "../../styles/containers/ContainerH";
import BlockGreyText from "../../styles/blosks/BlockGreyText";

export const HeadBlock = styled.div`
  width: 90rem;
  height: 5rem;
  border-radius: 20px;
  background-color: #0e0e11;
  color: white;
  font-weight: 700;
  font-size: 32px;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  margin-top: 9rem;
`;

const BorderBlock = styled.div`
  background-color: #191b21;
  border: 1px solid #293039;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Title = styled.h2`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
`;
const TitleLittle = styled.h5`
  color: white;
  margin-bottom: 20px;
  align-self: flex-start;
`;

const EmailInput = styled.input`
  background-color: #191b21;
  border: none;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-self: flex-start;
`;

const SubmitButton = styled.button`
  background-color: #fcd535;
  color: #191b21;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-top: 2rem;

  &:hover {
    background-color: #ffff66;
  }
`;

export const GreyButton = styled.button`
  background-color: #2f323c;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10rem;

  &:hover {
    background-color: #3c404c;
  }
`;

const WorkerBinding: React.FC = () => {
  return (
    <>
      <ContainerV
        style={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          padding: "0 5rem 0 5rem",
        }}
      >
        <HeadBlock>Binding</HeadBlock>
        <ContainerH
          style={{
            gap: "3rem",
            maxWidth: "100%",
          }}
        >
          <ContainerV
            style={{ justifyContent: "flex-start", alignSelf: "flex-start" }}
          >
            <BorderBlock
              style={{
                width: "100%",
              }}
            >
              <Title>Hand Binding</Title>
              <BorderBlock
                style={{
                  width: "100%",
                }}
              >
                <TitleLittle>E-mail</TitleLittle>
                <EmailInput type="email" placeholder="Введите адрес почты" />
              </BorderBlock>
              <SubmitButton>Bind</SubmitButton>
            </BorderBlock>
            <BorderBlock>
              <Title>Hand Binding</Title>
              <BorderBlock
                style={{
                  width: "100%",
                }}
              >
                <TitleLittle>E-mail</TitleLittle>
                <EmailInput type="email" placeholder="Введите адрес почты" />
              </BorderBlock>
              <BlockGreyText>
                Пользователи, которые зарегистрируются, используя ваш домен,
                будут автоматически прикреплены к вашему аккаунту
              </BlockGreyText>
            </BorderBlock>
          </ContainerV>
          <BorderBlock
            style={{
              width: "100%",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Title>Promo Binding</Title>
            <BorderBlock
              style={{
                width: "100%",
              }}
            >
              <TitleLittle>PROMO</TitleLittle>
              <ContainerH>
                <EmailInput
                  type="text"
                  placeholder="Пожалуйста, введите PROMO CODE"
                  style={{ justifySelf: "flex-end", width: "25rem" }}
                />
                <GreyButton style={{ justifySelf: "flex-end" }}>
                  Random
                </GreyButton>
              </ContainerH>
            </BorderBlock>
            <BlockGreyText>
              Промокод, активировав который, юзер получит указанный ниже бонус и
              привяжется к вашему аккаунту
            </BlockGreyText>
            <BorderBlock>
              <TitleLittle>Promo Amount</TitleLittle>
              <BlockGreyText>0,01</BlockGreyText>
            </BorderBlock>
            <BorderBlock>
              <TitleLittle>First deposit bonus %</TitleLittle>
              <BlockGreyText>0,01</BlockGreyText>
            </BorderBlock>
            <BlockGreyText>
              Размер бонуса в % от его первого депозита. Необязательно для
              заполнения.
            </BlockGreyText>
            <BorderBlock>
              <TitleLittle>Text After Activation</TitleLittle>
              <BlockGreyText>
                Your PROMO-CODE has been successfully activated!
              </BlockGreyText>
            </BorderBlock>
            <SubmitButton>Add promo</SubmitButton>
          </BorderBlock>
        </ContainerH>
      </ContainerV>
    </>
  );
};
export default WorkerBinding;
