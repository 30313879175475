import styled from "styled-components";


const CustomInput = styled.input`
  font-size: 16px;
  border: 1px solid #2F323C;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  background-color: #191B21;
  width: 340px;
  height: 60px;
  align-self: center;
  margin-right: 2rem;
  padding: 10px;

  //&:focus {
  //  border-color: #3498db;
  //  box-shadow: 0 0 5px rgba(52, 152, 219, 0.7);
  //}
`;

export default CustomInput;
