import React, { useState } from "react";
import styled from "styled-components";
import ContainerV from "../../../styles/containers/ContainerV";
import CustomRadioButton from "../../../styles/functional/buttons/CustomRadioButton";
import ContainerH from "../../../styles/containers/ContainerH";
import HeaderFAL from "../../../styles/headers/HeaderFAL";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  width: 646px;
  height: 856px;
  background-color: #1e2329;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #848e9c;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
`;

const InputField = styled.input`
  width: 100%;
  min-width: 100%;
  padding: 10px;
  border-radius: 12px;
  color: white;
  background-color: #191b21;
  border: 1px solid #293039;
  margin-bottom: 1rem;
  height: 55px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  //padding-top: 0.5rem;
  font-weight: 550;
`;

const GreyButton = styled.button`
  background-color: #2f323c;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: #ffffff;
  border: none;
  //padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  height: 2rem;
  z-index: 1001;
  margin-left: 0.5rem;

  &:hover {
    background-color: #3c404c;
  }
`;

const RedText = styled.sup`
  color: darkred;
`;

const AvatarButton = styled.button`
  background-color: #fcd535;
  border: 1px solid #fcd535;
  padding: 10px 20px;
  cursor: pointer;
  width: 10rem;
  font-weight: 700;
  border-radius: 12px;
  //margin-top: 2rem;
  align-self: center;
  justify-self: flex-start;
  color: #191b21;
  align-content: center;
  //margin-left: 8rem;
  white-space: nowrap;

  &:hover {
    background-color: #ffff66;
  }
`;

const IconInputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

interface EditDomainModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const EditDomainModal: React.FC<EditDomainModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [username, setUsername] = useState<string>("");
  const [ip, setIp] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("option1");

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <HeaderFAL style={{ fontSize: "32px" }}>Edit my domain</HeaderFAL>
          <ContainerV style={{ width: "100%", paddingTop: "2rem" }}>
            <Label htmlFor="username">
              Domain <RedText>*</RedText>
            </Label>
            <InputField
              id="username"
              type="text"
              // defaultValue={profile?.body.username}
              value={username}
              placeholder="Введите значение"
              onChange={(e) => setUsername(e.target.value)}
            />

            <Label htmlFor="logo">
              Domain logo <RedText>*</RedText>
            </Label>
            <IconInputContainer>
              <GreyButton>Icon</GreyButton>
              <InputField
                id="logo"
                type="logo"
                style={{
                  paddingLeft: "3.5rem",
                  minWidth: "0",
                  width: "70%",
                  justifySelf: "flex-start",
                  margin: "0",
                }}
                defaultValue="icon2231_1313.svg"
              />
              <AvatarButton>
                <img
                  src="/assets/images/forpages/accountpage/Upload_Icon.svg"
                  alt="Download"
                  style={{ marginRight: "0.5rem" }}
                />
                Upload
              </AvatarButton>
            </IconInputContainer>

            <Label htmlFor="ip">
              Domain little <RedText>*</RedText>
            </Label>
            <InputField
              id="ip"
              type="text"
              // defaultValue="192.168.0.1 "
              value={ip}
              placeholder="Введите значение"
              onChange={(e) => setIp(e.target.value)}
            />
            <Label htmlFor="ip">
              Exchange name <RedText>*</RedText>
            </Label>
            <InputField
              id="ip"
              type="text"
              // defaultValue="192.168.0.1 "
              value={ip}
              placeholder="Введите значение"
              onChange={(e) => setIp(e.target.value)}
            />
            <ContainerH>
              <CustomRadioButton
                label="Option 1"
                isSelected={selectedOption === "option1"}
                onClick={() => setSelectedOption("option1")}
              />
              <CustomRadioButton
                label="Option 2"
                isSelected={selectedOption === "option2"}
                onClick={() => setSelectedOption("option2")}
              />
              <CustomRadioButton
                label="Option 3"
                isSelected={selectedOption === "option3"}
                onClick={() => setSelectedOption("option3")}
              />
            </ContainerH>
            <AvatarButton style={{ marginTop: "4rem", height: "4rem" }}>
              Save
            </AvatarButton>
          </ContainerV>
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

export default EditDomainModal;
