import React from "react";
import BasicDiv from "../../styles/blosks/BasicDiv";
import TradeInfTable from "../../styles/tables/TradeInfTable";
import ContainerH from "../../styles/containers/ContainerH";
import ContainerV from "../../styles/containers/ContainerV";
import HeaderALTH from "../../styles/headers/HeaderALTH";

const TradeInfTables: React.FC = () => {
    const BTC = "BTC";
    const ETH = "ETH";
    const iconBTC = "/assets/images/cryptoicons/BTC.svg";
    const iconETH = "/assets/images/cryptoicons/ETH.svg";
    const courseBTC = "0.5 BTC";
    const courseETH = "6,616.21 ETH";

  return(
    <BasicDiv style={{paddingTop:"15rem"}}>
        <ContainerH>
            <ContainerV>
                <HeaderALTH>С BTC на ETH</HeaderALTH>
                <TradeInfTable text={BTC} text_second={ETH} img_adress_1={iconBTC} img_adress_2={iconETH} course_1={courseBTC} course_2={courseETH}/>
            </ContainerV>
            <ContainerV>
                <HeaderALTH>С ETH на BTC</HeaderALTH>
                <TradeInfTable text={ETH} text_second={BTC} img_adress_1={iconETH} img_adress_2={iconBTC} course_1={courseETH} course_2={courseBTC}/>
            </ContainerV>
        </ContainerH>
    </BasicDiv>
  );
};
export default TradeInfTables;