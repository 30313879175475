import React from "react";
import styled from "styled-components";
import ButtonStat from "../functional/buttons/ButtonStat";

const ContainerTradeInf = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #293039;
  border-radius: 20px;
  color: #848E9C;
  padding: 1.5rem;
  width: 100%;

    `;

const RowTradeInf = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;  
  position: relative;

  &:first-child {
    margin-top: 0;
  }
  
  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: -1rem; 
    left: 1rem; /* Отступ слева */
    width: 95%; /* Ширина линии */
    height: 2px;
    background-color: #293039;
  }
  
  
    
  `;

const CellTradeInf = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    margin: 0.5rem;
    
  
  `;

interface TradeInfTableText {
    text: string;
    text_second:string;
    img_adress_1: string;
    img_adress_2: string;
    course_1: string;
    course_2: string;
}
const TradeInfTable: React.FC<TradeInfTableText> = ({text, text_second,img_adress_1, img_adress_2,course_1, course_2}) => {

    const day ="24ч.";
    const month = "1 мес.";
    const threeMonth = "3 мес.";
    const value = " +0.3%";


    return<>
      <ContainerTradeInf>
        <RowTradeInf>
            <CellTradeInf>
                <img src={img_adress_1} alt="icon"/>
            </CellTradeInf>
            <CellTradeInf>
                {text}
            </CellTradeInf>
            <CellTradeInf>
                <img src={img_adress_2} alt="icon"/>
            </CellTradeInf>
            <CellTradeInf>
                {text_second}
            </CellTradeInf>
            <CellTradeInf>
                <ButtonStat period={day} value={value}/>
                <ButtonStat period={month} value={value}/>
                <ButtonStat period={threeMonth} value={value}/>
            </CellTradeInf>
        </RowTradeInf>
        <RowTradeInf>
            <CellTradeInf>
                {course_1}
            </CellTradeInf>
            <CellTradeInf>
                {course_2}
            </CellTradeInf>
        </RowTradeInf>
          <RowTradeInf>
              <CellTradeInf>
                  {course_1}
              </CellTradeInf>
              <CellTradeInf>
                  {course_2}
              </CellTradeInf>
          </RowTradeInf>
          <RowTradeInf>
              <CellTradeInf>
                  {course_1}
              </CellTradeInf>
              <CellTradeInf>
                  {course_2}
              </CellTradeInf>
          </RowTradeInf>
          <RowTradeInf>
          <CellTradeInf>
              {course_1}
          </CellTradeInf>
          <CellTradeInf>
              {course_2}
          </CellTradeInf>
      </RowTradeInf>
          <RowTradeInf>
          <CellTradeInf>
              {course_1}
          </CellTradeInf>
          <CellTradeInf>
              {course_2}
          </CellTradeInf>
      </RowTradeInf>
      </ContainerTradeInf>
  </>;
};
export default TradeInfTable;