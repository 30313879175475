import React from "react";
import styled from "styled-components";

const CustomTableContainer = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
`;

interface TableCellProps {
    isImage?: boolean;
    isThirdFromStart?: boolean;
}

const TableCell = styled.div<TableCellProps>`
  padding: 8px;
  text-align: left;
  align-self: center;
  white-space: nowrap;
  font-weight: 700;
  ${({ isThirdFromStart }) => isThirdFromStart && `
          color: #848E9C;
          font-weight: 500; 
          margin-left: -1rem;
    `}
  &.red-text {
    color: #F6465D;
  }

  &.green-text {
    color: #10BA7B;
  }
`;

const Image = styled.img`
  margin-right: 0.5rem;
`;

const GrowthImage = styled.img`
  width: 20px;
  margin-left: 5px;
`;

const FallImage = styled.img`
  width: 20px;
  margin-left: 5px;
`;

interface CryptoData {
    image: string;
    abbreviation: string;
    name: string;
    price: string;
    growth: string;
}

interface TCTableProps {
    data: CryptoData[];
}

const TCTable: React.FC<TCTableProps> = ({ data }) => {
    return (
        <CustomTableContainer>
            {data.map((crypto, index) => (
                <React.Fragment key={index}>
                    <TableCell isImage>
                        <Image src={crypto.image} alt="" />
                    </TableCell>
                    <TableCell>{crypto.abbreviation}</TableCell>
                    <TableCell isThirdFromStart>{crypto.name}</TableCell>
                    <TableCell>{crypto.price}</TableCell>
                    <TableCell className={crypto.growth.includes("-") ? "red-text" : "green-text"}>
                        {crypto.growth} {crypto.growth.includes("-") ? <FallImage src="/assets/images/icons/fall.svg" alt="" /> : <GrowthImage src="/assets/images/icons/growth.svg" alt="" />}
                    </TableCell>
                    <TableCell></TableCell>
                </React.Fragment>
            ))}
        </CustomTableContainer>
    );
};

export default TCTable;
