import React from "react";
import {HeadBlock} from "./WorkerBinding";
import ContainerV from "../../styles/containers/ContainerV";
import FiveBlocksAtTop from "./forWorkerDepositCoins/FiveBlocksAtTop";
import MoneyForDeposit from "./forWorkerDepositCoins/MoneyForDeposit";
import WorkerAddedCoinsTable from "../../styles/tables/forWorker/WorkerAddedCoinsTable";

const WorkerDepositCoins:React.FC = () => {
    const TestData = [
        {
            id: 50,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            coin: "ETH",
            amount: 0.0005,
            switch: true // Assuming the switch is off by default
        },
        {
            id: 51,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            coin: "ETH",
            amount: 0.0005,
            switch: false // Assuming the switch is off by default
        },
        {
            id: 52,
            imagePath: "/assets/images/forpages/accountpage/GreenETH.svg",
            coin: "ETH",
            amount: 0.0005,
            switch: true // Assuming the switch is off by default
        },
    ];
  return <>
      <ContainerV
          style={{
              alignSelf: "flex-start",
              justifyContent: "flex-start",
              padding:"0 5rem 0 5rem"
      }}>
          <HeadBlock>Deposit coins</HeadBlock>
          <FiveBlocksAtTop/>
          <MoneyForDeposit/>
          <WorkerAddedCoinsTable data={TestData}/>
      </ContainerV>
  </>;
};
export default WorkerDepositCoins;