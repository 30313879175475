import React from "react";
import styled from "styled-components";

interface Message {
  id: string;
  message: string;
  user: {
    id: string;
    username: string;
    first_name: string | null;
    last_name: string | null;
    email: string;
    avatar: string | null;
  };
  is_read: boolean;
  is_support: boolean;
  created_at: string;
  updated_at: string;
}

interface MessagesListProps {
  messages: Message[];
  currentUserId: string; // id текущего пользователя
}

const MessageContainer = styled.div<{ isCurrentUser: boolean }>`
  display: flex;
  justify-content: ${({ isCurrentUser }) =>
    isCurrentUser ? "flex-end" : "flex-start"};
  margin: 10px 0;
`;

const MessageBox = styled.div<{ isCurrentUser: boolean }>`
  background-color: ${({ isCurrentUser }) =>
    isCurrentUser ? "#a9a9a9" : "#808080"};
  border-radius: 10px;
  padding: 10px;
  max-width: 60%;
  min-width: 30%;
  word-wrap: break-word;
`;

const MessagesList: React.FC<MessagesListProps> = ({
  messages,
  currentUserId,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {messages.map((msg) => (
        <MessageContainer
          key={msg.id}
          isCurrentUser={msg.user.id === currentUserId}
        >
          <MessageBox isCurrentUser={msg.user.id === currentUserId}>
            <p>{msg.message}</p>
            <small>{msg.user.username}</small>
          </MessageBox>
        </MessageContainer>
      ))}
    </div>
  );
};

export default MessagesList;
