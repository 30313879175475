import React from "react";
import styled from "styled-components";
import IndexOnlineButton from "../../functional/buttons/IndexOnlineButton";
import DeleteButton from "../../functional/buttons/DeleteButton";

const BorderBlock  = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #191B21;
    border: 1px solid #293039;
    border-radius: 1rem;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 5rem;
    margin-right: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
    min-width: 100%;
`;


const TableHeader = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #848E9C;
  max-width: 9rem;
`;

const TableRow = styled.tr`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto repeat(9,1fr); 
  gap: 1rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #293039;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  flex: 1;
  padding: 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  max-width: 9rem;
`;

const AvatarImg = styled.img`
  height: 62px;
  width: 62px;
  padding-right: 1rem;
`;

interface TableProps {
    data: any[]; // Массив объектов для отображения в таблице
}


const AdminDomainsTable:React.FC<TableProps> = ({ data }) => {

    return (
        <BorderBlock>
            <table>
                <TableRow style={{borderBottom:"none"}}>
                    <TableHeader style={{width:"3rem"}}>User</TableHeader>
                    <TableHeader ></TableHeader>
                    <TableHeader>Username</TableHeader>
                    <TableHeader>Password</TableHeader>
                    <TableHeader>Users</TableHeader>
                    <TableHeader>Deposits</TableHeader>
                    <TableHeader>Registered</TableHeader>
                    <TableHeader>Domains</TableHeader>
                    <TableHeader>Last activity</TableHeader>
                    <TableHeader>Action</TableHeader>

                </TableRow>
                <tbody>
                {data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell style={{width:"3rem"}}>
                            <AvatarImg src={item.imagePath} alt="Avatar" />
                        </TableCell>
                        <TableCell>
                            {item.user}
                            <IndexOnlineButton text = {item.status}/>
                        </TableCell>
                        <TableCell>{item.username}</TableCell>
                        <TableCell>{item.password}</TableCell>
                        <TableCell>{item.users}</TableCell>
                        <TableCell>{item.deposits}</TableCell>
                        <TableCell>{item.registered}</TableCell>
                        <TableCell>{item.domain}</TableCell>
                        <TableCell>{item.lastActivity}</TableCell>
                        <TableCell><DeleteButton/></TableCell>

                    </TableRow>
                ))}
                </tbody>
            </table>
        </BorderBlock>
    );
};
export default AdminDomainsTable;