import React from 'react';
import styled from 'styled-components';

const AccountStatTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  width: 100%;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: auto auto repeat(3, 1fr);
  gap: 2rem 0;
  padding: 10px;
  min-width: 100%;
  align-items: center;

  &:hover {
    background-color: #1E2329;
  }
`;

interface TableCellProps {
    isImage?: boolean;
    isSecondFromStart?: boolean;
    isThird?: boolean;
    isFourth?: boolean;
}

const TableCell = styled.div<TableCellProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ isThird }) => (isThird ? 'flex-start' : 'center')};
  padding: 8px;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;

  ${({ isImage }) =>
          isImage &&
          `
    grid-column: 1;
  `}

  ${({ isSecondFromStart }) =>
          isSecondFromStart &&
          `
    color: #848E9C;
    font-weight: 500;
    margin-right: 12rem;
    padding-left: 0;
  `}

  ${({ isThird }) =>
          isThird &&
          `
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  ${({ isFourth }) =>
          isFourth &&
          `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.red-text {
      color: #F6465D;
    }

    &.green-text {
      color: #10BA7B;
    }

    .arrow-icon {
      width: 20px;
      margin-left: 5px;
    }
  `}
`;

const Image = styled.img`
  width: 30px;
  margin-right: 0.5rem;
`;

const ImageSmall = styled.img`
  margin-left: 10rem;
`;

const ArrowIcon = styled.img`
  width: 20px;
  margin-left: 7px;
`;

const GrayText = styled.span`
  color: #848E9C;
`;

interface AccountPopularProps {
    data: {
        image: string;
        cells: string[];
        image5: string;
        grayText?: string;
    }[];
}

const AccountPopularTable: React.FC<AccountPopularProps> = ({ data }) => {
    return (
        <AccountStatTableContainer>
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => (
                        <TableCell
                            key={cellIndex}
                            isImage={cellIndex === 0}
                            isSecondFromStart={cellIndex === 1}
                            isThird={cellIndex === 2}
                            isFourth={cellIndex === 3}
                            className={cellIndex === 3 ? (cell.includes('-') ? 'red-text' : 'green-text') : ''}
                        >
                            {cellIndex === 0 && <Image src={row.image} alt="Image" />}
                            {cellIndex === 4 && <ImageSmall src={cellIndex === 4 ? row.image5 : ''} alt={`Image ${cellIndex}`} />}
                            {cellIndex === 2 && (
                                <>
                                    <span>{cell}</span>
                                    <GrayText>{row.grayText || 'Серый текст по умолчанию'}</GrayText>
                                </>
                            )}
                            {cellIndex === 3 && (
                                <>
                                    {cell}
                                    <ArrowIcon
                                        className="arrow-icon"
                                        src={cell.includes('-') ? '/assets/images/icons/fall.svg' : '/assets/images/icons/growth.svg'}
                                        alt="Arrow"
                                    />
                                </>
                            )}
                            {cellIndex !== 2 && cellIndex !== 3 && <span>{cell}</span>}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </AccountStatTableContainer>
    );
};

export default AccountPopularTable;
