import styled from "styled-components";

const BlockH = styled.div`
    display: flex;
    flex: 1;
    color: #fff;
    margin-right: 1rem;
    align-items: center;
    align-content: center;
    white-space: nowrap;
    
    `;
export default BlockH;